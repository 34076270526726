import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { HeaderComponent } from '../header/header.component';
import { DataTableDirective } from 'angular-datatables';
// import "rxjs/add/operator/map";
import { DomSanitizer } from '@angular/platform-browser';
// import * as XLSX from 'xlsx'; 
import { ExcelService } from '../_service/excel.service';
import { DatePipe } from '@angular/common';


// import {MatExpansionModule} from '@angular/material/expansion'; 

import { IDropdownSettings } from 'src/ng-multiselect-dropdown/src';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { timeStamp } from 'console';
import { resolve } from 'path';
//import { ConsoleReporter } from 'jasmine';
@Component({
  selector: 'app-view-farmer',
  templateUrl: './view-farmer.component.html',
  styleUrls: ['./view-farmer.component.css']
})


export class ViewFarmerComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  startpage: any = 1;
  premiumData: any[] = [];
  page: any = '1';
  pageSize = 10;
  defaultPagination = '1';
  defaultLimit = '10';
  datatableElement: DataTableDirective;
  dataArray = [];
  dtOptions: DataTables.Settings = {};
  clickEventsubscription: Subscription;
  dtTrigger: Subject<any> = new Subject();
  panelOpenState = false;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  dams: any = [];
  projects: any = [];

  selectedItems: any = [];
  dropdownSettings: any = {};
  level1Form: FormGroup;
  searchForm: FormGroup;
  public permissions = [];
  public filteredList = this.permissions.slice();
  selectedItemsRoot: any[];
  typeArrValues: string;
  typeArr: any = [];
  projectNameArr: any = [];
  projectList: any;
  projectList1: any;
  damNameArr: any;
  typeArrDams: any;
  projectGisCode: any;
  damSelect: any[];
  project_gis_no
  damsSelect
  wuaArray
  getDistArray
  getFarmerTaluka
  getFarmerVillage
  user_type

  CoprationArr: any[];
  RegionArr: any[];
  CircleArr: any[];
  DivisionArr: any[];
  SubDivArr: any[];
  SectionArr: any[];
  userPosition: any;
  userOfficeType: string;
  office_details_en: any = {};
  office_details_mr: any = {};
  MantraId: any;
  circleId: any;
  subDivisId: any;
  RegionId: any;
  DivisionId: any;
  CorporationId: any;
  SectionId: any;
  showCir: boolean = false;
  showDiv: boolean = false;
  showSub: boolean = false;
  showSec: boolean = false;
  showReg: boolean = false;
  showCor: boolean = false;
  showAdvSearchDiv: boolean = true;
  OfficeId: any = null;
  officeType: any = null
  cor: string;
  enableAddOption: boolean = false
  enableEditOption: boolean = false
  enableApproveOption: boolean = false
  count: any;
  project_unique_gis: any;
  dam_unique_code: any;
  wua_id: any;
  district_id: any;
  farmer_taluka: any;
  farmer_village: any;
  temp_export_array: any;
  export_array: any;
  FarmerDetails: any;

  //datepipe: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    public translate: TranslateService,
    private sharedService: SharedService,
    private ngxService: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private excelService: ExcelService,
    public datepipe: DatePipe,
    public cdr: ChangeDetectorRef
  ) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.selectedlang = localStorage.getItem('selected_lang');
      //  alert(this.selectedlang)
      //  $('#dd').dataTable().destroy();
      //  $('#dd').DataTable().clear();
      this.dtOptions = {};
      if (this.selectedlang == 'mr') {
        // alert("lll")
        // $('#dd').dataTable( {"oLanguage": 'english'} );

        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 10,
          language: HeaderComponent.marathi_datatables

        };
        // this.cdr.detectChanges();
      } else {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 10,
          language: HeaderComponent.english_datatables

        };

      }
      this.getDistrict();
      // $('#dd').DataTable().ajax.reload();
      // $('#dd').dataTable().destroy();
      // $('#documentsTable tbody').empty();
      this.ngOnInit();
      // console.log(localStorage.getItem('user_type'))
      // this.user_type = localStorage.getItem('user_type')
      // this.ngAfterViewInit();



    })


    this.groupData = this.organise(this.excelData);
  }



  selectedlang = localStorage.getItem('selected_lang');
  data: any = {};
  fileUrl;
  en_data: any = {
    farmer: "Farmer Master",
    add: "Add",
    project: "Project",
    dam: "Dam",
    wua: "WUA",
    gut_no: "GUT No.",
    Area_of_gut: " Area of Gut(Ha)",
    owned_area: "Owned Area Excl Pot Kharab (Ha)",
    owned_pot_area: "Owned Pot Kharab Area(Ha)",
    farmer_name: "Farmer Name",
    Farmer_Mobile: "Farmer Mobile Number",
    Farmer_District: "District",
    Farmer_Taluka: "Taluka",
    Farmer_Village: "Village",
    Farmer_project: "Project Name",
    Farmer_dam: "Dam Name",
    Farmer_irrigation: "Irrigation Source",
    Wua_details: "WUA Details",
    Action: "Action",
    select_all: "Select All",
    farmer_approved: "Farmer Approved",

    select: "Select",
    save: "Save",
    Ok: "OK",
    cancel_string: "Cancel",
    searchPlaceholderText: "Search",
    un_select_all: "UnSelect All",
  }
  mr_data: any = {
    farmer: "शेतकऱ्यांची माहिती",
    add: "माहिती भरा",
    farmer_name: "शेतकऱ्याचा नाव",
    Farmer_Mobile: "शेतकऱ्याचा मोबाईल क्र.",
    Farmer_District: "जिल्हा",
    Farmer_Taluka: "तालुका",
    Farmer_Village: "गाव",
    Farmer_project: "प्रकल्पाचे",
    Farmer_dam: "धरणाचे",
    Farmer_irrigation: "सिंचनाचा",
    Wua_details: "पाणी वापर संस्था",
    Action: "कृती",
    project: "प्रकल्पाचे नाव",
    dam: "धरणाचे नाव",
    wua: "पाणी वापर संस्थेचे  नाव",
    gut_no: "गट क्र. ",
    Area_of_gut: "गट क्षेत्र(हे)",
    owned_area: "स्वतःची मिळकत (पोट खराब वगळून)",
    owned_pot_area: "पोट खराब क्षेत्र(हे)",
    select_all: "सर्व पर्याय निवडा",
    select: "निवडा",
    save: "जतन करा",
    Ok: "होय",
    un_select_all: "निवडलेले सर्व काढा",
    farmer_approved: "शेतकऱ्याचा मान्यता",
    cancel_string: "नाही",
    searchPlaceholderText: "शोधा"





  }
  // enableApproveOption: boolean = false
  FirstKey: string;
  userOfficeId: any;
  userId: String


  groupData: any;
  name = 'Angular';
  excelData: any = [];

  ngOnInit(): void {

    this.userId = localStorage.getItem('post_user_id')

    this.getDataOnChangeMarathi();
    this.getUserDetails();
    this.getDistrict();
    this.level1Form = this.fb.group({
      "farmer_master_project_unique_gis": new FormControl(null),
      "farmer_master_dam_unique_code": new FormControl(null),
      "farmer_master_wua_id": new FormControl(null),
      "farmer_master_farmer_district_id": new FormControl(null),
      "farmer_master_farmer_taluka": new FormControl(null),
      "farmer_master_farmer_village": new FormControl(null)
    });
    this.searchForm = this.fb.group({
      "farmer_master_search": new FormControl(null)
    })
    this.projects = [
    ];
    this.getProjectList();

    // alert("ngOnInit")

    // console.log(this.selectedlang, '=====')
    switch (this.selectedlang) {
      case "en":
        this.data = this.en_data;
        break;
      case "mr":
        this.data = this.mr_data;
        break;
    }


    if (this.selectedlang == 'mr') {

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.marathi_datatables

      };
      // this.cdr.detectChanges();
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.english_datatables

      };

    }
    //  this.ngAfterViewInit();


    // this.dams = [
    // ];


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      // selectAllText: 'Select All',
      selectAllText: this.data.select_all,
      unSelectAllText: this.data.un_select_all,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: this.data.searchPlaceholderText,
      noDataAvailablePlaceholderText: this.selectedlang == 'en' ? 'No data available' : 'माहिती उपलब्ध नाही',
    };
    // this.getUserOfficeDetails();

  }

  search() {

    this.ngxService.start();

    var pageS = this.pageSize.toString();
    let post_user_id = localStorage.getItem('post_user_id');

    const body = new HttpParams({
      fromObject: {
        search: this.searchForm.value.farmer_master_search,
        post_user_id: post_user_id,
        farmer_master_project_unique_gis: this.level1Form.value.farmer_master_project_unique_gis,
        farmer_master_dam_unique_code: this.level1Form.value.farmer_master_dam_unique_code,
        farmer_master_wua_id: this.level1Form.value.farmer_master_wua_id,
        farmer_master_farmer_district_id: this.level1Form.value.farmer_master_farmer_district_id,
        farmer_master_farmer_taluka: this.level1Form.value.farmer_master_farmer_taluka,
        farmer_master_farmer_village: this.level1Form.value.farmer_master_farmer_village,
        user_office_id: this.userOfficeId,
        user_post: this.userPosition,
        page: this.page,
        limit: pageS,
        user_type: localStorage.getItem('user_type'),
      }
    })
    this.http.farmersListings(body).subscribe((data: any) => {
      // console.log(this.dataArray)
      // alert("Checking")
      this.dataArray = data.data;
      this.count = data.datacount;
      // this.dtTrigger.next();
      this.ngxService.stop();
    })
  }

  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        this.FirstKey = key;
        break;
      }
      this.userOfficeId = resolve.data.userDetail.user_office_id;

      this.userPosition = resolve.data.userDetail.user_post
      // console.log(this.userPosition + "==========" + this.userOfficeType);
      this.user_type = resolve.data.userDetail.user_type

      if ((this.userOfficeType == "Section" && !this.userPosition.includes("Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer") && !this.userPosition.includes("Peon"))) {
        // console.log(this.userOfficeType + "=============" + this.userPosition);

        this.enableAddOption = true
        // this.enableEditOption = true
      }

      if ((this.userOfficeType == "Section" && !this.userPosition.includes("Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer") && !this.userPosition.includes("Peon"))) {
        // console.log(this.userOfficeType + "=============" + this.userPosition);

        // this.enableAddOption = true
        this.enableEditOption = true
      }
      console.log(this.enableEditOption)

      if (this.userOfficeType == "Section" && this.userPosition.includes("Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer")) {
        this.enableApproveOption = true
      }

      // console.log(this.enableEditOption + "===============")
      //this.userOfficeType ='Corporation';
      if (this.userOfficeType == 'Division') {

        var parent_id = resolve.data.userDetail.user_office_detail.Circle.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Sub Division') {


        var parent_id = resolve.data.userDetail.user_office_detail.Division.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Circle') {

        var parent_id = resolve.data.userDetail.user_office_detail.Region.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      else if (this.userOfficeType == 'Region') {

        var parent_id = resolve.data.userDetail.user_office_detail.Corporation.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Corporation') {

        parent_id = '1';
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Section') {
        const subDivision = "Sub Division";
        var parent_id = resolve.data.userDetail.user_office_detail?.subDivision?.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      if (this.userOfficeType == "Division") {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == "Circle") {

        this.showCir = false;

        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;

        this.showReg = false;
        this.showCor = false;

      } else if (this.userOfficeType == "Mantralaya") {

        // this.showCir =false;
        // this.showDiv =false;
        // this.showSub =true;
        // this.showSec =true;
        // this.showReg =false;
        // this.showCor = false;

      } else if (this.userOfficeType == "Region") {


        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;
      } else if (this.userOfficeType == 'Sub Division') {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == 'Corporation') {

        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = true;
        this.showCor = false;

      }
      else {


        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = false;
        this.showReg = false;
        this.showCor = false;


      }
      this.getData();

    });
  }

  approveFarmer(farmerId, Status) {
    // console.log(farmerId)
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
        farmer_is_approved: Status,
        farmer_master_id: farmerId
      }
    });

    this.http.approveFarmer(body).subscribe((resolve: any) => {
      // console.log(resolve)
      if (resolve.status) {
        this.sendAlert(resolve.message)
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/ViewFarmer'])
        );
      }
      else {
        this.sendAlert(resolve.message)
      }
    })
  }

  sendAlert(message) {
    switch (this.selectedlang) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }

  // getUserOfficeDetails() {

  //   // console.log(localStorage);
  //   // alert("ggg")

  //   let post_user_id = localStorage.getItem('post_user_id');

  //   //  console.log(post_user_id);
  //   const body = new HttpParams({
  //     fromObject: {

  //       post_user_id: post_user_id,
  //     }
  //   });

  //   this.http.getUserDetail(body).subscribe((resolve: any) => {

  //     let data1 = resolve;

  //     //console.log(resolve.data,'=====')

  //     for (let key in resolve.data.userDetail.user_office_detail) {
  //       this.FirstKey = key;
  //       this.userOfficeType = key;
  //       break;
  //     }
  //     console.log(this.userOfficeType)


  //     this.userOfficeId = resolve.data.userDetail.user_office_id;

  //     this.userPosition = resolve.data.userDetail.user_post
  //     console.log(this.userOfficeType + "=============" + this.userPosition);
  //     if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (resolve.data.userDetail.user_type == 0)) {
  //       // console.log(this.userOfficeType + "=============" + this.userPosition);

  //       // this.enableAddOption = true
  //       // this.enableEditOption = true
  //     }

  //     if (this.userOfficeType == "Circle") {
  //       this.enableApproveOption = true
  //     }
  //     // console.log(localStorage.getItem('user_post') + "++++++++++++++=")


  //   },
  //     (err) => {

  //       console.log(err);


  //     }
  //   )

  // }

  level1FormSubmit() {


    // console.log(this.userOfficeId);
    this.ngxService.start();
    ///this.filter = '1';


    this.project_unique_gis = this.level1Form.value.farmer_master_project_unique_gis;
    this.dam_unique_code = this.level1Form.value.farmer_master_dam_unique_code;
    this.wua_id = this.level1Form.value.farmer_master_wua_id;
    this.district_id = this.level1Form.value.farmer_master_farmer_district_id;
    this.farmer_taluka = this.level1Form.value.farmer_master_farmer_taluka;
    this.farmer_village = this.level1Form.value.farmer_master_farmer_village;
    var pageS = this.pageSize.toString();
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        farmer_master_project_unique_gis: this.level1Form.value.farmer_master_project_unique_gis,
        farmer_master_dam_unique_code: this.level1Form.value.farmer_master_dam_unique_code,
        farmer_master_wua_id: this.level1Form.value.farmer_master_wua_id,
        farmer_master_farmer_district_id: this.level1Form.value.farmer_master_farmer_district_id,
        farmer_master_farmer_taluka: this.level1Form.value.farmer_master_farmer_taluka,
        farmer_master_farmer_village: this.level1Form.value.farmer_master_farmer_village,
        user_office_id: this.userOfficeId,
        user_post: this.userPosition,
        page: this.page,
        limit: pageS,
        user_type: localStorage.getItem('user_type'),

      }
    });
    this.http.farmersListings(body).subscribe((data: any) => {
      console.log(this.dataArray)
      this.dataArray = data.data;
      this.count = data.datacount;
      // this.dtTrigger.next();
      this.ngxService.stop();
    })
  }

  forFamerTaluka(data) {
    // console.log(data);
    // console.log(this.getDistArray)
    var item = this.getDistArray.find(abcd => abcd.district_code == data);
    // // console.log(item);

    // this.firstStepForm.patchValue({ farmer_master_farmer_district_id: item.district_code })
    const body = new HttpParams({
      fromObject: {
        district_code: item.district_code
      }
    });
    this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.getFarmerTaluka = resolve.data
      if (this.route.snapshot.params.id) {
        this.forarmerVillage(this.level1Form.value.farmer_master_farmer_taluka)
      }
    })
  }

  forarmerVillage(data) {
    // console.log(data);

    var item = this.getFarmerTaluka.find(abcd => abcd.taluka_code == data);
    // console.log(item);
    // this.farmerTallukaCode = data
    // this.farmerTallukaName = item.taluka_name
    // this.farmerTallukaNameMarathi = item.taluka_name_marathi
    // this.firstStepForm.patchValue({ farmer_master_farmer_taluka: data })
    const body = new HttpParams({
      fromObject: {
        district_code: this.level1Form.value.farmer_master_farmer_district_id,
        taluka_code: data
      }
    });
    this.http.getTalukaVillage(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.getFarmerVillage = resolve.data


    })
  }



  getDistrict() {
    this.http.getDistricts().subscribe((resolve: any) => {
      this.getDistArray = resolve.data
    })


  }

  getwuaMaster() {


    // console.log("getwuaMaster");
    const body = new HttpParams({
      fromObject: {
        project_id: this.projectGisCode,
        user_post_id: localStorage.getItem('post_user_id')
      }
    });
    this.http.getWuaProject(body).subscribe((resolve: any) => {
      if (resolve.data) {

        this.wuaArray = resolve.data;
      } else {
      }



    })
  }

  onChangeforDams(id) {


    let post_user_id = localStorage.getItem('post_user_id');
    this.project_gis_no = id;
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_id: id,
      }
    });
    this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
      // console.log(resolve);
      //return false;
      let data1 = resolve;

      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          this.damsSelect = resolve.data;
          this.getwuaMaster();
          return false;
        } else
          return false;
      }
    },
      (err) => {
        console.log(err);
      })
  }

  ngAfterViewInit(): void {
    // this.dtTrigger.subscribe(() => {
    //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     dtInstance.columns().every(function () {
    //       const that = this;
    //       $('input', this.footer()).on('keyup change', function () {
    //         if (that.search() !== this['value']) {
    //           that.search(this['value']).draw();
    //         }
    //       });
    //     });
    //   });
    //   this.dtTrigger.complete();
    // });

  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getProjectList() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });
    this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
      // console.log(resolve)
      let data1 = resolve;

      // console.log(resolve.data);
      if (resolve.data) {
        this.projects = [];
        // for (let k = 0; k < resolve.data.length; k++) {

        //   if(this.selectedlang === 'en'){
        //     this.projects.push({
        //       item_id: resolve.data[k]['project_unique_gis'],
        //       item_text: resolve.data[k]['project_name']
        //     });
        //   }else if(this.selectedlang === 'mr'){
        //     this.projects.push({
        //       item_id: resolve.data[k]['project_unique_gis'],
        //       item_text: resolve.data[k]['project_name_marathi']
        //     });
        //   }      
        //  }

        this.projects = resolve.data

        //  console.log("jj")

        // console.log(this.projects)
      }


    },
      (err) => {
        console.log(err);
      }
    )
  }

  getDamList(ob) {
    //	console.log(ob)
    this.projectGisCode = ob
    this.damSelect = [];
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_id: ob,
      }
    });
    this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.damSelect = resolve.data

      for (let k = 0; k < resolve.data.length; k++) {

        if (this.selectedlang === 'en') {
          this.dams.push({
            item_id: resolve.data[k]['dam_unique_code'],
            item_text: resolve.data[k]['dam_name']
          });
        } else if (this.selectedlang === 'mr') {
          this.dams.push({
            item_id: resolve.data[k]['dam_unique_code'],
            item_text: resolve.data[k]['dam_name_marathi']
          });
        }
      }
      // if (this.route.snapshot.params.id) {
      // 	this.getDistrictList(this.firstStepForm.value.farmer_master_dam_id)
      // }

    })
  }
  exportAsXLSX(): void {

    let user_type = localStorage.getItem('user_type')

    if (user_type == '0') {
      if (this.level1Form.value.farmer_master_project_unique_gis == null && this.level1Form.value.farmer_master_farmer_district_id == null) {
        this.sendAlert('Please select Project or Farmer District')
        return;
      }

      this.ngxService.start();

      this.excelData = [];

      let post_user_id = localStorage.getItem('post_user_id');
      const body = new HttpParams({
        fromObject: {
          language: this.selectedlang,
          post_user_id: post_user_id,
          farmer_master_project_unique_gis: this.level1Form.value.farmer_master_project_unique_gis,
          farmer_master_dam_unique_code: this.level1Form.value.farmer_master_dam_unique_code,
          farmer_master_wua_id: this.level1Form.value.farmer_master_wua_id,
          farmer_master_farmer_district_id: this.level1Form.value.farmer_master_farmer_district_id,
          farmer_master_farmer_taluka: this.level1Form.value.farmer_master_farmer_taluka,
          farmer_master_farmer_village: this.level1Form.value.farmer_master_farmer_village,
          user_office_id: this.OfficeId,
          user_office_type: this.officeType
        }
      });

      this.http.exportExcelAdmin(body).subscribe((data: any) => {
        // console.log(data)
        this.ngxService.stop();
        this.export_array = data;
        if (data.status && data.data) {
          this.showExportModal()
        }
        else {
          this.sendAlert(data.message)
        }
      })


    }
    else {
      this.ngxService.start();

      this.excelData = [];

      let post_user_id = localStorage.getItem('post_user_id');
      const body = new HttpParams({
        fromObject: {

          post_user_id: post_user_id,
          farmer_master_project_unique_gis: this.level1Form.value.farmer_master_project_unique_gis,
          farmer_master_dam_unique_code: this.level1Form.value.farmer_master_dam_unique_code,
          farmer_master_wua_id: this.level1Form.value.farmer_master_wua_id,
          farmer_master_farmer_district_id: this.level1Form.value.farmer_master_farmer_district_id,
          farmer_master_farmer_taluka: this.level1Form.value.farmer_master_farmer_taluka,
          farmer_master_farmer_village: this.level1Form.value.farmer_master_farmer_village,
          user_office_id: this.OfficeId,
          user_office_type: this.officeType,
          user_type: user_type,
        }
      });

      this.http.exportFarmerAndGetLink(body).subscribe((data: any) => {
        // console.log(data)
        this.ngxService.stop();
        this.export_array = data;
        if (data.status && data.data) {
          this.showExportModal()
        }
        else {
          this.sendAlert(data.message)
        }
      })
    }



  }

  exportAsTemXLSX(): void {

    if (this.level1Form.value.farmer_master_farmer_district_id == null) {
      this.sendAlert("please select district!")
      return;
    }

    // if (this.level1Form.value.farmer_master_farmer_taluka == null) {
    //   this.sendAlert("please select taluka!")
    //   return;
    // }

    // if (this.level1Form.value.farmer_master_farmer_village == null) {
    //   this.sendAlert("please select village!")
    //   return;
    // }



    this.ngxService.start();

    this.excelData = [];
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        farmer_master_farmer_district_id: this.level1Form.value.farmer_master_farmer_district_id,
        // farmer_master_farmer_taluka_code: this.level1Form.value.farmer_master_farmer_taluka,
        // farmer_master_farmer_village: this.level1Form.value.farmer_master_farmer_village
      }
    });

    this.http.exportTempFarmer(body).subscribe((data: any) => {
      this.temp_export_array = data.data
      for (var i in this.temp_export_array) {

        if (this.temp_export_array[i].mwrd_wua_master) {

          var wuaName = this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_wua_master.wua_name : this.temp_export_array[i].mwrd_wua_master.wua_name_marathi
        } else {
          wuaName = '';

        }

        if (this.temp_export_array[i].mwrd_irrigation_source_master) {

          var irrigationsource = this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_irrigation_source_master.irrigation_source : this.temp_export_array[i].mwrd_irrigation_source_master.irrigation_source_marathi
        } else {
          irrigationsource = '';

        }

        // console.log(this.temp_export_array[i].farmer_master_sugar_factory_name);
        for (var j in this.temp_export_array[i].mwrd_gut_masters) {

          if (this.temp_export_array[i].farmer_master_sugar_factory_name) {
            var sugarFactoryName = this.temp_export_array[i].farmer_master_sugar_factory_name;

          } else {

            sugarFactoryName = ''
          }
          if (this.temp_export_array[i].mwrd_district_master) {

            var district = this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_district_master.district_name : this.temp_export_array[i].mwrd_district_master.district_name_marathi
          } else {
            district = '';

          }

          if (this.temp_export_array[i].mwrd_village_master) {

            var village = this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_village_master.village_name : this.temp_export_array[i].mwrd_village_master.village_name_marathi
          } else {
            village = '';

          }


          if (this.temp_export_array[i].mwrd_taluka_master) {

            var taluka = this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_taluka_master.taluka_name : this.temp_export_array[i].mwrd_taluka_master.taluka_name_marathi
          } else {
            taluka = '';

          }

          var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय'
          var No = this.selectedlang == 'en' ? 'No' : 'नाही'
          var dateTo = new Date((this.temp_export_array[i].farmer_master_valid_from));
          var dateToUpto = new Date((this.temp_export_array[i].farmer_master_valid_upto));

          let dateTo_split = '';
          let dateToUpto_split = '';

          this.excelData.push(
            {
              'Project': this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_project_master.project_name : this.temp_export_array[i].mwrd_project_master.project_name_marathi,
              'Dam Name': this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_dam_master.dam_name : this.temp_export_array[i].mwrd_dam_master.dam_name_marathi,
              'District in which dam is situated': this.selectedlang == 'en' ? this.temp_export_array[i].mwrd_district_master.district_name : this.temp_export_array[i].mwrd_district_master.district_name_marathi,
              'Irrigation Source': irrigationsource,
              'Name of WUA': wuaName,
              'Farmer Name (English)': this.temp_export_array[i].farmer_master_farmer_name,
              'Farmer Name (Marathi)': this.temp_export_array[i].farmer_master_farmer_marathi_name,
              'Name of the Cultivator (English)': this.temp_export_array[i].farmer_master_name_of_cultivator ? ' ' : this.temp_export_array[i].farmer_master_name_of_cultivator,
              'Name of the Cultivator (Marathi)': this.temp_export_array[i].farmer_master_name_of_cultivator_marathi ? ' ' : this.temp_export_array[i].farmer_master_name_of_cultivator_marathi,
              'Farmer Co-Stake Holder': this.temp_export_array[i].farmer_costake_holder ? ' ' : this.temp_export_array[i].farmer_costake_holder,
              'Farmer Mobile number': this.temp_export_array[i].farmer_master_farmer_mobile_no ? ' ' : this.temp_export_array[i].farmer_master_farmer_mobile_no,
              'Whatsapp Number ': this.temp_export_array[i].farmer_master_farmer_whatsapp_no ? this.temp_export_array[i].farmer_master_farmer_whatsapp_no : ' ',
              'Email': this.temp_export_array[i].farmer_master_farmer_email ? this.temp_export_array[i].farmer_master_farmer_email : ' ',
              'Address (English)': this.temp_export_array[i].farmer_master_farmer_address1 ? this.temp_export_array[i].farmer_master_farmer_address1 : ' ',
              'Address (Marathi)': this.temp_export_array[i].farmer_master_farmer_marathi_address1 ? this.temp_export_array[i].farmer_master_farmer_marathi_address1 : ' ',
              'District': district,
              'Taluka': taluka,
              'Village': village,
              'Farmer Ledger Number (Khatawani)': this.temp_export_array[i].farmer_master_farmer_ledger_no ? ' ' : this.temp_export_array[i].farmer_master_farmer_ledger_no,
              'Lift irrigation Permission Number ': this.temp_export_array[i].farmer_master_lift_irregation_permission_no ? this.temp_export_array[i].farmer_master_lift_irregation_permission_no : ' ',
              'Valid From': dateTo_split,
              'Valid Upto': dateToUpto_split,
              'Is farmer attached to Sugar Factory': sugarFactoryName ? Yes : No,
              'Name (English)': this.temp_export_array[i].farmer_master_sugar_factory_name ? this.temp_export_array[i].farmer_master_sugar_factory_name : ' ',
              'Name (Marathi)': this.temp_export_array[i].farmer_master_sugar_factory_name_marathi ? this.temp_export_array[i].farmer_master_sugar_factory_name_marathi : ' ',
              'Sugar Factory Email': this.temp_export_array[i].farmer_master_sugar_factory_email ? this.temp_export_array[i].farmer_master_sugar_factory_email : ' ',
              'GUT No': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_gut_no : ' ',
              'Part No': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_part_no : ' ',
              'Total Area GUT (Ha)': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_total_area_gut : ' ',
              'Owned Area EXCL POT KHARAB (Ha)': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_owned_area_exclude_potkharab : ' ',
              'Pot KHARAB Area (Ha)': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_potkharab_area : ' ',
              'Whether said gut number is in notified command area or not?': this.temp_export_array[i].mwrd_gut_masters ? this.temp_export_array[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',


            }
          )

        }
      }

      this.ngxService.stop();
      this.excelService.exportAsExcelFile(this.excelData, 'export-to-excel');


    })


  }
  organise(arr) {
    var headers = [], // an Array to let us lookup indicies by group
      objs = [],    // the Object we want to create
      i, j;
    for (i = 0; i < arr.length; ++i) {
      j = headers.indexOf(arr[i].id); // lookup
      if (j === -1) { // this entry does not exist yet, init
        j = headers.length;
        headers[j] = arr[i].id;
        objs[j] = {};
        objs[j].id = arr[i].id;
        objs[j].data = [];
      }
      objs[j].data.push( // create clone
        {
          case_worked: arr[i].case_worked,
          note: arr[i].note, id: arr[i].id
        }
      );
    }
    return objs;
  }

  //   filterProjectList(arr){
  //     //this.damList
  //     this.projectList =  this.projectList1;
  //     if(arr.length)
  //     this.projectList  = this.projectList.filter(element => arr.includes(element.project_unique_gis));
  //     else
  //     this.projectList =  this.projectList1;


  //     console.log(this.projectList);

  //  }
  onItemSelect(item: any) {
    this.typeArr.push(item.item_id);

    if (this.projectNameArr)
      this.projectNameArr += ',' + item.item_id;
    else
      this.projectNameArr = item.item_id;
    //this.filterProjectList(this.typeArr);

    this.getDamList(this.projectNameArr);
    // this.getbackDamDetails();
    // console.log(this.projectNameArr)
  }
  onSelectAll(items: any) {

    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }
    this.projectNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.projectNameArr)
        this.projectNameArr += ',' + this.typeArr[k];
      else
        this.projectNameArr = this.typeArr[k];
    }
    //console.log(this.typeArr);
    // this.filterProjectList(this.typeArr);
  }


  onDeSelect(items: any) {

    this.typeArr.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.projectNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.projectNameArr)
        this.projectNameArr += ',' + this.typeArr[k];
      else
        this.projectNameArr = this.typeArr[k];
    }

    // this.filterProjectList(this.typeArr);
    // console.log(this.projectNameArr+'sss');

  }

  onDeSelectAll(items: any) {
    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.projectNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.projectNameArr)
        this.projectNameArr += ',' + this.typeArr[k];
      else
        this.projectNameArr = this.typeArr[k];
    }

    // this.filterProjectList(this.typeArr);

  }

  OnItemDeSelect(items: any) {
    const index: number = this.typeArr.indexOf(items.item_id);
    this.typeArr.splice(index, 1);
    //this.selectedItemsRoot = [];
    // this.typeArr.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }
    this.projectNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.projectNameArr) {
        this.projectNameArr += ',' + this.typeArr[k];
      }
      else {
        this.projectNameArr = this.typeArr[k];
      }
    }

    // this.filterProjectList(this.typeArr);
    ///   console.log(this.projectNameArr+'----');
  }



  //dAAMS


  onItemSelect1(item: any) {
    this.typeArrDams.push(item.item_id);

    if (this.damNameArr)
      this.damNameArr += ',' + item.item_id;
    else
      this.damNameArr = item.item_id;
    //this.filterProjectList(this.typeArrDams);


    // this.getbackDamDetails();
    // console.log(this.damNameArr)
  }
  onSelectAll1(items: any) {

    this.typeArrDams = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArrDams.push(items[k]['item_id']);
    }
    this.damNameArr = ''
    for (let k = 0; k < this.typeArrDams.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArrDams[k];
      else
        this.damNameArr = this.typeArrDams[k];
    }
    //console.log(this.typeArrDams);
    // this.filterProjectList(this.typeArrDams);
  }


  onDeSelect1(items: any) {

    this.typeArrDams.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArrDams.push(items[k]['item_id']);
    }

    this.damNameArr = ''
    for (let k = 0; k < this.typeArrDams.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArrDams[k];
      else
        this.damNameArr = this.typeArrDams[k];
    }

    // this.filterProjectList(this.typeArrDams);
    // console.log(this.damNameArr+'sss');

  }

  onDeSelectAll1(items: any) {
    this.typeArrDams = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArrDams.push(items[k]['item_id']);
    }

    this.damNameArr = ''
    for (let k = 0; k < this.typeArrDams.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArrDams[k];
      else
        this.damNameArr = this.typeArrDams[k];
    }

    // this.filterProjectList(this.typeArrDams);

  }

  OnItemDeSelect1(items: any) {
    const index: number = this.typeArrDams.indexOf(items.item_id);
    this.typeArrDams.splice(index, 1);
    //this.selectedItemsRoot = [];
    // this.typeArrDams.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArrDams.push(items[k]['item_id']);
    }
    this.damNameArr = ''
    for (let k = 0; k < this.typeArrDams.length; k++) {
      if (this.damNameArr) {
        this.damNameArr += ',' + this.typeArrDams[k];
      }
      else {
        this.damNameArr = this.typeArrDams[k];
      }
    }

    // this.filterProjectList(this.typeArrDams);
    ///   console.log(this.damNameArr+'----');
  }
  getData() {
    //this.getPremiumData() 
    var startpage = this.page;

    var pageS = this.pageSize.toString();
    this.ngxService.start();
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        user_office_type: this.userOfficeType,
        user_office_id: this.userOfficeId,
        page: startpage,
        limit: pageS,
        user_type: localStorage.getItem('user_type'),
        user_post: this.userPosition
      }
    });
    // console.log(body)
    this.http.farmersListings(body).subscribe((data: any) => {

      this.dataArray = [];
      this.count = data.datacount;
      this.dataArray = data.data
      this.dtTrigger.next();
      this.ngxService.stop();
    })

  }
  endCount(currentPage, itemsPerPage, reportCount) {
    return Math.min((currentPage * itemsPerPage), reportCount);
  }
  onPageChange() {
    // this.getPremiumData() 

    var startpage = this.page;

    // var pageS = toString()
    var pageS = this.pageSize.toString();
    this.ngxService.start();
    let post_user_id = localStorage.getItem('post_user_id');



    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        user_office_type: this.userOfficeType,
        user_office_id: this.userOfficeId,
        page: startpage,
        limit: pageS,
        user_post: this.userPosition,
        user_type: localStorage.getItem('user_type'),
        farmer_master_project_unique_gis: this.project_unique_gis,
        farmer_master_dam_unique_code: this.dam_unique_code,
        farmer_master_wua_id: this.wua_id,
        farmer_master_farmer_district_id: this.district_id,
        farmer_master_farmer_taluka: this.farmer_taluka,
        farmer_master_farmer_village: this.farmer_village,
      }
    });
    // console.log(body)
    this.http.farmersListings(body).subscribe((data: any) => {
      // console.log(data)
      this.dataArray = [];
      this.dataArray = data.data;

      // console.log(this.dataArray)
      this.dtTrigger.next();
      // this.ngOnDestroy();
      this.ngxService.stop();
    })
  }


  getPremiumData() {

    //alert("hhhhh");
    this.onPageChange()
    this.premiumData = this.dataArray;

    // console.log(this.dataArray)
    var p = parseInt(this.page);
    ///alert(p);

    this.dataArray = this.premiumData.slice((p - 1) * this.pageSize, (p - 1) * this.pageSize + this.pageSize);
    // console.log(this.dataArray )
  }
  Add() {

    // this.router.navigate(['StepOne']);
    // this.ngxService.stop();
    this.router.navigate(['addFarmer']);

  }

  editButtonClick(farmerMasterId: number) {
    this.router.navigate(['/edit-farmer', farmerMasterId]);
  }
  getDataOnChangeMarathi() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        for (let key in resolve.data.userDetail.user_office_detail) {
        }
        if (resolve.data) {


          if (resolve.data.userDetail.user_office_change_request) {

            this.disabled = !this.disabled;



          } else {
            this.disabled = this.disabled;


          }
          const subDivision = "Sub Division";

          // console.log(resolve.data.userDetail.user_office_detail.Corporation);
          // console.log("kkk");
          if (resolve.data.userDetail.user_office_detail.Corporation == 'undefined' || resolve.data.userDetail.user_office_detail.Corporation == null || resolve.data.userDetail.user_office_detail.Corporation == '') {
            this.cor = 'NA';
          } else {


            if (this.selectedlang == 'en')
              this.cor = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "NA";
            else
              this.cor = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "NA";

          }
          //return;

          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : "NA",
            Corporation: this.cor,
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : "NA",
          }

          this.office_details_mr = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : "NA",
            Corporation: this.cor,
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : "NA",
          }



          this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
          this.circleId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
          this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
          this.RegionId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
          this.DivisionId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
          this.CorporationId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
          this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";

          // this.data.office_detail = {};
          switch (this.selectedlang) {
            case "en":
              this.data.label = this.en_data;
              this.data.office_detail = this.office_details_en;
              break;
            case "mr":
              this.data.label = this.mr_data;
              this.data.office_detail = this.office_details_mr;
              break;
          }




          // console.log(this.data.office_detail);

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }





  getChangeOfficeDetailsRefresh(id, value, change) {



    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {
            // 
            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;
            // 
          } if (value == 'Circle') {
            // this.allProject = false;
            // 
            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;
            // 
          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;
            // 
            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }

  // showAdvSearch(){

  //   this.showAdvSearchDiv = true;

  // }



  getChangeOfficeDetails(id, value, change) {

    this.OfficeId = id;
    this.officeType = value


    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']

              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {

            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;

          } if (value == 'Circle') {
            // this.allProject = false;

            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;

          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;

            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }
  getOfficeId(officeId) {
    this.OfficeId = officeId;
  }

  enableViewModal(farmerId) {
    // alert(farmerId)
    this.ngxService.start();
    this.show()
    this.getFarmerDetails(farmerId)
  }

  close() {
    $("#preModal").hide();
  }

  show() {
    $('#preModal').show()
  }

  closeExportModal() {
    $('#downloadExcelModal').hide();
  }

  showExportModal() {
    $('#downloadExcelModal').show()
  }

  getFarmerDetails(farmerId) {
    this.http.getFarmerDetailId(farmerId).subscribe((resolve: any) => {
      if (resolve.status) {
        // alert('check')
        this.FarmerDetails = resolve.data
        // alert(this.FarmerDetails.mwrd_project_master.project_name)
      }
      else {
        this.sendAlert('Something went wrong, try again later!')
      }

    })
    this.ngxService.stop();
  }


}
