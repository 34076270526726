import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  otpForm: FormGroup;
  validOtp: number;
  testdata: [];
  test: any;
  length: any;

  ngOnInit() {

    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(4)]]
    });


    this.test = {
      "status": "1",
      "message": "Welcome .",
      "data": {
        "users": [
          {
            "user_office_id": 2727,
            "user_title_name": "Miss",
            "user_full_name": "Shreya  Khandelwal",
            "user_mobile": "9720406603",
            "user_email": "shreya.khandelwal@cyfuture.com",
            "user_post_login_id": "so3ldisd2_sailu",
            "user_post": "Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer",
            "user_post_type": "R",
            "user_first_time_login": "",
            "user_office_detail": [
              {
                "Sub Division": {
                  "office_name": "Deputy Engineer, Lower Dudhana Irrigation Sub Division No. 2 - Sailu (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Sub Division",
                  "office_id": 2727,
                  "office_parent_id": 2702
                },
                "Division": {
                  "office_name": "Executive Engineer, Jalna Irrigation Division  - Jalna (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Division",
                  "office_id": 2702,
                  "office_parent_id": 2687
                },
                "Circle": {
                  "office_name": "Superintending Engineer & Administrator, Command Area Development Authority - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Circle",
                  "office_id": 2687,
                  "office_parent_id": 23
                },
                "Region": {
                  "office_name": "Chief Engineer and Chief Administrator, Water Resources Department, Command Area Development Authority (CADA) - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Region",
                  "office_id": 23,
                  "office_parent_id": 21
                },
                "Corporation": {
                  "office_name": "Executive Director, Godavari Marathwada Irrigation Development Corporation - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Corporation",
                  "office_id": 21,
                  "office_parent_id": 1
                },
                "Mantralaya": {
                  "office_name": "Principal Secretary, Water Resources Department - Mumbai",
                  "office_name_marathi": "",
                  "office_type": "Mantralaya",
                  "office_id": 1,
                  "office_parent_id": 0
                }
              }
            ]
          },
          {
            "user_office_id": 2727,
            "user_title_name": "Miss",
            "user_full_name": "Shreya  Khandelwal",
            "user_mobile": "9720406603",
            "user_email": "shreya.khandelwal@cyfuture.com",
            "user_post_login_id": "so3ldisd2_sailu1",
            "user_post": "Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer",
            "user_post_type": "A",
            "user_first_time_login": "",
            "user_office_detail": [
              {
                "Sub Division": {
                  "office_name": "Deputy Engineer, Lower Dudhana Irrigation Sub Division No. 2 - Sailu (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Sub Division",
                  "office_id": 2727,
                  "office_parent_id": 2702
                },
                "Division": {
                  "office_name": "Executive Engineer, Jalna Irrigation Division  - Jalna (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Division",
                  "office_id": 2702,
                  "office_parent_id": 2687
                },
                "Circle": {
                  "office_name": "Superintending Engineer & Administrator, Command Area Development Authority - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Circle",
                  "office_id": 2687,
                  "office_parent_id": 23
                },
                "Region": {
                  "office_name": "Chief Engineer and Chief Administrator, Water Resources Department, Command Area Development Authority (CADA) - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Region",
                  "office_id": 23,
                  "office_parent_id": 21
                },
                "Corporation": {
                  "office_name": "Executive Director, Godavari Marathwada Irrigation Development Corporation - Aurangabad (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Corporation",
                  "office_id": 21,
                  "office_parent_id": 1
                },
                "Mantralaya": {
                  "office_name": "Principal Secretary, Water Resources Department - Mumbai",
                  "office_name_marathi": "",
                  "office_type": "Mantralaya",
                  "office_id": 1,
                  "office_parent_id": 0
                }
              }
            ]
          },
          {
            "user_office_id": 2525,
            "user_title_name": "Miss",
            "user_full_name": "Shreya  Khandelwal",
            "user_mobile": "9720406603",
            "user_email": "shreya.khandelwal@cyfuture.com",
            "user_post_login_id": "so3ldisd2_sailu2",
            "user_post": "Assistant Engineer Grade-2/Sectional Engineer/Junior Engineer",
            "user_post_type": "A",
            "user_first_time_login": "",
            "user_office_detail": [
              {
                "Sub Division": {
                  "office_name": "Deputy Engineer, Pentakali  Project Sub Division No. 4 (Mehakar) - Chikhli (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Sub Division",
                  "office_id": 2525,
                  "office_parent_id": 2504
                },
                "Division": {
                  "office_name": "Executive Engineer, Khadakpurna Project Division - Deulgaon Raja (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Division",
                  "office_id": 2504,
                  "office_parent_id": 2500
                },
                "Circle": {
                  "office_name": "Superintending Engineer, Buldana Irrigation Project Circle - Buldana (M Cl)",
                  "office_name_marathi": "",
                  "office_type": "Circle",
                  "office_id": 2500,
                  "office_parent_id": 35
                },
                "Region": {
                  "office_name": "Chief Engineer, Water Resources Department - Amravati (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Region",
                  "office_id": 35,
                  "office_parent_id": 32
                },
                "Corporation": {
                  "office_name": "Executive Director, Vidarbha Irrigation Development Corporation - Nagpur (M Corp.)",
                  "office_name_marathi": "",
                  "office_type": "Corporation",
                  "office_id": 32,
                  "office_parent_id": 1
                },
                "Mantralaya": {
                  "office_name": "Principal Secretary, Water Resources Department - Mumbai",
                  "office_name_marathi": "",
                  "office_type": "Mantralaya",
                  "office_id": 1,
                  "office_parent_id": 0
                }
              }
            ]
          }
        ]
      }
    };

    /*  console.log(this.test.data['users']);
      console.log(this.test.data['users'].length);
      console.log(this.test.data['users'][0]['user_office_id']);
      console.log(this.test.data['users'][0]['user_office_detail']);
      console.log(this.test.data['users'][0]['user_office_detail'].length);*/
    //console.log(this.test.data['users'][0]['user_office_detail'][0]);
    //console.log(this.test.data['users'][0]['user_office_detail'][0]['Circle'].office_name);



    //  this.onEnterKeyDown(event);
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onEnterKeyDown(event) {



    if (event.keyCode === 13) {
      if (this.otpForm.invalid) {
        return;
      }
      // console.log('Mobile', this.common.mobile);
      //console.log('otp', this.otpForm.value.otp);


      //  mobile_no,otp

      const body = new HttpParams({
        fromObject: {
          // mobile_no:  localStorage.getItem('mobile'), 
          //otp: this.otpForm.value.otp,  

          mobile_no: localStorage.getItem('mobile'),
          // otp: '123456',  
          otp: this.otpForm.value.otp,
        }
      });
      this.http.checkValidOtp(body).subscribe((resolve: any) => {

        let data1 = resolve;
        if (data1.status == 0) {
          this.validOtp = 1;

          return false;

        } else {
          // alert(resolve.token)
          localStorage.setItem('userLoggedIn', 'true')
          localStorage.setItem('farmer_master_id', resolve.data['users'][0]['user_office_id']);
          localStorage.setItem('userName', resolve.data['users'][0]['user_full_name']);
          localStorage.setItem('userEmail', resolve.data['users'][0]['user_email']);
          localStorage.setItem('userEmail', resolve.data['users'][0]['user_email']);
          localStorage.setItem('user_type', resolve.data['users'][0]['user_type']);
          localStorage.setItem('AadharNumber', resolve.data.AadharNumber);
          localStorage.setItem('Gender', resolve.data.Gender);
          localStorage.setItem('Token', resolve.token)
          this.common.user_detail = resolve.data['users'];
          // this.router.navigate(['verifyDetails']);
          this.router.navigate(['userSelect']);
        }

      },
        (err) => {

          console.log(err);


        }
      )
    }
    // here you can open your confirmation modal if the form is valid
  }
  //Without Enter HIT

  onSubmit(form: FormGroup) {

    if (this.otpForm.invalid) {
      return;
    }
    // console.log('Mobile', this.common.mobile);
    //console.log('otp', this.otpForm.value.otp);


    //  mobile_no,otp

    const body = new HttpParams({
      fromObject: {
        mobile_no: localStorage.getItem('mobile'),
        otp: this.otpForm.value.otp,
        //otp: '123456',  

      }
    });
    this.http.checkValidOtp(body).subscribe((resolve: any) => {
      // console.log(resolve);
      let data1 = resolve;
      if (data1.status == 0) {
        this.validOtp = 1;

        return false;

      } else {


        // console.log(resolve.data);
        //     console.log(resolve.data['users'][0]['user_type']);
        // return false;
        // alert(resolve.token)
        localStorage.setItem('userLoggedIn', 'true')
        localStorage.setItem('farmer_master_id', resolve.data['users'][0]['user_office_id']);
        localStorage.setItem('userName', resolve.data['users'][0]['user_full_name']);
        localStorage.setItem('userEmail', resolve.data['users'][0]['user_email']);
        localStorage.setItem('user_type', resolve.data['users'][0]['user_type']);
        localStorage.setItem('AadharNumber', resolve.data.AadharNumber);
        localStorage.setItem('Gender', resolve.data.Gender);
        localStorage.setItem('selected_lang', 'mr');
        localStorage.setItem('Token', resolve.token)
        this.common.user_detail = resolve.data['users'];
        this.length = resolve.data['users'].length;
        //this.router.navigate(['verifyDetails']);
        this.router.navigate(['userSelect']);
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }




  resendOtp() {
    let data = {};

    // data['Mobile_Number'] = this.loginForm.value.mobilenumber ;

    const body = new HttpParams({
      fromObject: {
        mobile_no: localStorage.getItem('mobile'),
      }
    });
    this.http.getLoginValidNumber(body).subscribe((resolve: any) => {
      //console.log(resolve);
      let data = resolve;
      //console.log(resolve.status)


    },
      (err) => {

        console.log(err);


      }
    )
  }


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private common: CommonService,
    private http: HttpRequestService,
  ) { }



}
