<!-- <p #para>dsfsdssssssfsdfsdfsd</p> -->

<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->













        <div class="dashbordright viewfarmer-page exp-btn-parent">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{data.farmer}}</h1>
            </div>
            <div class="mid-content fullwidth">
                <form [formGroup]="level1Form">
                    <div class="search-bar panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="search-fields">
                            <div class="row frmrow">
                                <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                    <label class="steplbl">{{'Project Name' | translate}}</label>
                                    <select class="stepinpt" formControlName="farmer_master_project_unique_gis"
                                        (change)="onChangeforDams($event.target.value)">
                                        <option value="null">{{'Please Select1' | translate}}</option>
                                        <option *ngFor="let project of projects" [value]="project.project_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{project.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{project.project_name}}
                                            </ng-template>
                                        </option>
                                    </select>

                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2 hidden-xs">
                                    <label class="steplbl">{{'Dam' | translate}}</label>
                                    <select class="stepinpt" formControlName="farmer_master_dam_unique_code">
                                        <option value="null">{{'Please Select1' | translate}}</option>
                                        <option *ngFor="let dam of damsSelect;" [value]="dam.dam_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{dam.dam_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{dam.dam_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 col-lg-2 visible-lg">
                                    <label class="steplbl">{{'WUA' | translate}}</label>
                                    <select class="stepinpt" formControlName="farmer_master_wua_id">

                                        <option value="null">{{'Please Select1' | translate}}</option>
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock1">
                                            <option value="4326">वैयक्तिक लाभधारक शेतकरी</option>
                                        </ng-container>
                                        <ng-template #elseBlock1>
                                            <option value="4326">Individual Beneficial Farmer </option>
                                        </ng-template>

                                        <option *ngFor="let wua of wuaArray;" value="{{wua.wua_id}}">


                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlockWua">
                                                {{wua.wua_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlockWua>
                                                {{wua.wua_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>


                                <div class="col-md-2 col-lg-2 visible-md visible-lg">
                                    <!-- (change)="forFamerTaluka($event.target.value)" -->
                                    <label class="steplbl">{{'District' | translate}}</label>
                                    <select class="stepinpt" formControlName="farmer_master_farmer_district_id"
                                        (change)="forFamerTaluka($event.target.value)">

                                        <option value="null">{{'Please Select1' | translate}}</option>

                                        <option *ngFor="let list of getDistArray" [value]="list.district_code">
                                            <!-- {{list.district_name}} -->
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlockk">
                                                {{list.district_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlockk>
                                                {{list.district_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>


                                <div class="col-md-2 col-lg-2 visible-md visible-lg">
                                    <label class="steplbl">{{'Taluka' | translate}}</label>
                                    <!-- (change)="forFamerTaluka($event.target.value)" -->
                                    <select class="stepinpt" formControlName="farmer_master_farmer_taluka"
                                        (change)="forarmerVillage($event.target.value)">
                                        <option value="null">{{'Please Select1' | translate}}</option>
                                        <option *ngFor="let list of getFarmerTaluka" [value]="list.taluka_code">
                                            <!-- {{list.taluka_name}} -->

                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.taluka_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.taluka_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-xs-12 col-sm-4 col-md-2">
                                    <div class="btn btn-default btn-search-advanced btn-block" role="tab"
                                        id="headingOne"><a class="collapsed" data-toggle="collapse"
                                            data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne"></a></div>

                                    <button type="button" class="stepgreenbtn" (click)="level1FormSubmit()"
                                        data-dismiss="modal">{{'Filter' | translate}}</button>
                                </div>

                            </div>
                        </div>
                        <div class="panel-collapse collapse" id="collapseOne" role="tabpanel"
                            aria-labelledby="headingOne">
                            <div class="search-colapse" *ngIf="showAdvSearchDiv">
                                <div class="row frmrow">
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Village' | translate}}</label>
                                        <!-- (change)="forFamerTaluka($event.target.value)" -->
                                        <select class="stepinpt" formControlName="farmer_master_farmer_village">
                                            <option value="null">{{'Please Select1' | translate}}</option>
                                            <option *ngFor="let list of getFarmerVillage" [value]="list.village_code">
                                                <!-- {{list.village_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.village_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.village_name}}
                                                </ng-template>
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Corporation' | translate}}</label>

                                        <span> <input class="readonly" *ngIf="!showCor" disabled type="text"
                                                value="{{data.office_detail?.Corporation}}"></span>

                                        <select *ngIf="showCor" class="stepinpt"
                                            (change)="getChangeOfficeDetails($event.target.value,'Corporation',1)">

                                            <option *ngFor="let list of CoprationArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Region' | translate}}</label>
                                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                                value="{{data.office_detail?.Region}}"></span>
                                        <select *ngIf="showDiv" class="stepinpt"
                                            (change)="getChangeOfficeDetails($event.target.value,'Region',1)">

                                            <option *ngFor="let list of RegionArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Circle' | translate}}</label>

                                        <span> <input class="readonly" *ngIf="!showCir" disabled type="text"
                                                value="{{data.office_detail?.circle}}"></span>

                                        <select *ngIf="showCir" class="stepinpt"
                                            (change)="getChangeOfficeDetails($event.target.value,'Circle',1)">

                                            <option *ngFor="let list of CircleArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Division' | translate}}</label>

                                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                                value="{{data.office_detail?.Division}}"></span>


                                        <select *ngIf="showDiv" class="stepinpt"
                                            (change)="getChangeOfficeDetails($event.target.value,'Division','1')">

                                            <option *ngFor="let list of DivisionArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Sub Division' | translate}}</label>

                                        <span> <input class="readonly" *ngIf="!showSub" disabled type="text"
                                                value="{{data.office_detail?.subDivis}}"></span>
                                        <select *ngIf="showSub" class="stepinpt"
                                            (change)="getChangeOfficeDetails($event.target.value,'Sub Division','1')">

                                            <option *ngFor="let list of SubDivArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">{{'Section' | translate}}</label>

                                        <span> <input class="readonly" *ngIf="!showSec" disabled type="text"
                                                value="{{data.office_detail?.Section}}"></span>


                                        <select class="stepinpt" *ngIf="showSec"
                                            (change)="getOfficeId($event.target.value)">

                                            <option value="">{{'Please Select1' | translate}}</option>
                                            <option *ngFor="let list of SectionArr" [value]="list.office_id">
                                                <!-- {{list.district_name}} -->
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.office_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.office_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- row filter start-->

            <!-- row filter over-->

            <!-- dashboardbnnr over-->
            <!-- mid content start-->
            <div class="mid-content fullwidth tablerecordpg pt-0">





                <!-- table datat start-->
                <div class="viewpro-table">
                    <!-- <ng-multiselect-dropdown class="input-permissions" [placeholder]="data.select"
                        [settings]="dropdownSettings" [data]="projects" [disabled]="disabled"
                         (onSelect)="onItemSelect($event)"
                        (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)">
                    </ng-multiselect-dropdown>

                    <ng-multiselect-dropdown class="input-permissions" [placeholder]="data.select"
                    [settings]="dropdownSettings" [data]="dams" [disabled]="disabled"
                    (onSelect)="onItemSelect1($event)"
                    (onDeSelect)="OnItemDeSelect1($event)" (onSelectAll)="onSelectAll1($event)"
                    (onDeSelectAll)="onDeSelectAll1($event)">
                </ng-multiselect-dropdown> -->
                    <div class="table-topbtn">
                        <input (click)="exportAsXLSX()" class="stepgreenbtn exp" type="button"
                            value="{{'Export' | translate}}" />
                        <!-- <ng-container *ngIf="user_type==0">
                            <input (click)="exportAsTemXLSX()" class="stepgreenbtn exp" type="button"
                                value="{{'Temporary Export' | translate}}" />
                        </ng-container> -->

                        <ng-container *ngIf="enableAddOption">
                            <input (click)="Add()" class="stepgreenbtn" type="button" value="{{data.add}}" />
                        </ng-container>
                        <form [formGroup]="searchForm">
                            <!-- {{'search' | translate}} -->
                            <input class="exp" type="text" formControlName="farmer_master_search" />
                            <input type="button" (click)="search()" value="{{'search'|translate}}" />
                        </form>

                    </div>

                    <!-- <input (click)="Export()" class="stepgreenbtn1" type="button" value="Export" /> -->

                    <!-- <table width="100%" id="dd" datatable dt-columns="showCase.dtColumns" [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger"> -->

                    {{'Show' | translate}} <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="getPremiumData();">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20"> 20</option>
                        <option [ngValue]="25">25 </option>
                        <!-- <option [ngValue]="100">100 items per page</option> -->
                    </select> {{'entries' | translate}}
                    <table width="100%">


                        <thead>


                            <tr>
                                <th>{{data.project}}</th>
                                <th>{{data.dam}}</th>
                                <th>{{data.wua}}</th>

                                <th>{{data.Farmer_District}}</th>
                                <th>{{data.Farmer_Taluka}}</th>
                                <th>{{data.Farmer_Village}}</th>

                                <th>{{data.farmer_name}}</th>
                                <th>{{data.Farmer_Mobile}}</th>

                                <th>{{data.gut_no}}</th>
                                <th>{{data.Area_of_gut}}</th>
                                <th>{{data.owned_area}}</th>
                                <th>{{data.owned_pot_area}}</th>

                                <!-- <th>{{data.Farmer_project}}</th>
                                <th>{{data.Farmer_dam}}</th>
                                <th>{{data.Farmer_irrigation}}</th>
                                <th>{{data.Wua_details}}</th> -->
                                <th>{{data.farmer_approved}}</th>
                                <th>{{data.Action}}</th>
                            </tr>


                        </thead>


                        <tfoot>
                            <tr>

                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-project_name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-first-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Wua-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-District" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Taluka-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Father" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Mobile-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Gut-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Area" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Owned-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-ownedpot-name" /></th>
                                <th></th>
                                <th></th>

                            </tr>
                        </tfoot>

                        <tbody>
                            <ng-container *ngFor="let list of dataArray ">
                                <ng-container *ngIf="list.mwrd_gut_masters.length==0;else elseGutBlock">
                                    <tr>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{list.mwrd_project_master.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{list.mwrd_project_master.project_name}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                                {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseDamBlock>
                                                {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseWuaBlock">
                                                {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseWuaBlock>
                                                {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseDistrictBlock">
                                                {{list.mwrd_district_master?list.mwrd_district_master.district_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseDistrictBlock>
                                                {{list.mwrd_district_master?list.mwrd_district_master.district_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elsBlock">
                                                {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elsBlock>
                                                {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseVillageBlock">
                                                {{list.mwrd_village_master?list.mwrd_village_master.village_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseVillageBlock>
                                                {{list.mwrd_village_master?list.mwrd_village_master.village_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>

                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.farmer_master_farmer_marathi_name?list.farmer_master_farmer_marathi_name:'NA'}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.farmer_master_farmer_name?list.farmer_master_farmer_name:'NA'}}
                                            </ng-template>
                                        </td>

                                        <td>
                                            {{list.farmer_master_farmer_mobile_no?list.farmer_master_farmer_mobile_no:'NA'}}
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'en';else elseApprovedBlock">
                                                {{list.farmer_is_approved=='0'?'No':'Yes'}}
                                            </ng-container>
                                            <ng-template #elseApprovedBlock>
                                                {{list.farmer_is_approved=='0'?'नाही':'होय'}}
                                            </ng-template>
                                        </td>
                                        <td>

                                            <ul class="tbaction">
                                                <li title="View Farmer">
                                                    <i class="" aria-hidden="true"
                                                        (click)="enableViewModal(list.farmer_master_id)">
                                                        <span class="fa fa-eye"></span>
                                                    </i>
                                                </li>
                                                <ng-container
                                                    *ngIf="(enableEditOption|| (list.added_by==userId)) && list.farmer_is_approved==0 ">
                                                    <li title="Edit">
                                                        <i class="" (click)="editButtonClick(list.farmer_master_id)"
                                                            aria-hidden="true">
                                                            <span class="fa fa-pencil"></span>
                                                        </i>
                                                    </li>
                                                </ng-container>
                                                <ng-container *ngIf="list.farmer_is_approved==0 && enableApproveOption">
                                                    <li>
                                                        <i (click)="approveFarmer(list.farmer_master_id,1)"
                                                            class="fa fa-check">
                                                        </i>
                                                    </li>
                                                </ng-container>

                                            </ul>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseGutBlock>
                                    <tr *ngFor="let gutDetails of list.mwrd_gut_masters">
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{list.mwrd_project_master?.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{list.mwrd_project_master?.project_name}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                                {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseDamBlock>
                                                {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseWuaBlock">
                                                {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseWuaBlock>
                                                {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseDistrictBlock">
                                                {{list.mwrd_district_master?.district_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseDistrictBlock>
                                                {{list.mwrd_district_master?.district_name}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elsBlock">
                                                {{list.mwrd_taluka_master.taluka_name_marathi}}
                                            </ng-container>
                                            <ng-template #elsBlock>
                                                {{list.mwrd_taluka_master.taluka_name}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'mr';else elseVillageBlock">
                                                {{list.mwrd_village_master?list.mwrd_village_master.village_name_marathi:'NA'}}
                                            </ng-container>
                                            <ng-template #elseVillageBlock>
                                                {{list.mwrd_village_master?list.mwrd_village_master.village_name:'NA'}}
                                            </ng-template>
                                        </td>
                                        <td>

                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.farmer_master_farmer_marathi_name?list.farmer_master_farmer_marathi_name:'NA'}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.farmer_master_farmer_name?list.farmer_master_farmer_name:'NA'}}
                                            </ng-template>
                                        </td>

                                        <td>
                                            {{list.farmer_master_farmer_mobile_no?list.farmer_master_farmer_mobile_no:'NA'}}
                                        </td>
                                        <td>
                                            {{gutDetails.gut_master_gut_no}}
                                        </td>
                                        <td>
                                            {{gutDetails.gut_master_total_area_gut}}
                                        </td>
                                        <td>
                                            {{gutDetails.gut_master_owned_area_exclude_potkharab}}
                                        </td>
                                        <td>
                                            {{gutDetails.gut_master_potkharab_area}}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="selectedlang == 'en';else elseApprovedBlock">
                                                {{list.farmer_is_approved=='0'?'No':'Yes'}}
                                            </ng-container>
                                            <ng-template #elseApprovedBlock>
                                                {{list.farmer_is_approved=='0'?'नाही':'होय'}}
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ul class="tbaction">
                                                <li title="View Farmer">
                                                    <i class="" aria-hidden="true"
                                                        (click)="enableViewModal(list.farmer_master_id)">
                                                        <span class="fa fa-eye"></span>
                                                    </i>
                                                </li>
                                                <ng-container
                                                    *ngIf="(enableEditOption|| list.added_by==userId) && list.farmer_is_approved==0 ">
                                                    <li title="Edit">
                                                        <i class="" (click)="editButtonClick(list.farmer_master_id)"
                                                            aria-hidden="true">
                                                            <span class="fa fa-pencil"></span>
                                                        </i>
                                                    </li>
                                                </ng-container>
                                                <ng-container *ngIf="list.farmer_is_approved==0 && enableApproveOption">
                                                    <li>
                                                        <i (click)="approveFarmer(list.farmer_master_id,1)"
                                                            class="fa fa-check">
                                                        </i>
                                                    </li>
                                                </ng-container>

                                            </ul>
                                        </td>
                                    </tr>
                                </ng-template>




                            </ng-container>

                        </tbody>

                    </table>

                    <!-- <ngb-pagination [collectionSize]="70" [(page)]="startpage"  [boundaryLinks]="true"></ngb-pagination> -->
                    <!-- (pageChange)="onPageChange(startpage)" -->
                    <!-- <ngb-pagination (click)="onPageChange(startpage)"  [collectionSize]="count" #numPages [pageSize]="defaultLimit" [(page)]="startpage" ></ngb-pagination> -->

                    <ngb-pagination [collectionSize]="count" [(page)]="page" [(pageSize)]="pageSize" [maxSize]="3"
                        [rotate]="true" (pageChange)="onPageChange()" [boundaryLinks]="true"></ngb-pagination>

                    <span>

                        {{'Showing' | translate}} {{(page-1) * pageSize + 1}} {{'to' | translate}}
                        {{endCount(page,pageSize, count)}} {{'of' | translate}} {{count}} {{'entries' | translate}}
                    </span>

                </div>

                <!-- <pre><span class="float-md-left">Page: {{ page }} / {{numPages.pageCount}}</span><span class="float-md-right">Found items: {{ itemsPerPage }} / {{ users.length }}</span></pre> -->

                <!-- <pre>Current page: {{page}}</pre> -->
                <!-- table datat over-->


            </div>
            <!-- mid content over-->

        </div>
        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                        <h2>{{'View Farmer Details'|translate}}</h2>
                        <br />
                        <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                    </div>
                    <div class="modal-body">

                        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
                            aria-labelledby="myModalLabel" aria-hidden="true"
                            style="overflow: auto!important; display: block;">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                                        <h2>{{'View Farmer Details'|translate}}</h2>
                                        <br />
                                        <button type="button" class="close" data-dismiss="modal"
                                            (click)="close()">&times;</button>
                                    </div>
                                    <div class="modal-body">

                                        <ul class="pojectmstrbx">
                                            <li class="prjhead">{{'Farmer Details'|translate}}</li>
                                            <li>
                                                <label>{{'ProjectName'|translate}}</label>
                                                <div>
                                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectTypeBlock;">
                                                        <p>{{FarmerDetails?.mwrd_project_master?.project_name_marathi}}
                                                        </p>
                                                    </ng-container>
                                                    <ng-template #elseProjectTypeBlock>
                                                        <p>{{FarmerDetails?.mwrd_project_master?.project_name}}</p>
                                                    </ng-template>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'DamName'|translate}}</label>
                                                <div>
                                                    <ng-container *ngIf="selectedlang=='mr';else elseDamName;">
                                                        <p>{{FarmerDetails?.mwrd_dam_master?.dam_name_marathi}}</p>
                                                    </ng-container>
                                                    <ng-template #elseDamName>
                                                        <p>{{FarmerDetails?.mwrd_dam_master?.dam_name}}</p>
                                                    </ng-template>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Name_WUA'|translate}}</label>
                                                <div>
                                                    <ng-container *ngIf="selectedlang=='mr';else elseWuaName;">
                                                        <p>{{FarmerDetails?.mwrd_wua_master?.wua_name_marathi}}</p>
                                                    </ng-container>
                                                    <ng-template #elseWuaName>
                                                        <p>{{FarmerDetails?.mwrd_wua_master?.wua_name}}</p>
                                                    </ng-template>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Farmer_Name'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_farmer_name}}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Farmer_Name_Marathi'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_farmer_marathi_name}}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Farmer_Mobile_number'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_farmer_mobile_no?FarmerDetails.farmer_master_farmer_mobile_no:'-'}}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Farmer_Whatsapp_Number'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_farmer_whatsapp_no?FarmerDetails.farmer_master_farmer_whatsapp_no:'-'}}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Name of the Cultivator (English)' | translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_name_of_cultivator}}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Name of the Cultivator (Marathi)'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_master_name_of_cultivator_marathi}}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <label>{{'Farmer_Gender'|translate}}</label>
                                                <ng-container *ngIf="selectedlang=='mr';else elseFarmerGender;">
                                                    <p>{{FarmerDetails?.mwrd_gender_master?.gender_name_marathi}}</p>
                                                </ng-container>
                                                <ng-template #elseFarmerGender>
                                                    <p>{{FarmerDetails?.mwrd_gender_master?.gender_name}}</p>
                                                </ng-template>
                                            </li>
                                            <li>
                                                <label>{{'farmer_costake_holder'|translate}}</label>
                                                <div>
                                                    <p>{{FarmerDetails?.farmer_costake_holder}}</p>
                                                </div>
                                            </li>
                                            <li class="prjbglist">
                                                <label>{{'District'|translate}}</label>
                                                <ng-container *ngIf="selectedlang=='mr';else elseFarmerDistrict;">
                                                    <p>{{FarmerDetails?.mwrd_district_master?.district_name_marathi}}
                                                    </p>
                                                </ng-container>
                                                <ng-template #elseFarmerDistrict>
                                                    <p>{{FarmerDetails?.mwrd_district_master?.district_name}}</p>
                                                </ng-template>
                                            </li>
                                            <li>
                                                <label>{{'Taluka'|translate}}</label>
                                                <ng-container *ngIf="selectedlang=='mr';else elseFarmerTaluka;">
                                                    <p>{{FarmerDetails?.mwrd_taluka_master?.taluka_name_marathi}}</p>
                                                </ng-container>
                                                <ng-template #elseFarmerTaluka>
                                                    <p>{{FarmerDetails?.mwrd_taluka_master?.taluka_name}}</p>
                                                </ng-template>

                                            </li>
                                            <li>
                                                <label>{{'Village'|translate}}</label>
                                                <ng-container *ngIf="selectedlang=='mr';else elseFarmerVillage;">
                                                    <p>{{FarmerDetails?.mwrd_village_master?.village_name_marathi}}</p>
                                                </ng-container>
                                                <ng-template #elseFarmerVillage>
                                                    <p>{{FarmerDetails?.mwrd_village_master?.village_name}}</p>
                                                </ng-template>

                                            </li>

                                            <li class="prjhead">{{'GutDetails'|translate}}</li>
                                            <li>
                                                <table>
                                                    <tr>
                                                        <th>{{'GUTNo'|translate}}</th>
                                                        <th>{{'PartNo'|translate}}</th>
                                                        <th>{{'Total_GUT'|translate}}</th>
                                                        <th>{{'Owned_Area_EXCL_POT_KHARAB'|translate}}</th>
                                                        <th>{{'Pot_KHARAB_Area'|translate}}</th>
                                                    </tr>
                                                    <ng-container *ngIf="FarmerDetails?.mwrd_gut_masters">
                                                        <tr *ngFor="let gutDetails of FarmerDetails.mwrd_gut_masters">
                                                            <td>{{gutDetails.gut_master_gut_no}}</td>
                                                            <td>{{gutDetails.gut_master_part_no}}</td>
                                                            <td>{{gutDetails.gut_master_total_area_gut}}</td>
                                                            <td>{{gutDetails.gut_master_owned_area_exclude_potkharab}}
                                                            </td>
                                                            <td>{{gutDetails.gut_master_potkharab_area}}</td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-template>
                                                        <tr colspan="5">
                                                            {{'No Gut Details Found'}}
                                                        </tr>
                                                    </ng-template>
                                                </table>
                                            </li>
                                        </ul>


                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!-- dashboard right panel over-->

        <div class="masterpopup modal fade very-pop show" id="downloadExcelModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                        <h2>{{'Dowload File'|translate}}</h2>
                        <br />
                        <button type="button" class="close" data-dismiss="modal"
                            (click)="closeExportModal()">&times;</button>
                    </div>
                    <div class="modal-body">

                        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
                            aria-labelledby="myModalLabel" aria-hidden="true"
                            style="overflow: auto!important; display: block;">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                                        <h2>{{'Download Report'|translate}}</h2>
                                        <br />
                                        <button type="button" class="close" data-dismiss="modal"
                                            (click)="closeExportModal()">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="col-md-12">
                                            <a href="{{export_array?.data}}" download>
                                                <span class="fa fa-download">{{'Download your report'|translate}}</span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>