<body class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>Farmer Master</h1>
            </div>
            <div class="stepimg fullwidth">
                <img src="assets/images/step3.jpg" alt="step2" />
            </div>
            <!-- dashboardbnnr over-->
            <!-- mid content start-->
            <div class="mid-content fullwidth formstep">


                <!-- row start-->
                <div class="row">
                    <!-- col-md-12 start-->
                    <div class="col-md-12 recentlistbx">
                        <h2 class="smlheadh2 col-md-12"><span>Others</span></h2>

                    </div>
                    <!-- col-md-12 over-->
                    <!-- form step1 start-->
                    <form class="stepfrmcmn stepfrm2" [formGroup]="threeStepForm" class="stepfrmcmn stepfrm2"
                        (ngSubmit)="submit()">
                        <!-- step row start-->
                        <div class="row frmrow">

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Sugar Factory Name</label>
                                <input type="text" class="stepinpt" formControlName="farmer_master_sugar_factory_name">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Sugar Factory Email</label>
                                <input type="text" class="stepinpt" formControlName="farmer_master_sugar_factory_email">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Lift irrigation Permission Number </label>
                                <input type="text" class="stepinpt"
                                    formControlName="farmer_master_lift_irregation_permission_no">
                            </div>

                        </div>
                        <!-- step row over-->
                        <!-- step row start-->
                        <div class="row frmrow">



                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Valid From </label>
                                <input type="date" id="vfrom" class=" stepinpt"
                                    formControlName="farmer_master_valid_from">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Valid Upto </label>
                                <input type="date" id="vupto" class=" stepinpt"
                                    formControlName="farmer_master_valid_upto">
                            </div>

                        </div>
                        <!-- <script type="text/javascript">
                            $(document).ready(function(e){
                                $("body").on("click", ".gutblkaddbtn", function(e) {
                                    var guthtm = $(".gutbx").html();
                                    $('.newgutbx').append(guthtm);
                                });
                                $("body").on("click", ".gutblkminusbtn", function(e) {
                                    $(this).parents('.gutbxmain').remove();
                                });
    
                            });
                        </script> -->
                        <!-- step row over-->
                        <div class="col-md-12 recentlistbx gutblkaddblock">
                            <h2 class="smlheadh2 col-md-12"><span>Gut Details</span></h2>

                        </div>
                        <!-- gutbx start-->
                        <div class="gutbx">
                            <div class="gutbxmain" *ngFor="let tr of totalTr;  let i = index;">
                                <div class="row frmrow ">
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">GUT No.</label>
                                        <input type="text" class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_gut_no[i]"
                                            [ngModelOptions]="{standalone: true}">

                                    </div>

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">Part No</label>
                                        <input type="text" class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_part_no[i]"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">Total Area GUT</label>
                                        <input type="text" class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_total_area_gut[i]"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>

                                </div>
                                <div class="row frmrow">

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">Owned Area EXCL POT KHARAB </label>
                                        <input type="text" class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_owned_area_exclude_potkharab[i]"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>

                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">Pot KHARAB Area </label>
                                        <input type="text" class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_potkharab_area[i]"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-md-4 inptbx">
                                        <label class="steplbl">Whether said gut number is in notified command area or
                                            not? </label>
                                        <select class="stepinpt"
                                            [(ngModel)]="reqData.farmer_master_whether_said_gutnumber_notified_command_area[i]"
                                            [ngModelOptions]="{standalone: true}">
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </select>
                                    </div>
                                </div>
                                <hr>
                                <div class="btn-group" style="float: right;">
                                    <input type="button" value=" - " class="min btn btn-sm btn-danger"
                                        (click)="removeRow(i)" />
                                    <input *ngIf="i==totalTr.length-1" type="button" value=" + "
                                        class="add btn btn-sm btn-primary" (click)="addRow()" />
                                </div>
                            </div>

                        </div>
                        <div class="newgutbx"></div>
                        <!-- newgutbx over-->
                        <div class="clearfix height10"></div>
                        <!-- step row start-->
                        <div class="row frmrow">

                            <div class="col-md-12 inptbx">
                                <input class="stepgreenbtn" type="button" value="Save" />
                                <input class="stepgreenbtn" type="Submit" />
                                <input class="stepgreybtn" type="reset" value="Reset" />
                            </div>

                        </div>
                        <!-- step row over-->
                    </form>
                    <!-- form step1 over-->
                </div>



            </div>
            <!-- mid content over-->

        </div>
        <!-- dashboard right panel over-->
    </div>