import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators ,FormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpRequestService} from './../_service/http-request.service';
import {CommonService} from './../_service/common.service';
import { HttpClient ,HttpHeaders,HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.css']
})
export class StepTwoComponent implements OnInit {

  submitted = false;
   whatsNumber:any;


  twoStepForm: FormGroup;
  farmerSelect = [];
  optionsSelect=[];
  genderSelect=[];
  farmertDistArray= []

 constructor(
  private fb: FormBuilder,
  private router: Router,
  private route: ActivatedRoute,
  private http : HttpRequestService,
  private common : CommonService,
 
  
) {

}
  ngOnInit() {
    //console.log(this.optionsSelect[0]);
    
  
    this.twoStepForm = this.fb.group({
          farmer_master_id: [this.route.snapshot.params.id, Validators.required],
          farmer_master_farmer_name: ['', Validators.required],
          farmer_master_farmer_code: [''],
          farmer_master_farmer_gender: ['', Validators.required],
          farmer_master_farmer_mobile_no: ['', Validators.required],
          farmer_master_farmer_whatsapp_no: ['', Validators.required],
          farmer_master_farmer_email: ['', Validators.required],
          farmer_master_farmer_district_id: ['', Validators.required],
          farmer_master_farmer_taluka: ['', Validators.required],
          farmer_master_farmer_village: ['', Validators.required],
          farmer_master_farmer_address: ['', Validators.required],
          farmer_master_farmer_ledger_no: ['', Validators.required],
          post_user_id: [localStorage.getItem('post_user_id'), ],
          

    });
    this.getFarmerDist()
  }
  submit(){
    if(this.twoStepForm.status == "INVALID"){
      alert('All fields are mandatory')
      return
    }
        // console.log( this.twoStepForm)
    const body = new HttpParams({
      fromObject: this.twoStepForm.value
    });
    this.http.saveSecondStap(body).subscribe((resolve: any) => {
      // console.log(resolve);
      // this.wuaArray = resolve.data
      this.router.navigate(['/StepThree/'+resolve.data.id])

    })
  }
  getFarmerDist(){
    this.http.farmersDistricts().subscribe((resolve: any) => {
      // console.log(resolve);
      this.farmertDistArray = resolve.data
      // this.router.navigate(['/StepThree/'+resolve.data.id])

    })
  }

  
}
