import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { WuaMasterService } from './../_service/wua-master.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { switchMap, debounceTime, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { resolve } from 'dns';


import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wua-master',
  templateUrl: './wua-master.component.html',
  styleUrls: ['./wua-master.component.css']
})
export class WuaMasterComponent implements OnInit {

  clickEventsubscription: Subscription;
  language: string;
  wuaForm: FormGroup;
  projectSelect = [];
  selectedlang = localStorage.getItem('selected_lang');
  getdamBasinsArray: any;
  getdamSubBasinsArray: any;
  wuaTypeSelect: []
  wuaCategorySelect: []
  districtSelect: []
  reqData: any = {}
  territorialSelect: []
  cooperativeSelect: []
  damSelect: [];
  wuaStatusSelect: [];
  getDistArray: any;
  getFarmerTaluka: any;
  getFarmerVillage: any;
  farmerDistrictCode: any;
  farmerDistrict;
  farmerTalluka;
  farmerVillage;
  farmerVillageName: any;
  farmerVillageNameMarathi: any;
  farmerVillageCode: any;
  isShown: boolean = false
  isShowLiftIrri: boolean;
  showcanal: boolean;
  showbankType: boolean;
  mainCanelArray: any;
  projectGisCode: any;
  getDamId: any;
  damUniqueCode: any;
  irrigationSelect: any;
  bankArray: any;
  getFarmerDistructName: any;
  getFarmerDistructNameMarathi: any;
  farmerTallukaCode: any;
  farmerTallukaName: any;
  farmerTallukaNameMarathi: any;
  wuaDistructName: any;
  wuaDistructNameMarathi: any;
  wuaTallukaCode: any;
  wuaTallukaName: any;
  wuaTallukaNameMarathi: any;
  getFarmerVillage1: any;
  getFarmerTaluka1: any;
  wuaVillageName: any;
  wuaVillageNameMarathi: any;
  wuaVillageCode: any;
  getDistArray1: any;
  wuaDistrict;
  wuaTalluka;
  wuaVillage;
  benifitedAreaDetails: any = [];
  yesOrNo: any;
  contentEditable: boolean;
  mainCanelId: any;
  subMinorUniqueId: any;
  getOutlistingArray: any;
  subMinorCanelArray: any;
  minorCanelArray: any;
  branchCanelId: any;
  branchCanelArray: any;
  getWuaDetails: any;
  distributoryArray: any;
  distributoryUniqueId: any;
  minorUniqueId: any;
  FirstKey: string;
  projectLayoutArray = []
  userOfficeType: string
  userPosition: string
  haveAccess: Boolean = false
  Basin: any;
  SubBasin: string;
  basinMarathiName: string;
  subbasinMarathiName: string;
  BasinId: any;
  SubBasinId: any;
  BankList: any;
  districtName: any;
  talukaName: any;
  VillageName: any;
  aWuaDetails: any;
  wua_id: Number = -1;
  temp_benefitted_taluka: Number;
  temp_benefitter_village: Number;
  isAdmin: Number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpRequestService,
    private wuaService: WuaMasterService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,

  ) {

    this.ngxService.stop();
    this.userOfficeType = localStorage.getItem('user_office_type')
    this.userPosition = localStorage.getItem('user_post')
    // console.log(this.userOfficeType + "=========" + this.userPosition)
    // this.getUserOfficeDetails();
    // this.checkUserAccess()
    // if (!this.haveAccess) {

    //   this.sendAlert("You don't have access to Add WUA")
    //   this.router.navigate(["/dashboard"])
    // }
    // this.getProjectList();
    // this.getBasins();
    // this.getWuaType();
    // this.getWuaCategories();
    // this.getAllDistrict();
    // this.getWuaTerritorial();
    // this.getWuaActCooperative();
    // this.getDistrict()
    // this.getDistrict1()
    // this.getWuaStatuses();
    // this.getYesNo();
    // this.getUserOfficeDetails();
    //	this.getYesNo()
  }


  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    this.cdr.markForCheck();
  }

  getYesNo() {
    this.http.getYesNo().subscribe((data: any) => {
      // console.log(data)
      if (data) {
        this.yesOrNo = data.data
      }
      else {
        return false;
      }
    })
  }

  checkUserAccess() {
    if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (this.isAdmin == 0)) {
      // console.log(this.userOfficeType + "=============" + this.userPosition);
      this.haveAccess = true
    }
  }

  getUserOfficeDetails() {

    // alert("ggg")

    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });

    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;

      for (let key in resolve.data.userDetail.user_office_detail) {

        this.FirstKey = key;
        //   console.log(key)
        break;
      }
      // console.log(resolve.data,"====")
      this.isAdmin = resolve.data.userDetail.user_type;
      // console.log( resolve.data.userDetail.user_type,"==dd==")
      this.wuaForm.patchValue({ wua_added_by_user_office_type: this.FirstKey })
      this.wuaForm.patchValue({ wua_added_by_user_office_id: resolve.data.userDetail.user_office_id })
      var officeDetails = []
      officeDetails = resolve.data.userDetail.user_office_detail;


      for (let key in officeDetails) {
        // console.log("Checking in for loop")
        switch (key) {
          case "Mantralaya":
            // console.log(officeDetails[key]['office_id']);
            this.wuaForm.patchValue({
              wua_mantralaya_id: officeDetails[key]['office_id']
            })
            // this.firstStepForm.value.farmer_mantralaya_id = officeDetails[key]['office_id']
            break;
          case "Corporation":
            this.wuaForm.patchValue({
              wua_corporation_id: officeDetails[key]['office_id']
            })
            // console.log(officeDetails[key]['office_id']);
            // this.firstStepForm.value.farmer_corporation_id = officeDetails[key]['office_id']
            break;
          case "Region":
            this.wuaForm.patchValue({
              wua_region_id: officeDetails[key]['office_id']
            })
            // console.log(officeDetails[key]['office_id']);
            // this.firstStepForm.value.farmer_region_id = officeDetails[key]['office_id']
            break;
          case "Circle":
            this.wuaForm.patchValue({
              wua_circle_id: officeDetails[key]['office_id']
            })
            // console.log(officeDetails[key]['office_id']);
            // this.firstStepForm.value.farmer_circle_id = officeDetails[key]['office_id']
            break;
          case "Division":
            this.wuaForm.patchValue({
              wua_division_id: officeDetails[key]['office_id']
            })
            // console.log(officeDetails[key]['office_id']);
            // this.firstStepForm.value.farmer_division_id = officeDetails[key]['office_id']
            break;
          case "Sub Division":
            this.wuaForm.patchValue({
              wua_subdivision_id: officeDetails[key]['office_id']
            })
            // this.firstStepForm.value.farmer_subdivision_id = officeDetails[key]['office_id']
            // console.log(officeDetails[key]['office_id']);
            break;
          case "Section":
            this.wuaForm.patchValue({
              wua_section_id: officeDetails[key]['office_id']
            })
            // this.firstStepForm.value.farmer_section_id = officeDetails[key]['office_id']
            // console.log(officeDetails[key]['office_id']);
            break;
        }

      }

      // console.log(this.wuaForm);

    },
      (err) => {

        console.log(err);


      }
    )

  }
  finalSubmit() {



    // console.log(this.wuaForm);
    // return;
    if (this.wuaForm.status == "INVALID") {
      this.sendAlert('All fields are mandatory')
      return;
    }
    // this.wuaForm.patchValue({ farmer_master_farmer_village: this.farmerVillageCode, farmer_master_dam_id: this.getDamId })
    // console.log(this.wuaForm, this.isShown)

    if (this.isShown == true) {
      // if (this.wuaForm.value.wua_main_canal_id == "" || this.wuaForm.value.wua_main_canal_id == undefined || this.wuaForm.value.wua_main_canal_id == null) {
      //   this.sendAlert('Main Canal Is Mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_branch_id == "" || this.wuaForm.value.wua_branch_id == undefined || this.wuaForm.value.wua_branch_id == null) {
      //   this.sendAlert('Branch Canal Is Mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_distributory_id == "" || this.wuaForm.value.wua_distributory_id == undefined || this.wuaForm.value.wua_distributory_id == null) {
      //   this.sendAlert('Distributory are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_minor_id == "" || this.wuaForm.value.wua_minor_id == undefined || this.wuaForm.value.wua_minor_id == null) {
      //   this.sendAlert('Minor are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_subminor_id == "" || this.wuaForm.value.wua_subminor_id == undefined || this.wuaForm.value.wua_subminor_id == null) {
      //   this.sendAlert('Sub Minor are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_outlet_id == "" || this.wuaForm.value.wua_outlet_id == undefined || this.wuaForm.value.wua_outlet_id == null) {
      //   this.sendAlert('Outlet are mandatory')
      //   return
      // }

      if (this.wuaForm.value.wua_project_layout_id == "" || this.wuaForm.value.wua_project_layout_id == undefined || this.wuaForm.value.wua_project_layout_id == null) {
        this.sendAlert('Please select project layout')
        return
      }



    }

    var newData: any = JSON.stringify(this.benifitedAreaDetails)
    this.wuaForm.value.wua_benefitted_details = newData;
    // this.wuaForm.value.wau_river_basin = this.BasinId;
    this.wuaForm.value.wau_river_basin = 1;
    // this.wuaForm.value.wua_river_subbasin = this.SubBasinId;
    this.wuaForm.value.wua_river_subbasin = 2
    this.wuaForm.value.wua_user_type = localStorage.getItem('user_type')
    // project_user_type: localStorage.getItem('user_type'),


    // console.log(this.wuaForm);
    // return false;
    this.wuaForm.value.wua_benefitted_details = newData
    const body = new HttpParams({
      fromObject: this.wuaForm.value
    });
    this.http.addWuaMaster(body).subscribe((resolve: any) => {

      if (resolve.status) {
        if (this.selectedlang == 'mr') {

          alert("सदर माहिती जतन करण्यात आलेली आहे.");

        } else {
          alert("This information has been saved");

        }

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/wuaMaster'])
        );
      }
      else {
        this.sendAlert(resolve.message)
      }


    })
  }

  sendAlert(message) {
    switch (this.language) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }
  getDistrict() {
    this.http.getDistricts().subscribe((resolve: any) => {
      this.getDistArray = resolve.data
    })
    this.http.getBankId().subscribe((resolve: any) => {
      //console.log(resolve);

      this.bankArray = resolve.data;
      if (this.route.snapshot.params.id) {
        this.getMainCanel()
      }
    })
  }

  getDistrict1() {
    this.http.getDistricts().subscribe((resolve: any) => {
      this.getDistArray1 = resolve.data;

    })
    this.http.getBankId().subscribe((resolve: any) => {
      this.bankArray = resolve.data;
    })
  }


  forFamerTaluka(data) {
    // console.log(data + "7777777777777777777777777");
    // console.log(this.getDistArray)
    var item = this.getDistArray.find(abcd => abcd.district_code == data);
    this.farmerDistrictCode = data;
    this.getFarmerDistructName = item.district_name;

    // console.log(this.getFarmerDistructName, "======")
    this.getFarmerDistructNameMarathi = item.district_name_marathi
    //	this.wuaForm.patchValue({ farmer_master_farmer_district_id: item.district_code })
    const body = new HttpParams({
      fromObject: {
        district_code: data
      }
    });
    this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.getFarmerTaluka = resolve.data
      if (this.route.snapshot.params.id) {
        // console.log(this.aWuaDetails.wua_taluka_code + "((((((((((((((((((((((((((((((((")
        this.forarmerVillage(this.aWuaDetails.wua_taluka_code)
      }
    })
  }

  forarmerVillage(data) {
    // console.log(data);

    // console.log(this.getFarmerTaluka)

    var item = this.getFarmerTaluka.find(abcd => abcd.taluka_code == data);
    this.farmerTallukaCode = data
    this.farmerTallukaName = item.taluka_name
    this.farmerTallukaNameMarathi = item.taluka_name_marathi

    const body = new HttpParams({
      fromObject: {
        district_code: this.farmerDistrictCode,
        taluka_code: data
      }
    });
    this.http.getTalukaVillage(body).subscribe((resolve: any) => {
      this.getFarmerVillage = resolve.data
      // console.log(this.getFarmerVillage)
      if (this.route.snapshot.params.id) {
        this.getAllVillage(this.aWuaDetails.wua_village_code)
      }
    })

  }
  getDistrictList(ob: any) {

    var dams = this.damSelect;
    // dams.forEach(element => {
    //   if (element.dam_id == ob) {
    //     this.BasinId = element.dam_basin;
    //     this.SubBasinId = element.dam_subbasin;
    //     this.Basin = element.mwrd_basin_master ? element.mwrd_basin_master.basin_name : element.dam_basin;
    //     this.SubBasin = element.mwrd_subbasin_master ? element.mwrd_subbasin_master.subbasin_name : element.dam_subbasin;
    //     this.basinMarathiName = element.mwrd_basin_master ? element.mwrd_basin_master.basin_name_marathi : element.dam_basin;
    //     this.subbasinMarathiName = element.mwrd_subbasin_master ? element.mwrd_basin_master.subbasin_name_marathi : element.dam_subbasin;
    //   }
    // });
    this.getDamId = ob
    //this.damUniqueCode = item.dam_unique_code
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        dam_district_code: ob,
      }
    });
    this.http.getDamDistricts(body).subscribe((resolve: any) => {
      this.districtSelect = resolve.data;
    })
  }
  getIrrigations() {
    // 
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        user_post_id: post_user_id,
        dam_id: this.getDamId,
      }
    });
    // this.http.getDamIrrigatations(body).subscribe((resolve: any) => {
    this.http.getIrrigation().subscribe((resolve: any) => {
      this.irrigationSelect = resolve.data.irrigation_sources


    })

  }

  getAllVillage(data) {

    //console.log( abcd.village_code);

    // console.log(data, "ddat");
    // console.log(this.getFarmerVillage)
    var item = this.getFarmerVillage.find(abcd => abcd.village_code == data);
    // console.log(item.village_name + '-----');
    this.farmerVillageName = item.village_name
    this.farmerVillageNameMarathi = item.village_name_marathi
    this.farmerVillageCode = data

  }
  getAllVillage1(data) {
    var item = this.getFarmerVillage1.find(abcd => abcd.village_code == data);
    this.wuaVillageName = item.village_name
    this.wuaVillageNameMarathi = item.village_name_marathi
    this.wuaVillageCode = data
    if (this.language == 'mr')
      this.VillageName = this.wuaVillageNameMarathi
    else
      this.VillageName = this.wuaVillageName

  }
  forFamerTaluka1(data) {
    // console.log(data + "----------------")

    var item = this.getDistArray1.find(abcd => abcd.district_code == data);
    this.farmerDistrictCode = data;
    this.wuaDistructName = item.district_name
    this.wuaDistructNameMarathi = item.district_name_marathi


    if (this.language == 'mr')
      this.districtName = item.district_name_marathi
    else
      this.districtName = item.district_name
    const body = new HttpParams({
      fromObject: {
        district_code: data
      }
    });
    this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
      this.getFarmerTaluka1 = resolve.data;
      // if (this.route.snapshot.params.id) {
      //   this.forarmerVillage1(data)
      // }
    })
  }

  forarmerVillage1(data) {

    // console.log(data + "5555555555555555")
    // console.log(this.getFarmerTaluka1);

    var item = this.getFarmerTaluka1.find(abcd => abcd.taluka_code == data);
    this.wuaTallukaCode = data
    this.wuaTallukaName = item.taluka_name
    this.wuaTallukaNameMarathi = item.taluka_name_marathi


    if (this.language == 'mr')
      this.talukaName = this.wuaTallukaNameMarathi
    else
      this.talukaName = this.wuaTallukaName
    const body = new HttpParams({
      fromObject: {
        district_code: this.farmerDistrictCode,
        taluka_code: data
      }
    });
    this.http.getTalukaVillage(body).subscribe((resolve: any) => {
      this.getFarmerVillage1 = resolve.data
      // if (this.route.snapshot.params.id) {
      //   this.getAllVillage1(this.aWuaDetails.wua_village_code)
      // }


    })
  }
  getDamList(ob) {

    this.projectGisCode = ob;
    this.damSelect = [];

    // console.log(ob,"======")

    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        //   project_id: ob,
        wua_project_primary_id: ob,
      }
    });
    // this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
    //   this.damSelect = resolve.data

    // })

    this.http.getDamWuaDistricts(body).subscribe((resolve: any) => {
      this.damSelect = resolve.data;

      // this.Basin = '';
      // this.SubBasin = '';
      // this.BasinId = resolve.data[0].mwrd_basin_master.basin_master_id;
      // this.SubBasinId = resolve.data[0].mwrd_subbasin_master.subbasin_master_id;

      // if (this.language == 'mr') {

      //   this.Basin = resolve.data[0].mwrd_basin_master.basin_name_marathi;
      //   this.SubBasin = resolve.data[0].mwrd_subbasin_master.subbasin_name_marathi;


      // } else if (this.language == 'en') {

      //   this.Basin = resolve.data[0].mwrd_basin_master.basin_name;
      //   this.SubBasin = resolve.data[0].mwrd_subbasin_master.subbasin_name;

      // }

    })

    this.cdr.detectChanges()
  }


  // getAllList() {
  // 	let post_user_id = localStorage.getItem('post_user_id');
  // 	const body = new HttpParams({
  // 		fromObject: {
  // 			post_user_id: post_user_id,
  // 			project_unique_gis: this.project_gis_no,
  // 			dam_unique_code: this.dam_unique,
  // 			main_canal_bank_id: this.LayoutForm.value.bank_id,
  // 			branch_canal_bank_id: this.LayoutForm.value.bank_id,
  // 			distributory_bank_id: this.LayoutForm.value.bank_id,
  // 			minor_bank_id: this.LayoutForm.value.bank_id,
  // 			subminor_bank_id: this.LayoutForm.value.bank_id,
  // 			outlet_bank_id: this.LayoutForm.value.bank_id,
  // 		}
  // 	});
  // 	this.http.getCanalDetailLayout(body).subscribe((resolve: any) => {
  // 		// /	console.log(resolve);
  // 		this.mainCanalSelect = resolve.data;
  // 	})

  // 	this.http.getBranchesOfCanel(body).subscribe((branchesData: any) => {
  // 		// console.log(branchesData)
  // 		this.getCanlBranchArray = branchesData.data
  // 	})
  // 	this.http.getDistributory(body).subscribe(data => {
  // 		// console.log(data)
  // 		var arr: any = data
  // 		this.getDistubutoryArray = arr.data

  // 	})
  // 	this.http.getListOfMinor(body).subscribe(data => {
  // 		// console.log(data)
  // 		var arr: any = data
  // 		this.getMinorCanalArray = arr.data

  // 	})
  // 	this.http.getListIfSubMinor(body).subscribe((data: any) => {
  // 		this.getSubMinorCanalArray = data.data

  // 	})
  // 	this.http.getoutletListing(body).subscribe((data: any) => {
  // 		this.getOutlistingArray = data.data

  // 	})
  // }

  getMainCanel() {
    this.getProjectLayouts();
    // this.wuaForm.value.main_canal_id = null
    // this.wuaForm.value.minor_canal_id = null
    // const body = new HttpParams({
    //   fromObject: {
    //     project_unique_gis: this.projectGisCode,
    //     dam_unique_code: this.getDamId,
    //     post_user_id: localStorage.getItem('post_user_id'),
    //     main_canal_bank_id: this.wuaForm.value.wua_canal_bank_type_id
    //   }
    // });
    // // console.log(body)
    // this.http.getMainCanel(body).subscribe((resolve: any) => {
    //   // console.log(resolve);
    //   this.mainCanelArray = resolve.data
    //   // if (this.route.snapshot.params.id) {
    //   // 	this.wuaForm.patchValue({ farmer_master_canal_id: this.getAllFarmerDetails.farmer_master_canal_id })
    //   // 	this.getBranchCanalList(this.getAllFarmerDetails.farmer_master_canal_id)
    //   // }

    // })
  }
  toggleShow(evt) {
    //	console.log(evt);
    if (evt == "1") {  //1  for canal flow
      this.isShown = true;
      this.isShowLiftIrri = false
      this.showcanal = true
      this.showbankType = true;
      // this.getMainCanel()

    } else if (evt == "2") {  // 2 for canal lift
      this.isShown = true;
      this.isShowLiftIrri = true
      this.showcanal = true;
      this.showbankType = true;

      // this.getMainCanel()
    } else {
      this.isShowLiftIrri = true
      this.showcanal = false
      this.showbankType = false;
      this.wuaForm.patchValue({
        farmer_master_canal_id: '',
        farmer_master_canal_branch_id: '',
        farmer_master_canal_distributary_id: '',
        farmer_master_minor_id: '',
        farmer_master_subminor_id: '',
        farmer_master_outlet_id: '',
      })
      this.cdr.markForCheck()
      this.isShown = false;
    }
  }

  ngOnInit(): void {

    this.getDistrict()
    this.getDistrict1()
    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));

    if (this.route.snapshot.params['id']) {
      this.wua_id = this.route.snapshot.params['id'];
      this.getSingleWuaDetails(this.wua_id);
    }

    this.getProjectList();
    this.getBasins();
    this.getWuaType();
    this.getWuaCategories();
    this.getAllDistrict();
    this.getWuaTerritorial();
    this.getWuaActCooperative();


    this.getWuaStatuses();
    this.getYesNo();
    // this.getUserOfficeDetails();
    this.getAllBank();
    this.getIrrigations()
    this.getUserOfficeDetails();
    this.checkUserAccess();
    // alert(this.haveAccess);
    // if (!this.haveAccess) {
    //   this.sendAlert("You don't have access to Add WUA")
    //   this.router.navigate(["/dashboard"])
    // }

    this.wuaForm = this.fb.group({
      wua_id: new FormControl(0),
      wua_project_primary_id: new FormControl(null, [Validators.required]),
      wua_name: new FormControl('', [Validators.required]),
      wua_name_marathi: new FormControl('', [Validators.required]),
      wua_address: new FormControl('', [Validators.required]),
      wua_address_marathi: new FormControl('', [Validators.required]),
      //wau_river_basin: new FormControl('', [Validators.required]),
      //   wua_river_subbasin: new FormControl('', [Validators.required]),
      wua_type: new FormControl(null, [Validators.required]),
      wua_category: new FormControl(null, [Validators.required]),

      wua_territorial_reach: new FormControl(null, [Validators.required]),
      wua_under_mmisf_act_cooperative_society: new FormControl(null, [Validators.required]),
      wua_registration_no: new FormControl('', [Validators.required]),
      wua_registration_date: new FormControl('', [Validators.required]),
      wua_status: new FormControl(null, [Validators.required]),
      wua_measuring_installed: new FormControl(null, [Validators.required]),
      wua_irregation_source: new FormControl(null, [Validators.required]),
      wua_project_layout_id: new FormControl(null),
      wua_branch_id: new FormControl(null),
      wua_distributory_id: new FormControl(null),
      wua_minor_id: new FormControl(null),
      wua_subminor_id: new FormControl(null),
      wua_outlet_id: new FormControl(null),
      wua_main_canal_id: new FormControl(null),
      wua_bank_name: new FormControl('', [Validators.required]),
      wua_bank_ac_no: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      wua_dam_primary_id: new FormControl(null, [Validators.required]),
      wua_pincode: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6)]),
      wua_canal_bank_type_id: new FormControl(0),
      wua_bank_branch_name: new FormControl('', [Validators.required]),
      wua_district_code: new FormControl('', [Validators.required]),
      wua_taluka_code: ['', Validators.required],
      wua_village_code: ['', Validators.required],

      wua_mantralaya_id: [0],
      wua_corporation_id: [0],
      wua_region_id: [0],
      wua_circle_id: [0],
      wua_division_id: [0],
      wua_subdivision_id: [0],
      wua_section_id: [0],


      wua_delineation_area_ha: ['', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$')]],
      wua_total_area_ha: ['', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$')]],
      wua_no_of_beneficiaries: new FormControl('', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$')]),

      wua_bennefitted_district_code: new FormControl(''),
      wua_bennefitted_taluka_code: new FormControl(''),
      wua_bennefitted_village_code: new FormControl(''),

      wua_added_by_user_office_type: new FormControl(null, [Validators.required]),
      wua_added_by_user_office_id: new FormControl(null, [Validators.required]),
      wua_user_type: new FormControl(1)
    });
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.selectedlang = localStorage.getItem('selected_lang');
      this.ngOnInit();

      if (!this.haveAccess) {
        this.sendAlert("You don't have access to Add WUA")
        this.router.navigate(["/dashboard"])
      }
    })



  }

  getSingleWuaDetails(wuaId) {
    const body = new HttpParams({
      fromObject: {
        wua_id: wuaId,
      }
    });
    this.http.getSingleWuaMaster(body).subscribe((resolve: any) => {
      this.aWuaDetails = resolve.data;
      // console.log(this.aWuaDetails)
      // console.log("----------");
      this.editWuaDetails(this.aWuaDetails)
      // console.log(this.aWuaDetails)
    });
  }

  editWuaDetails(wuaDetails: any) {


    // console.log("Shreya");
    console.log(wuaDetails);
    this.wuaForm.patchValue({
      wua_id: wuaDetails.wua_id,
      wua_project_primary_id: wuaDetails.wua_project_primary_id,
      wua_name: wuaDetails.wua_name,
      wua_name_marathi: wuaDetails.wua_name_marathi,
      wua_address: wuaDetails.wua_address,
      wua_address_marathi: wuaDetails.wua_address_marathi,
      //wau_river_basin: new FormControl('', [Validators.required]),
      //   wua_river_subbasin: new FormControl('', [Validators.required]),
      wua_type: wuaDetails.wua_type,
      wua_category: wuaDetails.wua_category,

      wua_territorial_reach: wuaDetails.wua_territorial_reach,
      wua_under_mmisf_act_cooperative_society: wuaDetails.wua_under_mmisf_act_cooperative_society,
      wua_registration_no: wuaDetails.wua_registration_no,
      wua_registration_date: wuaDetails.wua_registration_date,
      wua_status: wuaDetails.wua_status,
      wua_measuring_installed: wuaDetails.wua_measuring_installed,
      wua_irregation_source: wuaDetails.wua_irregation_source,
      // wua_branch_id: wuaDetails.a,
      // wua_distributory_id: wuaDetails.a,
      // wua_minor_id: wuaDetails.a,
      // wua_subminor_id: wuaDetails.a,
      // wua_outlet_id: wuaDetails.a,
      // wua_main_canal_id: wuaDetails.a,
      wua_bank_name: wuaDetails.wua_bank_name,
      wua_bank_ac_no: wuaDetails.wua_bank_ac_no,
      wua_dam_primary_id: wuaDetails.wua_dam_primary_id,
      wua_pincode: wuaDetails.wua_pincode,
      wua_project_layout_id: wuaDetails.wua_project_layout_id,
      // wua_canal_bank_type_id: wuaDetails.a,
      wua_bank_branch_name: wuaDetails.wua_bank_branch_name,
      wua_district_code: wuaDetails.wua_district_code,
      wua_taluka_code: wuaDetails.wua_taluka_code,
      wua_village_code: wuaDetails.wua_village_code,

      // wua_delineation_area_ha: wuaDetails.a,
      // wua_total_area_ha: wuaDetails.a,
      // wua_no_of_beneficiaries: wuaDetails.a,

      // wua_bennefitted_district_code: wuaDetails.a,
      // wua_bennefitted_taluka_code: wuaDetails.a,
      // wua_bennefitted_village_code: wuaDetails.a,

      wua_added_by_user_office_type: wuaDetails.wua_added_by_user_office_type,
      wua_added_by_user_office_id: wuaDetails.wua_added_by_user_office_id,
    });

    if (wuaDetails.wua_irregation_source == 1 || wuaDetails.wua_irregation_source == 2) {

      this.wuaForm.patchValue({
        wua_canal_bank_type_id: wuaDetails.wua_canal_bank_type_id,
        main_canal_bank_id: wuaDetails.wua_canal_bank_type_id,
        wua_main_canal_id: wuaDetails.wua_main_canal_id,
        wua_branch_id: wuaDetails.wua_branch_id,
        wua_distributory_id: wuaDetails.wua_distributory_id,
        wua_minor_id: wuaDetails.wua_minor_id,
        wua_subminor_id: wuaDetails.wua_subminor_id,
        wua_outlet_id: wuaDetails.wua_outlet_id
      })
      this.isShown = true;
      this.isShowLiftIrri = false
      this.showcanal = true
      this.showbankType = true;
      this.BasinId = wuaDetails.wau_river_basin;
      this.SubBasinId = wuaDetails.wua_river_subbasin;
      this.Basin = wuaDetails.wau_river_basin
      this.SubBasin = wuaDetails.wua_river_subbasin
      this.basinMarathiName = wuaDetails.wau_river_basin
      this.subbasinMarathiName = wuaDetails.wua_river_subbasin
      this.getMainCanel()
    }

    this.getDamList(this.aWuaDetails.wua_project_primary_id)
    this.forFamerTaluka(this.aWuaDetails.wua_district_code)

    if (wuaDetails.mwrd_wua_benefitted_details_masters != null || wuaDetails.mwrd_wua_benefitted_details_masters != undefined) {
      let benefittedDetails = wuaDetails.mwrd_wua_benefitted_details_masters;


      // console.log(benefittedDetails, '==');

      //return false;
      benefittedDetails.forEach(element => {

        //  console.log("here");
        //  console.log(element);
        // console.log(  element.mwrd_taluka_master.district_name);
        // element.mwrd_taluka_master.district_name
        element.mwrd_village_master.district_name
        // this.reqData.wua_bennefitted_district_code = element.wua_bennefitted_district_code;
        // this.reqData.wua_bennefitted_taluka_code = element.wua_bennefitted_taluka_code;
        // this.reqData.wua_bennefitted_village_code = element.wua_bennefitted_village_code;
        // this.reqData.wua_bennefitted_id = element.wua_bennefitted_id;
        // this.reqData.wua_master_id = element.wua_master_id;
        this.reqData.wua_delineation_area = element.wua_delineation_area;
        this.reqData.wua_no_of_beneficiaries = element.wua_no_of_beneficiaries
        this.reqData.wua_total_area_ha = element.wua_total_area_ha;


        this.reqData.wua_bennefitted_district_name = element.mwrd_district_master.district_name
        this.reqData.wua_bennefitted_taluka_name = element.mwrd_taluka_master.taluka_name;
        this.reqData.wua_bennefitted_village_name = element.mwrd_village_master.village_name



        this.pushBenefitedDetails();

      });
    }

  }

  getProjectList() {
    this.http.getDamMasterProject().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;

      } else {
        //console.log( data1.data);
        this.projectSelect = data1.data;


      }
    })
  }

  getBasins() {
    this.http.getdamBasins().subscribe((resolve: any) => {
      this.getdamBasinsArray = resolve.data;
      // console.log(resolve.data,"====");
    })

  }

  getSubBasins(data) {
    const body = new HttpParams({
      fromObject: {
        subbasin_basin_id: data,
      }
    });
    this.http.getSubbasins(body).subscribe((resolve: any) => {
      this.getdamSubBasinsArray = resolve.data
      //console.log(resolve.data,"====");
    })
  }

  translateIntoMarathi(text, param) {
    if (!text) {
      return
    }

    const body = new HttpParams({
      fromObject: {
        text: text,
      }
    });

    this.http.englishToMarathi(body).pipe(debounceTime(2000)).subscribe((data: any) => {
      switch (param) {
        case "name":
          this.wuaForm.patchValue({ wua_name_marathi: data.data })
          break;
        case "address":
          this.wuaForm.patchValue({ wua_address_marathi: data.data })
          break;
      }

    })
  }

  getWuaType() {
    this.wuaTypeSelect = []
    this.wuaService.getWuaTypes().subscribe((resolve: any) => {
      if (resolve.status == "0") {
        // console.log("Wua Types Didn't Get")
        return false;
      }
      else {
        //  console.log(resolve.data)
        // return;
        this.wuaTypeSelect = resolve.data.wua_types
      }
    });
  }

  getWuaCategories() {
    this.wuaCategorySelect = []
    this.wuaService.getWuaCategories().subscribe((resolve: any) => {
      if (resolve.data) {
        this.wuaCategorySelect = resolve.data.wua_category
      }
      else {
        console.log("No wua category found!");
      }
    })
  }

  getAllDistrict() {
    this.http.getDistricts().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      }
      else {
        this.districtSelect = data1.data;
      }
    })
  }



  getWuaTerritorial() {
    this.territorialSelect = []
    this.wuaService.getWuaTerritorial().subscribe((resolve: any) => {
      if (resolve.data) {
        this.territorialSelect = resolve.data.wua_territorials
      }
      else {
        console.log('No territorial found!');
      }
    })
  }

  getWuaActCooperative() {
    this.cooperativeSelect = []
    this.wuaService.getWuaActs().subscribe((resolve: any) => {
      if (resolve.data) {
        this.cooperativeSelect = resolve.data.wua_acts
      }
      else {
        console.log('No wua act cooperative found!')
      }
    })
  }

  getWuaStatuses() {
    this.wuaStatusSelect = [];
    this.wuaService.getWuaStatus().subscribe((resolve: any) => {
      if (resolve.data) {
        this.wuaStatusSelect = resolve.data.wua_statuses
      }
      else {
        // console.log(resolve.message)
      }
    })
  }

  getBack() {
    this.router.navigate(['viewWua']);
  }

  pushBenefitedDetails() {

    // console.log("push")

    // console.log(this.reqData)
    console.log(this.wuaForm)
    if (this.wuaForm.controls['wua_delineation_area_ha'].invalid) {
      this.sendAlert('wua delineation area ha Not valid');
      return;
    }

    if (this.wuaForm.controls['wua_total_area_ha'].invalid) {
      this.sendAlert('wua total area ha Not valid');
      return;
    }

    if (this.wuaForm.controls['wua_no_of_beneficiaries'].invalid) {
      this.sendAlert('wua no of beneficiaries Not valid');
      return;
    }

    if (this.wuaForm.controls['wua_bennefitted_district_code'].invalid) {
      this.sendAlert('wua bennefitted district code Not valid');
      return;
    }

    if (this.wuaForm.controls['wua_bennefitted_taluka_code'].invalid) {
      this.sendAlert('wua bennefitted taluka code Not valid');
      return;
    }

    if (this.wuaForm.controls['wua_bennefitted_village_code'].invalid) {
      this.sendAlert('wua bennefitted village code* Not valid');
      return;
    }
    // return

    let post_user_id = localStorage.getItem('post_user_id');
    this.reqData.user_post_id = post_user_id
    //console.log(this.reqData);
    this.reqData.districtname = this.districtName;
    this.reqData.villageName = this.VillageName;
    this.reqData.talukaName = this.talukaName;
    this.reqData.is_deleted = 0;



    if (this.route.snapshot.params['id']) {
      // this.benifitedAreaDetails = [];
      // this.wua_id = this.route.snapshot.params['id'];
      //this.getSingleWuaDetails(this.wua_id);

      this.reqData.districtname = this.reqData.wua_bennefitted_district_name;
      this.reqData.villageName = this.reqData.wua_bennefitted_village_name;
      this.reqData.talukaName = this.reqData.wua_bennefitted_taluka_name;
      this.reqData.is_deleted = 0;
      this.benifitedAreaDetails.push(this.reqData)
    }
    else
      this.benifitedAreaDetails.push(this.reqData)

    this.reqData = {}
    this.wuaDistructNameMarathi = null;
    this.wuaTallukaNameMarathi = null;
    this.wuaVillageNameMarathi = null;
    this.wuaDistructName = null;
    this.wuaTallukaName = null;
    this.wuaVillageName = null;
    console.log(this.benifitedAreaDetails)
  }

  deleteGutDetails(i) {
    this.benifitedAreaDetails.splice(i, 1);
    //	console.log(this.newGutDetailsArray)

  }

  getBranchCanalList(id) {

    this.wuaForm.patchValue({
      wua_branch_id: null,
      wua_distributory_id: null,
      wua_minor_id: null,
      wua_subminor_id: null,
      wua_outlet_id: null,
    })

    // this.wuaForm.value.wua_branch_id = null
    // this.wuaForm.value.wua_distributory_id = null
    // this.wuaForm.value.wua_minor_id = null
    // this.wuaForm.value.wua_subminor_id = null
    // this.wuaForm.value.wua_outlet_id = null

    if (id == 'no') {
      return;
    }
    else {

      this.mainCanelId = id
      const body = new HttpParams({
        fromObject: {
          project_unique_gis: this.projectGisCode,
          dam_unique_code: this.getDamId,
          main_canal_id: id,
          branch_canal_bank_id: this.wuaForm.value.wua_canal_bank_type_id
        }
      });
      this.http.getBranchCanel(body).subscribe((resolve: any) => {
        // console.log(resolve);
        this.branchCanelArray = resolve.data
        if (this.route.snapshot.params.id) {
          this.wuaForm.patchValue({ wua_branch_id: this.getWuaDetails.wua_branch_id })
          this.getDistributoryList(this.getWuaDetails.farmer_master_canal_branch_id)
        }
      })
    }
  }

  getDistributoryList(id) {

    this.wuaForm.patchValue({
      // wua_branch_id: null,
      wua_distributory_id: null,
      wua_minor_id: null,
      wua_subminor_id: null,
      wua_outlet_id: null,
    })

    this.branchCanelId = id
    const body = new HttpParams({
      fromObject: {
        project_unique_gis: this.projectGisCode,
        dam_unique_code: this.getDamId,
        main_canal_id: this.mainCanelId,
        branch_canal_id: id,
        distributory_bank_id: this.wuaForm.value.wua_canal_bank_type_id
      }
    });
    this.http.getdistributory(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.distributoryArray = resolve.data
      if (this.route.snapshot.params.id) {
        this.wuaForm.patchValue({ wua_distributory_id: this.getWuaDetails.wua_distributory_id })
        this.getMinorList(this.getWuaDetails.wua_distributory_id)
      }
    })
  }

  getMinorList(id) {
    this.wuaForm.patchValue({
      wua_minor_id: null,
      wua_subminor_id: null,
      wua_outlet_id: null,
    })

    this.distributoryUniqueId = id
    const body = new HttpParams({
      fromObject: {
        project_unique_gis: this.projectGisCode,
        dam_unique_code: this.getDamId,
        main_canal_id: this.mainCanelId,
        branch_canal_id: this.branchCanelId,
        distributory_canal_id: id,
        minor_bank_id: this.wuaForm.value.wua_canal_bank_type_id
      }
    });
    this.http.getMinorListStep1(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.minorCanelArray = resolve.data
      if (this.route.snapshot.params.id) {
        this.wuaForm.patchValue({ wua_minor_id: this.getWuaDetails.wua_minor_id })
        this.getSubMinorList(this.getWuaDetails.wua_minor_id)
      }
    })
  }


  getSubMinorList(id) {

    this.wuaForm.patchValue({
      // wua_minor_id: null,
      wua_subminor_id: null,
      wua_outlet_id: null,
    })

    this.minorUniqueId = id
    const body = new HttpParams({
      fromObject: {
        project_unique_gis: this.projectGisCode,
        dam_unique_code: this.getDamId,
        main_canal_id: this.mainCanelId,
        branch_canal_id: this.branchCanelId,
        distributory_canal_id: this.distributoryUniqueId,
        minor_canal_id: id,
        subminor_bank_id: this.wuaForm.value.wua_canal_bank_type_id
      }
    });
    this.http.getSubMinorListStep1(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.subMinorCanelArray = resolve.data
      if (this.route.snapshot.params.id) {
        this.wuaForm.patchValue({ wua_subminor_id: this.getWuaDetails.farmer_master_subminor_id })
        this.getOutletList(this.getWuaDetails.farmer_master_subminor_id)
      }

    })
  }


  getOutletList(id) {
    this.wuaForm.patchValue({
      // wua_minor_id: null,
      // wua_subminor_id: null,
      wua_outlet_id: null,
    })
    this.subMinorUniqueId = id
    const body = new HttpParams({
      fromObject: {
        project_unique_gis: this.projectGisCode,
        dam_unique_code: this.getDamId,
        main_canal_id: this.mainCanelId,
        branch_canal_id: this.branchCanelId,
        distributory_canal_id: this.distributoryUniqueId,
        minor_canal_id: this.minorUniqueId,
        subminor_canal_id: id,
        outlet_bank_id: this.wuaForm.value.wua_canal_bank_type_id
      }
    });
    this.http.getoutletListing(body).subscribe((data: any) => {
      this.getOutlistingArray = data.data
      if (this.route.snapshot.params.id) {
        this.wuaForm.patchValue({ farmer_master_outlet_id: this.getWuaDetails.farmer_master_outlet_number })
      }
    })
  }

  getAllBank() {
    this.http.getAllBank().subscribe((resolve: any) => {
      this.BankList = resolve.data;
      // console.log("Shreya");
      // console.log(this.BankList);
    })

  }

  updateWua() {


    console.log(this.wuaForm);
    if (this.wuaForm.status == "INVALID") {
      this.sendAlert('All fields are mandatory')
      return;
    }


    if (this.isShown == true) {
      // if (this.wuaForm.value.wua_main_canal_id == "" || this.wuaForm.value.wua_main_canal_id == undefined || this.wuaForm.value.wua_main_canal_id == null) {
      //   this.sendAlert('Main Canal Is Mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_branch_id == "" || this.wuaForm.value.wua_branch_id == undefined || this.wuaForm.value.wua_branch_id == null) {
      //   this.sendAlert('Branch Canal Is Mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_distributory_id == "" || this.wuaForm.value.wua_distributory_id == undefined || this.wuaForm.value.wua_distributory_id == null) {
      //   this.sendAlert('Distributory are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_minor_id == "" || this.wuaForm.value.wua_minor_id == undefined || this.wuaForm.value.wua_minor_id == null) {
      //   this.sendAlert('Minor are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_subminor_id == "" || this.wuaForm.value.wua_subminor_id == undefined || this.wuaForm.value.wua_subminor_id == null) {
      //   this.sendAlert('Sub Minor are mandatory')
      //   return
      // }
      // if (this.wuaForm.value.wua_outlet_id == "" || this.wuaForm.value.wua_outlet_id == undefined || this.wuaForm.value.wua_outlet_id == null) {
      //   this.sendAlert('Outlet are mandatory')
      //   return
      // }
      if (this.wuaForm.value.wua_project_layout_id == "" || this.wuaForm.value.wua_project_layout_id == undefined || this.wuaForm.value.wua_project_layout_id == null) {
        this.sendAlert('Please select project layout')
        return
      }


    }

    var newData: any = JSON.stringify(this.benifitedAreaDetails)
    this.wuaForm.value.wua_benefitted_details = newData;
    // this.wuaForm.value.wau_river_basin = this.BasinId;
    // this.wuaForm.value.wua_river_subbasin = this.SubBasinId;
    // this.wuaForm.patchValue({
    //   wau_river_basin: this.BasinId,
    //   wua_river_subbasin: this.SubBasinId,
    //   wua_id: this.wua_id
    // })
    this.wuaForm.patchValue({
      wau_river_basin: 1,
      wua_river_subbasin: 2,
      wua_id: this.wua_id
    })


    // console.log(this.wuaForm);

    // return false;
    this.wuaForm.value.wua_benefitted_details = newData
    const body = new HttpParams({
      fromObject: this.wuaForm.value
    });
    this.http.addWuaMaster(body).subscribe((resolve: any) => {

      if (this.selectedlang == 'mr') {

        alert("सदर माहिती जतन करण्यात आलेली आहे.");

      } else {
        alert("This information has been saved");

      }

      // this.sendAlert('Wua updated successfully');
      this.router.navigate(["/viewWua"])

      // if (this.contentEditable == true) {
      // } else {

      // }

    })

  }

  getProjectLayouts() {
    const body = new HttpParams({
      fromObject: {
        project_id: this.wuaForm.value.wua_project_primary_id,
        dam_id: this.wuaForm.value.wua_dam_primary_id,
        bank_id: this.wuaForm.value.wua_canal_bank_type_id,
      }
    });

    // console.log(body)

    this.http.getprojectLayout(body).subscribe((resolve: any) => {
      // console.log(resolve)
      if (resolve.status)
        this.projectLayoutArray = resolve.data
      else
        console.log(resolve.message)
    });
  }

  onProjectLayoutChange(layoutId) {
    // console.log(layoutId)

    if (layoutId == 0) {
      this.wuaForm.patchValue({
        wua_main_canal_id: 0,
        wua_branch_id: 0,
        wua_distributory_id: 0,
        wua_minor_id: 0,
        wua_subminor_id: 0,
        wua_outlet_id: 0
      })
    }
    else {
      var item;
      item = this.projectLayoutArray.find(abcd => abcd.project_layout_id == layoutId);
      // console.log(item)
      this.wuaForm.patchValue({
        wua_main_canal_id: item.project_layout_main_canal_id,
        wua_branch_id: item.project_layout_branch_canal_id,
        wua_distributory_id: item.project_layout_distributory_canal_id,
        wua_minor_id: item.project_layout_minor_canal_id,
        wua_subminor_id: item.project_layout_subminor_canal_id,
        wua_outlet_id: item.project_layout_outlet_id
      })
    }
    console.log(this.wuaForm)
  }


}
