import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpComponent } from './otp/otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewFarmerComponent } from './view-farmer/view-farmer.component';
import { StepOneComponent } from './step-one/step-one.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { FooterComponent } from './footer/footer.component';
import { VerifyDetailsComponent } from './verify-details/verify-details.component';
import { ProfileComponent } from './profile/profile.component';

import { VerifyProjectComponent } from './verify-project/verify-project.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { WuaMasterComponent } from './wua-master/wua-master.component';
import { ViewWuaComponent } from './view-wua/view-wua.component';
import { IrrigationComponent } from './irrigation/irrigation.component';
import { DamsComponent } from './dams/dams.component';
import { ProjectLayoutComponent } from './project-layout/project-layout.component';
import { ViewProjectLayoutComponent } from './view-project-layout/view-project-layout.component';
import { ProjectLayoutNewComponent } from './project-layout-new/project-layout-new.component';
import { FarmerMasterComponent } from './farmer-master/farmer-master.component';
import { ProjectMasterComponent } from './project-master/project-master.component';

import { DamMasterComponent } from './dam-master/dam-master.component';
import { ViewProjectMasterComponent } from './view-project-master/view-project-master.component';
import { ViewDamMasterComponent } from './view-dam-master/view-dam-master.component';
import { DemoDatatablesComponent } from './demo-datatables/demo-datatables.component';
import { AuthGuard } from './authguard/auth.guard'
// import { NestedTreeComponent } from './shared/nested-tree/nested-tree.component';





const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'otp', component: OtpComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'ViewFarmer', component: ViewFarmerComponent, canActivate: [AuthGuard] },
  { path: 'StepOne', component: StepOneComponent, canActivate: [AuthGuard] },

  { path: 'StepTwo', component: StepTwoComponent, canActivate: [AuthGuard] },
  { path: 'StepTwo/:id', component: StepTwoComponent, canActivate: [AuthGuard] },
  { path: 'StepThree', component: StepThreeComponent, canActivate: [AuthGuard] },

  { path: 'StepThree/:id', component: StepThreeComponent, canActivate: [AuthGuard] },
  { path: 'Footer', component: FooterComponent, canActivate: [AuthGuard] },
  { path: 'verifyDetails', component: VerifyDetailsComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'verifyProject', component: VerifyProjectComponent, canActivate: [AuthGuard] },
  { path: 'userSelect', component: UserSelectComponent, canActivate: [AuthGuard] },
  { path: 'wuaMaster', component: WuaMasterComponent, canActivate: [AuthGuard] },
  { path: 'viewWua', component: ViewWuaComponent, canActivate: [AuthGuard] },
  { path: 'dams', component: DamsComponent, canActivate: [AuthGuard] },
  { path: 'irrigation', component: IrrigationComponent, canActivate: [AuthGuard] },

  { path: 'wuaMaster/:id', component: WuaMasterComponent, canActivate: [AuthGuard] },

  { path: 'viewProject', component: ViewProjectLayoutComponent, canActivate: [AuthGuard] },
  { path: 'projectLayout', component: ProjectLayoutNewComponent, canActivate: [AuthGuard] },
  { path: 'projectLayoutold', component: ProjectLayoutComponent, canActivate: [AuthGuard] },
  { path: 'farmer-master', component: FarmerMasterComponent, canActivate: [AuthGuard] },
  { path: 'edit-farmer/:id', component: FarmerMasterComponent, canActivate: [AuthGuard] },
  { path: 'wua-master/:id', component: WuaMasterComponent, canActivate: [AuthGuard] },
  { path: 'addFarmer', component: FarmerMasterComponent, canActivate: [AuthGuard] },
  { path: 'damMaster', component: DamMasterComponent, canActivate: [AuthGuard] },
  { path: 'projectMaster', component: ProjectMasterComponent, canActivate: [AuthGuard] },
  { path: 'edit-project-master/:id', component: ProjectMasterComponent, canActivate: [AuthGuard] },
  { path: 'ViewprojectMaster', component: ViewProjectMasterComponent, canActivate: [AuthGuard] },
  { path: 'viewdamMaster', component: ViewDamMasterComponent, canActivate: [AuthGuard] },
  { path: 'edit-dam-master/:id', component: DamMasterComponent, canActivate: [AuthGuard] },
  { path: 'demo-datatables', component: DemoDatatablesComponent, canActivate: [AuthGuard] },
  // { path: 'nestedTree', component: NestedTreeComponent }



];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
