import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WuaMasterService } from './../_service/wua-master.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpRequestService } from './../_service/http-request.service';

declare var $: any;
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { HeaderComponent } from '../header/header.component';
import { DataTableDirective } from 'angular-datatables';

import { ExcelService } from '../_service/excel.service';
// import "rxjs/add/operator/map";
@Component({
  selector: 'app-view-wua',
  templateUrl: './view-wua.component.html',
  styleUrls: ['./view-wua.component.css']
})
export class ViewWuaComponent implements OnInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dataArray = []
  dtOptions: DataTables.Settings = {};
  clickEventsubscription: Subscription;

  userName = localStorage.getItem('userName');
  projectName = localStorage.getItem('project_gis_no');
  id = localStorage.getItem('post_user_id');
  selectedlang = localStorage.getItem('selected_lang');
  wuaDataAgainstProjectCode: any;
  FirstKey: string;
  userOfficeId: any;
  WuaList: any;
  dtTrigger: Subject<any> = new Subject();
  aWuaDetails: any;
  userOfficeType: string
  userPosition: string
  enableAddOption: boolean = false
  enableEditOption: boolean = false
  enableApproveOption: boolean = false
  excelData: any[];
  UserType: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private wuaService: WuaMasterService,
    public translate: TranslateService,
    private sharedService: SharedService,
    private excelService: ExcelService,

    private ngxService: NgxUiLoaderService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      //  console.log("test11")

      this.ngxService.stop();
      this.selectedlang = localStorage.getItem('selected_lang');
      //console.log("shreya")
      //console.log(this.selectedlang);

      this.ngOnInit();
    })

    this.ngxService.stop();
  }

  ngOnInit() {
    // this.getWuaLsiting();

    if (this.selectedlang == 'mr') {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.marathi_datatables

      };
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.english_datatables

      };

    }
    this.UserType = localStorage.getItem('user_type')
    this.getUserOfficeDetails();
  }


  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      // console.log("I am here")
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();

              // console.log(that.search(this['value']).draw())
            }
          });
        });
      });

      this.dtTrigger.complete();
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  // this.getWUAData();


  getUserOfficeDetails() {

    // console.log(localStorage);
    // alert("ggg")

    let post_user_id = localStorage.getItem('post_user_id');

    //  console.log(post_user_id);
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });

    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;

      //console.log(resolve.data,'=====')

      for (let key in resolve.data.userDetail.user_office_detail) {
        this.FirstKey = key;
        this.userOfficeType = key;
        break;
      }
      // console.log(this.userOfficeType)


      this.userOfficeId = resolve.data.userDetail.user_office_id;
      this.getWuaLsiting();

      this.userPosition = resolve.data.userDetail.user_post

      if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (resolve.data.userDetail.user_type == 0)) {
        // console.log(this.userOfficeType + "=============" + this.userPosition);

        this.enableAddOption = true
        this.enableEditOption = true
      }

      if (this.userOfficeType == "Circle" || resolve.data.userDetail.user_type == 0) {
        this.enableApproveOption = true
      }
      // console.log(localStorage.getItem('user_post') + "++++++++++++++=")


    },
      (err) => {

        console.log(err);


      }
    )

  }

  getWuaLsiting() {
    this.ngxService.start();
    const body = new HttpParams({
      fromObject: {
        office_id: this.userOfficeId,
        user_office_type: this.userOfficeType,
        wua_user_type: localStorage.getItem('user_type')
      }
    });

    // console.log(body);

    this.wuaService.getWuaLsiting(body).subscribe((resolve: any) => {
      // let data1 =resolve;
      // console.log(data1);

      this.wuaDataAgainstProjectCode = resolve.data;

      this.dtTrigger.next();
      this.ngxService.stop();


    })
    // this.ngxService.stop();
  }

  editWua(wuaId) {
    this.router.navigate(['/wua-master', wuaId]);
  }
  Add() {

    this.router.navigate(['wuaMaster']);

  }

  enableViewModel(wua_id) {
    this.show();
    this.getSingleProjectDetails(wua_id)
  }

  // getUserDetails() {
  //   const body = new HttpParams({
  //     fromObject: {
  //       post_user_id: localStorage.getItem('post_user_id'),
  //     }
  //   });
  //   this.http.getUserDetail(body).subscribe((resolve: any) => {
  //     for (let key in resolve.data.userDetail.user_office_detail) {
  //       this.userOfficeType = key;
  //       break;
  //     }
  //     this.userPosition = resolve.data.userDetail.user_post
  //     //Superintending Engineer and Deputy Secretary (Technical) - circle
  //     //Executive Engineer and Under Secretary (Technical) - division

  //     if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer'))) {
  //       this.enableAddOption = true
  //       this.enableEditOption = true
  //     }

  //     if (this.userOfficeType == "Circle") {
  //       this.enableApproveOption = true
  //     }
  //   });
  // }

  getSingleProjectDetails(wuaId) {
    const body = new HttpParams({
      fromObject: {
        wua_id: wuaId,
      }
    });
    this.http.getSingleWuaMaster(body).subscribe((resolve: any) => {
      this.aWuaDetails = resolve;
      // console.log(this.aWuaDetails.data.wua_registration_date);
      // console.log("----------");
      // console.log(this.aWuaDetails)
    });
  }

  show() {
    $('#preModal').show()
  }

  close() {
    $("#preModal").hide();
  }

  approveWua(wuaId) {
    var body = new HttpParams({
      fromObject: {
        wua_id: wuaId,
        wua_is_approved: "1",
        post_user_id: localStorage.getItem('post_user_id')
      }
    })
    this.wuaService.approvedWuaMaster(body).subscribe((resolve: any) => {
      if (resolve.status && resolve.data) {
        if (this.selectedlang == 'mr') {

          alert("सदर माहिती जतन करण्यात आलेली आहे.");

        } else {
          alert("This information has been saved");

        }
        // this.sendAlert("WUA Approved Successfully!")
        this.ngOnInit()
      }
      else {
        this.sendAlert("Something went wrong, while approving WUA")
      }
    })
  }

  sendAlert(message) {
    switch (this.selectedlang) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }



  exportAsXLSX(): void {

    this.ngxService.start();

    this.excelData = [];
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        office_id: localStorage.getItem('user_office_id'),
        wua_user_type: localStorage.getItem('user_type')
      }
    });

    this.wuaService.getWuaLsiting(body).subscribe((resolve: any) => {
      console.log(resolve);

      // return 
      this.dataArray = resolve.data;
      for (var i in this.dataArray) {

        if (this.dataArray[i].mwrd_project_master) {
          var projectName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_master.project_name : this.dataArray[i].mwrd_project_master.project_name_marathi
        } else {
          projectName = '';
        }

        if (this.dataArray[i]) {

          var dam_name = this.dataArray[i].dam_name
          var dam_name_marathi = this.dataArray[i].dam_name_marathi
        } else {
          dam_name = '';
          dam_name_marathi = ''

        }

        if (this.dataArray[i].mwrd_dam_master)
          var damstoragecreation = this.selectedlang == 'en' ? this.dataArray[i].mwrd_dam_storage_creation_master.storage_creation_name : this.dataArray[i].mwrd_dam_storage_creation_master.storage_creation_name_marathi
        else
          damstoragecreation = '';

        if (this.dataArray[i].mwrd_project_ownership_master)
          var projectOwnership = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_ownership_master.project_owner_name : this.dataArray[i].mwrd_project_ownership_master.project_marathi_owner_name
        else
          projectOwnership = '';


        if (this.dataArray[i].mwrd_state_master)
          var state = this.selectedlang == 'en' ? this.dataArray[i].mwrd_state_master.state_name : this.dataArray[i].mwrd_state_master.state_marathi_name
        else
          state = '';

        if (this.dataArray[i].mwrd_district_master)
          var district = this.selectedlang == 'en' ? this.dataArray[i].mwrd_district_master.district_name : this.dataArray[i].mwrd_district_master.district_name_marathi
        else
          district = '';


        if (this.dataArray[i].mwrd_taluka_master)
          var taluka = this.selectedlang == 'en' ? this.dataArray[i].mwrd_taluka_master.taluka_name : this.dataArray[i].mwrd_taluka_master.taluka_name_marathi
        else
          taluka = '';

        if (this.dataArray[i].mwrd_village_master)
          var village = this.selectedlang == 'en' ? this.dataArray[i].mwrd_village_master.village_name : this.dataArray[i].mwrd_village_master.village_name_marathi
        else
          village = '';

        if (this.dataArray[i].mwrd_basin_master)
          var basin = this.selectedlang == 'en' ? this.dataArray[i].mwrd_basin_master.basin_name : this.dataArray[i].mwrd_basin_master.basin_name_marathi
        else
          basin = '';

        if (this.dataArray[i].mwrd_subbasin_master)
          var subbasin = this.selectedlang == 'en' ? this.dataArray[i].mwrd_subbasin_master.subbasin_name : this.dataArray[i].mwrd_subbasin_master.subbasin_name_marathi
        else
          subbasin = '';




        if (this.dataArray[i].project_is_interstate) {

          var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय';



        } else {

          var Yes = this.selectedlang == 'en' ? 'No' : 'नाही'

        }

        //  var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय'
        //var No = this.selectedlang == 'en' ? 'No' : 'नाही'
        var dateTo = new Date((this.dataArray[i].farmer_master_valid_from));
        var dateToUpto = new Date((this.dataArray[i].farmer_master_valid_upto));
        let dateTo_split = '';
        let dateToUpto_split = '';
        this.excelData.push(
          {

            'Name of Project': projectName,
            'Name of Dam': projectName,
            'Name of Wua (English )': dam_name,
            'Name of Wua Marathi': dam_name_marathi,
            'Address Of WUA': damstoragecreation,
            'Address Of WUA Marathi ': district,
            'District': district,
            'Taluka': taluka,
            'Village': village,
            'Pincode': village,
            'WUA Type': village,
            'WUA Category': village,
            'Territorial Reach': village,
            'WUA Under MMISF Act/Co-operative soceity': village,
            'Registration No*': '',
            'Registration Date': '',
            'Status': '',
            'Measuring Device Installed ': '',
            'Basin': basin,
            'Sub Basin': subbasin,
            'Irrigation Source': '',
            'Canal Type': ' ',
            'Name of Main Canal': '',
            'Name of Branch Canal': '',
            'Name of Distributory Canal': '',
            'Name of Minor': '',
            'Name of SubMinor': '',
            'Name of Outlet': '',
            'Name of Bank': '',
            'Name of Branch': '',
            'Bank Account Number': ''



          }
        )

      }


      this.excelService.exportAsExcelFile(this.excelData, 'export-to-excel');

      this.ngxService.stop();




    })


  }

}
