<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">

        <!-- <app-sidebar></app-sidebar> -->


        <div class="dashbordright">

            <app-header></app-header>
            <app-sidebar></app-sidebar>


            <div class="greenstrip fullwidth">
                <h1>Farmer Master</h1>
            </div>
            <div class="stepimg fullwidth">
                <img src="assets/images/step2.jpg" alt="step2" />
            </div>


            <div class="mid-content fullwidth formstep">



                <div class="row">

                    <div class="col-md-12 recentlistbx">
                        <h2 class="smlheadh2 col-md-12"><span>Basic Details</span></h2>

                    </div>


                    <form [formGroup]="twoStepForm" class="stepfrmcmn stepfrm2" (ngSubmit)="submit()">

                        <div class="row frmrow">

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer Ndddame</label>
                                <input type="text" placeholder="Vedprakash" class="stepinpt"
                                    formControlName="farmer_master_farmer_name">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer Code </label>
                                <input type="text" placeholder="FC-589210" disabled class="stepinpt"
                                    formControlName="farmer_master_farmer_code">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer Gender</label>
                                <select class="stepinpt" formControlName="farmer_master_farmer_gender">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>

                        </div>


                        <div class="row frmrow">

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's Mobile number </label>
                                <input type="text" placeholder="1234567890" class="stepinpt"
                                    formControlName="farmer_master_farmer_mobile_no">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's Whatsapp Number </label>
                                <input type="text" placeholder="0987654321" class="stepinpt"
                                    formControlName="farmer_master_farmer_whatsapp_no">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's Email </label>
                                <input type="text" placeholder="xyz@abc.com" class="stepinpt"
                                    formControlName="farmer_master_farmer_email">
                            </div>

                        </div>


                        <div class="row frmrow">

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's District</label>
                                <select class="stepinpt" formControlName="farmer_master_farmer_district_id">
                                    <option *ngFor="let list of farmertDistArray" [value]="list.district_id">
                                        {{list.district_name}}</option>
                                </select>

                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's Taluka</label>
                                <input type="text" placeholder="Raigad" class="stepinpt"
                                    formControlName="farmer_master_farmer_taluka">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer's Village</label>
                                <input type="text" placeholder="Kurkur Village" class="stepinpt"
                                    formControlName="farmer_master_farmer_village">
                            </div>

                        </div>


                        <div class="row frmrow">

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Former's Address</label>
                                <input type="text" placeholder="4, 11, Dadri, Gautam Budh Nagar, UP" class="stepinpt"
                                    formControlName="farmer_master_farmer_address">
                            </div>

                            <div class="col-md-4 inptbx">
                                <label class="steplbl">Farmer Ledger Number (Khatawani) </label>
                                <input type="text" placeholder="LD0001230" class="stepinpt"
                                    formControlName="farmer_master_farmer_ledger_no">
                            </div>

                        </div>



                        <div class="row frmrow">

                            <div class="col-md-12 inptbx">
                                <input class="stepgreenbtn" type="button" value="Save" (click)="submit()" />
                                <input class="stepgreenbtn" type="submit" value="Submit" />
                                <input class="stepgreybtn" type="reset" value="Reset" />
                            </div>

                        </div>

                    </form>

                </div>




            </div>


        </div>

    </div>
</div>