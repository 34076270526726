<body class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position dmamaster-page">
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- top blue nav over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'Dam Master' | translate}}</h1>
            </div>
            <div class="mid-content fullwidth formstep overlapmultiselect mulitiselectdammas overlaperrorlbl">
                <div class="row">
                    <div class="col-md-12 recentlistbx">
                        <h2 class="smlheadh2 col-md-12"><span>{{'Salient Features of Dam' | translate}}</span></h2>
                    </div>
                    <div class="stepfrmcmn stepfrm1">
                        <form [formGroup]="dammasterForm">
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name of Project' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <select class="stepinpt" formControlName="dam_project_primary_id">
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let project of projectSelect;" [value]="project.project_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{project.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{project.project_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_project_primary_id'].invalid &&
                                    (dammasterForm.controls['dam_project_primary_id'].dirty || dammasterForm.controls['dam_project_primary_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_project_primary_id'].errors.required">
                                            {{'Please select project!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name (English)' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <input class="stepinpt" type="text"
                                        (blur)="translateIntoMarathi($event.target.value)" formControlName="dam_name">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_name'].invalid &&
                                    (dammasterForm.controls['dam_name'].dirty || dammasterForm.controls['dam_name'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_name'].errors.required">
                                           {{'Please enter Dam name' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name (Marathi)' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <input class="stepinpt" type="text" formControlName="dam_name_marathi">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_name_marathi'].invalid &&
                                    (dammasterForm.controls['dam_name_marathi'].dirty || dammasterForm.controls['dam_name_marathi'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_name_marathi'].errors.required">
                                            {{'Please enter Dam name in marathi' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx scroll-selection">
                                    <label class="steplbl">{{'Type of Dam' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="damType"
                                        [settings]="dropdownSettingsForDamType" [data]='getdamTypeArr'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselectType($event)"
                                        (onSelect)="onItemSelectType($event)">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_type'].invalid &&
                                    (dammasterForm.controls['dam_type'].dirty || dammasterForm.controls['dam_type'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_type'].errors.required">
                                            {{'Please select type of dam' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx scroll-selection">
                                    <label class="steplbl">{{'Purpose of Dam' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="damPurpose"
                                        [settings]="dropdownSettingsForDamPurpose" [data]='getdamPurposesArr'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselectPurpose($event)"
                                        (onSelect)="onItemSelectPurpose($event)">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_purpose'].invalid &&
                                    (dammasterForm.controls['dam_purpose'].dirty || dammasterForm.controls['dam_purpose'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_purpose'].errors.required">
                                            {{'Please select purpose of dam' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Status of Storage Creation of Dam' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_storage_creation_status">

                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getdamStorageseArr"
                                            value="{{list.dam_storage_creation_id}}">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.storage_creation_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.storage_creation_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_storage_creation_status'].invalid &&
                                    (dammasterForm.controls['dam_storage_creation_status'].dirty || dammasterForm.controls['dam_storage_creation_status'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_storage_creation_status'].errors.required">
                                            {{'Please select Storage Creation!' | translate}}
                                        </p>
                                    </div>
                                </div>






                                <!--/div-->
                                <!-- step row over-->
                                <!-- step row start-->
                                <!--div class="row frmrow"-->
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'District' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_district_code"
                                        (change)="forFamerTaluka($event.target.value)">
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getDistArray" [value]="list.district_code">
                                            <!-- {{list.district_name}} -->
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.district_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.district_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_district_code'].invalid &&
                                    (dammasterForm.controls['dam_district_code'].dirty || dammasterForm.controls['dam_district_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_district_code'].errors.required">
                                            {{'Please select District!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Taluka' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_taluka_code"
                                        (change)="forarmerVillage($event.target.value)">
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getFarmerTaluka" [value]="list.taluka_code">
                                            <!-- {{list.taluka_name}} -->
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.taluka_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.taluka_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_taluka_code'].invalid &&
                                    (dammasterForm.controls['dam_taluka_code'].dirty || dammasterForm.controls['dam_taluka_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_taluka_code'].errors.required">
                                            {{'Please select Taluka!' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Village' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_village_code">
                                        <!--(change)="getAllVillage($event.target.value)">-->
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getFarmerVillage" [value]="list.village_code">

                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.village_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.village_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_village_code'].invalid &&
                                    (dammasterForm.controls['dam_village_code'].dirty || dammasterForm.controls['dam_village_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_village_code'].errors.required">
                                            {{'Please select Village!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Basin' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="basin" formControlName="dam_basin_code"
                                        (change)="getSubBasins($event.target.value)">
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getdamBasinsArray" [value]="list.basin_master_id">


                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.basin_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.basin_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_basin_code'].invalid &&
                                (dammasterForm.controls['dam_basin_code'].dirty || dammasterForm.controls['dam_basin_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_basin_code'].errors.required">
                                            {{'Please select Basin!' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Sub-Basin' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_subbasin_code">
                                        <option value="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of getdamSubBasinsArray"
                                            [value]="list.subbasin_basin_id">

                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.subbasin_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.subbasin_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_subbasin_code'].invalid &&
                                (dammasterForm.controls['dam_subbasin_code'].dirty || dammasterForm.controls['dam_subbasin_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_subbasin_code'].errors.required">
                                            {{'Please select Sub-Basin!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Secondary District' | translate}}</label>
                                    <select class="stepinpt" formControlName="secondaryDistrict">
                                        <option>Raigad</option>
                                    </select>
                                </div> -->

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Secondary Taluka' | translate}}</label>
                                    <select class="stepinpt" formControlName="secondaryTaluka">
                                        <option>Raigad</option>
                                    </select>
                                </div> -->

                            </div>



                            <!-- col-md-12 start-->
                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12">
                                    <span>{{'Primary Authorities of Dam' | translate}}</span>
                                </h2>
                            </div>
                            <!-- col-md-12 over-->
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl"> {{'Corporation' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-container *ngIf="language == 'mr';else elseCorpBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.Corporation}}"
                                            type="text" disabled />
                                    </ng-container>
                                    <ng-template #elseCorpBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.Corporation}}"
                                            type="text" disabled />
                                    </ng-template>


                                </div>

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Region' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <input class="stepinpt" value="{{this.data.office_detail.Region}}" formControlName="region"  type="text"  disabled/> -->
                                    <ng-container *ngIf="language == 'mr';else elseRegionBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.Region}}" type="text"
                                            disabled />
                                    </ng-container>
                                    <ng-template #elseRegionBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.Region}}" type="text"
                                            disabled />
                                    </ng-template>

                                </div>

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Circle' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <input class="stepinpt" value="{{this.data.office_detail.circle}}" formControlName="circle" type="text"  disabled/> -->
                                    <ng-container *ngIf="language == 'mr';else elseCircleBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.circle}}" type="text"
                                            disabled />
                                    </ng-container>
                                    <ng-template #elseCircleBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.circle}}" type="text"
                                            disabled />
                                    </ng-template>

                                </div>

                                <!--/div-->
                                <!-- step row over-->
                                <!-- step row start-->
                                <!--div class="row frmrow"-->

                                <div class="col-md-2 inptbx" *ngIf="divselectshow">
                                    <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_division_code"
                                        (change)="getOfficesPrimary($event.target.value,'Sub Division')">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_Division;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_division_code'].invalid &&
                                    (dammasterForm.controls['dam_division_code'].dirty || dammasterForm.controls['dam_division_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_division_code'].errors.required">
                                            Please select primary Division!
                                        </p>
                                    </div>
                                </div>
                                <!-- dam_division_code -->
                                <ng-container *ngIf="language == 'mr';else elseBlock1">
                                    <div class="col-md-2 inptbx" *ngIf="divTextshow">
                                        <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <input class="stepinpt" value="{{this.office_details_mr.Division}}" type="text"
                                            disabled />
                                    </div>
                                </ng-container>
                                <ng-template #elseBlock1>
                                    <div class="col-md-2 inptbx" *ngIf="divTextshow">
                                        <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <input class="stepinpt" value="{{this.office_details_en.Division}}" type="text"
                                            disabled />
                                    </div>
                                </ng-template>


                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Sub Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="dam_subdivision_code"
                                        (change)="getOfficesPrimary($event.target.value,'Section')">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock2">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock2>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_SubDivision;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_subdivision_code'].invalid &&
                                    (dammasterForm.controls['dam_subdivision_code'].dirty || dammasterForm.controls['dam_subdivision_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_subdivision_code'].errors.required">
                                            Please select primary Sub Division!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Section' | translate}}</label>
                                    <select class="stepinpt" formControlName="dam_section_code">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_Section;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_section_code'].invalid &&
                                    (dammasterForm.controls['dam_section_code'].dirty || dammasterForm.controls['dam_section_code'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_section_code'].errors.required">
                                            Please select primary Section!
                                        </p>
                                    </div>
                                </div>

                            </div>


                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12">
                                    <span>{{'Secondary Authorities of Dam' | translate}}</span>
                                </h2>
                            </div>
                            <!-- col-md-12 over-->
                            <!-- step row start-->
                            <div class="row frmrow">



                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl">{{'Corporation' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <select class="stepinpt">
                                        <option>Sindhudurg </option>
                                    </select> -->
                                    <ng-multiselect-dropdown class="input-permissions" [(ngModel)]="CorporatesEditArray"
                                        [ngModelOptions]="{standalone: true}" name="secondaryCorporation"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [data]='secondary_corporation'
                                        (onDeSelect)="onItemDeselect($event,'Corporation')"
                                        (onSelect)="onItemSelect($event,'Corporation')">

                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_corporation_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_corporation_id'].dirty || dammasterForm.controls['dam_secondary_corporation_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_corporation_id'].errors.required">
                                            Please select secondary corporation!
                                        </p>
                                    </div>
                                </div>




                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl">{{'Region' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <select class="stepinpt">
                                        <option>Ratnagiri </option>
                                    </select> -->
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryRegion"
                                        (onDeSelect)="onItemDeselect($event,'Region')" [settings]="dropdownSettings"
                                        [placeholder]="this.select" [data]='secondary_Region'
                                        (onSelect)="onItemSelect($event,'Region')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_region_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_region_id'].dirty || dammasterForm.controls['dam_secondary_region_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_region_id'].errors.required">
                                            Please select secondary region!
                                        </p>
                                    </div>
                                </div>



                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl"> {{'Circle'| translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryCircle"
                                        [settings]="dropdownSettings" [data]='secondary_Circle'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselect($event,'Circle')"
                                        (onSelect)="onItemSelect($event,'Circle')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_circle_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_circle_id'].dirty || dammasterForm.controls['dam_secondary_circle_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_circle_id'].errors.required">
                                            Please select secondary circle!
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <!-- step row over-->
                            <!-- step row start-->
                            <div class="row frmrow">



                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryDivision"
                                        [settings]="dropdownSettings" [data]='secondary_Division'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselect($event,'Division')"
                                        (onSelect)="onItemSelect($event,'Division')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_division_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_division_id'].dirty || dammasterForm.controls['dam_secondary_division_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_division_id'].errors.required">
                                            Please select secondary division!
                                        </p>
                                    </div>
                                </div>



                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl">{{'Sub Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondarySubDivision"
                                        [settings]="dropdownSettings" [data]='secondary_SubDivision'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselect($event,'Sub Division')"
                                        (onSelect)="onItemSelect($event,'Sub Division')">
                                    </ng-multiselect-dropdown>

                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_subdivision_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_subdivision_id'].dirty || dammasterForm.controls['dam_secondary_subdivision_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_subdivision_id'].errors.required">
                                            Please select secondary sub division!
                                        </p>
                                    </div>
                                </div>


                                <div class="col-md-4 inptbx scroll-selection">
                                    <label class="steplbl">{{'Section' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondarySection"
                                        [settings]="dropdownSettings" [data]='secondary_Section'
                                        [placeholder]="this.select" (onDeSelect)="onItemDeselect($event,'Section')"
                                        (onSelect)="onItemSelect($event,'Section')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="dammasterForm.controls['dam_secondary_section_id'].invalid &&
                                (dammasterForm.controls['dam_secondary_section_id'].dirty || dammasterForm.controls['dam_secondary_section_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_section_id'].errors.required">
                                            Secondary Dam Section is required!
                                        </p>
                                    </div>
                                    <!-- <div
                                        *ngIf="dammasterForm.controls['dam_secondary_section_id'].invalid &&
                                    (dammasterForm.controls['dam_secondary_section_id'].dirty || dammasterForm.controls['dam_secondary_section_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_secondary_section_id'].errors.required">
                                            Please select secondary sub division!
                                        </p>
                                    </div> -->
                                </div>

                            </div>


                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Storage Planning' | translate}}</span></h2>
                            </div>
                            <!-- col-md-12 over-->
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl"> {{'Designed Live Storage (Mcum)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text"
                                        formControlName="dam_designed_live_storage_mcum">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_live_storage_mcum'].invalid &&
                                (dammasterForm.controls['dam_designed_live_storage_mcum'].dirty || dammasterForm.controls['dam_designed_live_storage_mcum'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_live_storage_mcum'].errors.required">
                                            {{'Dam Designed Live Storage MCUM is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_live_storage_mcum'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>






                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Designed Live Storage (TMC)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text" formControlName="dam_designed_live_storage_tmc">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_live_storage_tmc'].invalid &&
                                (dammasterForm.controls['dam_designed_live_storage_tmc'].dirty || dammasterForm.controls['dam_designed_live_storage_tmc'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_live_storage_tmc'].errors.required">
                                            {{'Dam Designed Live Storage TMC is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_live_storage_tmc'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>




                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Designed Dead Storage (Mcum)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text"
                                        formControlName="dam_designed_dead_storage_mcum">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_dead_storage_mcum'].invalid &&
                                (dammasterForm.controls['dam_designed_dead_storage_mcum'].dirty || dammasterForm.controls['dam_designed_dead_storage_mcum'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_dead_storage_mcum'].errors.required">
                                            {{'Dam Designed Dead Storage MCUM is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_dead_storage_mcum'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>



                                <!--/div-->
                                <!-- step row over-->
                                <!-- step row start-->
                                <!--div class="row frmrow"-->

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Designed Dead Storage (TMC)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text" formControlName="dam_designed_dead_storage_tmc">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_dead_storage_tmc'].invalid &&
                                (dammasterForm.controls['dam_designed_dead_storage_tmc'].dirty || dammasterForm.controls['dam_designed_dead_storage_tmc'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_dead_storage_tmc'].errors.required">
                                            {{'Dam Designed Dead Storage TMC is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_dead_storage_tmc'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>



                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Designed Gross Storage (Mcum)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text"
                                        formControlName="dam_designed_gross_storage_mcum">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_gross_storage_mcum'].invalid &&
                                (dammasterForm.controls['dam_designed_gross_storage_mcum'].dirty || dammasterForm.controls['dam_designed_gross_storage_mcum'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_gross_storage_mcum'].errors.required">
                                            {{'Dam Designed Gross Storage MCUM is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_gross_storage_mcum'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>





                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Designed Gross Storage (TMC)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input class="stepinpt" type="text"
                                        formControlName="dam_designed_gross_storage_tmc">
                                    <div
                                        *ngIf="dammasterForm.controls['dam_designed_gross_storage_tmc'].invalid &&
                                (dammasterForm.controls['dam_designed_gross_storage_tmc'].dirty || dammasterForm.controls['dam_designed_gross_storage_tmc'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_gross_storage_tmc'].errors.required">
                                            {{'Dam Designed Gross Storage TMC is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="dammasterForm.controls['dam_designed_gross_storage_tmc'].errors.pattern">
                                            Only number allowed & 10 digits before decimal & 2 after are allowed!
                                        </p>
                                    </div>
                                </div>



                            </div>
                            <!-- step row over-->
                            <!-- col-md-12 start-->

                            <!-- step row over-->
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-12 inptbx">
                                    <!-- <input class="stepgreenbtn" type="button" value="Save" /> -->
                                    <input class="stepgreenbtn" (click)="finalSubmit()" type="Submit"
                                        value="{{'Submit' | translate}}" />
                                    <!-- <input class="stepgreybtn" type="reset" value="Reset" /> -->
                                </div>

                            </div>
                            <!-- step row over-->
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <!-- dashboard right panel over-->
    <!-- footer start-->
    <footer class="dboardfooter fullwidth">
        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
    </footer>
    <!-- footer over-->
</body>