import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../_service/shared.service';




@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {

  userName = localStorage.getItem('userName');
  selectedlang = localStorage.getItem('selected_lang');


  form = new FormGroup({

    postLogin: new FormControl('', Validators.required)

  });


  userarr: any;
  key: string;
  ps: any;
  postLoginid: any;
  promptErrMsg: boolean = false;
  postLoginIdforOneUser: any;
  clickEventsubscription: Subscription;
  language: string;
  userOfficeType: string

  nextStage() {

    //console.log(this.form.value.postLogin);
    //console.log(this.userarr[0].user_post_login_id);return false;
    //this.form.value.postLogin
    //postLoginid
    if (this.postLoginIdforOneUser) {
      this.ps = this.userarr[0].user_post_login_id
    } else {
      this.ps = this.form.value.postLogin
    }
    // else{


    //  console.log(this.form.value.postLogin,"ooooooooo");
    // this.ps = this.form.value.postLogin
    // }

    //console.log(this.ps+'===========');return false;
    const body = new HttpParams({
      fromObject: {

        post_user_id: this.ps,
      }
    });

    localStorage.setItem('post_user_id', this.ps);
    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        //this.validOtp=1;

        return false;

      } else {
        //console.log('resolve.data',"==")
        // console.log(resolve.data);return false;

        if (resolve.data) {
          this.common.office_detail = resolve.data;
          for (let key in resolve.data.userDetail.user_office_detail) {
            this.userOfficeType = key;
            break;
          }
          // console.log(resolve.data.userDetail.user_office_id + "==========")
          localStorage.setItem('user_office_id', resolve.data.userDetail.user_office_id);
          localStorage.setItem('user_office_type', this.userOfficeType);
          localStorage.setItem('user_post', resolve.data.userDetail.user_post)
          // console.log(localStorage.getItem('user_office_id'));
          this.router.navigate(['verifyDetails']);

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }
  Errorhide() {

    this.promptErrMsg = false;
  }

  //  submit(form: FormGroup) {
  submit() {

    // console.log(this.form.value);
    //this.isSubmitted = true;

    if (!this.form.valid) {
      this.promptErrMsg = true;
      return false;
    } else {
      this.promptErrMsg = false;
      //console.log(JSON.stringify(this.firstStepForm.value))
    }


    //console.log(this.form.value.postLogin);
    // this.postLoginid = this.form.value.postLogin;
    // console.log(this.form.value);
    //return false;
    this.nextStage()

  }
  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    // console.log('aaaaa', this.language);

    // this.common.language$.subscribe((res: string) => {
    // console.log('language', res);
    this.cdr.markForCheck();

    // })
  }
  /*sonSubmit(form: FormGroup) {
    this.isSubmitted = true;

    if (!this.firstStepForm.valid) {
      return false;
    } else {
      console.log(JSON.stringify(this.firstStepForm.value))
    }
 console.log(this.firstStepForm.value);
    
    this.firstStepFormSave()

  }*/

  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      this.selectedlang = localStorage.getItem('selected_lang');
      this.ngOnInit();
    })

    const body = new HttpParams({
      fromObject: {
        mobile_no: localStorage.getItem('mobile'),
      }
    });

    this.http.getDataUser(body).subscribe((resolve: any) => {
      let data = resolve;
      // console.log("here");
      if (data.status == 0) {
        return false;
      } else {


        this.common.user_detail = resolve.data['users'];
        this.userarr = resolve.data['users'];
        // console.log(this.userarr[0].user_first_time_login, "====");
        //console.log(this.userarr[0].user_post_login_id)

        //  console.log(resolve.data['users'].length,"SSSSSSS");

        //  console.
        //console.log(resolve.data,"====");
        //   console.log(localStorage)


        if (this.userarr[0].user_first_time_login == 1) {

          localStorage.setItem('post_user_id', this.userarr[0].user_post_login_id);
          this.router.navigate(['dashboard']);


        }
        this.postLoginIdforOneUser = '';
        if (resolve.data['users'].length == 1) {

          this.postLoginIdforOneUser = this.userarr[0].user_post_login_id;
          this.nextStage();
          //this.router.navigate(['verifyDetails']);

        }

        //console.log(resolve.data['users'].length);

        for (let key in resolve.data['users'].user_office_detail) {
          // console.log(key) 
          this.key = key;
          return;

        }


        // this.router.navigate(['userSelect']);
      }

    },
      (err) => {

        console.log(err);


      }
    )
  }

  ngOnInit(): void {


    switch (this.selectedlang) {
      case "en":
        //  this.data.label = this.en_data;
        // this.data.office_detail = this.office_details_en;
        break;
      case "mr":
        //  this.data.label = this.mr_data;
        // this.data.office_detail = this.office_details_mr;
        break;
    }

    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));
  
  }

}
