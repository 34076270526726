
<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
       <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
                 <!-- header start-->
                 <app-header></app-header>
                <!-- header over-->
                <!-- dashboardbnnr start-->
                <div class="greenstrip fullwidth">
                    <h1>{{'Profile Details' | translate}}</h1>
                </div>
                <!--<div class="stepimg fullwidth">
                    <img src="assets/images/step2.jpg" alt="step2" />
                </div>-->
                <!-- dashboardbnnr over-->
                <!-- mid content start-->
                <div class="mid-content fullwidth formstep">
    
                    
                    <!-- row start-->
                    <div class="row">
                        <!-- col-md-12 start-->
                        <div class="col-md-12 recentlistbx">
                            <h2 class="smlheadh2 col-md-12"><span>{{'Personal Details' | translate}}</span></h2>
                                
                        </div>
                        <!-- col-md-12 over-->
                        <!-- form step1 start-->
                        <div class="stepfrmcmn stepfrm1 col-md-12">
                          <!-- <form [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm)" >-->
                                <div >
                            <!-- step row start-->
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl"> {{'Name' | translate}}</label>
                                     <input type="text" placeholder="{{ this.userName }}" class="stepinpt" disabled />

                                </div>
                                
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Email' | translate}}</label>
                                    <input type="text" value ="{{ this.userEmail }}"  class="stepinpt" disabled />
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Mobile' | translate}}</label>  
                                    <input type="text"  value ="{{ this.userMobile }}" class="stepinpt" disabled />
                                </div>
                            </div>
                            <!-- step row over-->

                            <!-- step row start-->
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Post name' | translate}}</label>
                                     <input type="text" placeholder="{{ this.userPosition }}" class="stepinpt"  />

                                </div>
                                
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Office Name' | translate}}</label>
                                    <input type="text" value ="{{ this.userOfficeType }}"  class="stepinpt" disabled />
                                </div>

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Mobile</label>  
                                    <input type="text"  value ="{{ this.userMobile }}" class="stepinpt" disabled />
                                </div> -->
                            </div>
                            <!-- step row over-->

                            <!-- step row start-->
                            <!-- <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl"> Name</label>
                                     <input type="text" placeholder="{{ this.userName }}" class="stepinpt"  />

                                </div>
                                
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Email</label>
                                    <input type="text" value ="{{ this.userEmail }}"  class="stepinpt" disabled />
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Mobile</label>  
                                    <input type="text"  value ="{{ this.userMobile }}" class="stepinpt" disabled />
                                </div>
                            </div> -->
                            <!-- step row over-->


                            <!-- <div class="col-md-12 inptbx">
                                <input class="stepgreenbtn ofc-hide" type="button" value="Confirm">
                                <button class="stepgreenbtn ofc-hide" type="button" value="Edit">Edit</button>
                            </div> -->

                           
                            
                            <!--</form>-->
                        </div>
                    </div>
                        <!-- form step1 over-->
                    </div>
                    <!-- row over-->
    
                
    
                </div>
                <!-- mid content over-->
                
        </div>
        <!-- dashboard right panel over-->	
    </div>
</div>   