import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {CommonService} from './../_service/common.service';
import {HttpRequestService} from './../_service/http-request.service';
import { HttpClient ,HttpHeaders,HttpParams} from '@angular/common/http';
import { FormGroup, FormControl,Validators ,FormBuilder} from '@angular/forms';

import { IDropdownSettings} from 'src/ng-multiselect-dropdown/src';

@Component({
  selector: 'app-irrigation',
  templateUrl: './irrigation.component.html',
  styleUrls: ['./irrigation.component.css']
})
export class IrrigationComponent implements OnInit {

  userName = localStorage.getItem('userName');
  damNameArr:any;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any= [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  irrigationMasterForm: FormGroup;

  /*public permissions = [

    { id: 'Canal Flow', value: "Canal Flow" },
    { id: 'Canal Lift', value: "Canal Lift" },
    { id: 'Reservoir', value: "Reservoir" },
    { id: 'River-Nalla ', value: "River-Nalla " },
    { id: 'Well', value: "Well" },
  
  ];*/
 

  //public filteredList = this.permissions.slice();
  irrigation: any='';
  projects: any;
  circle: any='';
  Division: any='';
  Mantralaya: any='';
  Region: any='';
  SubDivision: any='';
  dams: string;
  permissions:any = []
  Corporation: any='';
  cirShow: boolean;
  subDivis: any;
  subShow: boolean =false;
  manShow: boolean  =false;
  regShow: boolean  =false;
  divShow: boolean  =false;
  corShow: boolean  =false;
  selectedItemsRoot: any[];
  typeArr: any=[];
   RChecked: any[];
  CFChecked: any[];
  RiverChecked: any[];
  CLChecked: any[];
  WellChecked: any[];

 /* public isFiltered(permission) {
    return this.filteredList.find(item => item.id === permission.id);
  }*/
  preview(){

    const body = new HttpParams({
      fromObject: {
        post_user_id:  localStorage.getItem('post_user_id')
      }
    });

    this.http.previewFirstTime(body).subscribe((resolve:any)=>{
    let data1 = resolve;
      if(data1.status == 0){
  
        return false;
        
      }else {

        this.irrigation = resolve.data.irrigation;
        //this.projects = resolve.data.projects;

        this.projects='';
        for(let k=0; k<resolve.data.projects.length; k++){
          if(this.projects)
          this.projects +=','+resolve.data.projects[k].project_name;
          else 
          this.projects =  resolve.data.projects[k].project_name;
        }
        const subDivision = "Sub Division";

        this.cirShow=true ;
        this.subShow=true ;
        this.manShow=true; 
        this.regShow=true ;
        this.divShow=true ;
        this.corShow=true ;

        this.circle = resolve.data.userDetail.user_office_detail.Circle ?  resolve.data.userDetail.user_office_detail.Circle.office_name: " ";
        this.Division = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name  : " ";
        this.Mantralaya = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : " ";
        this.Region = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : " ";
        this.SubDivision = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : " ";
        this.Corporation = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name: " ";

        console.log( this.Corporation);
       // console.log("ddd")
        if(this.circle != " "){
         // console.log(this.circle);
          this.cirShow = true;
         }
         if(this.SubDivision != " "){
          this.subShow = true;
         }
         if(this.Mantralaya != " "){
          this.manShow = true;
        }
        if(this.Region != " "){
          this.regShow = true;
        }
        if(this.Division != " "){
          this.divShow = true;
        }
        if(this.Corporation != " "){
          this.corShow = true; 
        }

       // console.log(  this.corShow);


        this.dams='';
        for(let k=0; k<resolve.data.dams.length; k++){
          if(this.dams)
          this.dams +=','+resolve.data.dams[k].dam_name;
          else 
          this.dams =  resolve.data.dams[k].dam_name;
        }

      //console.log(resolve.data);return false;
       //console.log(resolve.data.damList.length);
        if(resolve.data){

          //alert("Confirmed");
          ///this.router.navigate(['irrigation']);
          
      }
    }
    },
    (err)=>{
       
      console.log(err);
  
       
    }
    )




  }



  getbackIrrigationDetails() {


  ///  console.log( localStorage.getItem('post_user_id'));
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),

      }
    });

    this.http.getBackDetailsIrrigation(body).subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {

        return false;

      } else {

      //  console.log(resolve.data.selected.dams)
        if (resolve.data.selected.irrigation) {
          this.selectedItemsRoot = [];
          for (let k = 0; k < resolve.data.selected.irrigation.length; k++) {

            this.typeArr.push(resolve.data.selected.irrigation[k]['irrigation_id']);

            if (this.damNameArr)
            this.damNameArr += ',' + resolve.data.selected.irrigation[k]['irrigation_id'];
             else
            this.damNameArr =  resolve.data.selected.irrigation[k]['irrigation_id'];

            this.selectedItemsRoot.push({
              item_id: resolve.data.selected.irrigation[k]['irrigation_id'],
              item_text: resolve.data.selected.irrigation[k]['irrigation_val']
            });

            // console.log(this.selectedItemsRoot+'ddd');

           /* this.typeArr = ''
            if (this.typeArr)
              this.typeArr += ',' + resolve.data.selected.dams[k]['dam_unique_code'];
            else
              this.typeArr =resolve.data.selected.dams[k]['dam_unique_code'];*/
          }
        }
       
       

       // this.getProjectDetails();
       // return false;
      }
    },
      (err) => {

        console.log(err);


      }
    )
  }


  onCheckboxChagen(event, value) {

    if (event.checked) {

      this.typeArr.push(value);
    } 

    if (!event.checked) {

      let index = this.typeArr.indexOf(value);
      if (index > -1) {
        this.typeArr.splice(index, 1);
      }
    }

    // console.log("Irrigation array => " + JSON.stringify(this.typeArr, null, 2));
  }

  onIrrigationnameSelected(ob){

  
 
      // console.log(ob.length);
        this.damNameArr='';
       for(let k=0; k<ob.length; k++){
         if(this.damNameArr)
         this.damNameArr +=','+ob[k].id;
         else 
         this.damNameArr =  ob[k].id;
         //this.typeArr.push(ob[k].id);
        
       } 
      
      //  console.log(this.damNameArr)
      }
  onSubmit(form: FormGroup) {

    //console.log(this.damNameArr);return false;

    if (this.irrigationMasterForm.invalid) {
       return false;
      }
      else {
        const body = new HttpParams({
          fromObject: {
            post_user_id:  localStorage.getItem('post_user_id'), 
            irrigation_sources:this.damNameArr,  
          
          }
        });
    
        this.http.getIrrigationLinking(body).subscribe((resolve:any)=>{
        let data1 = resolve;
          if(data1.status == 0){
      
            return false;
            
          }else {
    
           // console.log(resolve.data);
           //console.log(resolve.data.damList.length);
            if(resolve.data){
            //  alert("Data Finally Confirmed");
              ///this.router.navigate(['irrigation']);
              
          }
        }
        },
        (err)=>{
           
          console.log(err);
      
           
        }
        )
           
         
       
  
      }
  
    
  
  }

  backSubmit(){
    this.router.navigate(['dams']);
  }
 finalSubmit() {

  if (this.irrigationMasterForm.invalid) {
       return false;
      }
      else {
        const body = new HttpParams({
          fromObject: {
            post_user_id:  localStorage.getItem('post_user_id'), 
          
          
          }
        });
    
        this.http.userUpdateFirstTime(body).subscribe((resolve:any)=>{
        let data1 = resolve;
          if(resolve.status == 0){
      
            return false;
            
          }else {
            //alert("dfhgdfgshfjsd");
            //console.log(resolve);
            if(resolve.status){


              confirm("Are You Sure You Want to Submit The Details");
              this.router.navigate(['dashboard']);
              
          }
        }
        },
        (err)=>{
           
          console.log(err);
      
           
        }
        )
           
         
       
  
      }
  
}
  
constructor(
  private fb: FormBuilder,
  private router: Router,
  private route: ActivatedRoute,
  private http : HttpRequestService,
  private common : CommonService,) {}
   
  

  ngOnInit() {

   // this.getbackIrrigationDetails()
    this.irrigationMasterForm = this.fb.group({
      //"irrigationSelectname": new FormControl('', [ Validators.required ]),
    //  "RChecked": new FormControl('', [ Validators.required ]),
     // "CFChecked": new FormControl('', [ Validators.required ]),
     // "CLChecked": new FormControl('', [ Validators.required ]),
     // "RiverChecked": new FormControl('', [ Validators.required ]),
     // "WellChecked": new FormControl('', [ Validators.required ]),
     
     // RChecked; CFChecked; CLChecked;RiverChecked;WellChecked

 });
 this.permissions = [
  
  { item_id: 'Reservoir', item_text: "Reservoir" },
  { item_id: 'River-Nalla', item_text: "River-Nalla" },
  { item_id: 'Well', item_text: "Well" },
  { item_id: 'Canal Flow', item_text: "Canal Flow" },
  { item_id: 'Canal Lift', item_text: "Canal Lift" },
];
//'Reservoir','Canal Flow','Canal Lift','River-Nalla','Well'
this.dropdownSettings = {
  singleSelection: false,
  idField: 'item_id',
  textField: 'item_text',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3,
  allowSearchFilter: true
};
}


onItemSelect(item: any) {
//console.log('ll')

this.typeArr.push(item.item_id);
  if(this.damNameArr)
  this.damNameArr +=','+item.item_id;
  else 
  this.damNameArr = item.item_id;
// this.getProjectDetails();
// console.log(this.damNameArr)
}


onSelectAll(items: any) {

  this.typeArr = [];
  for (let k = 0; k < items.length; k++) {
    this.typeArr.push(items[k]['item_id']);
  }
  this.damNameArr = ''
  for (let k = 0; k < this.typeArr.length; k++) {
    if (this.damNameArr)
      this.damNameArr += ',' + this.typeArr[k];
    else
      this.damNameArr = this.typeArr[k];
  }
  console.log(this.typeArr);
}


onDeSelect(items: any) {

 //this.typeArr.pop(items.item_id);

  for (let k = 0; k < items.length; k++) {
    this.typeArr.push(items[k]['item_id']);
  }

  this.damNameArr = ''
  for (let k = 0; k < this.typeArr.length; k++) {
    if (this.damNameArr)
      this.damNameArr += ',' + this.typeArr[k];
    else
      this.damNameArr = this.typeArr[k];
  }
  // console.log(this.damNameArr+'sss');

}

onDeSelectAll(items: any) {
  this.typeArr = [];
  for (let k = 0; k < items.length; k++) {
    this.typeArr.push(items[k]['item_id']);
  }

  this.damNameArr = ''
  for (let k = 0; k < this.typeArr.length; k++) {
    if (this.damNameArr)
      this.damNameArr += ',' + this.typeArr[k];
    else
      this.damNameArr = this.typeArr[k];
  }
  
}

OnItemDeSelect(items: any) {

  this.damNameArr = ''
   const index: number = this.typeArr.indexOf(items.item_id);

   this.typeArr.splice(index, 1);




   // console.log(this.typeArr);
  //  console.log("Ddd")

   // return false;
  //this.typeArr.pop(items.item_id);

 // console.log(this.typeArr);

  for (let k = 0; k < items.length; k++) {
    this.typeArr.push(items[k]['item_id']);
  }
  //
  for (let k = 0; k < this.typeArr.length; k++) {
    if (this.damNameArr){
      this.damNameArr += ',' + this.typeArr[k];
    }
    else{
      this.damNameArr = this.typeArr[k];
    }
  }
 // console.log(this.damNameArr+'----');
}
toogleShowFilter() {
  this.ShowFilter = !this.ShowFilter;
  this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
}

handleLimitSelection() {
  if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
  } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
  }
}

}

