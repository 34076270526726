import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { Location } from "@angular/common";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IDropdownSettings } from 'src/ng-multiselect-dropdown/src';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  single: any[];
  multi: any[];
  dropdownSettings: any = {};
  //view: any[] = [1200, 400];
  farmerDistrict
  selectedlang = localStorage.getItem('selected_lang');
  data: any = {};
  fileUrl;
  allProject: boolean = true
  en_data: any = {

    select_all: "Select All",
    select: "Select",
    save: "Save",
    Ok: "OK",
    cancel_string: "Cancel",
    searchPlaceholderText: "Search",
    un_select_all: "UnSelect All",


  }
  mr_data: any = {

    select_all: "सर्व पर्याय निवडा",
    select: "निवडा",
    save: "जतन करा",
    Ok: "होय",
    un_select_all: "निवडलेले सर्व काढा",

    cancel_string: "नाही",
    searchPlaceholderText: "शोधा"





  }
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = this.selectedlang == 'en' ? 'No. Of Farmers' : 'शेतकऱ्यांची संख्या';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme = {
    domain: ['#00daff', '#00daff', '#00daff', '#00daff']
  };

  clickEventsubscription: Subscription;
  typeArr: any = [];
  selectedItemsRoot2: any[];
  proArr: any[];
  proNameArr: string;
  typeArrValues: string;
  proSType: any[];
  projectType: any;
  getDistArray: any;
  bankArray: any;
  farmerDistrictCode: any = '';
  singleMajor: any[];
  singleMedium: any[];
  singleMinor: any[];
  CoprationArr: any[];
  RegionArr: any[];
  CircleArr: any[];
  DivisionArr: any[];
  SubDivArr: any[];
  SectionArr: any[];
  userPosition: any;
  userOfficeType: string;
  office_details_en: any = {};
  office_details_mr: any = {};
  disabled: boolean;
  MantraId: any;
  circleId: any;
  subDivisId: any;
  RegionId: any;
  DivisionId: any;
  CorporationId: any;
  SectionId: any;
  showCir: boolean = false;
  showDiv: boolean = false;
  showSub: boolean = false;
  showSec: boolean = false;
  showReg: boolean = false;
  showCor: boolean = false;

  showMedium: boolean = true;

  showMajor: boolean = true;

  showMinor: boolean = true;
  singleOffices: any[];

  constructor(private sharedService: SharedService, private location: Location,
    private ngxService: NgxUiLoaderService,
    private http: HttpRequestService, ) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      this.ngxService.stop();
      this.selectedlang = localStorage.getItem('selected_lang');


      this.ngOnInit();
      this.xAxisLabel = this.selectedlang == 'en' ? 'No. Of Farmers' : 'शेतकऱ्यांची संख्या';
      // this.getFramerCountMajor();
      // this.getFramerCountMedium();
      // this.getFramerCountMinor();

      // var single1 = this.single;
      // Object.assign(this, { single1 })

      // var single2 = this.singleMajor;
      // Object.assign(this, { single2 })

      // var single3 = this.singleMedium;
      // Object.assign(this, { single3 })

      // var single4 = this.singleMinor;
      // Object.assign(this, { single4 })
      // var single5 = this.singleOffices;
      // Object.assign(this, { single5 })
    })


    var single1 = this.single;
    Object.assign(this, { single1 })

    var single2 = this.singleMajor;
    Object.assign(this, { single2 })

    var single3 = this.singleMedium;
    Object.assign(this, { single3 })

    var single4 = this.singleMinor;
    Object.assign(this, { single4 })
    var single5 = this.singleOffices;
    Object.assign(this, { single5 })
  }
  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {

    this.getFramerCountMajor();
    this.getFramerCountMedium();
    this.getFramerCountMinor();

    this.getProjectTypes();
    this.getDistrict();
    this.getDataOnChangeMarathi();
    this.getUserDetails();


    this.proSType = [
    ];
    // this.location.subscribe(currentLocation => {
    //   if (currentLocation.url === '/dashboard') 
    //   {
    //     window.onpopstate = function (event) 
    //     {
    //       history.go(1);
    //     }
    //   }
    // });


    let currentUrl = window.location.href;
    let tmpVar = currentUrl.includes('/dashboard');
    if (currentUrl.includes('/dashboard')) {
      window.onpopstate = function (event) {
        history.go(1);
      }
    }

    switch (this.selectedlang) {
      case "en":
        this.data = this.en_data;
        break;
      case "mr":
        this.data = this.mr_data;
        break
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: this.data.select_all,
      unSelectAllText: this.data.un_select_all,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: this.data.searchPlaceholderText
    };
  }

  getdistrictchange(data) {

    this.allProject = false;

    this.farmerDistrictCode = data;

    this.getFramerCount();

  }
  getFramerCount() {

    this.showMedium = false;
    this.showMinor = false;
    this.showMajor = false;
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;

    if (this.typeArr) {
      this.projectType = this.typeArr;

    } else {
      this.projectType = '';

    }


    // console.log(this.projectType);


    //  console.log(this.projectType.length,'==');
    for (var i = 0; i < this.projectType.length; i++) {

      if (this.projectType[i] == 1) {
        this.getFramerCountMinor();
        this.showMinor = true

      } else if (this.projectType[i] == 2) {

        this.getFramerCountMedium();
        this.showMedium = true;


      } else if (this.projectType[i] == 3) {

        this.getFramerCountMajor()
        this.showMajor = true;


      }
    }


    // const body = new HttpParams({
    //   fromObject: {
    //     post_user_id: post_user_id,
    //     project_type: this.projectType,
    //     project_district_code: this.farmerDistrictCode,
    //   }
    // });
    // this.http.getFarmerCount(body).subscribe((resolve: any) => {
    //   this.single = [];

    //   for (let k = 0; k < resolve.message.length; k++) {

    //     if (this.selectedlang === 'en') {


    //       this.single.push({
    //         name: resolve.message[k]['project_name'],
    //         value: resolve.message[k]['farmerCount']
    //       });
    //     } else if (this.selectedlang === 'mr') {
    //       this.single.push({
    //         name: resolve.message[k]['project_name_marathi'],
    //         value: resolve.message[k]['farmerCount']
    //       });
    //     }

    //   }
    // })
  }

  getFramerCountMajor() {


    // alert("kkghh");
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;

    if (this.typeArr) {
      this.projectType = this.typeArr;

    } else {
      this.projectType = '';

    }
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_type: '3',
        project_district_code: this.farmerDistrictCode,
      }
    });
    this.http.getFarmerCount(body).subscribe((resolve: any) => {
      console.log(resolve.message);
      this.singleMajor = [];

      for (let k = 0; k < resolve.message.length; k++) {

        if (this.selectedlang === 'en') {
          this.singleMajor.push({
            name: resolve.message[k]['project_name'],
            value: resolve.message[k]['farmerCount']
          });
        } else if (this.selectedlang === 'mr') {
          this.singleMajor.push({
            name: resolve.message[k]['project_name_marathi'],
            value: resolve.message[k]['farmerCount']
          });
        }

      }



      console.log(this.singleMajor);
    })
  }

  getFramerCountMedium() {
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;

    if (this.typeArr) {
      this.projectType = this.typeArr;

    } else {
      this.projectType = '';

    }
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_type: '2',
        project_district_code: this.farmerDistrictCode,
      }
    });
    this.http.getFarmerCount(body).subscribe((resolve: any) => {
      console.log(resolve.message);
      this.singleMedium = [];

      for (let k = 0; k < resolve.message.length; k++) {

        if (this.selectedlang === 'en') {
          this.singleMedium.push({
            name: resolve.message[k]['project_name'],
            value: resolve.message[k]['farmerCount']
          });
        } else if (this.selectedlang === 'mr') {
          this.singleMedium.push({
            name: resolve.message[k]['project_name_marathi'],
            value: resolve.message[k]['farmerCount']
          });
        }

      }



      console.log(this.singleMedium);
    })
  }


  getFramerCountMinor() {
    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = ob;

    if (this.typeArr) {
      this.projectType = this.typeArr;

    } else {
      this.projectType = '';

    }
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_type: '1',
        project_district_code: this.farmerDistrictCode,
      }
    });
    this.http.getFarmerCount(body).subscribe((resolve: any) => {

      // console.log("here");
      // console.log(resolve.message);
      this.singleMinor = [];
// console.log(this.selectedlang );
      for (let k = 0; k < resolve.message.length; k++) {

        if (this.selectedlang === 'en') {
          this.singleMinor.push({
            name: resolve.message[k]['project_name'],
            value: resolve.message[k]['farmerCount']
          });
        } else if (this.selectedlang === 'mr') {
          this.singleMinor.push({
            name: resolve.message[k]['project_name_marathi'],
            value: resolve.message[k]['farmerCount']
          });
        }

      }


      // console.log("here");
      // console.log(this.singleMinor);
    })
  }
  getDistrict() {
    this.http.getDistricts().subscribe((resolve: any) => {
      this.getDistArray = resolve.data
    })

  }


  OnItemDeSelect(items: any) {

    // console.log("OnItemDeSelect");

    const index: number = this.typeArr.indexOf(items.item_id);
    this.typeArr.splice(index, 1);
    this.selectedItemsRoot2 = [];
    this.proArr = [];
    this.proNameArr = '';
    // this.typeArr.pop(items.item_id);
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }

    if (!this.typeArrValues)
      this.allProject = true;
    //console.log(this.typeArrValues);
    //this.getProjectDetails();
    this.getFramerCount();
  }
  onSelectAll(items: any) {
    this.selectedItemsRoot2 = [];
    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    //this.getProjectDetails();
    this.getFramerCount();
  }

  onDeSelectAll(items: any) {
    this.allProject = true;
    this.selectedItemsRoot2 = [];
    this.typeArr = [];
    this.proArr = [];
    this.proNameArr = '';
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    // this.getProjectDetails();
    this.getFramerCount();
  }

  onItemSelect(item: any) {


    this.allProject = false;
    this.selectedItemsRoot2 = [];
    this.typeArr.push(item.item_id);
    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //this.getProjectDetails();
    this.getFramerCount();

  }
  getProjectTypes() {
    const body = new HttpParams({
      fromObject: {
        project_id: '',
      }
    });
    this.http.getProjectTypes().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          // console.log(resolve.data.project_types);

          this.proSType = [];
          for (let k = 0; k < resolve.data.project_types.length; k++) {

            if (this.selectedlang === 'en') {
              this.proSType.push({
                item_id: resolve.data.project_types[k]['project_type_id'],
                item_text: resolve.data.project_types[k]['project_type']
              });
            } else {

              this.proSType.push({
                item_id: resolve.data.project_types[k]['project_type_id'],
                item_text: resolve.data.project_types[k]['project_type_marathi']
              });
            }
          }
          console.log(this.proSType)
        } else
          return false;
      }

    },
      (err) => {

        console.log(err);

      }
    )
  }

  getChangeOfficeDetailsRefresh(id, value, change) {



    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {
            // this.getAnalysisOffice(id);
            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;
            // this.getAnalysisOffice(id);
          } if (value == 'Circle') {
            // this.allProject = false;
            // this.getAnalysisOffice(id);
            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;
            // this.getAnalysisOffice(id);
          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;
            // this.getAnalysisOffice(id);
            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }




  getChangeOfficeDetails(id, value, change) {



    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {
            this.getAnalysisOffice(id);
            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;
            this.getAnalysisOffice(id);
          } if (value == 'Circle') {
            // this.allProject = false;
            this.getAnalysisOffice(id);
            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;
            this.getAnalysisOffice(id);
          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;
            this.getAnalysisOffice(id);
            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }


  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      console.log(this.userPosition + "==========" + this.userOfficeType);

      //this.userOfficeType ='Corporation';
      if (this.userOfficeType == 'Division') {

        var parent_id = resolve.data.userDetail.user_office_detail.Circle.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Sub Division') {


        var parent_id = resolve.data.userDetail.user_office_detail.Division.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Circle') {

        var parent_id = resolve.data.userDetail.user_office_detail.Region.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      else if (this.userOfficeType == 'Region') {

        var parent_id = resolve.data.userDetail.user_office_detail.Corporation.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Corporation') {

        parent_id = '1';
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Section') {
        const subDivision = "Sub Division";
        var parent_id = resolve.data.userDetail.user_office_detail.subDivision.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      if (this.userOfficeType == "Division") {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == "Circle") {

        this.showCir = false;

        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;

        this.showReg = false;
        this.showCor = false;

      } else if (this.userOfficeType == "Mantralaya") {

        // this.showCir =false;
        // this.showDiv =false;
        // this.showSub =true;
        // this.showSec =true;
        // this.showReg =false;
        // this.showCor = false;

      } else if (this.userOfficeType == "Region") {


        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;
      } else if (this.userOfficeType == 'Sub Division') {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == 'Corporation') {

        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = true;
        this.showCor = false;

      }
      else {


        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = false;
        this.showReg = false;
        this.showCor = false;


      }

    });
  }


  getDataOnChangeMarathi() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        for (let key in resolve.data.userDetail.user_office_detail) {
        }
        if (resolve.data) {


          if (resolve.data.userDetail.user_office_change_request) {

            this.disabled = !this.disabled;



          } else {
            this.disabled = this.disabled;


          }
          const subDivision = "Sub Division";

          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : "NA",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "NA",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : "NA",
          }

          this.office_details_mr = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : "NA",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "NA",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : "NA",
          }



          this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
          this.circleId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
          this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
          this.RegionId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
          this.DivisionId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
          this.CorporationId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
          this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";

          this.data.office_detail = {};
          switch (this.selectedlang) {
            case "en":
              this.data.label = this.en_data;
              this.data.office_detail = this.office_details_en;
              break;
            case "mr":
              this.data.label = this.mr_data;
              this.data.office_detail = this.office_details_mr;
              break;
          }




          // console.log(this.data.office_detail);

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }


  getAnalysisOffice(user_office_id) {


    let post_user_id = localStorage.getItem('post_user_id');

    if (this.typeArr) {
      this.projectType = this.typeArr;

    } else {
      this.projectType = '';

    }


    if (this.projectType.length > 0) {
      for (var i = 0; i < this.projectType.length; i++) {


        if (this.projectType[i] == '1') {
          const body = new HttpParams({
            fromObject: {
              user_office_id: user_office_id,
              project_type: '1',
              project_district_code: this.farmerDistrictCode,
            }
          });
          this.http.getAnalysisOffice(body).subscribe((resolve: any) => {



            this.singleMinor = [];

            // console.log("jj")
            // console.log( resolve);
            // console.log( resolve.message);
            if (resolve.message == 'No assigned project!') {



            } else {


              for (let k = 0; k < resolve.message.length; k++) {

                if (this.selectedlang === 'en') {
                  this.singleMinor.push({
                    name: resolve.message[k]['project_name'],
                    value: resolve.message[k]['farmerCount']
                  });
                } else if (this.selectedlang === 'mr') {
                  this.singleMinor.push({
                    name: resolve.message[k]['project_name_marathi'],
                    value: resolve.message[k]['farmerCount']
                  });
                }

              }
            }
            // console.log("jjjMinorjj");
            console.log(this.singleMinor);
          })
        } else if (this.projectType[i] == '2') {
          const body = new HttpParams({
            fromObject: {
              post_user_id: post_user_id,
              user_office_id: user_office_id,
              project_type: '2',
              project_district_code: this.farmerDistrictCode,
            }
          });
          this.http.getAnalysisOffice(body).subscribe((resolve: any) => {


            this.singleMedium = [];

            if (resolve.message == 'No assigned project!') {



            } else {

              for (let k = 0; k < resolve.message.length; k++) {

                if (this.selectedlang === 'en') {
                  this.singleMedium.push({
                    name: resolve.message[k]['project_name'],
                    value: resolve.message[k]['farmerCount']
                  });
                } else if (this.selectedlang === 'mr') {
                  this.singleMedium.push({
                    name: resolve.message[k]['project_name_marathi'],
                    value: resolve.message[k]['farmerCount']
                  });
                }

              }
            }
            console.log(this.singleMedium);
          })


        } else if (this.projectType[i] == 3) {
          const body = new HttpParams({
            fromObject: {
              post_user_id: post_user_id,
              user_office_id: user_office_id,
              project_type: '3',
              project_district_code: this.farmerDistrictCode,
            }
          });
          this.http.getAnalysisOffice(body).subscribe((resolve: any) => {
            this.singleMajor = [];

            if (resolve.message == 'No assigned project!') {



            } else {
              for (let k = 0; k < resolve.message.length; k++) {
                if (this.selectedlang === 'en') {
                  this.singleMajor.push({
                    name: resolve.message[k]['project_name'],
                    value: resolve.message[k]['farmerCount']
                  });
                } else if (this.selectedlang === 'mr') {
                  this.singleMajor.push({
                    name: resolve.message[k]['project_name_marathi'],
                    value: resolve.message[k]['farmerCount']
                  });
                }

              }
            }
          })
        }

      }

    } else {



      const body = new HttpParams({
        fromObject: {
          post_user_id: post_user_id,
          user_office_id: user_office_id,
          project_type: '1',
          project_district_code: this.farmerDistrictCode,
        }
      });
      this.http.getAnalysisOffice(body).subscribe((resolve: any) => {



        this.singleMinor = [];
        console.log("heee")

        console.log(resolve);

        if (resolve.message == 'No assigned project!') {



        } else {

          // console.log(resolve.message.length);

          for (let k = 0; k < resolve.data.length; k++) {

            if (this.selectedlang === 'en') {
              this.singleMinor.push({
                name: resolve.data[k]['project_name'],
                value: resolve.data[k]['farmerCount']
              });
            } else if (this.selectedlang === 'mr') {
              this.singleMinor.push({
                name: resolve.data[k]['project_name_marathi'],
                value: resolve.data[k]['farmerCount']
              });
            }

          }


          console.log("sss");
          console.log(this.singleMinor);
        }
      })





      const body2 = new HttpParams({
        fromObject: {
          post_user_id: post_user_id,
          user_office_id: user_office_id,
          project_type: '2',
          project_district_code: this.farmerDistrictCode,
        }
      });
      this.http.getAnalysisOffice(body2).subscribe((resolve: any) => {



        this.singleMedium = [];

        if (resolve.message == 'No assigned project!') {



        } else {

          for (let k = 0; k < resolve.data.length; k++) {

            if (this.selectedlang === 'en') {
              this.singleMedium.push({
                name: resolve.data[k]['project_name'],
                value: resolve.data[k]['farmerCount']
              });
            } else if (this.selectedlang === 'mr') {
              this.singleMedium.push({
                name: resolve.data[k]['project_name_marathi'],
                value: resolve.data[k]['farmerCount']
              });
            }

          }
        }
      })





      const body1 = new HttpParams({
        fromObject: {
          post_user_id: post_user_id,
          user_office_id: user_office_id,
          project_type: '3',
          project_district_code: this.farmerDistrictCode,
        }
      });
      this.http.getAnalysisOffice(body1).subscribe((resolve: any) => {



        this.singleMajor = [];

        if (resolve.message == 'No assigned project!') {



        } else {

          for (let k = 0; k < resolve.data.length; k++) {

            if (this.selectedlang === 'en') {
              this.singleMajor.push({
                name: resolve.data[k]['project_name'],
                value: resolve.data[k]['farmerCount']
              });
            } else if (this.selectedlang === 'mr') {
              this.singleMajor.push({
                name: resolve.data[k]['project_name_marathi'],
                value: resolve.data[k]['farmerCount']
              });
            }

          }
        }
      })
    }
  }
}
