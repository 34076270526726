
import {  Component, OnInit,ViewChild,ElementRef } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  
  fontSize = 14;
  @ViewChild('para', { static: true }) para: ElementRef;
  title = 'mwrd-frontend';


  changeFont(operator) {
    operator === '+' ? this.fontSize++ : this.fontSize--;
    (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;
    
  }
}
