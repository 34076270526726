<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!---->
        <!-- dashboard right panel start-->
        <div class="dashbordright dashboard-box">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <!-- <div class="dashboardbnnr fullwidth">
                    <img class="fullwidth" src="assets/images/innerbanner.jpg" alt="banner" />
                </div> -->
            <!-- dashboardbnnr over-->
            <!-- mid content start-->
            <div class="greenstrip fullwidth">
                <h1>{{'Dashboard' | translate}}</h1>
            </div>
            <!-- <p #para>dsfsdfsdfsdfsd</p> -->
            <div class="mid-content fullwidth">
                <!-- mid content nav start-->
                <div class="row frmrow">
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Project Status' | translate}}</label>
                        <ng-multiselect-dropdown class="input-permissions" name="city1" [placeholder]="data.select"
                            [settings]="dropdownSettings" [data]="proSType" (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>


                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'District' | translate}}</label>
                        <select class="stepinpt" (change)="getdistrictchange($event.target.value)">

                            <option *ngFor="let list of getDistArray" [value]="list.district_code">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.district_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.district_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Corporation' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCor" disabled type="text"
                                value="{{this.data.office_detail?.Corporation}}"></span>

                        <select *ngIf="showCor" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Corporation',1)">

                            <option *ngFor="let list of CoprationArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Region' | translate}}</label>
                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Region}}"></span>
                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Region',1)">

                            <option *ngFor="let list of RegionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>
                <div class="row frmrow">
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Circle' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCir" disabled type="text"
                                value="{{this.data.office_detail?.circle}}"></span>

                        <select *ngIf="showCir" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Circle',1)">

                            <option *ngFor="let list of CircleArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>

                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Division}}"></span>


                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Division','1')">

                            <option *ngFor="let list of DivisionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Sub Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSub" disabled type="text"
                                value="{{this.data.office_detail?.subDivis}}"></span>
                        <select *ngIf="showSub" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Sub Division','1')">

                            <option *ngFor="let list of SubDivArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Section' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSec" disabled type="text"
                                value="{{this.data.office_detail?.Section}}"></span>


                        <select class="stepinpt" *ngIf="showSec" (change)="getAnalysisOffice($event.target.value)">
                            <option *ngFor="let list of SectionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>




                <!-- <div *ngIf = '!allProject'>

                <table>
                    <tr>
                        <th>Names of Dams of projects</th>
                        <th>No. Of Farmers</th>
                    </tr>
                    <tr *ngFor="let list of single">
                        <td>{{list.name}}</td>
                        <td>{{list.value}}</td>
                    </tr>

                </table>
                <ul class="midnav">
                 // [legend]="showLegend" -->


                <!--   <ngx-charts-bar-horizontal  [scheme]="colorScheme" [results]="single"
                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                    </ngx-charts-bar-horizontal>

                </ul>
                


            </div> -->


                <!-- <div *ngIf = 'allProject'> -->
                <div class="row frmrow">
                    <div class="col-md-6">
                        <div class="graphbox" *ngIf='showMinor'>

                            <table>
                                <tr>
                                    <th>{{'Names of Dams of Minor projects' | translate}}</th>
                                    <th>{{'No. Of Farmers' | translate}}</th>
                                </tr>
                                <tr *ngFor="let list of singleMinor">
                                    <td>{{list.name}}</td>
                                    <td>{{list.value}}</td>
                                </tr>

                            </table>
                            <div class="midnav">
                                <!-- // [legend]="showLegend" -->
                                <!-- [view]="view" -->


                                <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="singleMinor"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                                </ngx-charts-bar-horizontal>

                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="graphbox" *ngIf='showMedium'>
                            <table>
                                <tr>
                                <tr>
                                    <th>{{'Names of Dams of Medium projects' | translate}}</th>
                                    <th>{{'No. Of Farmers' | translate}}</th>
                                </tr>
                                <tr *ngFor="let list of singleMedium">
                                    <td>{{list.name}}</td>
                                    <td>{{list.value}}</td>
                                </tr>
                            </table>
                            <ul class="midnav">
                                <!-- // [legend]="showLegend" -->
                                <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="singleMedium"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                                </ngx-charts-bar-horizontal>

                            </ul>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="graphbox" *ngIf='showMajor'>
                            <table>
                                <tr>
                                    <th>{{'Names of Dams of Major projects' | translate}}</th>
                                    <th>{{'No. Of Farmers' | translate}}</th>
                                </tr>
                                <tr *ngFor="let list of singleMajor">
                                    <td>{{list.name}}</td>
                                    <td>{{list.value}}</td>
                                </tr>
                            </table>
                            <ul class="midnav">
                                <!-- // [legend]="showLegend" -->
                                <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="singleMajor"
                                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                                </ngx-charts-bar-horizontal>

                            </ul>


                        </div>
                    </div>
                    <!-- mid content nav over-->
                    <!-- row start-->
                    <div class="row margintop">

                        <!-- col-md-6 over-->
                        <!-- col-md-6 start-->
                        <div class="col-md-6 chartimgbx">
                            <!-- <h2 class="smlheadh2 col-md-9"><span>Daily Submitted Data</span></h2> -->



                        </div>
                        <!-- col-md-6 over-->
                    </div>
                    <!-- row over-->

                    <!-- footer start-->
                    <footer class="dboardfooter fullwidth">
                        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                    </footer>
                    <!-- footer over-->

                </div>
                <!-- mid content over-->

            </div>
            <!-- dashboard right panel over-->
        </div>