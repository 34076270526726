import { Injectable } from '@angular/core';
import { url } from './url';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './../_service/common.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class WuaMasterService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    })
  }
  serviceUrl: url;
  constructor(
    private http: HttpClient,
    private common: CommonService,

  ) {
    this.serviceUrl = new url();
  }


  getWuaData(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/wua-details', data)

  }

  saveWuaMasterData(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/save-wua-details', data)
  }

  getUserLinkData(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'wua-master/get-user-link-data-against-pos-id', data)
  }


  getWuaLsiting(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'wua-master/get-wua-master-listing', data)
  }


  getWUADataAgainstID(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/get-wua-data-against-wua-id', data)
  }

  getWuaTypes() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + 'wua-master/get-wua-types')
  }

  getWuaCategories() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + 'wua-master/get-wua-categories')
  }

  getWuaTerritorial() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + "wua-master/get-wua-territorial")
  }

  getWuaActs() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + "wua-master/get-wua-acts")
  }

  getWuaStatus() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + "wua-master/get-wua-statuses");
  }

  approvedWuaMaster(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + "wua-master/approve-wua-master", data);
  }

}
