import { Component, OnInit, ChangeDetectorRef, Injectable, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../_service/shared.service';
// import { MatTreeNestedDataSource } from '@angular/material/tree';
// import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

declare var $: any;




interface FoodNode {
	name: string;
	children?: FoodNode[];
}

var TREE_DATA: FoodNode[] = [
	// {
	// 	name: 'Fruit',
	// 	children: [
	// 		{ name: 'Apple' },
	// 		{ name: 'Banana' },
	// 		{ name: 'Fruit loops' },
	// 	]
	// }, {
	// 	name: 'Vegetables',
	// 	children: [
	// 		{
	// 			name: 'Green',
	// 			children: [
	// 				{ name: 'Broccoli' },
	// 				{ name: 'Brussels sprouts' },
	// 			]
	// 		}, {
	// 			name: 'Orange',
	// 			children: [
	// 				{ name: 'Pumpkins' },
	// 				{ name: 'Carrots' },
	// 			]
	// 		},
	// 	]
	// },
];

//   TREE_DATA.push()

interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	level: number;
}
@Component({
	selector: 'app-project-layout-new',
	templateUrl: './project-layout-new.component.html',
	styleUrls: ['./project-layout-new.component.css'],
	encapsulation: ViewEncapsulation.None,

})


export class ProjectLayoutNewComponent implements OnInit {


	// treeData = 
	// 		{
	// 			ProjectName: {
	// 				'Hatgegahar' : {
	// 					'Canle type':{
	// 						main: 'main',
	// 						branch:'branch',
	// 						distributory: 'distributory'
	// 					}
	// 				},
	// 			},
	// 		  };

	projectSelect = []
	clickEventsubscription: Subscription;
	damsSelect = []
	project_gis_no
	mainCanalSelect = []
	dam_unique
	LayoutForm: FormGroup;
	PopLayoutForm: FormGroup;
	branchPopup: FormGroup;
	distruPopupForm: FormGroup;
	minorPopupForm: FormGroup;
	subMinorPopupForm: FormGroup;
	outletForm: FormGroup;
	canalSelect: any = [];
	NA = { id: 1, name: 'NA' }
	NaN = { id: 1, name: 'NaN' }
	mainCanelNaN: boolean = true
	branchNaN: boolean = true
	distryNaN: boolean = true
	minorNaN: boolean = true
	subminorNaN: boolean = true
	outletNaN: boolean = true
	getCanlBranchArray = []
	getDistubutoryArray = []
	getSubMinorCanalArray = []
	getMinorCanalArray = []
	isShowEditMainCanel: boolean = false
	isshowEditBranchCanel: boolean = false
	isshowEditDistry: boolean = false
	isshowEditMinor: boolean = false
	isshowEditSubMinor: boolean = false
	isShowEditOutlet: boolean = false
	outlet_canal_master_id
	selectedlang = localStorage.getItem('selected_lang');
	data: any = {};
	mainCanelType
	outletCanalSide
	dataListBranch = [];






	en_data: any = {

		view_project: "Project Layout",
		project_name: "Name of Project",
		dam_name: "Name of Dam",
		canal_name: "Name of Canal",
		type_canal: "Type of Canal",
		ica_main: "ICA of Main Canal",
		cca_main: "CCA of Main canal",
		gca_main: "GCA of Main canal",
		name_marathi: "Marathi Name",
		name_branch: "Name of Branch",
		ica_branch: "ICA of Branch",
		cca_branch: "CCA of Branch",
		gca_branch: "GCA of Branch",
		offtaking_branch: "Offtaking Chainage (Km)  of Branch",
		distriutory: "Name of Distributory",
		ica_distriutory: "ICA of Distributory",
		cca_distriutory: "CCA of Distributory",
		gca_distriutory: "GCA of Distributory",
		off_distriutory: "Offtaking Chainage (Km)  of Distributory",
		minor_name: "Name of Minor",
		gca_minor: "GCA of Minor",
		cca_minor: "CCA of Minor",
		ica_minor: "ICA of Minor",
		off_minor: "Offtaking Chainage (Km)  of Minor",
		name_subminor: "Name of Subminor",
		gca_subminor: "GCA of Subminor",
		cca_subminor: "CCA of Subminor",
		ica_subminor: "ICA of Subminor",
		off_subminor: "Offtaking Chainage (Km) of Subminor",
		name_outlet: "Name of Outlet",
		save: "Save",
		reset: "Reset",
		Ok: "Ok"





	}

	mr_data: any = {
		view_project: "प्रकल्पाच्या कालवा वितरण व्यवस्थेचा  मास्टर",
		project_name: "प्रकल्पाचे नाव",
		dam_name: "धरणाचे नाव",
		canal_name: "कालव्याचे नाव",
		type_canal: "कालव्याचा प्रकार",
		ica_main: "मुख्य कालव्याचे सिंचित लाभक्षेत्र",
		cca_main: "मुख्य कालव्याचा लागवडीयोग्य लाभक्षेत्र",
		gca_main: "मुख्य कालव्याचे एकुण लाभक्षेत्र",
		name_marathi: "मराठी नाव",
		name_branch: "शाखा कालव्याचे नाव",
		ica_branch: "शाखा कालव्याचे सिंचित लाभक्षेत्र",
		cca_branch: "शाखा कालव्याचे लागवडीयोग्य लाभक्षेत्र",
		gca_branch: "शाखा कालव्याचे एकुण लाभक्षेत्र",
		offtaking_branch: "शाखा कालव्याचे साखळी क्र. (Km)",
		distriutory: "वितरिकाचे नाव",
		ica_distriutory: "वितरिकाचे सिंचित लाभक्षेत्र",
		cca_distriutory: "वितरिकाचे लागवडीयोग्य लाभक्षेत्र",
		gca_distriutory: "वितरिकाचे एकुण लाभक्षेत्र",
		off_distriutory: "वितरिकाचे साखळी क्र. (Km)",
		minor_name: "लघुवितरिकाचे नाव",
		gca_minor: "लघुवितरिकाचे सिंचित लाभक्षेत्र",
		cca_minor: "लघुवितरिकाचे लागवडीयोग्य लाभक्षेत्र",
		ica_minor: "लघुवितरिकाचे एकुण लाभक्षेत्र",
		off_minor: "लघुवितरिकाचे  साखळी क्र. (Km)",
		name_subminor: "उप लघुवितरिकाचे नाव",
		gca_subminor: "उप लघुवितरिकाचे सिंचित लाभक्षेत्र",
		cca_subminor: "उप लघुवितरिकाचे लागवडीयोग्य लाभक्षेत्र",
		ica_subminor: "उप लघुवितरिकाचे एकुण लाभक्षेत्र",
		off_subminor: "उप लघुवितरिकाचे साखळी क्र. (Km)",
		name_outlet: "विमोचकाचे नाव",
		save: "पुष्टी करा",
		reset: "पुन्हा माहिती भरा",
		Ok: "ठीक आहे"


	}





	getOutlistingArray = []
	bankArray = []
	mainCanelSubmitted = false
	//	treeData: any;


	private _transformer = (node: FoodNode, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			level: level,
		};
	}

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		node => node.level, node => node.expandable);

	treeFlattener = new MatTreeFlattener(
		this._transformer, node => node.level, node => node.expandable, node => node.children);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	projectLayoutData: any = {};
	dataListBranch1: any;
	uniqueNames: any;

	ica: boolean = true;
	cca: boolean = true;
	gca: boolean = true;
	branchica: boolean = true;
	branchcca: boolean = true;
	branchgca: boolean = true;
	disica: boolean = true;
	discca: boolean = true;
	disgca: boolean = true;
	minorica: boolean = true;
	minorcca: boolean = true;
	minorgca: boolean = true;
	subminorica: boolean = true;
	subminorcca: boolean = true;
	subminorgca: boolean = true;
	outletica: boolean = true;
	outletcca: boolean = true;
	outletgca: boolean = true;
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpRequestService,
		private common: CommonService,
		public translate: TranslateService,
		private sharedService: SharedService,
		private cdr: ChangeDetectorRef,
		private ngxService: NgxUiLoaderService,
	) {


		this.dataSource.data = TREE_DATA;

		this.LayoutForm = this.fb.group({
			"projectName": new FormControl(null, [Validators.required]),
			"damName": new FormControl(null, [Validators.required]),
			"canalName": new FormControl(''),
			"bank_id": new FormControl(null, [Validators.required]),

		});
		this.getProjectDetails()
		this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
			// this.common.language$.next(localStorage.getItem('selected_lang'));
			this.ngOnInit();
			TREE_DATA = [];
			this.dataSource.data = TREE_DATA;
		})

	}
	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
	ngOnInit(): void {


		this.LayoutForm = this.fb.group({
			"projectName": new FormControl(null, [Validators.required]),
			"damName": new FormControl(null, [Validators.required]),
			"canalName": new FormControl(''),
			"bank_id": new FormControl(null, [Validators.required]),

		});
		// TREE_DATA.push(this.LayoutForm.projectName.value)
		// this.nestedDataSource.data =  JSON.stringify(this.treeData);
		this.PopLayoutForm = this.fb.group({
			main_canal_object: new FormControl(null),
			main_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			main_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			main_canal_type: new FormControl(null, [Validators.required]),
			// main_canal_main_type: new FormControl(null, [Validators.required]),
			main_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			main_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			main_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			// main_canal_outlet: new FormControl(null, [Validators.required]),^([0-9]{1,10})+(.[0-9]{0,2})?$// "\d{1,2}(\.\d{0,4})?"
			abcd: new FormControl(null),
			project_unique_gis: new FormControl(null),
			dam_unique_code: new FormControl(null),
			main_canal_id: new FormControl(null),
			post_user_id: new FormControl(localStorage.getItem('post_user_id')),
			main_canal_bank_id: new FormControl(null),

		});
		this.branchPopup = this.fb.group({
			branch_code_object: new FormControl(null),
			project_unique_gis: new FormControl(null),
			dam_unique_code: new FormControl(null),
			main_canal_id: new FormControl(null),
			// branch_canal_code: new FormControl(null, [Validators.required]),
			branch_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			branch_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			// branch_canal_structure: new FormControl(null, [Validators.required]),
			branch_canal_chainage_km: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 ./']*$")]),
			// branch_canal_chainage_m: new FormControl(null, [Validators.required]),
			branch_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			branch_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			branch_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			branch_canal_bank_id: new FormControl(null),
			branch_canal_master_id: new FormControl(null),
			abcd: new FormControl(null),
			post_user_id: new FormControl(localStorage.getItem('post_user_id')),
		});
		this.distruPopupForm = this.fb.group({
			project_unique_gis: new FormControl(null),
			dam_unique_code: new FormControl(null),
			main_canal_id: new FormControl(null),
			branch_canal_id: new FormControl(null),
			abcd: new FormControl(null),
			distributory_canal_id: new FormControl(null),
			// distributory_canal_code: new FormControl(null, [Validators.required]),
			distributory_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			distributory_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			// distributory_canal_structure: new FormControl(null, [Validators.required]),
			distributory_canal_chainage_km: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 . /']*$")]),
			// distributory_canal_chainage_m: new FormControl(null, [Validators.required]),
			distributory_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			distributory_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			distributory_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			distributory_bank_id: new FormControl(null),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))
		});
		this.minorPopupForm = this.fb.group({
			project_unique_gis: new FormControl(null),
			dam_unique_code: new FormControl(null),
			main_canal_id: new FormControl(null),
			branch_canal_id: new FormControl(null),
			abcd: new FormControl(null),
			minor_canal_id: new FormControl(null),
			distributory_canal_id: new FormControl(null),
			// minor_canal_code: new FormControl(null, [Validators.required]),
			minor_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			minor_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			// minor_canal_structure: new FormControl(null, [Validators.required]),
			minor_canal_chainage_km: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 . /']*$")]),
			minor_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			minor_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			minor_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			minor_bank_id: new FormControl(null),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))
		})
		this.subMinorPopupForm = this.fb.group({
			project_unique_gis: new FormControl(null),
			dam_unique_code: new FormControl(null),
			main_canal_id: new FormControl(null),
			branch_canal_id: new FormControl(null),
			distributory_canal_id: new FormControl(null),
			minor_canal_id: new FormControl(null),
			subminor_canal_id: new FormControl(null),
			abcd: new FormControl(null),
			// subminor_canal_code: new FormControl(null, [Validators.required]),
			subminor_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			subminor_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			// subminor_canal_structure: new FormControl(null, [Validators.required]),
			subminor_canal_chainage_km: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 . /']*$")]),
			// subminor_canal_chainage_m: new FormControl(null, [Validators.required]),'^([0-9]{1,10})(.[0-9]{0,2})?$'
			subminor_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			subminor_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			subminor_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			subminor_bank_id: new FormControl(null),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))

		})
		this.outletForm = this.fb.group({
			outlet_canal_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9. _-]+$"), Validators.maxLength(80)]),
			outlet_canal_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(160)]),
			outlet_canal_position: new FormControl(null, [Validators.required]),
			outlet_canal_offtaking_chainage_km: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 . /']*$")]),
			outlet_canal_ica: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			outlet_canal_cca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			outlet_canal_gca: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
			post_user_id: new FormControl(localStorage.getItem('post_user_id')),
			abcd: new FormControl(null),
			outlet_canal_id: new FormControl(null),
			outlet_bank_id: new FormControl(null),
		});


		this.subscribeToLanguageChange(localStorage.getItem('selected_lang'))
		switch (this.selectedlang) {
			case "en":
				this.data = this.en_data;
				break;
			case "mr":
				this.data = this.mr_data;
				break;
		}
		this.cdr.markForCheck()

		this.disableMainCanalFields()
		this.disableBranchCanalFields()
		this.disableDistributoryFields()
		this.disableMinorCanalFields()
		this.disableSubminorCanalFields()
		this.disableOutletFields()




		this.LayoutForm.valueChanges.subscribe(data => {











		});

	}

	disableMainCanalFields() {
		// this.PopLayoutForm.controls.main_canal_id.disable();
		this.PopLayoutForm.controls.main_canal_name.disable()
		this.PopLayoutForm.controls.main_canal_name_marathi.disable();
		this.PopLayoutForm.controls.main_canal_type.disable();
		this.PopLayoutForm.controls.main_canal_ica.disable();
		this.PopLayoutForm.controls.main_canal_cca.disable();
		this.PopLayoutForm.controls.main_canal_gca.disable();
	}

	disableBranchCanalFields() {
		this.branchPopup.controls.branch_canal_name.disable();
		this.branchPopup.controls.branch_canal_name_marathi.disable();
		this.branchPopup.controls.branch_canal_chainage_km.disable();
		this.branchPopup.controls.branch_canal_ica.disable();
		this.branchPopup.controls.branch_canal_cca.disable();
		this.branchPopup.controls.branch_canal_gca.disable();
	}

	disableDistributoryFields() {
		this.distruPopupForm.controls.distributory_canal_name.disable();
		this.distruPopupForm.controls.distributory_canal_name_marathi.disable();
		this.distruPopupForm.controls.distributory_canal_chainage_km.disable();
		this.distruPopupForm.controls.distributory_canal_ica.disable();
		this.distruPopupForm.controls.distributory_canal_cca.disable();
		this.distruPopupForm.controls.distributory_canal_gca.disable();
	}

	disableMinorCanalFields() {
		this.minorPopupForm.controls.minor_canal_name.disable();
		this.minorPopupForm.controls.minor_canal_name_marathi.disable();
		this.minorPopupForm.controls.minor_canal_gca.disable();
		this.minorPopupForm.controls.minor_canal_ica.disable();
		this.minorPopupForm.controls.minor_canal_cca.disable();
		this.minorPopupForm.controls.minor_canal_chainage_km.disable();
	}

	disableSubminorCanalFields() {
		this.subMinorPopupForm.controls.subminor_canal_name.disable();
		this.subMinorPopupForm.controls.subminor_canal_name_marathi.disable();
		this.subMinorPopupForm.controls.subminor_canal_chainage_km.disable();
		this.subMinorPopupForm.controls.subminor_canal_ica.disable();
		this.subMinorPopupForm.controls.subminor_canal_cca.disable();
		this.subMinorPopupForm.controls.subminor_canal_gca.disable();
	}

	disableOutletFields() {
		this.outletForm.controls.outlet_canal_name.disable();
		this.outletForm.controls.outlet_canal_name_marathi.disable();
		this.outletForm.controls.outlet_canal_position.disable();
	}

	subscribeToLanguageChange(lang) {
		this.selectedlang = lang
		this.translate.use(lang)
	}
	getProjectDetails() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});
		this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve)
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.projectSelect = resolve.data;
				} else
					return false;
			}
		},
			(err) => {
				console.log(err)
			})
	}
	onChangeforDams(id) {


		let post_user_id = localStorage.getItem('post_user_id');
		this.project_gis_no = id;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_id: id,
			}
		});
		this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve);
			//return false;
			let data1 = resolve;

			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.damsSelect = resolve.data;

					return false;
				} else
					return false;
			}
		},
			(err) => {
				console.log(err);
			})
	}
	getAllList() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_bank_id: this.LayoutForm.value.bank_id,
				branch_canal_bank_id: this.LayoutForm.value.bank_id,
				distributory_bank_id: this.LayoutForm.value.bank_id,
				minor_bank_id: this.LayoutForm.value.bank_id,
				subminor_bank_id: this.LayoutForm.value.bank_id,
				outlet_bank_id: this.LayoutForm.value.bank_id,
			}
		});
		this.http.getCanalDetailLayout(body).subscribe((resolve: any) => {
			this.mainCanalSelect = resolve.data;

		})

		this.http.getBranchesOfCanel(body).subscribe((branchesData: any) => {
			// console.log(branchesData)
			this.getCanlBranchArray = branchesData.data

		})
		this.http.getDistributory(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.getDistubutoryArray = arr.data

		})
		this.http.getListOfMinor(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.getMinorCanalArray = arr.data

		})
		this.http.getListIfSubMinor(body).subscribe((data: any) => {
			this.getSubMinorCanalArray = data.data

		})
		this.http.getoutletListing(body).subscribe((data: any) => {
			this.getOutlistingArray = data.data
			this.getPushTreeData();
		})

	}


	// getProjectLayout() {
	// 	let post_user_id = localStorage.getItem('post_user_id');
	// 	const body = new HttpParams({
	// 		fromObject: {
	// 			project_id: this.LayoutForm.value.projectName,
	// 			dam_id: this.LayoutForm.value.damName,
	// 			bank_id:  this.LayoutForm.value.bank_id,
	// 		}
	// 	});

	// 	this.http.getprojectLayout(body).subscribe((data: any) => {
	// 		this.projectLayoutData = data.data;
	// 	})

	// }
	getMainCanalDetails(ob) {
		this.dam_unique = ob;
		this.getBanks()

	}
	forBankCanal($evt) {
		this.getMainCanalType()
		this.getOutletSide()
		this.getAllList()


		// database.dataChange.subscribe(data => {
		// 	this.dataSource.data = data;
		//   });


		// var obj = {
		// 	name: this.LayoutForm.value.projectName,
		// 	children: {
		// 		name: this.LayoutForm.value.damName
		// 	}
		// };




	}


	getPushTreeData() {
		let project = this.projectSelect.find(s => s.project_id == this.LayoutForm.value.projectName);
		let dam = this.damsSelect.find(s => s.dam_id == this.LayoutForm.value.damName);
		let canal = this.bankArray.find(s => s.bank_id == this.LayoutForm.value.bank_id)
		// console.log(canal);


		if (this.selectedlang == 'en') {

			var canalTupe = canal.bank_name;
		} else {

			canalTupe = canal.bank_name_marathi;


		}
		// var mainCanalNameArr = [];
		// var branchCanalNameArr = [];
		// var distributoryNameArr = [];
		// var minorCanalNameArr = [];
		// var subminorCanalNameArr = [];
		// var outeltCanalNameArr = [];

		TREE_DATA = [];
		// this.getCanlBranchArray.forEach(element => {
		// 	// console.log(this.selectedlang,'===')

		// 	if (this.selectedlang == 'en') {
		// 		var ob1 = {
		// 			name: element.branch_canal_name
		// 		}

		// 	} else {

		// 		var ob1 = {
		// 			name: element.branch_canal_name_marathi
		// 		}
		// 	}
		// 	branchCanalNameArr.push(ob1);
		// });
		// this.mainCanalSelect.forEach(element => {

		// 	if (this.selectedlang == 'en') {
		// 		var ob = {
		// 			name: element.main_canal_name
		// 		}
		// 	} else {

		// 		var ob = {
		// 			name: element.main_canal_name_marathi
		// 		}


		// 	}
		// 	mainCanalNameArr.push(ob);


		// 	// console.log(mainCanalNameArr,"=====");
		// });

		// this.getDistubutoryArray.forEach(element => {


		// 	if (this.selectedlang == 'en') {
		// 		var ob = {
		// 			name: element.distributory_canal_name
		// 		}
		// 	} else {

		// 		var ob = {
		// 			name: element.distributory_canal_name_marathi
		// 		}
		// 	}
		// 	distributoryNameArr.push(ob);
		// });

		// this.getMinorCanalArray.forEach(element => {

		// 	if (this.selectedlang == 'en') {
		// 		var ob = {
		// 			name: element.minor_canal_name
		// 		}
		// 	} else {

		// 		var ob = {
		// 			name: element.minor_canal_name_marathi
		// 		}

		// 	}
		// 	minorCanalNameArr.push(ob);
		// });

		// this.getSubMinorCanalArray.forEach(element => {


		// 	if (this.selectedlang == 'en') {
		// 		var ob = {
		// 			name: element.subminor_canal_name
		// 		}

		// 	} else {
		// 		var ob = {
		// 			name: element.subminor_canal_name_marathi
		// 		}

		// 	}
		// 	subminorCanalNameArr.push(ob);
		// });

		// this.getOutlistingArray.forEach(element => {

		// 	if (this.selectedlang == 'en') {
		// 		var ob = {
		// 			name: element.outlet_canal_name
		// 		}
		// 	} else {


		// 		var ob = {
		// 			name: element.outlet_canal_name_marathi
		// 		}
		// 	}
		// 	outeltCanalNameArr.push(ob);
		// });


		const body = new HttpParams({
			fromObject: {
				project_id: this.LayoutForm.value.projectName,
				dam_id: this.LayoutForm.value.damName,
				bank_id: this.LayoutForm.value.bank_id,
			}
		});

		this.http.getprojectLayout(body).subscribe((data: any) => {

			TREE_DATA = [];
			this.dataListBranch = [];
			this.projectLayoutData = data.data;
			console.log(this.projectLayoutData);
			for (var i in this.projectLayoutData) {



				if (this.projectLayoutData[i].mwrd_main_canal_master) {

					var mainCanalName = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_main_canal_master.main_canal_name : this.projectLayoutData[i].mwrd_main_canal_master.main_canal_name_marathi
				}
				else {
					mainCanalName = this.selectedlang == 'en' ? 'No' : 'नाही'
				}

				if (this.projectLayoutData[i].mwrd_branch_canal_master) {

					var branchmainCanalName = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_branch_canal_master.branch_canal_name : this.projectLayoutData[i].mwrd_branch_canal_master.branch_canal_name_marathi
				}
				else {
					branchmainCanalName = this.selectedlang == 'en' ? 'No' : 'नाही'
				}


				if (this.projectLayoutData[i].mwrd_distributory_canal_master) {

					var distributoryName = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_distributory_canal_master.distributory_canal_name : this.projectLayoutData[i].mwrd_distributory_canal_master.distributory_canal_name_marathi
				}
				else {
					distributoryName = this.selectedlang == 'en' ? 'No' : 'नाही'
				}




				if (this.projectLayoutData[i].mwrd_minor_canal_master) {

					var MinorName = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_minor_canal_master.minor_canal_name : this.projectLayoutData[i].mwrd_minor_canal_master.minor_canal_name_marathi
				}
				else {
					MinorName = this.selectedlang == 'en' ? 'No' : 'नाही'
				}



				if (this.projectLayoutData[i].mwrd_subminor_canal_master) {

					var subMinor = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_subminor_canal_master.subminor_canal_name : this.projectLayoutData[i].mwrd_subminor_canal_master.subminor_canal_name_marathi
				}
				else {
					subMinor = this.selectedlang == 'en' ? 'No' : 'नाही'
				}



				if (this.projectLayoutData[i].mwrd_outlet_canal_master) {

					var oulteName = this.selectedlang == 'en' ? this.projectLayoutData[i].mwrd_outlet_canal_master.outlet_canal_name : this.projectLayoutData[i].mwrd_outlet_canal_master.outlet_canal_name_marathi
				}
				else {
					oulteName = this.selectedlang == 'en' ? 'No' : 'नाही'
				}


				this.dataListBranch.push({
					name: mainCanalName,
					children: [{
						name: branchmainCanalName,
						children: [{
							name: distributoryName,
							children: [{
								name: MinorName,
								children: [{
									name: subMinor,
									children: [{
										name: oulteName,

									}],
								}],
							}],

						}],
					}],
				});
			}


			// console.log(this.dataListBranch);

			// this.dataListBranch1 = JSON.stringify(this.dataListBranch);

			// console.log("ddd")
			// console.log(this.dataListBranch1);


			var obj = {
				name: this.selectedlang == 'en' ? project.project_name : project.project_name_marathi,
				children: [
					{
						name: this.selectedlang == 'en' ? dam.dam_name : dam.dam_name_marathi,
						children: [{

							name: canalTupe,
							children: this.dataListBranch,
						}]
					},
				]
			};
			TREE_DATA.push(obj);
			console.log(TREE_DATA, '==')
			this.dataSource.data = TREE_DATA;








		})

		// var obj = {
		// 	name: this.selectedlang == 'en' ? project.project_name : project.project_name_marathi,
		// 	children: [
		// 		{
		// 			name: this.selectedlang == 'en' ? dam.dam_name : dam.dam_name_marathi,
		// 			children: [
		// 				{
		// 					name: this.selectedlang == 'en' ? canal.bank_name : canal.bank_name_marathi,

		// 					children: [
		// 						{
		// 							name: this.selectedlang == 'en' ?'Main Canal Details' :'मुख्य कालव्याची माहिती',
		// 							children: mainCanalNameArr

		// 						}, {
		// 							name: this.selectedlang == 'en' ?'Branch Details' : 'शाखा कालव्याची माहिती', 
		// 							children: branchCanalNameArr
		// 						},
		// 						{
		// 							name: this.selectedlang == 'en' ?'Distributory Details' :'वितरीकेची माहिती',
		// 							children: distributoryNameArr
		// 						},
		// 						{
		// 							name: this.selectedlang == 'en' ?'Minor Details' : 'लघुवितरिकाची माहिती',
		// 							children: minorCanalNameArr
		// 						},
		// 						{
		// 							name: this.selectedlang == 'en' ?'Sub Minor Details':'उप लघुवितरीकेची माहिती',
		// 							children: subminorCanalNameArr
		// 						},
		// 						{
		// 							name: this.selectedlang == 'en'  ?'Outlet Details':'विमोचकाचे माहिती',
		// 							children: outeltCanalNameArr
		// 						}


		// 					],

		// 				},
		// 			]
		// 		},
		// 	]
		// };

		// TREE_DATA.push(obj);
		// console.log(TREE_DATA, "=")
		// this.dataSource.data = TREE_DATA;


	}
	getBanks() {
		// getBankId  bankArray
		this.http.getBankId().subscribe((resolve: any) => {
			// console.log(resolve);

			this.bankArray = resolve.data;
			// 
		})

	}

	getMainCanalType() {
		this.http.getMainCanalType().subscribe((resolve: any) => {

			if (resolve.data) {
				this.mainCanelType = resolve.data
			}
			else {
				return false;
			}
		})
	}

	myChangeMainCanel(evt) {
		// console.log(evt)
		if (evt.name == 'NA') {
			// this.PopLayoutForm.reset()
			this.PopLayoutForm.enable()
			$("#preModal").show()
			this.mainCanelNaN = true
			this.PopLayoutForm.patchValue({
				main_canal_name: '',
				main_canal_name_marathi: '',
				main_canal_type: '',
				main_canal_ica: '',
				main_canal_cca: '',
				main_canal_gca: '',
				main_canal_id: '',
				main_canal_bank_id: ''
			})
		} else if (evt.name == 'NaN') {
			this.mainCanelNaN = false
			// this.PopLayoutForm.reset()
			this.PopLayoutForm.patchValue({
				main_canal_name: '',
				main_canal_name_marathi: '',
				main_canal_type: '',
				main_canal_ica: '',
				main_canal_cca: '',
				main_canal_gca: '',
				main_canal_id: '',
				main_canal_bank_id: ''
			})
		} else {
			this.mainCanelNaN = true
			this.PopLayoutForm.patchValue({
				main_canal_name: evt.main_canal_name,
				main_canal_name_marathi: evt.main_canal_name_marathi,
				main_canal_type: evt.main_canal_type,
				main_canal_ica: evt.main_canal_ica,
				main_canal_cca: evt.main_canal_cca,
				main_canal_gca: evt.main_canal_gca,
				main_canal_id: evt.main_canal_id,
				main_canal_bank_id: this.LayoutForm.value.bank_id
			})
			this.PopLayoutForm.controls.main_canal_name.disable();
			this.PopLayoutForm.controls.main_canal_name_marathi.disable();
			this.PopLayoutForm.controls.main_canal_type.disable();
			this.PopLayoutForm.controls.main_canal_ica.disable();
			this.PopLayoutForm.controls.main_canal_cca.disable();
			this.PopLayoutForm.controls.main_canal_gca.disable();
			this.PopLayoutForm.controls.main_canal_bank_id.disable();




		}
	}
	translateMarathi(evt, type) {


		//alert("here");

		if (!evt.target.value) {
			return
		}
		const body = new HttpParams({
			fromObject: {
				text: evt.target.value,
			}
		});
		this.http.englishToMarathi(body).subscribe((data: any) => {
			// console.log(data)
			if (type == 'maincanel') {
				this.PopLayoutForm.patchValue({ main_canal_name_marathi: data.data })
			} else if (type == 'branchcanel') {
				this.branchPopup.patchValue({ branch_canal_name_marathi: data.data })
			} else if (type == 'distCanel') {
				this.distruPopupForm.patchValue({ distributory_canal_name_marathi: data.data })
			} else if (type == 'minorcanel') {
				this.minorPopupForm.patchValue({ minor_canal_name_marathi: data.data })
			} else if (type == 'subminorcanel') {
				this.subMinorPopupForm.patchValue({ subminor_canal_name_marathi: data.data })
			} else if (type == 'outlet') {
				this.outletForm.patchValue({ outlet_canal_name_marathi: data.data })
			} else if (type == 'forAlert') {
				return data.data;
			}
		})
	}
	submitMainCanalDetails() {
		this.mainCanelSubmitted = true;
		this.ica = true;
		this.cca = true;
		this.gca = true;
		if (this.PopLayoutForm.value.main_canal_ica && this.PopLayoutForm.value.main_canal_cca && this.PopLayoutForm.value.main_canal_gca) {
			if (this.PopLayoutForm.value.main_canal_ica > this.PopLayoutForm.value.main_canal_cca) {
				this.ica = false;
				return;

			} else if (this.PopLayoutForm.value.main_canal_cca > this.PopLayoutForm.value.main_canal_gca) {
				this.cca = false;
				return;

			} else if (this.PopLayoutForm.value.main_canal_ica > this.PopLayoutForm.value.main_canal_gca) {
				this.gca = false;
				return;
			}

		}
		if (this.PopLayoutForm.status == "INVALID") {
			if (this.selectedlang == 'mr') {

				alert("सर्व माहिती भरणे अनिवार्य आहे.");

			} else {
				alert("All Fields are Mandatory!");

			}
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.PopLayoutForm.value.dam_unique_code = this.dam_unique
		this.PopLayoutForm.value.project_unique_gis = this.project_gis_no
		this.PopLayoutForm.value.main_canal_bank_id = this.LayoutForm.value.bank_id
		// console.log(this.dam_unique)
		body = new HttpParams({
			fromObject: this.PopLayoutForm.value
		});

		this.http.addUpdateMainCanal(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.PopLayoutForm.value.main_canal_id) {

				// this.mainCanalSelect.splice(this.PopLayoutForm.value.main_canal_id, 1)
				var updateName = this.mainCanalSelect.findIndex((obj => obj.main_canal_id == this.PopLayoutForm.value.main_canal_id));
				this.mainCanalSelect[updateName].main_canal_name = resolve.data.main_canal_name

				this.sendAlert('Details Successfully updated')
				this.isShowEditMainCanel = false
			} else {
				//this.sendAlert('Details Successfully added')
				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.mainCanalSelect.push(resolve.data)
				this.PopLayoutForm.controls["main_canal_object"].setValue('');
				this.PopLayoutForm.controls["main_canal_object"].setValue(resolve.data);
			}
			this.myChangeMainCanel(resolve.data)
			$("#preModal").hide();
			this.getPushTreeData();

		},
			(err) => {
				console.log(err);
			})
	}
	get f() { return this.PopLayoutForm.controls; }
	onChangeCanelBranches(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.branchPopup.enable()
			$("#canalBranch").show()
			this.branchNaN = true

			this.branchPopup.patchValue({
				branch_canal_code: '',
				branch_canal_name: '',
				branch_canal_name_marathi: '',
				branch_canal_structure: '',
				branch_canal_chainage_km: '',
				branch_canal_chainage_m: '',
				branch_canal_ica: '',
				branch_canal_cca: '',
				branch_canal_gca: '',
				branch_canal_master_id: '',
				branch_canal_bank_id: '',
			})

		} else if (evt.name == 'NaN') {
			this.branchNaN = false
			this.branchPopup.patchValue({
				branch_canal_code: '',
				branch_canal_name: '',
				branch_canal_name_marathi: '',
				branch_canal_structure: '',
				branch_canal_chainage_km: '',
				branch_canal_chainage_m: '',
				branch_canal_ica: '',
				branch_canal_cca: '',
				branch_canal_gca: '',
				branch_canal_master_id: '',
				branch_canal_bank_id: '',
			})
		} else {
			this.branchNaN = true
			this.branchPopup.patchValue({
				branch_canal_code: evt.branch_canal_code,
				branch_canal_name: evt.branch_canal_name,
				branch_canal_name_marathi: evt.branch_canal_name_marathi,
				branch_canal_structure: evt.branch_canal_structure,
				branch_canal_chainage_km: evt.branch_canal_offtaking_chainage_km,
				branch_canal_chainage_m: evt.branch_canal_chainage_m,
				branch_canal_ica: evt.branch_canal_ica,
				branch_canal_cca: evt.branch_canal_cca,
				branch_canal_gca: evt.branch_canal_gca,
				branch_canal_master_id: evt.branch_canal_master_id,
				branch_canal_bank_id: this.LayoutForm.value.bank_id,
			})
			this.branchPopup.controls.branch_canal_name.disable();
			this.branchPopup.controls.branch_canal_name_marathi.disable();
			this.branchPopup.controls.branch_canal_chainage_km.disable();
			this.branchPopup.controls.branch_canal_ica.disable();
			this.branchPopup.controls.branch_canal_cca.disable();
			this.branchPopup.controls.branch_canal_gca.disable();
			// this.branchPopup.controls.branch_canal_bank_id.disable();
		}
		// const body = new HttpParams({
		// 	fromObject: {
		// 		project_unique_gis: this.project_gis_no,
		// 		dam_unique_code: this.dam_unique,
		// 		main_canal_id: this.PopLayoutForm.value.main_canal_id,
		// 		branch_canal_id: evt.branch_canal_master_id
		// 	}
		// });

	}
	submitBranchCanelDetails() {
		//	console.log(this.branchPopup)


		this.branchica = true;
		this.branchcca = true;
		this.branchgca = true;
		if (this.branchPopup.value.branch_canal_ica && this.branchPopup.value.branch_canal_cca && this.branchPopup.value.branch_canal_gca) {

			if (this.branchPopup.value.branch_canal_ica > this.branchPopup.value.branch_canal_cca) {
				// alert("dddd")
				this.branchica = false;
				return;

			} if (this.branchPopup.value.branch_canal_cca > this.branchPopup.value.branch_canal_gca) {
				this.branchcca = false;
				return;

			} if (this.branchPopup.value.branch_canal_ica > this.branchPopup.value.branch_canal_gca) {
				this.branchgca = false;
				return;
			}

		}
		if (this.branchPopup.status == "INVALID") {
			//this.sendAlert('All feilds are mandatory')

			if (this.selectedlang == 'mr') {

				alert("सर्व माहिती भरणे अनिवार्य आहे.");

			} else {
				alert("All Fields are Mandatory!");

			}
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.branchPopup.value.dam_unique_code = this.dam_unique
		this.branchPopup.value.project_unique_gis = this.project_gis_no
		this.branchPopup.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.branchPopup.value.branch_canal_bank_id = this.LayoutForm.value.bank_id
		body = new HttpParams({
			fromObject: this.branchPopup.value
		});
		this.http.addCanelBranches(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.branchPopup.value.branch_canal_master_id) {
				this.isshowEditBranchCanel = false
				var updateName = this.getCanlBranchArray.findIndex((obj => obj.branch_canal_master_id == this.branchPopup.value.branch_canal_master_id));
				this.getCanlBranchArray[updateName].branch_canal_name = resolve.data.branch_canal_name
				this.sendAlert('Details Successfully updated')
			} else {
				//	this.sendAlert('Details Successfully added')
				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.getCanlBranchArray.push(resolve.data)
				this.branchPopup.controls["branch_code_object"].setValue('');
				this.branchPopup.controls["branch_code_object"].setValue(resolve.data);
			}
			this.onChangeCanelBranches(resolve.data)
			$("#canalBranch").hide();
			this.getPushTreeData();
		},
			(err) => {
				console.log(err);
			}
		)
	}
	onChangeDistributory(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.distruPopupForm.enable()
			$("#distCanel").show()
			this.distryNaN = true
			this.distruPopupForm.patchValue({
				distributory_canal_code: '',
				distributory_canal_name: '',
				distributory_canal_name_marathi: '',
				distributory_canal_structure: '',
				distributory_canal_chainage_km: '',
				distributory_canal_chainage_m: '',
				distributory_canal_ica: '',
				distributory_canal_cca: '',
				distributory_canal_gca: '',
				distributory_canal_id: '',
				distributory_bank_id: '',
				//branch_canal_offtaking_chainage_km
			})
		} else if (evt.name == 'NaN') {
			this.distryNaN = false
			this.distruPopupForm.patchValue({
				distributory_canal_code: '',
				distributory_canal_name: '',
				distributory_canal_name_marathi: '',
				distributory_canal_structure: '',
				distributory_canal_chainage_km: '',
				distributory_canal_chainage_m: '',
				distributory_canal_ica: '',
				distributory_canal_cca: '',
				distributory_canal_gca: '',
				distributory_canal_id: '',
				distributory_bank_id: '',
				//branch_canal_offtaking_chainage_km
			})
		} else {
			this.distryNaN = true
			this.distruPopupForm.patchValue({
				distributory_canal_code: evt.distributory_canal_code,
				distributory_canal_name: evt.distributory_canal_name,
				distributory_canal_name_marathi: evt.distributory_canal_name_marathi,
				distributory_canal_structure: evt.distributory_canal_structure,
				distributory_canal_chainage_km: evt.distributory_canal_offtaking_chainage_km,
				distributory_canal_chainage_m: evt.distributory_canal_chainage_m,
				distributory_canal_ica: evt.distributory_canal_ica,
				distributory_canal_cca: evt.distributory_canal_cca,
				distributory_canal_gca: evt.distributory_canal_gca,
				distributory_canal_id: evt.distributory_canal_master_id,
				distributory_bank_id: this.LayoutForm.value.bank_id,
				//branch_canal_offtaking_chainage_km
			})
			this.distruPopupForm.controls.distributory_canal_name.disable();
			this.distruPopupForm.controls.distributory_canal_name_marathi.disable();
			this.distruPopupForm.controls.distributory_canal_chainage_km.disable();
			this.distruPopupForm.controls.distributory_canal_ica.disable();
			this.distruPopupForm.controls.distributory_canal_cca.disable();
			this.distruPopupForm.controls.distributory_canal_gca.disable();
			this.distruPopupForm.controls.distributory_bank_id.disable();
		}
		// const body = new HttpParams({
		// 	fromObject: {
		// 		project_unique_gis: this.project_gis_no,
		// 		dam_unique_code: this.dam_unique,
		// 		main_canal_id: this.PopLayoutForm.value.main_canal_id,
		// 		branch_canal_id: this.branchPopup.value.branch_canal_master_id,
		// 		distributory_canal_id: evt.distributory_canal_master_id

		// 	}
		// });

	}
	submitDistributoryDetails() {
		// console.log(this.distruPopupForm.value)



		this.disica = true;
		this.discca = true;
		this.disgca = true;
		if (this.distruPopupForm.value.distributory_canal_ica && this.distruPopupForm.value.distributory_canal_cca && this.distruPopupForm.value.distributory_canal_gca) {
			//alert("dddd")

			if (this.distruPopupForm.value.distributory_canal_ica > this.distruPopupForm.value.distributory_canal_cca) {
				// alert("dddd")
				this.disica = false;
				return;

			} else if (this.distruPopupForm.value.distributory_canal_cca > this.distruPopupForm.value.distributory_canal_gca) {
				this.discca = false;
				return;

			} else if (this.distruPopupForm.value.distributory_canal_ica > this.distruPopupForm.value.distributory_canal_gca) {
				this.disgca = false;
				return;
			}

		}
		if (this.distruPopupForm.status == "INVALID") {
			///this.sendAlert('All feilds are mandatory')
			if (this.selectedlang == 'mr') {

				alert("सर्व माहिती भरणे अनिवार्य आहे.");

			} else {
				alert("All Fields are Mandatory!");

			}
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.distruPopupForm.value.dam_unique_code = this.dam_unique
		this.distruPopupForm.value.project_unique_gis = this.project_gis_no
		this.distruPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.distruPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.distruPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id
		this.distruPopupForm.value.distributory_bank_id = this.LayoutForm.value.bank_id
		body = new HttpParams({
			fromObject: this.distruPopupForm.value
		});
		this.http.addUpdateDistributory(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.distruPopupForm.value.distributory_canal_id) {
				console.log(this.getDistubutoryArray);
				console.log();

				var updateName = this.getDistubutoryArray.findIndex((obj => obj.distributory_canal_master_id == this.distruPopupForm.value.distributory_canal_id));
				console.log(this.getDistubutoryArray);

				this.getDistubutoryArray[updateName].distributory_canal_name = resolve.data.distributory_canal_name
				this.sendAlert('Details Successfully updated')
				this.isshowEditDistry = false
			} else {
				//this.sendAlert('Details Successfully added')
				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.getDistubutoryArray.push(resolve.data)
				this.distruPopupForm.controls["abcd"].setValue('');
				this.distruPopupForm.controls["abcd"].setValue(resolve.data);
				this.onChangeDistributory(resolve.data)
			}
			this.onChangeDistributory(resolve.data)
			$("#distCanel").hide();
			this.getPushTreeData();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	onChangeMinorCanal(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.minorPopupForm.enable()
			$("#minorCanel").show()
			this.minorNaN = true

			this.minorPopupForm.patchValue({
				minor_canal_code: '',
				minor_canal_name: '',
				minor_canal_name_marathi: '',
				minor_canal_structure: '',
				branch_canal_master_id: '',
				minor_canal_id: '',
				minor_canal_gca: '',
				minor_canal_ica: '',
				minor_canal_cca: '',
				minor_canal_chainage_km: '',
				minor_bank_id: '',
			})

		} else if (evt.name == 'NaN') {
			this.minorNaN = false
			this.minorPopupForm.patchValue({
				minor_canal_code: '',
				minor_canal_name: '',
				minor_canal_name_marathi: '',
				minor_canal_structure: '',
				branch_canal_master_id: '',
				minor_canal_id: '',
				minor_canal_gca: '',
				minor_canal_ica: '',
				minor_canal_cca: '',
				minor_canal_chainage_km: '',
				minor_bank_id: '',
			})
		} else {
			this.minorNaN = true
			this.minorPopupForm.patchValue({
				minor_canal_code: evt.minor_canal_code,
				minor_canal_name: evt.minor_canal_name,
				minor_canal_name_marathi: evt.minor_canal_name_marathi,
				minor_canal_structure: evt.minor_canal_structure,
				branch_canal_master_id: evt.branch_canal_master_id,
				minor_canal_id: evt.minor_canal_master_id,
				minor_canal_gca: evt.minor_canal_gca,
				minor_canal_ica: evt.minor_canal_ica,
				minor_canal_cca: evt.minor_canal_cca,
				minor_canal_chainage_km: evt.minor_canal_offtaking_chainage_km,
				minor_bank_id: this.LayoutForm.value.bank_id,
			})
			this.minorPopupForm.controls.minor_canal_name.disable();
			this.minorPopupForm.controls.minor_canal_name_marathi.disable();
			this.minorPopupForm.controls.minor_canal_gca.disable();
			this.minorPopupForm.controls.minor_canal_ica.disable();
			this.minorPopupForm.controls.minor_canal_cca.disable();
			this.minorPopupForm.controls.minor_canal_chainage_km.disable();
			this.minorPopupForm.controls.minor_bank_id.disable();
		}
		// const body = new HttpParams({
		// 	fromObject: {
		// 		project_unique_gis: this.project_gis_no,
		// 		dam_unique_code: this.dam_unique,
		// 		main_canal_id: this.PopLayoutForm.value.main_canal_id,
		// 		branch_canal_id: this.branchPopup.value.branch_canal_master_id,
		// 		distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
		// 		minor_canal_id: evt.minor_canal_master_id,


		// 	}
		// });

	}
	submitMinorCanalDetails() {
		// console.log(this.minorPopupForm)


		this.minorica = true;
		this.minorcca = true;
		this.minorgca = true;
		if (this.minorPopupForm.value.minor_canal_ica && this.minorPopupForm.value.minor_canal_cca && this.minorPopupForm.value.minor_canal_gca) {
			//alert("dddd")

			if (this.minorPopupForm.value.minor_canal_ica > this.minorPopupForm.value.minor_canal_cca) {
				// alert("dddd")
				this.minorica = false;
				return;

			} else if (this.minorPopupForm.value.minor_canal_cca > this.minorPopupForm.value.minor_canal_gca) {
				this.minorcca = false;
				return;

			} else if (this.minorPopupForm.value.minor_canal_ica > this.minorPopupForm.value.minor_canal_gca) {
				this.minorgca = false;
				return;
			}

		}
		if (this.minorPopupForm.status == "INVALID") {
			//	this.sendAlert('All feilds are mandatory')
			if (this.selectedlang == 'mr') {

				alert("सर्व माहिती भरणे अनिवार्य आहे.");

			} else {
				alert("All Fields are Mandatory!");

			}
			return
		}


		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.minorPopupForm.value.dam_unique_code = this.dam_unique
		this.minorPopupForm.value.project_unique_gis = this.project_gis_no
		this.minorPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.minorPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.minorPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id
		this.minorPopupForm.value.minor_bank_id = this.LayoutForm.value.bank_id
		body = new HttpParams({
			fromObject: this.minorPopupForm.value
		});
		this.http.addUpdateMinor(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.minorPopupForm.value.minor_canal_id) {
				var updateName = this.getMinorCanalArray.findIndex((obj => obj.minor_canal_master_id == this.minorPopupForm.value.minor_canal_id));
				this.getMinorCanalArray[updateName].minor_canal_name = resolve.data.minor_canal_name

				this.sendAlert('Details Successfully updated')
				this.isshowEditMinor = false
			} else {
				//this.sendAlert('Details Successfully added')
				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.getMinorCanalArray.push(resolve.data)
				this.minorPopupForm.controls["abcd"].setValue('');
				this.minorPopupForm.controls["abcd"].setValue(resolve.data);
				this.onChangeMinorCanal(resolve.data)
			}
			this.onChangeMinorCanal(resolve.data)
			$("#minorCanel").hide();
			this.getPushTreeData();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	onChangeSubMinorCanal(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {

			this.subMinorPopupForm.enable()
			$("#subMinorCanel").show()
			this.subminorNaN = true

			this.subMinorPopupForm.patchValue({
				subminor_canal_code: '',
				subminor_canal_name: '',
				subminor_canal_name_marathi: '',
				subminor_canal_structure: '',
				subminor_canal_chainage_km: '',
				subminor_canal_chainage_m: '',
				subminor_canal_ica: '',
				subminor_canal_cca: '',
				subminor_canal_gca: '',
				subminor_canal_id: '',
				subminor_bank_id: '',

			})

		} else if (evt.name == 'NaN') {
			this.subminorNaN = false
			this.subMinorPopupForm.patchValue({
				subminor_canal_code: '',
				subminor_canal_name: '',
				subminor_canal_name_marathi: '',
				subminor_canal_structure: '',
				subminor_canal_chainage_km: '',
				subminor_canal_chainage_m: '',
				subminor_canal_ica: '',
				subminor_canal_cca: '',
				subminor_canal_gca: '',
				subminor_canal_id: '',
				subminor_bank_id: '',

			})
		} else {
			this.subminorNaN = true
			this.subMinorPopupForm.patchValue({
				subminor_canal_code: evt.subminor_canal_code,
				subminor_canal_name: evt.subminor_canal_name,
				subminor_canal_name_marathi: evt.subminor_canal_name_marathi,
				subminor_canal_structure: evt.subminor_canal_structure,
				subminor_canal_chainage_km: evt.subminor_canal_offtaking_chainage_km,
				subminor_canal_chainage_m: evt.subminor_canal_chainage_m,
				subminor_canal_ica: evt.subminor_canal_ica,
				subminor_canal_cca: evt.subminor_canal_cca,
				subminor_canal_gca: evt.subminor_canal_gca,
				subminor_canal_id: evt.subminor_canal_master_id,
				subminor_bank_id: this.LayoutForm.value.bank_id,

			})
			this.subMinorPopupForm.controls.subminor_canal_name.disable();
			this.subMinorPopupForm.controls.subminor_canal_name_marathi.disable();
			this.subMinorPopupForm.controls.subminor_canal_chainage_km.disable();
			this.subMinorPopupForm.controls.subminor_canal_ica.disable();
			this.subMinorPopupForm.controls.subminor_canal_cca.disable();
			this.subMinorPopupForm.controls.subminor_canal_gca.disable();
			this.subMinorPopupForm.controls.subminor_bank_id.disable();
			// this.getOutlistingArray = []
			// const body = new HttpParams({
			// 	fromObject: {
			// 		project_unique_gis: this.project_gis_no,
			// 		dam_unique_code: this.dam_unique,
			// 		main_canal_id: this.PopLayoutForm.value.main_canal_id,
			// 		branch_canal_id: this.branchPopup.value.branch_canal_master_id,
			// 		distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
			// 		minor_canal_id: this.minorPopupForm.value.minor_canal_id,
			// 		subminor_canal_id: this.subMinorPopupForm.value.subminor_canal_id,
			// 		outlet_bank_id:this.LayoutForm.value.bank_id,
			// 	}
			// });
			// this.http.getoutletListing(body).subscribe((data: any) => {
			// 	this.getOutlistingArray = data.data

			// })


		}
	}
	submitSubMinorCanalDetails() {
		// console.log(this.subMinorPopupForm)



		this.subminorica = true;
		this.subminorcca = true;
		this.subminorgca = true;
		if (this.subMinorPopupForm.value.subminor_canal_ica && this.subMinorPopupForm.value.subminor_canal_cca && this.subMinorPopupForm.value.subminor_canal_gca) {
			//	alert("dddd")

			if (this.subMinorPopupForm.value.subminor_canal_ica > this.subMinorPopupForm.value.subminor_canal_cca) {
				// alert("dddd")
				this.subminorica = false;
				return;

			} else if (this.subMinorPopupForm.value.subminor_canal_cca > this.subMinorPopupForm.value.subminor_canal_gca) {
				this.subminorcca = false;
				return;

			} else if (this.subMinorPopupForm.value.subminor_canal_ica > this.subMinorPopupForm.value.subminor_canal_gca) {
				this.subminorgca = false;
				return;
			}

		}

		if (this.subMinorPopupForm.status == "INVALID") {
			//	this.sendAlert('All feilds are mandatory')

			if (this.selectedlang == 'mr') {

				alert("सदर माहिती जतन करण्यात आलेली आहे.");
				return

			} else {
				alert("This information has been saved");
				return

			}

		}



		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.subMinorPopupForm.value.dam_unique_code = this.dam_unique
		this.subMinorPopupForm.value.project_unique_gis = this.project_gis_no
		this.subMinorPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.subMinorPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.subMinorPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id
		this.subMinorPopupForm.value.minor_canal_id = this.minorPopupForm.value.minor_canal_id
		this.subMinorPopupForm.value.subminor_bank_id = this.LayoutForm.value.bank_id

		body = new HttpParams({
			fromObject: this.subMinorPopupForm.value
		});
		this.http.addUpdateSubMinor(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.subMinorPopupForm.value.subminor_canal_id) {
				var updateName = this.getSubMinorCanalArray.findIndex((obj => obj.subminor_canal_master_id == this.subMinorPopupForm.value.subminor_canal_id));
				this.getSubMinorCanalArray[updateName].subminor_canal_name = resolve.data.subminor_canal_name
				this.sendAlert('Details Successfully updated')
				this.isshowEditSubMinor = false
			} else {
				//	this.sendAlert('Details Successfully added')
				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.getSubMinorCanalArray.push(resolve.data)
				this.subMinorPopupForm.controls["abcd"].setValue('');
				this.subMinorPopupForm.controls["abcd"].setValue(resolve.data);
				this.onChangeSubMinorCanal(resolve.data)
			}
			this.onChangeSubMinorCanal(resolve.data)
			$("#subMinorCanel").hide();
			this.getPushTreeData();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	onChangeOutListing(evt) {
		if (evt.name == 'NA') {
			this.outletForm.enable()
			$("#outlet").show()
			this.outletNaN = true
			this.outletForm.patchValue({
				outlet_canal_name: '',
				outlet_canal_name_marathi: '',
				outlet_canal_position: '',
				outlet_canal_id: '',
				outlet_bank_id: '',
				outlet_canal_offtaking_chainage_km: '',
				outlet_canal_cca: '',
				outlet_canal_ica: '',
				outlet_canal_gca: '',
			})
		} else if (evt.name == 'NaN') {
			this.outletNaN = false;

			$("#outlet").hide();
			this.outletForm.patchValue({
				outlet_canal_name: '',
				outlet_canal_name_marathi: '',
				outlet_canal_position: '',
				outlet_canal_id: '',
				outlet_bank_id: '',
				outlet_canal_offtaking_chainage_km: '',
				outlet_canal_cca: '',
				outlet_canal_ica: '',
				outlet_canal_gca: '',
			})

		} else {
			this.outletNaN = true
			this.outletForm.patchValue({
				outlet_canal_name: evt.outlet_canal_name,
				outlet_canal_name_marathi: evt.outlet_canal_name_marathi,
				outlet_canal_position: evt.outlet_canal_position,
				outlet_canal_id: evt.outlet_canal_master_id,
				outlet_bank_id: this.LayoutForm.value.bank_id,
				outlet_canal_offtaking_chainage_km: evt.outlet_canal_offtaking_chainage_km,
				outlet_canal_cca: evt.outlet_canal_cca,
				outlet_canal_ica: evt.outlet_canal_ica,
				outlet_canal_gca: evt.outlet_canal_gca,
			})
			this.outlet_canal_master_id = evt.outlet_canal_master_id
			this.outletForm.controls.outlet_canal_name.disable();
			this.outletForm.controls.outlet_canal_name_marathi.disable();
			this.outletForm.controls.outlet_canal_position.disable();
			this.outletForm.controls.outlet_bank_id.disable();
			this.outletForm.controls.outlet_canal_offtaking_chainage_km.disable();
			this.outletForm.controls.outlet_canal_cca.disable();
			this.outletForm.controls.outlet_canal_ica.disable();
			this.outletForm.controls.outlet_canal_gca.disable();
		}
	}
	submitOutletForm() {




		this.outletica = true;
		this.outletcca = true;
		this.outletgca = true;
		if (this.outletForm.value.outlet_canal_ica && this.outletForm.value.outlet_canal_cca && this.outletForm.value.outlet_canal_gca) {
			//	alert("dddd")

			if (this.outletForm.value.outlet_canal_ica > this.outletForm.value.outlet_canal_cca) {
				// alert("dddd")
				this.outletica = false;
				return;

			} else if (this.outletForm.value.outlet_canal_cca > this.outletForm.value.outlet_canal_gca) {
				this.outletcca = false;
				return;

			} else if (this.outletForm.value.outlet_canal_ica > this.outletForm.value.outlet_canal_gca) {
				this.outletgca = false;
				return;
			}

		}

		if (this.outletForm.status == "INVALID") {
			//	this.sendAlert('all fields are mandatory')
			if (this.selectedlang == 'mr') {

				alert("सदर माहिती जतन करण्यात आलेली आहे.");

			} else {
				alert("This information has been saved");

			}
			return
		}
		var body = new HttpParams({
			fromObject: {
				post_user_id: localStorage.getItem('post_user_id'),
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_id: this.PopLayoutForm.value.main_canal_id,
				branch_canal_id: this.branchPopup.value.branch_canal_master_id,
				distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
				minor_canal_id: this.minorPopupForm.value.minor_canal_id,
				subminor_canal_id: this.subMinorPopupForm.value.subminor_canal_id,
				outlet_canal_position: this.outletForm.value.outlet_canal_position,
				outlet_canal_name: this.outletForm.value.outlet_canal_name,
				outlet_canal_name_marathi: this.outletForm.value.outlet_canal_name_marathi,
				outlet_canal_offtaking_chainage_km: this.outletForm.value.outlet_canal_offtaking_chainage_km,
				outlet_canal_cca: this.outletForm.value.outlet_canal_cca,
				outlet_canal_ica: this.outletForm.value.outlet_canal_ica,
				outlet_canal_gca: this.outletForm.value.outlet_canal_gca,
				outlet_canal_id: this.outletForm.value.outlet_canal_id,
				outlet_bank_id: this.LayoutForm.value.bank_id
			}
		});
		this.http.addUpdateOutletCanel(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.outlet_canal_master_id = resolve.data.outlet_canal_master_id
			if (this.outletForm.value.outlet_canal_id) {
				this.sendAlert('Details Successfully updated')
				this.outletForm.controls.outlet_canal_name.disable();
				this.outletForm.controls.outlet_canal_name_marathi.disable();
				this.outletForm.controls.outlet_canal_position.disable();
				this.outletForm.controls.outlet_bank_id.disable();
				this.outletForm.controls.outlet_canal_offtaking_chainage_km.disable();
				this.outletForm.controls.outlet_canal_cca.disable();
				this.outletForm.controls.outlet_canal_ica.disable();
				this.outletForm.controls.outlet_canal_gca.disable();
				var updateName = this.getOutlistingArray.findIndex((obj => obj.outlet_canal_master_id == this.outletForm.value.outlet_canal_id));
				this.getOutlistingArray[updateName].outlet_canal_name = resolve.data.outlet_canal_name
				this.isShowEditOutlet = false
			} else {
				//this.sendAlert("Details Successfully added")

				if (this.selectedlang == 'mr') {

					alert("सदर माहिती जतन करण्यात आलेली आहे.");

				} else {
					alert("This information has been saved");

				}
				this.getOutlistingArray.push(resolve.data)
				this.outletForm.controls["abcd"].setValue('');
				this.outletForm.controls["abcd"].setValue(resolve.data);
				this.onChangeOutListing(resolve.data)
				this.getPushTreeData();
			}
		}, (err) => {
			console.log(err)
		})
		$("#outlet").hide();
	}
	finalSubmit() {
		console.log(this.PopLayoutForm.value.main_canal_object);
		if (this.LayoutForm.status == "INVALID") {
			this.sendAlert("please select project name ,dam name and Canal Type");
			return
		}

		if (!this.PopLayoutForm.value.main_canal_id
			&& !this.branchPopup.value.branch_canal_master_id
			&& !this.distruPopupForm.value.distributory_canal_id
			&& !this.minorPopupForm.value.minor_canal_id
			&& !this.subMinorPopupForm.value.subminor_canal_id
			&& !this.outlet_canal_master_id) {
			this.sendAlert("please select Any of the Options");
			return
		}

		

		if(!this.PopLayoutForm.value.main_canal_object){

			this.sendAlert("please select Main Canal Details ");
			return
		}

		if(!this.branchPopup.value.branch_code_object){

			this.sendAlert("please select Branch Details ");
			return
		}
		if(!this.distruPopupForm.value.abcd){

			this.sendAlert("please select Distributory Details ");
			return
		}
		if(!this.minorPopupForm.value.abcd){

			this.sendAlert("please select Minor Canal Details ");
			return
		}
		if(!this.subMinorPopupForm.value.abcd){

			this.sendAlert("please select Subminor Canal Details ");
			return
		}
		if(!this.outletForm.value.abcd){

			this.sendAlert("please select Outlet Canal Details ");
			return
		}
		//	return false;

		// 	if (this.LayoutForm.value.bank_id == "") {
		// 	this.sendAlert("Please Select Canal Type");
		// 	return
		// }


		// console.log(this.LayoutForm);
		// console.log(this.PopLayoutForm);
		// console.log(this.branchPopup);
		// console.log(this.distruPopupForm)
		// console.log(this.minorPopupForm)
		// console.log(this.subMinorPopupForm)
		// console.log(this.outletForm)

		//return false;



		if (!this.outlet_canal_master_id) {
			this.outlet_canal_master_id = null
		}
		// if(this.outletForm.status == "INVALID"){
		// 	alert('All feilds are mandatory')
		// 	return
		// }


		this.ngxService.start();
		var body = new HttpParams({
			fromObject: {
				project_layout_project_unique_code: this.project_gis_no,
				project_layout_dam_unique_code: this.dam_unique,
				project_layout_main_canal_id: this.PopLayoutForm.value.main_canal_id,
				project_layout_branch_canal_id: this.branchPopup.value.branch_canal_master_id,
				project_layout_distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
				project_layout_minor_canal_id: this.minorPopupForm.value.minor_canal_id,
				project_layout_subminor_canal_id: this.subMinorPopupForm.value.subminor_canal_id,
				post_user_id: localStorage.getItem('post_user_id'),
				project_layout_outlet_id: this.outlet_canal_master_id,
				project_layout_bank_id: this.LayoutForm.value.bank_id
			}
		});
		this.http.addProjectlayoutMaster(body).subscribe((resolve: any) => {
			// console.log(resolve);
			// this.submitOutletForm()
			// this.router.navigate(['/projectLayout'])
			if (this.selectedlang == 'en') {
				this.ngxService.stop();
				alert('This information has been saved')
			}
			else {
				alert('सदर माहिती जतन करण्यात आलेली आहे')
			}
			// this.sendAlert("This information has been saved")
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
				this.router.navigate(['/projectLayout'])
			);
		},
			(err) => {
				console.log(err)
			})
	}
	close(id) {
		if (id == 'mainCanel') {
			$("#preModal").hide();
			this.disableMainCanalFields();
			this.PopLayoutForm.controls["main_canal_object"].setValue('');

		} else if (id == "canalBranch") {
			this.disableBranchCanalFields();
			this.branchPopup.controls["branch_code_object"].setValue('');
			$('#canalBranch').hide();
		} else if (id == "distCanel") {
			this.disableDistributoryFields();
			this.distruPopupForm.controls["abcd"].setValue('');
			$('#distCanel').hide();
		} else if (id == "minorCanel") {
			this.disableMinorCanalFields()
			this.minorPopupForm.controls["abcd"].setValue('');
			$('#minorCanel').hide();
		} else if (id == "subMinorCanel") {

			this.disableSubminorCanalFields();
			this.subMinorPopupForm.controls["abcd"].setValue('');
			$('#subMinorCanel').hide();
		} else if (id == "outlet") {
			this.disableOutletFields();
			this.outletForm.controls["abcd"].setValue('');
			$('#outlet').hide();
		}

	}
	enableTexxtBox(txt) {
		if (txt == "maincanel") {
			this.PopLayoutForm.enable()
			this.isShowEditMainCanel = true
		} else if (txt == 'canelbranch') {
			this.branchPopup.enable()
			this.isshowEditBranchCanel = true
		} else if (txt == 'distCanel') {
			this.distruPopupForm.enable()
			this.isshowEditDistry = true
		} else if (txt == 'minorCanel') {
			this.minorPopupForm.enable()
			this.isshowEditMinor = true
		} else if (txt == 'subMinorCanel') {
			this.subMinorPopupForm.enable()
			this.isshowEditSubMinor = true
		} else if (txt == 'outlet') {
			this.outletForm.enable()
			this.isShowEditOutlet = true
		}
	}
	disableTexxtBox(txt) {
		if (txt == "maincanel") {
			this.PopLayoutForm.controls.main_canal_name.disable();
			this.PopLayoutForm.controls.main_canal_name_marathi.disable();
			this.PopLayoutForm.controls.main_canal_type.disable();
			this.PopLayoutForm.controls.main_canal_ica.disable();
			this.PopLayoutForm.controls.main_canal_cca.disable();
			this.PopLayoutForm.controls.main_canal_gca.disable();
			this.PopLayoutForm.controls.main_canal_bank_id.disable();
			this.isShowEditMainCanel = false
		} else if (txt == 'canelbranch') {
			this.branchPopup.controls.branch_canal_name.disable();
			this.branchPopup.controls.branch_canal_name_marathi.disable();
			this.branchPopup.controls.branch_canal_chainage_km.disable();
			this.branchPopup.controls.branch_canal_ica.disable();
			this.branchPopup.controls.branch_canal_cca.disable();
			this.branchPopup.controls.branch_canal_gca.disable();
			this.branchPopup.controls.branch_canal_bank_id.disable();
			this.isshowEditBranchCanel = false
		} else if (txt == 'distCanel') {
			this.distruPopupForm.controls.distributory_canal_name.disable();
			this.distruPopupForm.controls.distributory_canal_name_marathi.disable();
			this.distruPopupForm.controls.distributory_canal_chainage_km.disable();
			this.distruPopupForm.controls.distributory_canal_ica.disable();
			this.distruPopupForm.controls.distributory_canal_cca.disable();
			this.distruPopupForm.controls.distributory_canal_gca.disable();
			this.distruPopupForm.controls.distributory_bank_id.disable();
			this.isshowEditDistry = false
		} else if (txt == 'minorCanel') {
			this.minorPopupForm.controls.minor_canal_name.disable();
			this.minorPopupForm.controls.minor_canal_name_marathi.disable();
			this.minorPopupForm.controls.minor_canal_gca.disable();
			this.minorPopupForm.controls.minor_bank_id.disable();
			this.minorPopupForm.controls.minor_canal_ica.disable();
			this.minorPopupForm.controls.minor_canal_cca.disable();
			this.minorPopupForm.controls.minor_canal_chainage_km.disable();
			this.isshowEditMinor = false
		} else if (txt == 'subMinorCanel') {
			this.subMinorPopupForm.controls.subminor_canal_name.disable();
			this.subMinorPopupForm.controls.subminor_canal_name_marathi.disable();
			this.subMinorPopupForm.controls.subminor_canal_chainage_km.disable();
			this.subMinorPopupForm.controls.subminor_canal_ica.disable();
			this.subMinorPopupForm.controls.subminor_canal_cca.disable();
			this.subMinorPopupForm.controls.subminor_canal_gca.disable();
			this.subMinorPopupForm.controls.subminor_bank_id.disable();
			this.isshowEditSubMinor = false
		} else if (txt == 'outlet') {
			this.outletForm.controls.outlet_canal_name.disable();
			this.outletForm.controls.outlet_canal_name_marathi.disable();
			this.outletForm.controls.outlet_canal_position.disable();
			this.outletForm.controls.outlet_bank_id.disable();
			this.isShowEditOutlet = false
		}
	}

	sendAlert(message) {
		switch (this.selectedlang) {
			case "mr":
				const body = new HttpParams({
					fromObject: {
						text: message,
					}
				});
				this.http.englishToMarathi(body).subscribe((data: any) => {
					alert(data.data);
				});
				break;
			case "en":
				alert(message);
				break;
		}
	}

	getOutletSide() {
		this.http.getOutletCanalSide().subscribe((resolve: any) => {

			if (resolve.data) {
				this.outletCanalSide = resolve.data
			}
			else {
				return false;
			}
		})
	}

	reset() {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate(['/projectLayout'])
		);
	}
}
