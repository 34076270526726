export class url {

  // loginUrl = "http://localhost:8201/api/";
  // loginUrl = "http://49.50.68.140:8201/api/";

  // loginUrl = "http://49.50.806.205:8201/api/";
  // loginUrl = "http://localhost:8201/api/";
  localUrl = "http://localhost:8201/api/";
  // localUrl = "http://localhost:8080/api/";
  loginUrl = "http://wrd.cyfuture.com:8201/api/";
  // loginUrl = "http://localhost:8202/api/";

}