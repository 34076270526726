<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->


        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'Dam Master' | translate}}</h1>
            </div>

            <div class="mid-content fullwidth tablerecordpg">
                <div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Corporation' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCor" disabled type="text"
                                value="{{this.data.office_detail?.Corporation}}"></span>

                        <select *ngIf="showCor" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Corporation',1)">

                            <option *ngFor="let list of CoprationArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Region' | translate}}</label>
                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Region}}"></span>
                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Region',1)">

                            <option *ngFor="let list of RegionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>
                <div class="row frmrow">
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Circle' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCir" disabled type="text"
                                value="{{this.data.office_detail?.circle}}"></span>

                        <select *ngIf="showCir" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Circle',1)">

                            <option *ngFor="let list of CircleArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>

                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Division}}"></span>


                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Division','1')">

                            <option *ngFor="let list of DivisionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Sub Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSub" disabled type="text"
                                value="{{this.data.office_detail?.subDivis}}"></span>
                        <select *ngIf="showSub" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Sub Division','1')">

                            <option *ngFor="let list of SubDivArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Section' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSec" disabled type="text"
                                value="{{this.data.office_detail?.Section}}"></span>


                        <select class="stepinpt" *ngIf="showSec"
                            (change)="getChangeOfficeDetails($event.target.value,'Section','1')">
                            <option *ngFor="let list of SectionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-4 col-md-2">
                    <div class="btn btn-default btn-search-advanced btn-block" role="tab" id="headingOne"><a
                            class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne"></a></div>

                    <button type="button" class="stepgreenbtn" (click)="damMasterSearchFormSubmit()"
                        data-dismiss="modal">{{'Filter' | translate}}</button>
                </div>

                <!-- table datat start-->
                <div class="viewpro-table">
                    <!-- <ng-container *ngIf="enableAddOption"> -->

                    <div class="viewpro-table-ab viewpro-table-ab-change">
                        <ng-container *ngIf="enableAddOption">
                            <div class="page-top-btn inline-div">

                                <input (click)="Add()" class="stepgreenbtn" type="button"
                                    value="{{'Add' | translate}}" />
                                <input (click)="exportAsXLSX()" class="topgreenbtn" type="button"
                                    value="{{'Export' | translate}}" />
                            </div>
                        </ng-container>
                        <!-- <ng-container>
                                <div class="page-top-btn inline-div"> 
                                      
                                    <input (click)="Add()" class="stepgreenbtn" type="button" value="{{'Add' | translate}}" />
                                    <input (click)="exportAsXLSX()" class="topgreenbtn" type="button" value="{{'Export' | translate}}" />
                                </div>
                            </ng-container> -->
                    </div>


                    <!-- <div class="page-top-btn inline-div"> 
                                  
                            <input (click)="Add()" class="stepgreenbtn" type="button" value="{{'Add' | translate}}" />
                            <input (click)="exportAsXLSX()" class="topgreenbtn" type="button" value="{{'Export' | translate}}" />
                        </div>
                        
                    </ng-container> -->


                    <table width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                        <!-- <table width = "100%" datatable > -->
                        <thead>
                            <tr>
                                <th>{{'Project Name' | translate}}</th>
                                <th>{{'Dam Name' | translate}}</th>
                                <th>{{'District' | translate}}</th>
                                <th>{{'Taluka' | translate}}</th>
                                <th>{{'Village' | translate}}</th>
                                <th>{{'Dam Storage Creation' | translate}}</th>
                                <th>{{'Dam Basin' | translate}}</th>
                                <th>{{'Dam SubBasin' | translate}}</th>
                                <th>{{'Dam Approved'|translate}}</th>
                                <th>{{'Action' | translate}}</th>
                                <!-- {{data.action}} -->
                            </tr>
                        </thead>


                        <tfoot class="search-tr-top">
                            <tr>

                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-project_name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-first-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Wua-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-District" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Taluka-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Father" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Mobile-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Mobile-name" /></th>
                                <th></th>

                            </tr>
                        </tfoot>




                        <tbody>

                            <tr *ngFor="let list of dataArray">
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                        {{list.mwrd_project_master?.project_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectBlock>
                                        {{list.mwrd_project_master?.project_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                        {{list.dam_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseDamBlock>
                                        {{list.dam_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBankBlock">
                                        {{list.mwrd_district_master?list.mwrd_district_master.district_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBankBlock>
                                        {{list.mwrd_district_master?list.mwrd_district_master.district_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMainBlock">
                                        {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMainBlock>
                                        {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBranchBlock">
                                        {{list.mwrd_village_master?list.mwrd_village_master.village_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBranchBlock>
                                        {{list.mwrd_village_master?list.mwrd_village_master.village_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDistBlock">
                                        {{list.mwrd_dam_storage_creation_master?list.mwrd_dam_storage_creation_master.storage_creation_marathi_name:'NA'}}
                                    </ng-container>
                                    <ng-template #elseDistBlock>
                                        {{list.mwrd_dam_storage_creation_master?list.mwrd_dam_storage_creation_master.storage_creation_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMinorBlock">
                                        {{list.mwrd_basin_master?list.mwrd_basin_master.basin_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMinorBlock>
                                        {{list.mwrd_basin_master?list.mwrd_basin_master.basin_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseSubminorBlock">
                                        {{list.mwrd_subbasin_master?list.mwrd_subbasin_master.subbasin_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseSubminorBlock>
                                        {{list.mwrd_subbasin_master?list.mwrd_subbasin_master.subbasin_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'en';else elseApprovedBlock">
                                        {{list.dam_is_approved=='0'?'No':'Yes'}}
                                    </ng-container>
                                    <ng-template #elseApprovedBlock>
                                        {{list.dam_is_approved=='0'?'नाही':'होय'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ul class="tbaction">
                                        <ng-container
                                            *ngIf="list.dam_is_approved=='0' && ((list.dam_added_by_user_office_type=='Circle' && UserType==0) || (list.dam_added_by_user_office_type == 'Division' && userOfficeType == 'Circle' && userPosition.includes('Superintending Engineer')))">
                                            <li>
                                                <i (click)="approveDam(list.dam_id)" class="fa fa-check">
                                                </i>
                                            </li>
                                        </ng-container>
                                        <li title="View">
                                            <i class="" aria-hidden="true" (click)="enableViewModel(list.dam_id)">
                                                {{ 'View' | translate }}</i>
                                        </li>
                                        <!-- <li title="View"><i class="fa fa-eye" aria-hidden="true"></i></li> -->
                                        <ng-container *ngIf="list.dam_is_approved=='0' && enableEditOption">
                                            <li title="Edit">
                                                <i class="" (click)="editButtonClick(list.dam_id)" aria-hidden="true">
                                                    {{ 'Edit' | translate }}
                                                </i>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>


                    </table>
                </div>
                <br />

                <!-- table datat over-->

            </div>

            <!-- mid content over-->

        </div>
        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                        <h2>{{'View Dam Master'|translate}}</h2>
                        <br />
                        <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                    </div>
                    <div class="modal-body">




                        <ul class="pojectmstrbx">
                            <!-- <li class="prjhead">Project Details</li> -->
                            <li>
                                <label>{{'Name of Project'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseDamProjectBlock;">
                                        <p>{{aDamDetails?.data?.mwrd_project_master?.project_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseDamProjectBlock>
                                        <p>{{aDamDetails?.data?.mwrd_project_master?.project_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Name'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_name}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Marathi Name'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_name_marathi}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Dam Storage Creation Type'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseDamTypeDamBlock;">
                                        <p>{{aDamDetails?.data?.mwrd_dam_storage_creation_master?.storage_creation_marathi_name}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseDamTypeDamBlock>
                                        <p>{{aDamDetails?.data?.mwrd_dam_storage_creation_master?.storage_creation_name}}
                                        </p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'District'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseDamDistrictBlock;">
                                        <p>{{aDamDetails?.data?.mwrd_district_master?.district_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseDamDistrictBlock>
                                        <p>{{aDamDetails?.data?.mwrd_district_master?.district_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Taluka'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseDamTalukaBlock;">
                                        <p>{{aDamDetails?.data?.mwrd_taluka_master?.taluka_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseDamTalukaBlock>
                                        <p>{{aDamDetails?.data?.mwrd_taluka_master?.taluka_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Village'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseDamVillageBlock;">
                                        <p>{{aDamDetails?.data?.mwrd_village_master?.village_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseDamVillageBlock>
                                        <p>{{aDamDetails?.data?.mwrd_village_master?.village_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li class="prjhead">{{'Primary Authorities of Dam'|translate}}</li>

                            <li>
                                <label>{{'Corporation'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCorprationBlock;">
                                        <p>{{aDamDetails?.data?.primary_corporations?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCorprationBlock>
                                        <p>{{aDamDetails?.data?.primary_corporations?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Region'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectRegionBlock;">
                                        <p>{{aDamDetails?.data?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectRegionBlock>
                                        <p>{{aDamDetails?.data?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Circle'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCircleBlock;">
                                        <p>{{aDamDetails?.data?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCircleBlock>
                                        <p>{{aDamDetails?.data?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDivisionBlock;">
                                        <p>{{aDamDetails?.data?.primary_division?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectDivisionBlock>
                                        <p>{{aDamDetails?.data?.primary_division?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Sub Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSubdivisionBlock;">
                                        <p>{{aDamDetails?.data?.primary_subdivision?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSubdivisionBlock>
                                        <p>{{aDamDetails?.data?.primary_subdivision?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Section'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSectionBlock;">
                                        <p>{{aDamDetails?.data?.primary_section?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSectionBlock>
                                        <p>{{aDamDetails?.data?.primary_section?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li class="prjhead">
                                {{'Secondary Authorities of Dam'|translate}}
                            </li>
                            <li>
                                <label>{{'Corporation'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_corporations">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_corporations">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCorporationBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecCorporationBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Region'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_regions">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_regions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecRegionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecRegionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Circle'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_circles">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_circles">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCirclesBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecCirclesBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Division'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_divisions">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_divisions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecDivisionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecDivisionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Sub Division'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_subdivisions">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_subdivisions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSubDivisionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecSubDivisionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Section'|translate}}</label>
                                <div *ngIf="aDamDetails?.dam_secondary_sections">

                                    <p *ngFor="let list of aDamDetails.dam_secondary_sections">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSectionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecSectionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>
                                </div>
                            </li>
                            <li class="prjhead">
                                {{'Storage Planning'|translate}}
                            </li>
                            <!-- <li>Storage Planing</li> -->
                            <li>
                                <label>{{'Designed Live Storage (Mcum)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_live_storage_mcum}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Designed Live Storage (TMC)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_live_storage_tmc}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Designed Dead Storage (Mcum)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_dead_storage_mcum}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Designed Dead Storage (TMC)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_dead_storage_tmc}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Designed Gross Storage (Mcum)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_gross_storage_mcum}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Designed Gross Storage (TMC)'|translate}}</label>
                                <div>
                                    <p>{{aDamDetails?.data?.dam_designed_gross_storage_tmc}}</p>
                                </div>
                            </li>

                        </ul>
                        <!-- <li class="prjhead">Primary Authorities of Project</li>
dam_secondary_sections
                            <li>
                                <label>{{'Corporation'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCorprationBlock;">
                                        <p>{{aProjectDetails?.primary_corporations?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCorprationBlock>
                                        <p>{{aProjectDetails?.primary_corporations?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Region'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectRegionBlock;">
                                        <p>{{aProjectDetails?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectRegionBlock>
                                        <p>{{aProjectDetails?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Circle'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCircleBlock;">
                                        <p>{{aProjectDetails?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCircleBlock>
                                        <p>{{aProjectDetails?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDivisionBlock;">
                                        <p>{{aProjectDetails?.primary_division?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectDivisionBlock>
                                        <p>{{aProjectDetails?.primary_division?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Sub Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSubdivisionBlock;">
                                        <p>{{aProjectDetails?.primary_subdivision?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSubdivisionBlock>
                                        <p>{{aProjectDetails?.primary_subdivision?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Section Office'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSectionBlock;">
                                        <p>{{aProjectDetails?.primary_section?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSectionBlock>
                                        <p>{{aProjectDetails?.primary_section?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                        </ul> -->
                        <!--   
                            <li class="prjbglist">
                                <label>{{'Secondary District'|translate}}</label>
                                <div>
                                   

                                </div>
                            </li>
                            <li>
                                <label>Secondary Taluka</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjhead">Primary Authorities of Project</li>

                            <li>
                                <label>{{'Corporation'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCorprationBlock;">
                                        <p>{{aProjectDetails?.primary_corporations?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCorprationBlock>
                                        <p>{{aProjectDetails?.primary_corporations?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Region'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectRegionBlock;">
                                        <p>{{aProjectDetails?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectRegionBlock>
                                        <p>{{aProjectDetails?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Circle'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCircleBlock;">
                                        <p>{{aProjectDetails?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCircleBlock>
                                        <p>{{aProjectDetails?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDivisionBlock;">
                                        <p>{{aProjectDetails?.primary_division?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectDivisionBlock>
                                        <p>{{aProjectDetails?.primary_division?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Sub Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSubdivisionBlock;">
                                        <p>{{aProjectDetails?.primary_subdivision?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSubdivisionBlock>
                                        <p>{{aProjectDetails?.primary_subdivision?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Section Office'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSectionBlock;">
                                        <p>{{aProjectDetails?.primary_section?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSectionBlock>
                                        <p>{{aProjectDetails?.primary_section?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li class="prjhead">Secondary Authorities of Project</li>

                            <li class="prjbglist">
                                <label>Corporation</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>Region</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>Circle</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>Division</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>Sub Division</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>Section Office</label>
                                <div>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know don't know</p>
                                    <p>don't know don't know don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                    <p>don't know</p>
                                </div>
                            </li>

                        </ul> -->


                    </div>
                </div>
            </div>
        </div>
        <!-- dashboard right panel over-->
    </div>

</div>