<div class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->


        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{data.view_project}}</h1>
            </div>

            <div class="mid-content fullwidth">
                <form [formGroup]="projectLayoutSearchForm">
                    <div class="search-bar">
                        <div class="row frmrow">
                            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                <label class="steplbl">{{'Name of Project' | translate}}
                                    <span aria-hidden="true" style="color: red;">*</span>
                                    <!--   <span aria-hidden="true" style="color: red;">*</span> -->

                                </label>

                                <select class="stepinpt" formControlName="project_layout_project_unique_code"
                                    (change)="onChangeforDams($event.target.value)">
                                    <option *ngFor="let project of projectSelect;" [value]="project.project_id">
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                            {{project.project_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{project.project_name}}
                                        </ng-template>
                                    </option>

                                </select>

                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                <label class="steplbl">{{'Name of Dam' | translate}} <span aria-hidden="true"
                                        style="color: red;">*</span></label>
                                <select class="stepinpt" formControlName="project_layout_dam_unique_code">
                                    <option *ngFor="let dam of damsSelect;" [value]="dam.dam_id">
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                            {{dam.dam_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{dam.dam_name}}
                                        </ng-template>
                                    </option>

                                </select>
                            </div>

                            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                <label class="steplbl">{{'Canal Type' | translate}} <span aria-hidden="true"
                                    style="color: red;">*</span></label>
                                <select class="stepinpt" formControlName="project_layout_bank_id">
                                    <option *ngFor="let list of bankArray;" [value]="list.bank_id">
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                            {{list.bank_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{list.bank_name}}
                                        </ng-template>
                                    </option>

                                </select>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-2">
                                <div class="btn btn-default btn-search-advanced btn-block" role="tab" id="headingOne"><a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></a></div>

                            <button type="button" class="stepgreenbtn" (click)="projectLayoutSearchFormSubmit()" data-dismiss="modal">{{'Filter' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="mid-content fullwidth tablerecordpg">



                <!-- table datat start-->
                <div class="viewpro-table">
                    <div class="viewpro-table-ab viewpro-table-ab-change">
                        <div class="page-top-btn inline-div">
                           
                            <input (click)="exportAsXLSX()" class="stepgreenbtn exp" type="button"
                            value="{{'Export' | translate}}" />
                            <input (click)="Add()" class="stepgreenbtn" type="button" value="{{data.add}}" />
                        </div>
                    </div>

                    <table width="100%" id="display" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                        <!-- <table width = "100%" datatable > -->
                        <thead>
                            <tr>
                                <th>{{data.project_name}}</th>
                                <th>{{data.dam_name}}</th>
                                <th>{{data.bank_type}}</th>
                                <th>{{data.canal_name}}</th>
                                <th>{{data.branch}}</th>
                                <th>{{data.distributory}}</th>
                                <th>{{data.minor}}</th>
                                <th>{{data.subminor}}</th>
                                <th>{{data.outlet}}</th>
                                <th>{{data.action}}</th>
                                <!-- {{data.action}} -->
                            </tr>
                        </thead>

                        <tfoot>
                            <tr>


                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-project_name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-first-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Wua-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-District" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Taluka-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>

                                <th></th>
                                <th></th>

                            </tr>
                        </tfoot>
                        <tbody>

                            <tr *ngFor="let list of dataArray">
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                        {{list.mwrd_project_master?.project_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectBlock>
                                        {{list.mwrd_project_master?.project_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                        {{list.mwrd_dam_master?.dam_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseDamBlock>
                                        {{list.mwrd_dam_master?.dam_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBankBlock">
                                        {{list.mwrd_bank_master?list.mwrd_bank_master.bank_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBankBlock>
                                        {{list.mwrd_bank_master?list.mwrd_bank_master.bank_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMainBlock">
                                        {{list.mwrd_main_canal_master?list.mwrd_main_canal_master.main_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMainBlock>
                                        {{list.mwrd_main_canal_master?list.mwrd_main_canal_master.main_canal_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBranchBlock">
                                        {{list.mwrd_branch_canal_master?list.mwrd_branch_canal_master.branch_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBranchBlock>
                                        {{list.mwrd_branch_canal_master?list.mwrd_branch_canal_master.branch_canal_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDistBlock">
                                        {{list.mwrd_distributory_canal_master?list.mwrd_distributory_canal_master.distributory_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseDistBlock>
                                        {{list.mwrd_distributory_canal_master?list.mwrd_distributory_canal_master.distributory_canal_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMinorBlock">
                                        {{list.mwrd_minor_canal_master?list.mwrd_minor_canal_master.minor_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMinorBlock>
                                        {{list.mwrd_minor_canal_master?list.mwrd_minor_canal_master.minor_canal_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseSubminorBlock">
                                        {{list.mwrd_subminor_canal_master?list.mwrd_subminor_canal_master.subminor_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseSubminorBlock>
                                        {{list.mwrd_subminor_canal_master?list.mwrd_subminor_canal_master.subminor_canal_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseOutletBlock">
                                        {{list.mwrd_outlet_canal_master?list.mwrd_outlet_canal_master.outlet_canal_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseOutletBlock>
                                        {{list.mwrd_outlet_canal_master?list.mwrd_outlet_canal_master.outlet_canal_name:'NA'}}
                                    </ng-template>

                                </td>
                                <td>
                                    <ul class="tbaction">
                                        <!-- <li title="View"><i class="fa fa-eye" aria-hidden="true"></i></li> -->
                                        <li title="Destroy">
                                            <i class="fa fa-trash"
                                                (click)="destroyProjectLayout(list.project_layout_id)"
                                                aria-hidden="true">
                                            </i>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>



                    </table>
                </div>
                <br />

                <!-- table datat over-->

            </div>

            <!-- mid content over-->

        </div>
        <!-- dashboard right panel over-->
    </div>

</div>