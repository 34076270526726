<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">

        <div class="dashbordright viewfarmer-page">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->

            <div class="greenstrip fullwidth">
                <h1>{{data.farmer}}</h1>
            </div>

            <div class="mid-content fullwidth tablerecordpg pt-0">

                <div class="viewpro-table">


                    <div class="table-topbtn">

                        <!-- <input (click)="Export()" class="stepgreenbtn1" type="button" value="Export" /> -->

                        <table width="100%" id="display" style="margin-top: 20px;">

                            <!-- <table width = "100%" datatable > -->
                            <thead>
                                <tr>
                                    <th>{{'Project Name' | translate}}</th>
                                    <th>{{'Dam Name' | translate}}</th>
                                    <th>{{'Name_WUA' | translate}}</th>

                                    <th>{{'District' | translate}}</th>
                                    <th>{{'Taluka' | translate}}</th>
                                    <th>{{'Village' | translate}}</th>
                                    <th>{{'Farmer_Name' | translate}}</th>
                                    <th>{{'Farmer_Mobile_number' | translate}}</th>

                                    <th>{{'GUTNo' | translate}}</th>
                                    <th>{{'Total_GUT' | translate}}</th>
                                    <th>{{'Pot_KHARAB_Area' | translate}}</th>
                                    <th>{{'Owned_Area_EXCL_POT_KHARAB' | translate}}</th>

                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngFor="let list of dataArray ">
                                    <ng-container *ngIf="list.mwrd_gut_masters.length==0;else elseGutBlock">
                                        <tr>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                    {{list.mwrd_project_master.project_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseProjectBlock>
                                                    {{list.mwrd_project_master.project_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                                    {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name_marathi:'NA'}}
                                                </ng-container>
                                                <ng-template #elseDamBlock>
                                                    {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name:'NA'}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseWuaBlock">
                                                    {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name_marathi:'NA'}}
                                                </ng-container>
                                                <ng-template #elseWuaBlock>
                                                    {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name:'NA'}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseDistrictBlock">
                                                    {{list.mwrd_district_master.district_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseDistrictBlock>
                                                    {{list.mwrd_district_master.district_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elsBlock">
                                                    {{list.mwrd_taluka_master.taluka_name_marathi}}
                                                </ng-container>
                                                <ng-template #elsBlock>
                                                    {{list.mwrd_taluka_master.taluka_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseVillageBlock">
                                                    {{list.mwrd_village_master.village_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseVillageBlock>
                                                    {{list.mwrd_village_master.village_name}}
                                                </ng-template>
                                            </td>
                                            <td>
    
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.farmer_master_farmer_marathi_name?list.farmer_master_farmer_marathi_name:'NA'}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.farmer_master_farmer_name?list.farmer_master_farmer_name:'NA'}}
                                                </ng-template>
                                            </td>
    
                                            <td>
                                                {{list.farmer_master_farmer_mobile_no?list.farmer_master_farmer_mobile_no:'NA'}}
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            
                                        </tr>
                                    </ng-container>
                                    <ng-template #elseGutBlock>
                                        <tr *ngFor="let gutDetails of list.mwrd_gut_masters">
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                    {{list.mwrd_project_master.project_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseProjectBlock>
                                                    {{list.mwrd_project_master.project_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                                    {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name_marathi:'NA'}}
                                                </ng-container>
                                                <ng-template #elseDamBlock>
                                                    {{list.mwrd_dam_master?list.mwrd_dam_master.dam_name:'NA'}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseWuaBlock">
                                                    {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name_marathi:'NA'}}
                                                </ng-container>
                                                <ng-template #elseWuaBlock>
                                                    {{list.mwrd_wua_master?list.mwrd_wua_master.wua_name:'NA'}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseDistrictBlock">
                                                    {{list.mwrd_district_master?.district_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseDistrictBlock>
                                                    {{list.mwrd_district_master?.district_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elsBlock">
                                                    {{list.mwrd_taluka_master.taluka_name_marathi}}
                                                </ng-container>
                                                <ng-template #elsBlock>
                                                    {{list.mwrd_taluka_master.taluka_name}}
                                                </ng-template>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="selectedlang == 'mr';else elseVillageBlock">
                                                    {{list.mwrd_village_master.village_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseVillageBlock>
                                                    {{list.mwrd_village_master.village_name}}
                                                </ng-template>
                                            </td>
                                            <td>
    
                                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                    {{list.farmer_master_farmer_marathi_name?list.farmer_master_farmer_marathi_name:'NA'}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{list.farmer_master_farmer_name?list.farmer_master_farmer_name:'NA'}}
                                                </ng-template>
                                            </td>
    
                                            <td>
                                                {{list.farmer_master_farmer_mobile_no?list.farmer_master_farmer_mobile_no:'NA'}}
                                            </td>
                                            <td>
                                                {{gutDetails.gut_master_gut_no}}
                                            </td>
                                            <td>
                                                {{gutDetails.gut_master_total_area_gut}}
                                            </td>
                                            <td>
                                                {{gutDetails.gut_master_owned_area_exclude_potkharab}}
                                            </td>
                                            <td>
                                                {{gutDetails.gut_master_potkharab_area}}
                                            </td>
                                            
                                        </tr>
                                    </ng-template>
    
    
    
    
                                </ng-container>
                            </tbody>



                        </table>
                        

                    </div>
                    <!-- table datat over-->


                </div>
            </div>
        </div>
    </div>
</div>