<div class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position">
        <!-- <app-sidebar></app-sidebar> -->
        <div class="dashbordright">
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <div class="greenstrip fullwidth">
                <h1>Project Layout Master</h1>
            </div>
            <div class="stepimg fullwidth">

            </div>
            <div class="mid-content fullwidth formstep">
                <div class="row">
                    <div class="col-md-12 recentlistbx">
                        <h2 class="smlheadh2 col-md-12"><span>General</span></h2>
                    </div>
                    <div class="stepfrmcmn stepfrm1">
                        <form [formGroup]="LayoutForm">
                            <div class="row frmrow">

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Project Name</label>
                                    <select class="stepinpt" formControlName="projectName"
                                        (change)="onChangeforDams($event.target.value)">
                                        <option *ngFor="let project of projectSelect;"
                                            [value]="project.project_unique_gis">
                                            {{project.project_name}}
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Dams Name</label>
                                    <select class="stepinpt" formControlName="damName"
                                        (change)="getMainCanalDetails($event.target.value)">
                                        <option *ngFor="let dam of damsSelect;" [value]="dam.dam_unique_code">
                                            {{dam.dam_name}}
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal</label>
                                    <select class="stepinpt" formControlName="canalName"
                                        (ngModelChange)="myChangeMainCanel($event)">
                                        <option [ngValue]="NA">NA</option>
                                        <option *ngFor="let canal of canalSelect;" [ngValue]="canal">
                                            {{canal.main_canal_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="stepfrmcmn stepfrm1" *ngIf="isMainBranchShow">
                        <form [formGroup]="PopLayoutForm">
                            <div class="row frmrow">
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Code:</label>
                                    <input class="stepinpt" formControlName="main_canal_code" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Name:</label>
                                    <input class="stepinpt" formControlName="main_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Marathi Name:</label>
                                    <input class="stepinpt" formControlName="main_canal_name_marathi" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Type:</label>
                                    <input class="stepinpt" formControlName="main_canal_type" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Type:</label>
                                    <select formControlName="main_canal_type" class="stepinpt">
                                        <option value="Feeder/Link">Feeder/Link</option>
                                        <option value="Left"> Left</option>
                                        <option value="LIS"> Lift Irrigation Scheme</option>
                                        <option value="Main">Main</option>
                                        <option value="Other">Other</option>
                                        <option value="Pipe Network">Pipe Network</option>
                                        <option value="Right">Right</option>
                                    </select>
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal Ica::</label>
                                    <input type="number" class="stepinpt" formControlName="main_canal_ica" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal CCA:</label>
                                    <input type="number" class="stepinpt" formControlName="main_canal_cca" /> <br>
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Canal GCA:</label>
                                    <input type="number" class="stepinpt" formControlName="main_canal_gca" /> <br>
                                </div>
                                <div class="col-md-4">
                                    <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                        (click)="submitMainCanalDetails()" />
                                </div>
                                <br>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Canal Branches:</label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeCanelBranches($event)">
                                        <option [ngValue]="NA">NA</option>
                                        <option *ngFor="let canal of getCanlBranchArray;" [ngValue]="canal">
                                            {{canal.branch_canal_name}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </form>

                    </div>
                    <div class="stepfrmcmn stepfrm1" *ngIf="isBranchCanelShow">
                        <form [formGroup]="branchPopup">
                            <div class="row frmrow">
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Code:</label>
                                    <input class="stepinpt" formControlName="branch_canal_code" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Name:</label>
                                    <input class="stepinpt" formControlName="branch_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Marathi Name:</label>
                                    <input class="stepinpt" formControlName="branch_canal_name_marathi" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Structure:</label>
                                    <input class="stepinpt" formControlName="branch_canal_structure" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Chainage KM:</label>
                                    <input type="number" class="stepinpt" formControlName="branch_canal_chainage_km" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Chainage M:</label>
                                    <input class="stepinpt" formControlName="branch_canal_chainage_m" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal Ica::</label>
                                    <input type="number" class="stepinpt" formControlName="branch_canal_ica" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal CCA:</label>
                                    <input type="number" class="stepinpt" formControlName="branch_canal_cca" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Branch Canal GCA:</label>
                                    <input type="number" class="stepinpt" formControlName="branch_canal_gca" />
                                </div>
                                <div class="col-md-4">
                                    <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                        (click)="submitMainCanalDetails()" />
                                </div>

                            </div>
                            <br>
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory:</label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeDistributory($event)">
                                        <option [ngValue]="NA">NA</option>
                                        <option *ngFor="let canal of getDistubutoryArray;" [ngValue]="canal">
                                            {{canal.distributory_canal_name}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </form>


                    </div>
                    <!-- distu start -->
                    <div class="stepfrmcmn stepfrm1" *ngIf="isDistubutoryShow">
                        <form [formGroup]="distruPopupForm">
                            <div class="row frmrow">
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distubutory Canal Code:</label>
                                    <input class="stepinpt" formControlName="distributory_canal_code" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory Canal Name:</label>
                                    <input class="stepinpt" formControlName="distributory_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory Canal Marathi Name:</label>
                                    <input class="stepinpt" formControlName="distributory_canal_name_marathi" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distubutory Canal Structure:</label>
                                    <input class="stepinpt" formControlName="distributory_canal_structure" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory Canal Chainage KM:</label>
                                    <input type="number" class="stepinpt"
                                        formControlName="distributory_canal_chainage_km" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distubutory Canal Chainage M:</label>
                                    <input  type="number" class="stepinpt" formControlName="distributory_canal_chainage_m" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory Canal Ica::</label>
                                    <input type="number" class="stepinpt" formControlName="distributory_canal_ica" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distributory Canal CCA:</label>
                                    <input type="number" class="stepinpt" formControlName="distributory_canal_cca" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Distubutory Canal GCA:</label>
                                    <input type="number" class="stepinpt" formControlName="distributory_canal_gca" />
                                </div>
                                <div class="col-md-4">
                                    <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                        (click)="submitDistributoryDetails()" />
                                </div>

                            </div>
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal:</label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeMinorCanal($event)">
                                        <option [ngValue]="NA">NA</option>
                                        <option *ngFor="let canal of getMinorCanalArray;" [ngValue]="canal">
                                            {{canal.minor_canal_name}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </form>
                        <br>


                    </div>
                    <div class="stepfrmcmn stepfrm1" *ngIf="isMinorCanalShow">
                        <form [formGroup]="minorPopupForm">
                            <div class="row frmrow">
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal Code:</label>
                                    <input class="stepinpt" formControlName="minor_canal_code" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal Name:</label>
                                    <input class="stepinpt" formControlName="minor_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal Marathi Name:</label>
                                    <input class="stepinpt" formControlName="minor_canal_name_marathi" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal Chainage KM:</label>
                                    <input type="number" class="stepinpt" formControlName="minor_canal_chainage_km" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal ICA:</label>
                                    <input type="number" class="stepinpt" formControlName="minor_canal_ica" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal CCA:</label>
                                    <input type="number" class="stepinpt" formControlName="minor_canal_cca" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Minor Canal GCA:</label>
                                    <input type="number" class="stepinpt" formControlName="minor_canal_gca" />
                                </div>
                                <div class="col-md-4">
                                    <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                        (click)="submitMinorCanalDetails()" />
                                </div>
                            </div>
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Sub Minor Canal:</label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeSubMinorCanal($event)">
                                        <option [ngValue]="NA">NA</option>
                                        <option *ngFor="let canal of getSubMinorCanalArray;" [ngValue]="canal">
                                            {{canal.subminor_canal_name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="stepfrmcmn stepfrm1" *ngIf="isSubMinorCanalShow">
                        <form [formGroup]="subMinorPopupForm" class="main-canal">
                            <div class="row frmrow">

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor Code:</label>
                                    <input class="stepinpt" formControlName="subminor_canal_code" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor Name:</label>
                                    <input class="stepinpt" formControlName="subminor_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor Marathi Name:</label>
                                    <input class="stepinpt" formControlName="subminor_canal_name_marathi" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor structure:</label>
                                    <input class="stepinpt" formControlName="subminor_canal_structure" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor chainage km:</label>
                                    <input type="number" class="stepinpt"
                                        formControlName="subminor_canal_chainage_km" />
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor chainage m:</label>
                                    <input  type="number" class="stepinpt" formControlName="subminor_canal_chainage_m" />
                                </div> -->
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor Ica::</label>
                                    <input type="number" class="stepinpt" formControlName="subminor_canal_ica" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor CCA:</label>
                                    <input type="number" class="stepinpt" formControlName="subminor_canal_cca" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Main Sub Minor GCA:</label>
                                    <input type="number" class="stepinpt" formControlName="subminor_canal_gca" />
                                </div>

                                <div class="col-md-4 inptbx">
                                    <input class="stepgreenbtn" value="Save Sub Minor " type="button"
                                        (click)="submitSubMinorCanalDetails()" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="stepfrmcmn stepfrm1" *ngIf="isSubMinorCanalShow">
                        <form class="main-canal" [formGroup]="outletForm">
                            <div class="row frmrow">

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Outlet Canel Name:</label>
                                    <input class="stepinpt" formControlName = "outlet_canal_name" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Outlet Canel Name Marathi:</label>
                                    <input class="stepinpt" formControlName = "outlet_canal_name_marathi" />
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Side of outlet (left/right)</label>
                                    <select class="stepinpt" formControlName = "outlet_canal_position">
                                        <option value="Left">Left</option>
                                        <option value="Right">Right</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div class="row frmrow" *ngIf="isLastSubmitShow">

                            <div class="col-md-12 inptbx">
                                <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}" (click)="finalSubmit()" />

                                <input class="stepgreybtn" type="reset" value="Reset" (click)="reset()" />
                            </div>

                        </div>
                    </div>
                    <app-footer></app-footer>

                </div>

            </div>
        </div>
    </div>

    <!-- main canal -->
    <div class="modal fade very-pop show" id="preModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>Add Main Canal Details </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('mainCanel')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="PopLayoutForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Main Canal Code</label>
                            <input class="input-box" formControlName="main_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Name</label>
                                <input class="input-box" formControlName="main_canal_name" type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Marathi Name</label>
                                <input class="input-box" formControlName="main_canal_name_marathi"
                                    type="text"><br /><br />
                            </li>
                            <!-- <li>
                            <label class="label">Main Canal Type</label>
                            <input class="input-box" formControlName="main_canal_type" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Type</label>
                                <select formControlName="main_canal_type" class="input-box">
                                    <option value="Feeder/Link">Feeder/Link</option>
                                    <option value="Left"> Left</option>
                                    <option value="LIS">LIS</option>
                                    <option value="Main">Main</option>
                                    <option value="Other">Other</option>
                                    <option value="Pipe Network">Pipe Network</option>
                                    <option value="Right">Right</option>
                                </select>
                                <br /><br />
                            </li>

                            <li>
                                <label class="label">Ica</label>
                                <input type="number" class="input-box" formControlName="main_canal_ica"
                                    type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">CCA</label>
                                <input type="number" class="input-box" formControlName="main_canal_cca"
                                    type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">GCA</label>
                                <input type="number" class="input-box" formControlName="main_canal_gca"
                                    type="number"><br /><br />
                            </li>

                            <!-- <li>
                            <label class="label">Main Canal Outlet</label>
                            <input class="input-box" formControlName="main_canal_outlet" type="text"><br /><br />
                        </li> -->

                            <li>

                                <input class="stepgreenbtn" (click)="submitMainCanalDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- end main canal -->
    <!-- Canel branch start -->
    <div class="modal fade very-pop show" id="canalBranch" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>Add Branch Canal Details </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('canalBranch')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="branchPopup" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Branch Canal Code</label>
                            <input class="input-box" formControlName="branch_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Name</label>
                                <input class="input-box" formControlName="branch_canal_name" type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Marathi Name</label>
                                <input class="input-box" formControlName="branch_canal_name_marathi"
                                    type="text"><br /><br />
                            </li>
                            <!-- <li>
                            <label class="label">Branch Structure</label>
                            <input class="input-box" formControlName="branch_canal_structure" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Chainage KM</label>
                                <input class="input-box" formControlName="branch_canal_chainage_km"
                                    type="number"><br /><br />
                            </li>

                            <!-- <li>
                            <label class="label">Branch Canal Chainage M</label>
                            <input class="input-box" formControlName="branch_canal_chainage_m" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Ica</label>
                                <input type="number" class="input-box" formControlName="branch_canal_ica"><br /><br />
                            </li>

                            <li>
                                <label class="label">CCA</label>
                                <input type="number" class="input-box" formControlName="branch_canal_cca"><br /><br />
                            </li>

                            <li>
                                <label class="label">GCA</label>
                                <input type="number" class="input-box" formControlName="branch_canal_gca"><br /><br />
                            </li>


                            <li>

                                <input class="stepgreenbtn" (click)="submitBranchCanelDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- canel branch end -->
    <!-- dist sart -->
    <div class="modal fade very-pop show" id="distCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>Add Distributory Canal Details </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('distCanel')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="distruPopupForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Distubutory Canal Code</label>
                            <input class="input-box" formControlName="distributory_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Name</label>
                                <input class="input-box" formControlName="distributory_canal_name"
                                    type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Marathi Name</label>
                                <input class="input-box" formControlName="distributory_canal_name_marathi"
                                    type="text"><br /><br />
                            </li>
                            <!-- <li>
                            <label class="label">Distubutory Structure</label>
                            <input class="input-box" formControlName="distributory_canal_structure"
                                type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Chainage KM</label>
                                <input class="input-box" formControlName="distributory_canal_chainage_km"
                                    type="number"><br /><br />
                            </li>

                            <!-- <li>
                            <label class="label">Distubutory Canal Chainage M</label>
                            <input class="input-box" formControlName="distributory_canal_chainage_m"
                                type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Ica</label>
                                <input class="input-box" formControlName="distributory_canal_ica"
                                    type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">CCA</label>
                                <input class="input-box" formControlName="distributory_canal_cca"
                                    type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">GCA</label>
                                <input class="input-box" formControlName="distributory_canal_gca"
                                    type="number"><br /><br />
                            </li>


                            <li>

                                <input class="stepgreenbtn" (click)="submitDistributoryDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- dist end -->
    <!-- Minor Popup -->
    <div class="modal fade very-pop show" id="minorCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h2>Add Minor Details </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('minorCanel')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">


                    <form [formGroup]="minorPopupForm" class="main-canal">
                        <ul>

                            <li>
                                <label class="label">Name</label>
                                <input class="input-box" formControlName="minor_canal_name" type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Marathi Name</label>
                                <input class="input-box" formControlName="minor_canal_name_marathi"
                                    type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Chainage Km</label>
                                <input class="input-box" formControlName="minor_canal_chainage_km"
                                    type="number"><br /><br />
                            </li>
                            <li>
                                <label class="label">ICA</label>
                                <input class="input-box" formControlName="minor_canal_ica" type="number"><br /><br />
                            </li>
                            <li>
                                <label class="label">CCA</label>
                                <input class="input-box" formControlName="minor_canal_cca" type="number"><br /><br />
                            </li>
                            <li>
                                <label class="label">GCA</label>
                                <input class="input-box" formControlName="minor_canal_gca" type="number"><br /><br />
                            </li>

                            <li>

                                <input class="stepgreenbtn" (click)="submitMinorCanalDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>



            </div>
        </div>
    </div>
    <!-- Minor popup end -->
    <!-- sub minor popup -->
    <div class="modal fade very-pop show" id="subMinorCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h2>Add Sub minor Details </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('subMinorCanel')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">


                    <form [formGroup]="subMinorPopupForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Main Sub Minor Code</label>
                            <input class="input-box" formControlName="subminor_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Name</label>
                                <input class="input-box" formControlName="subminor_canal_name" type="text"><br /><br />
                            </li>
                            <li>
                                <label class="label">Marathi Name</label>
                                <input class="input-box" formControlName="subminor_canal_name_marathi"
                                    type="text"><br /><br />
                            </li>
                            <!-- <li>
                            <label class="label">Main Sub Minor Structure</label>
                            <input class="input-box" formControlName="subminor_canal_structure" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">Chainage Km</label>
                                <input class="input-box" formControlName="subminor_canal_chainage_km"
                                    type="number"><br /><br />
                            </li>
                            <!-- <li>
                            <label class="label">Main Sub Minor Chainage m</label>
                            <input class="input-box" formControlName="subminor_canal_chainage_m"
                                type="text"><br /><br />
                        </li> -->

                            <li>
                                <label class="label">Ica</label>
                                <input class="input-box" formControlName="subminor_canal_ica" type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">CCA</label>
                                <input class="input-box" formControlName="subminor_canal_cca" type="number"><br /><br />
                            </li>

                            <li>
                                <label class="label">GCA</label>
                                <input class="input-box" formControlName="subminor_canal_gca" type="number"><br /><br />
                            </li>



                            <li>

                                <input class="stepgreenbtn" (click)="submitSubMinorCanalDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>



            </div>
        </div>
    </div>
    <!-- end subminor -->
