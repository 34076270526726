import { ChangeDetectorRef, Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { JsonPipe } from '@angular/common';
import { NumberDirective } from '../_service/number';
import { switchMap, debounceTime, map } from 'rxjs/operators';

import { ValidationService } from '../_service/validation.service';
import { fromEvent } from 'rxjs';
import { ViewChild } from '@angular/core';
import { IfStmt, ReturnStatement } from '@angular/compiler';
import { data } from 'jquery';
declare var jQuery: any;
declare var $: any;
// import { resolve } from 'dns';
@Component({
	selector: 'app-farmer-master',
	templateUrl: './farmer-master.component.html',
	styleUrls: ['./farmer-master.component.css']
})
export class FarmerMasterComponent implements OnInit {
	farmer_id = -1;
	isSubmitted = false;
	clickEventsubscription: Subscription;
	language: string;
	firstStepForm: FormGroup;
	projectSelect: any = [];
	optionsSelect = []
	damSelect = []
	districtSelect = []
	irrigationSelect = []
	canalSelect = []
	branchCanalSelect = [];
	distributorySelect = []
	minorSelect = []
	subminorSelect = []
	outletSelect = []
	outletLeftRightSelect = []
	mainCanelArray = [];
	regionSelect = [];
	circleSelect = [];
	divisionSelect = [];
	regionSubSelect = [];
	wuaSelect = [];
	sectionSelect = [];
	isShown: boolean = false
	projectGisCode
	damUniqueCode
	branchCanelArray = []
	distributoryArray = []
	mainCanelId
	branchCanelId
	distributoryUniqueId
	minorUniqueId
	subMinorUniqueId
	minorCanelArray = []
	subMinorCanelArray = []
	outLetArray = []
	wuaArray = []
	reqData: any = {}
	totalTr: any = []
	myArr: any = []
	getDamId
	gutDetailsArray = []
	farmerDistrict
	farmerTalluka
	farmerVillage
	getDistArray = []
	getFarmerTaluka = []
	getFarmerVillage = []
	getFarmerDistructCode
	farmerDistrictCode
	farmerTallukaCode
	farmerVillageCode
	getFarmerDistructName
	farmerVillageName
	farmerTallukaName
	getFarmerDistructNameMarathi
	farmerVillageNameMarathi
	farmerTallukaNameMarathi
	newGutDetailsArray = []
	getOutlistingArray = []
	bankArray = []
	isShowLiftIrri: boolean = false
	farmerDetails: any = {}
	showSugar: boolean = false
	getAllFarmerDetails: any
	showcanal: boolean = false;
	farmer_master_wua_id: any;
	showbankType: boolean = false;
	gutNumberSaid
	//unamePattern: string;
	unamePattern = "^[A-Za-z0-9]+$";
	notGutValid: boolean = false;
	contentEditable: boolean = false;
	FirstKey
	userOfficeId
	projectLayoutArray = []
	newCheckArray = []
	user_type: string

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpRequestService,
		private common: CommonService,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService,
		private sharedService: SharedService,
		public validationService: ValidationService
	) {
		this.user_type = localStorage.getItem('user_type')
		this.getProjectList();
		this.getDistrict()
		this.getYesNo()
		this.getUserOfficeDetails()
		this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
			// this.common.language$.next(localStorage.getItem('selected_lang'));
			this.ngOnInit();
		})
	}
	@ViewChild('userNameRef') userNameRef: ElementRef;

	get username() {

		return this.firstStepForm.get('farmer_master_farmer_ledger_no');
	}




	//  isNumberKey(evt){

	// 	var charCode = (evt.which) ? evt.which : evt.keyCode;
	// 	console.log(charCode,"=====")
	//     if (charCode > 31 && (charCode < 48 || charCode > 57))
	//         return false;
	//     return true;
	// }
	IsNumeric(event) {
		//var numbers = /^[0-9]+$/;

		//var charCode = (event.which) ? event.which : event.keyCode;

		//	console.log(charCode,"ppppp");

		// if ((charCode < 48 || charCode > 57)) {
		// 	event.preventDefault();
		// 	this.notGutValid = true;
		// 	return false;
		//   } else {
		// 	this.notGutValid = false;
		// 	return true;
		//   }
		//var ret = ((keyCode >= 48 && keyCode <= 57) || specialKeys.indexOf(keyCode) != -1);
		// if(valu1e.value.match(numbers))
		// {
		// 	this.notGutValid = false;
		// return true;
		// }else {
		// 	this.notGutValid = false;
		//   }

		//console.log(value);



	}

	ngOnInit(): void {
		// alert("hhoooooooooo")
		this.firstStepForm = this.fb.group({
			farmer_master_id: new FormControl(null),
			farmer_master_project_code: new FormControl(null, [Validators.required]),
			farmer_master_dam_id: new FormControl(null, [Validators.required]),
			farmer_master_district_id_in_which_dam_situated: new FormControl(null, [Validators.required]),
			farmer_master_irregation_source: new FormControl(null, [Validators.required]),
			farmer_master_canal_id: new FormControl(null,),
			farmer_master_canal_branch_id: new FormControl(null,),
			farmer_master_canal_distributary_id: new FormControl(null,),
			farmer_master_minor_id: new FormControl(null,),
			farmer_master_subminor_id: new FormControl(null,),
			farmer_master_outlet_id: new FormControl(null,),
			// farmer_master_outlet_side: new FormControl(null,),
			// farmer_master_wua_id: new FormControl(null, [Validators.required]),
			farmer_master_wua_id: new FormControl(''),
			//	farmer_master_farmer_ledger_no : new FormControl('Validators.required',)
			//farmer-details
			farmer_master_farmer_name: ['', [Validators.required, Validators.maxLength(45), Validators.pattern('^[a-zA-Z ]*$')]],

			farmer_master_name_of_cultivator: ['', [Validators.required, Validators.maxLength(45), Validators.pattern('^[a-zA-Z ]*$')]],
			farmer_master_name_of_cultivator_marathi: ['', [Validators.required, Validators.maxLength(45)]],
			farmer_master_sugar_factory_name_marathi: ['',],
			farmer_costake_holder: new FormControl('', [Validators.required]),
			farmer_master_farmer_gender: ['', Validators.required],
			farmer_master_farmer_mobile_no: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^([0-9]{1,10})?$')]],
			farmer_master_farmer_whatsapp_no: ['', [Validators.maxLength(10), Validators.pattern('^([0-9]{1,10})?$')]],
			farmer_master_farmer_email: ['', [Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$')]],
			farmer_master_total_area_gut: ['', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$'), Validators.maxLength(7)]],
			farmer_master_owned_area_exclude_potkharab: ['', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$'), Validators.maxLength(7)]],
			farmer_master_potkharab_area: ['', [Validators.pattern('^([0-9]{1,4})(.[0-9]{1,2})?$'), Validators.maxLength(7)]],
			farmer_master_farmer_district_id: ['', Validators.required],
			farmer_master_farmer_taluka: ['', Validators.required],
			farmer_master_farmer_village: ['', Validators.required],
			farmer_master_farmer_address1: ['', Validators.required],
			//farmer_master_farmer_address2: ['', Validators.required],
			//farmer_master_farmer_address3: ['', Validators.required],
			// farmer_master_farmer_ledger_no: ['', Validators.required, Validators.pattern('^([a-zA-Z0-9]{1,10})?$')],
			farmer_master_farmer_ledger_no: [null, [Validators.required, Validators.pattern('^[A-Za-z0-9/]*$')]],
			farmer_master_farmer_marathi_name: ['',],
			farmer_master_farmer_marathi_address1: ['', Validators.required],
			//farmer_master_farmer_marathi_address2: ['', Validators.required],
			//farmer_master_farmer_marathi_address3: ['', Validators.required],
			//Lift Irrigation - only if Irrigation Source is Canal Flow
			farmer_master_lift_irregation_permission_no: [null, Validators.maxLength(30)],
			farmer_master_valid_from: [null,],
			farmer_master_project_layout_id: [null],
			farmer_master_valid_upto: [null,],
			farmer_master_bank_id: [null,],
			//farmer office details
			farmer_mantralaya_id: [null],
			farmer_corporation_id: [null],
			farmer_region_id: [null],
			farmer_circle_id: [null],
			farmer_division_id: [null],
			farmer_subdivision_id: [null],
			farmer_section_id: [null],
			//sugar factory
			isSugerFactory: ['No',],
			farmer_added_by_user_office_id: [0],
			farmer_added_by_user_office_type: [null],
			farmer_master_sugar_factory_name: ['',],
			farmer_is_approved: [0],
			farmer_user_type: [1],
			farmer_master_sugar_factory_email: [null, Validators.compose([Validators.pattern('^[a-zA-Z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$')])],
			post_user_id: new FormControl(localStorage.getItem('post_user_id'),),
			test: new FormControl("")

		});
		this.newGutDetailsArray = []
		this.getFarmerDistructNameMarathi = null
		this.farmerTallukaNameMarathi = null
		this.farmerVillageNameMarathi = null
		this.getFarmerDistructName = null
		this.farmerTallukaName = null
		this.farmerVillageName = null

		this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));

		if (this.route.snapshot.params['id']) {
			this.farmer_id = this.route.snapshot.params['id'];
			this.getFarmerDetails(this.farmer_id);

		}
		else {
			this.firstStepForm.controls.farmer_master_lift_irregation_permission_no.disable()
			this.firstStepForm.controls.farmer_master_valid_from.disable()
			this.firstStepForm.controls.farmer_master_valid_upto.disable()
			this.firstStepForm.controls.farmer_master_sugar_factory_name.disable()
			this.firstStepForm.controls.farmer_master_sugar_factory_email.disable()
			this.firstStepForm.controls.farmer_master_sugar_factory_name_marathi.disable()

			this.firstStepForm.controls.farmer_master_canal_id.disable()
			this.firstStepForm.controls.farmer_master_canal_branch_id.disable()
			this.firstStepForm.controls.farmer_master_canal_distributary_id.disable()
			this.firstStepForm.controls.farmer_master_minor_id.disable()
			this.firstStepForm.controls.farmer_master_subminor_id.disable()
			this.firstStepForm.controls.farmer_master_outlet_id.disable()
			this.reqData = {};
			this.myArr = [];
		}
	}

	onProjectLayoutChange(layoutId) {
		// console.log(layoutId)

		if (layoutId == 0) {
			this.firstStepForm.patchValue({
				farmer_master_canal_id: 0,
				farmer_master_canal_branch_id: 0,
				farmer_master_canal_distributary_id: 0,
				farmer_master_minor_id: 0,
				farmer_master_subminor_id: 0,
				farmer_master_outlet_id: 0
			})
		}
		else {
			var item = this.projectLayoutArray.find(abcd => abcd.project_layout_id == layoutId);
			// console.log(item)
			this.firstStepForm.patchValue({
				farmer_master_canal_id: item.project_layout_main_canal_id,
				farmer_master_canal_branch_id: item.project_layout_branch_canal_id,
				farmer_master_canal_distributary_id: item.project_layout_distributory_canal_id,
				farmer_master_minor_id: item.project_layout_minor_canal_id,
				farmer_master_subminor_id: item.project_layout_subminor_canal_id,
				farmer_master_outlet_id: item.project_layout_outlet_id
			})
		}
		// console.log(this.firstStepForm)
	}


	getFarmerDetails(farmerId) {
		this.http.getFarmerDetailId(farmerId).subscribe(
			(resolve: any) => this.editFarmerDetails(resolve.data),
			(err: any) => console.log(err)
		);
	}

	editFarmerDetails(farmerDetails: any) {
		// console.log("here");
		// console.log(farmerDetails);

		this.getAllFarmerDetails = farmerDetails
		// console.log(farmerDetails)
		this.firstStepForm.patchValue({
			farmer_master_project_code: farmerDetails.farmer_master_project_unique_gis,
			farmer_master_dam_id: farmerDetails.farmer_master_dam_unique_code,
			farmer_master_bank_id: farmerDetails.farmer_master_bank_id,
			farmer_master_district_id_in_which_dam_situated: farmerDetails.farmer_master_district_id_in_which_dam_situated,
			farmer_master_irregation_source: farmerDetails.farmer_master_farmer_irregation_source,
			farmer_master_wua_id: farmerDetails.farmer_master_wua_id,
			farmer_master_farmer_name: farmerDetails.farmer_master_farmer_name,
			farmer_master_name_of_cultivator: farmerDetails.farmer_master_name_of_cultivator,
			farmer_master_project_layout_id: farmerDetails.farmer_master_project_layout_id,
			farmer_master_name_of_cultivator_marathi: farmerDetails.farmer_master_name_of_cultivator_marathi,
			farmer_master_farmer_gender: farmerDetails.farmer_master_farmer_gender,
			farmer_costake_holder: farmerDetails.farmer_costake_holder,
			farmer_master_farmer_mobile_no: farmerDetails.farmer_master_farmer_mobile_no,
			farmer_master_farmer_whatsapp_no: farmerDetails.farmer_master_farmer_whatsapp_no,
			farmer_master_farmer_email: farmerDetails.farmer_master_farmer_email,
			farmer_master_farmer_marathi_name: farmerDetails.farmer_master_farmer_marathi_name,
			farmer_master_farmer_district_id: farmerDetails.farmer_master_farmer_district_id,
			farmer_master_farmer_taluka: farmerDetails.farmer_master_farmer_taluka,
			farmer_master_farmer_village: farmerDetails.farmer_master_farmer_village,
			farmer_master_farmer_address1: farmerDetails.farmer_master_farmer_address1,
			farmer_master_farmer_marathi_address1: farmerDetails.farmer_master_farmer_marathi_address1,
			//farmer_master_farmer_address2: farmerDetails.farmer_master_farmer_address2,
			//farmer_master_farmer_marathi_address2: farmerDetails.farmer_master_farmer_marathi_address2,
			//farmer_master_farmer_address3: farmerDetails.farmer_master_farmer_address3,
			//farmer_master_farmer_marathi_address3: farmerDetails.farmer_master_farmer_marathi_address3,
			farmer_master_farmer_ledger_no: farmerDetails.farmer_master_farmer_ledger_no,
			farmer_master_sugar_factory_name_marathi: farmerDetails.farmer_master_sugar_factory_name_marathi,
			farmer_added_by_user_office_id: farmerDetails.farmer_added_by_user_office_id,
			farmer_added_by_user_office_type: farmerDetails.farmer_added_by_user_office_type
		});



		if (farmerDetails.farmer_master_lift_irregation_permission_no != null || farmerDetails.farmer_master_lift_irregation_permission_no != undefined || farmerDetails.farmer_master_lift_irregation_permission_no) {
			this.firstStepForm.patchValue({
				farmer_master_lift_irregation_permission_no: farmerDetails.farmer_master_lift_irregation_permission_no,
			});
		}
		if (farmerDetails.farmer_master_valid_from != null || farmerDetails.farmer_master_valid_from != undefined || farmerDetails.farmer_master_valid_from) {
			// console.log('yes in date');
			let date = JSON.stringify(new Date(farmerDetails.farmer_master_valid_from))
			date = date.slice(1, 11)
			this.firstStepForm.patchValue({
				farmer_master_valid_from: date,

			});
		}
		if (farmerDetails.farmer_master_valid_upto != null || farmerDetails.farmer_master_valid_upto != undefined || farmerDetails.farmer_master_valid_upto) {
			let date1 = JSON.stringify(new Date(farmerDetails.farmer_master_valid_upto))
			date1 = date1.slice(1, 11)
			this.firstStepForm.patchValue({
				farmer_master_valid_upto: date1
			})
		}
		if (farmerDetails.farmer_master_sugar_factory_email != null || farmerDetails.farmer_master_sugar_factory_email != undefined || farmerDetails.farmer_master_sugar_factory_email != "") {
			this.firstStepForm.patchValue({
				farmer_master_sugar_factory_email: farmerDetails.farmer_master_sugar_factory_email,
			});
		}
		if (farmerDetails.farmer_master_sugar_factory_name != null || farmerDetails.farmer_master_sugar_factory_name != undefined || farmerDetails.farmer_master_sugar_factory_name != "") {
			this.firstStepForm.patchValue({
				farmer_master_sugar_factory_name: farmerDetails.farmer_master_sugar_factory_name,
				farmer_master_sugar_factory_name_marathi: farmerDetails.farmer_master_sugar_factory_name_marathi,
				isSugerFactory: 'Yes',

			});
			this.showSugar = true;
			this.firstStepForm.controls.farmer_master_sugar_factory_name.enable()
			this.firstStepForm.controls.farmer_master_sugar_factory_email.enable()
			this.firstStepForm.controls.farmer_master_sugar_factory_name_marathi.enable()

		}
		this.farmerDistrict = farmerDetails.farmer_master_farmer_district_id;
		this.getDamId = farmerDetails.farmer_master_dam_unique_code;
		//console.log(this.firstStepForm);
		this.getDamList(this.firstStepForm.value['farmer_master_project_code'])
		this.toggleShow(farmerDetails.farmer_master_farmer_irregation_source)
		// console.log(farmerDetails.farmer_master_farmer_district_id+"===========")
		this.forFamerTaluka(farmerDetails.farmer_master_farmer_district_id)
		//console.log(this.firstStepForm)
		if (farmerDetails.mwrd_gut_masters != null || farmerDetails.mwrd_dam_master != undefined) {
			let gutDetails = farmerDetails.mwrd_gut_masters;
			gutDetails.forEach(element => {
				this.reqData.gut_master_id = element.gut_master_id
				this.reqData.farmer_master_gut_no = element.gut_master_gut_no,
					this.reqData.farmer_master_part_no = element.gut_master_part_no,
					this.reqData.farmer_master_total_area_gut = element.gut_master_total_area_gut
				this.reqData.farmer_master_owned_area_exclude_potkharab = element.gut_master_owned_area_exclude_potkharab
				this.reqData.farmer_master_potkharab_area = element.gut_master_potkharab_area
				this.reqData.farmer_master_whether_said_gutnumber_notified_command_area = element.gut_master_whether_said_gutnumber_notified_command_area
				//	console.log(element);
				// console.log(this.reqData)
				this.addGutDetails();
				this.reqData = {}
			});
		}
		this.cdr.markForCheck()
		this.getDistrict()
	}


	getDistrict() {
		this.http.getDistricts().subscribe((resolve: any) => {
			this.getDistArray = resolve.data
		})
		this.http.getBankId().subscribe((resolve: any) => {
			//console.log(resolve);

			this.bankArray = resolve.data;
			if (this.route.snapshot.params.id) {
				this.getMainCanel()
			}
			// 
		})
	}

	onBankTypChange() {
		this.getAllList()
	}

	getAllList() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_unique_gis: this.firstStepForm.value.farmer_master_project_code,
				dam_unique_code: this.firstStepForm.value.farmer_master_dam_id,
				main_canal_bank_id: this.firstStepForm.value.farmer_master_bank_id,
				branch_canal_bank_id: this.firstStepForm.value.farmer_master_bank_id,
				distributory_bank_id: this.firstStepForm.value.farmer_master_bank_id,
				minor_bank_id: this.firstStepForm.value.farmer_master_bank_id,
				subminor_bank_id: this.firstStepForm.value.farmer_master_bank_id,
				outlet_bank_id: this.firstStepForm.value.farmer_master_bank_id,
			}
		});

		this.http.getCanalDetailLayout(body).subscribe((resolve: any) => {
			// /	console.log(resolve);
			this.mainCanelArray = resolve.data;
		})

		this.http.getBranchesOfCanel(body).subscribe((branchesData: any) => {
			// console.log(branchesData)
			this.branchCanelArray = branchesData.data
		})
		this.http.getDistributory(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.distributoryArray = arr.data
		})

		this.http.getListOfMinor(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.minorCanelArray = arr.data
		})
		this.http.getListIfSubMinor(body).subscribe((data: any) => {
			this.subMinorCanelArray = data.data

		})
		this.http.getoutletListing(body).subscribe((data: any) => {
			this.getOutlistingArray = data.data

		})
	}

	/*
		
		this.http.getListOfMinor(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.getMinorCanalArray = arr.data

		})
		this.http.getListIfSubMinor(body).subscribe((data: any) => {
			this.getSubMinorCanalArray = data.data

		})
		this.http.getoutletListing(body).subscribe((data: any) => {
			this.getOutlistingArray = data.data

		})
	}
	*/


	toggleShow(evt) {
		// console.log(evt);
		if (evt == "1") {
			this.isShown = true;
			this.isShowLiftIrri = false
			this.showcanal = true
			this.showbankType = true;
			this.firstStepForm.controls.farmer_master_lift_irregation_permission_no.disable()
			this.firstStepForm.controls.farmer_master_valid_from.disable()
			this.firstStepForm.controls.farmer_master_valid_upto.disable()

			this.firstStepForm.controls.farmer_master_canal_id.enable()
			this.firstStepForm.controls.farmer_master_canal_branch_id.enable()
			this.firstStepForm.controls.farmer_master_canal_distributary_id.enable()
			this.firstStepForm.controls.farmer_master_minor_id.enable()
			this.firstStepForm.controls.farmer_master_subminor_id.enable()
			this.firstStepForm.controls.farmer_master_outlet_id.enable()
			// this.firstStepForm.controls.farmer_master_outlet_side.enable()
			// this.firstStepForm.controls.farmer_master_wua_id.enable()
			// this.getMainCanel()

		} else if (evt == "2") {
			this.isShown = true;
			this.isShowLiftIrri = true
			this.showcanal = true;
			this.showbankType = true;
			this.firstStepForm.controls.farmer_master_canal_id.enable()
			this.firstStepForm.controls.farmer_master_canal_branch_id.enable()
			this.firstStepForm.controls.farmer_master_canal_distributary_id.enable()
			this.firstStepForm.controls.farmer_master_minor_id.enable()
			this.firstStepForm.controls.farmer_master_subminor_id.enable()
			this.firstStepForm.controls.farmer_master_outlet_id.enable()
			// this.firstStepForm.controls.farmer_master_outlet_side.enable()
			// this.firstStepForm.controls.farmer_master_wua_id.enable()

			this.firstStepForm.controls.farmer_master_lift_irregation_permission_no.enable()
			this.firstStepForm.controls.farmer_master_valid_from.enable()
			this.firstStepForm.controls.farmer_master_valid_upto.enable()
			// this.getMainCanel()
		} else {
			this.isShowLiftIrri = true
			this.showcanal = false
			this.showbankType = false;
			this.firstStepForm.controls.farmer_master_lift_irregation_permission_no.enable()
			this.firstStepForm.controls.farmer_master_valid_from.enable()
			this.firstStepForm.controls.farmer_master_valid_upto.enable()

			this.firstStepForm.controls.farmer_master_canal_id.disable()
			this.firstStepForm.controls.farmer_master_canal_branch_id.disable()
			this.firstStepForm.controls.farmer_master_canal_distributary_id.disable()
			this.firstStepForm.controls.farmer_master_minor_id.disable()
			this.firstStepForm.controls.farmer_master_subminor_id.disable()
			this.firstStepForm.controls.farmer_master_outlet_id.disable()


			this.firstStepForm.patchValue({
				farmer_master_canal_id: '',
				farmer_master_canal_branch_id: '',
				farmer_master_canal_distributary_id: '',
				farmer_master_minor_id: '',
				farmer_master_subminor_id: '',
				farmer_master_outlet_id: '',
			})
			this.cdr.markForCheck()
			this.isShown = false;
		}
	}

	subscribeToLanguageChange(lang) {
		this.language = lang;
		this.translate.use(lang)
		// console.log('aaaaa', this.language);

		// this.common.language$.subscribe((res: string) => {
		// console.log('language', res);
		this.cdr.markForCheck();

		// })
	}

	getUserOfficeDetails() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});

		this.http.getUserDetail(body).subscribe((resolve: any) => {
			let data1 = resolve;

			// console.log(resolve.data.userDetail.user_office_detail)
			this.userOfficeId = resolve.data.userDetail.user_office_id
			for (let key in resolve.data.userDetail.user_office_detail) {

				this.FirstKey = key;
				break;

			}

			var officeDetails = []
			officeDetails = resolve.data.userDetail.user_office_detail;
			// console.log(officeDetails)


			for (let key in officeDetails) {
				// console.log("Checking in for loop")
				switch (key) {
					case "Mantralaya":
						// console.log(officeDetails[key]['office_id']);
						this.firstStepForm.patchValue({
							farmer_mantralaya_id: officeDetails[key]['office_id']
						})
						// this.firstStepForm.value.farmer_mantralaya_id = officeDetails[key]['office_id']
						break;
					case "Corporation":
						this.firstStepForm.patchValue({
							farmer_corporation_id: officeDetails[key]['office_id']
						})
						// console.log(officeDetails[key]['office_id']);
						// this.firstStepForm.value.farmer_corporation_id = officeDetails[key]['office_id']
						break;
					case "Region":
						this.firstStepForm.patchValue({
							farmer_region_id: officeDetails[key]['office_id']
						})
						// console.log(officeDetails[key]['office_id']);
						// this.firstStepForm.value.farmer_region_id = officeDetails[key]['office_id']
						break;
					case "Circle":
						this.firstStepForm.patchValue({
							farmer_circle_id: officeDetails[key]['office_id']
						})
						// console.log(officeDetails[key]['office_id']);
						// this.firstStepForm.value.farmer_circle_id = officeDetails[key]['office_id']
						break;
					case "Division":
						this.firstStepForm.patchValue({
							farmer_division_id: officeDetails[key]['office_id']
						})
						// console.log(officeDetails[key]['office_id']);
						// this.firstStepForm.value.farmer_division_id = officeDetails[key]['office_id']
						break;
					case "Sub Division":
						this.firstStepForm.patchValue({
							farmer_subdivision_id: officeDetails[key]['office_id']
						})
						// this.firstStepForm.value.farmer_subdivision_id = officeDetails[key]['office_id']
						// console.log(officeDetails[key]['office_id']);
						break;
					case "Section":
						this.firstStepForm.patchValue({
							farmer_section_id: officeDetails[key]['office_id']
						})
						// this.firstStepForm.value.farmer_section_id = officeDetails[key]['office_id']
						// console.log(officeDetails[key]['office_id']);
						break;
				}

			}

			// console.log(this.FirstKey)
		});

	}


	getProjectList() {
		// let user_type = localStorage.getItem('user_type')
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});
		// console.log(user_type)
		if (this.user_type == '0') {
			this.http.getProjectDetail(body).subscribe((resolve: any) => {
				// console.log(resolve)
				let data1 = resolve;
				if (resolve.data) {

					this.projectSelect = resolve.data;
				}


			},
				(err) => {
					console.log(err);
				}
			)
		}
		else {
			this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
				// console.log(resolve)
				let data1 = resolve;
				if (resolve.data) {

					this.projectSelect = resolve.data;
				}


			},
				(err) => {
					console.log(err);
				}
			)
		}

	}

	getDamList(ob) {
		console.log(ob)
		this.projectGisCode = ob
		this.damSelect = [];
		this.districtSelect = [];
		this.wuaArray = []; // added by shreya for clear of WUA LIST on change
		let post_user_id = localStorage.getItem('post_user_id');
		// this.project_gis_no = ob;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_id: ob,
			}
		});
		if (this.user_type != '0') {
			this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
				// console.log(resolve);
				this.damSelect = resolve.data
				if (this.route.snapshot.params.id) {
					this.getDistrictList(this.firstStepForm.value.farmer_master_dam_id)
				}

			})
		}
		else {
			this.http.getDamAganistProject(body).subscribe((resolve: any) => {
				// console.log(resolve);
				this.damSelect = resolve.data
				if (this.route.snapshot.params.id) {
					this.getDistrictList(this.firstStepForm.value.farmer_master_dam_id)
				}

			})
		}


		this.cdr.detectChanges()
		this.getwuaMaster()
	}


	getDistrictList(ob: any) {

		// console.log(ob);
		//console.log(this.damSelect);

		var item = this.damSelect.find(abcd => abcd.dam_id == ob);
		this.getDamId = ob


		this.damUniqueCode = item.dam_unique_code
		let post_user_id = localStorage.getItem('post_user_id');
		// this.project_gis_no = ob;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				dam_district_code: item.dam_district_code,
			}
		});
		this.http.getDamDistricts(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.districtSelect = resolve.data;

			if (this.getDamId) {

				this.firstStepForm.controls["farmer_master_wua_id"].setValidators([Validators.required]);
			} else {
			}

		})
		// if(this.route.snapshot.params.id){

		// 	this.firstStepForm.patchValue({
		// 		farmer_master_dam_id: ob,
		// 	});
		// }
		// this.getwuaMaster()
		this.getIrrigations()


	}
	getIrrigations() {
		// 
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				user_post_id: post_user_id,
				dam_id: this.getDamId,
			}
		});
		this.http.getDamIrrigatations(body).subscribe((resolve: any) => {
			// /console.log(resolve);
			// this.districtSelect = resolve.data
			this.irrigationSelect = resolve.data.irrigation_sources

		})

	}

	getMainCanel() {
		this.getProjectLayouts();
		// this.firstStepForm.value.main_canal_id = null
		// this.firstStepForm.value.minor_canal_id = null
		// const body = new HttpParams({
		// 	fromObject: {
		// 		project_unique_gis: this.projectGisCode,
		// 		dam_unique_code: this.getDamId,
		// 		post_user_id: localStorage.getItem('post_user_id'),
		// 		main_canal_bank_id: this.firstStepForm.value.farmer_master_bank_id
		// 	}
		// });
		// // console.log(body)
		// this.http.getMainCanel(body).subscribe((resolve: any) => {
		// 	// console.log(resolve);
		// 	this.mainCanelArray = resolve.data
		// 	if (this.route.snapshot.params.id) {
		// 		this.firstStepForm.patchValue({ farmer_master_canal_id: this.getAllFarmerDetails.farmer_master_canal_id })
		// 		this.getBranchCanalList(this.getAllFarmerDetails.farmer_master_canal_id)
		// 	}

		// })
	}
	getBranchCanalList(id) {
		if (id == 'no') {
			return;
		}
		else {
			this.mainCanelId = id
			const body = new HttpParams({
				fromObject: {
					project_unique_gis: this.projectGisCode,
					dam_unique_code: this.getDamId,
					main_canal_id: id,
					branch_canal_bank_id: this.firstStepForm.value.farmer_master_bank_id
				}
			});
			this.http.getBranchCanel(body).subscribe((resolve: any) => {
				// console.log(resolve);
				this.branchCanelArray = resolve.data
				if (this.route.snapshot.params.id) {
					this.firstStepForm.patchValue({ farmer_master_canal_branch_id: this.getAllFarmerDetails.farmer_master_canal_branch_id })
					this.getDistributoryList(this.getAllFarmerDetails.farmer_master_canal_branch_id)
				}
			})
		}
	}

	getDistributoryList(id) {
		this.branchCanelId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.getDamId,
				main_canal_id: this.mainCanelId,
				branch_canal_id: id,
				distributory_bank_id: this.firstStepForm.value.farmer_master_bank_id
			}
		});
		this.http.getdistributory(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.distributoryArray = resolve.data
			if (this.route.snapshot.params.id) {
				this.firstStepForm.patchValue({ farmer_master_canal_distributary_id: this.getAllFarmerDetails.farmer_master_canal_distributary_id })
				this.getMinorList(this.getAllFarmerDetails.farmer_master_canal_distributary_id)
			}
		})
	}

	getMinorList(id) {
		this.distributoryUniqueId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.getDamId,
				main_canal_id: this.mainCanelId,
				branch_canal_id: this.branchCanelId,
				distributory_canal_id: id,
				minor_bank_id: this.firstStepForm.value.farmer_master_bank_id
			}
		});
		this.http.getMinorListStep1(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.minorCanelArray = resolve.data
			if (this.route.snapshot.params.id) {
				this.firstStepForm.patchValue({ farmer_master_minor_id: this.getAllFarmerDetails.farmer_master_minor_id })
				this.getSubMinorList(this.getAllFarmerDetails.farmer_master_minor_id)
			}
		})
	}


	getSubMinorList(id) {
		this.minorUniqueId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.getDamId,
				main_canal_id: this.mainCanelId,
				branch_canal_id: this.branchCanelId,
				distributory_canal_id: this.distributoryUniqueId,
				minor_canal_id: id,
				subminor_bank_id: this.firstStepForm.value.farmer_master_bank_id
			}
		});
		this.http.getSubMinorListStep1(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.subMinorCanelArray = resolve.data
			if (this.route.snapshot.params.id) {
				this.firstStepForm.patchValue({ farmer_master_subminor_id: this.getAllFarmerDetails.farmer_master_subminor_id })
				this.getOutletList(this.getAllFarmerDetails.farmer_master_subminor_id)
			}

		})
	}


	getOutletList(id) {
		this.subMinorUniqueId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.getDamId,
				main_canal_id: this.mainCanelId,
				branch_canal_id: this.branchCanelId,
				distributory_canal_id: this.distributoryUniqueId,
				minor_canal_id: this.minorUniqueId,
				subminor_canal_id: id,
				outlet_bank_id: this.firstStepForm.value.farmer_master_bank_id
			}
		});
		this.http.getoutletListing(body).subscribe((data: any) => {
			this.getOutlistingArray = data.data
			if (this.route.snapshot.params.id) {
				this.firstStepForm.patchValue({ farmer_master_outlet_id: this.getAllFarmerDetails.farmer_master_outlet_number })
			}
		})
	}


	getwuaMaster() {


		// console.log("getwuaMaster");
		const body = new HttpParams({
			fromObject: {
				project_id: this.projectGisCode,
				user_post_id: localStorage.getItem('post_user_id')
			}
		});
		this.http.getWuaProject(body).subscribe((resolve: any) => {
			if (resolve.data) {
				this.firstStepForm.controls.farmer_master_wua_id.enable()
				this.wuaArray = resolve.data;
			} else {
				// console.log(resolve)
				//	this.firstStepForm.controls.farmer_master_wua_id.disable()  // COMMENTED BY SHREYA 
				this.firstStepForm.controls.farmer_master_wua_id.enable()  // COMMENTED BY SHREYA 
				// this.firstStepForm.controls.farmer_master_wua_id.setValidators([null]);
			}



		})
	}
	finalSubmit() {

		// alert("dddd");

		this.firstStepForm.value.farmer_master_dam_id = this.getDamId
		// console.log(this.isShown)
		// console.log(this.firstStepForm)
		this.firstStepForm.patchValue({
			farmer_master_farmer_village: this.farmerVillageCode,
			farmer_master_dam_id: this.getDamId,
			farmer_added_by_user_office_type: this.FirstKey,
			farmer_added_by_user_office_id: this.userOfficeId,
			farmer_user_type: localStorage.getItem('user_type'),
		})
		console.log(this.firstStepForm)
		// return;
		if (this.firstStepForm.status == "INVALID") {
			this.sendAlert('All fields are mandatory')
			return
		}
		if (this.isShown == true) {
			if (this.firstStepForm.value.farmer_master_project_layout_id == "" || this.firstStepForm.value.farmer_master_project_layout_id == undefined || this.firstStepForm.value.farmer_master_project_layout_id == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			// if (this.firstStepForm.value.farmer_master_canal_id == "" || this.firstStepForm.value.farmer_master_canal_id == undefined || this.firstStepForm.value.farmer_master_canal_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_canal_branch_id == "" || this.firstStepForm.value.farmer_master_canal_branch_id == undefined || this.firstStepForm.value.farmer_master_canal_branch_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_canal_distributary_id == "" || this.firstStepForm.value.farmer_master_canal_distributary_id == undefined || this.firstStepForm.value.farmer_master_canal_distributary_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_minor_id == "" || this.firstStepForm.value.farmer_master_minor_id == undefined || this.firstStepForm.value.farmer_master_minor_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_subminor_id == "" || this.firstStepForm.value.farmer_master_subminor_id == undefined || this.firstStepForm.value.farmer_master_subminor_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_outlet_id == "" || this.firstStepForm.value.farmer_master_outlet_id == undefined || this.firstStepForm.value.farmer_master_outlet_id == null) {
			// 	this.sendAlert('All fields are mandatory')
			// 	return
			// }


			// if (this.firstStepForm.value.farmer_master_lift_irregation_permission_no == "" || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == undefined || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == null) {
			// 	alert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_valid_from == "" || this.firstStepForm.value.farmer_master_valid_from == undefined || this.firstStepForm.value.farmer_master_valid_from == null) {
			// 	alert('All fields are mandatory')
			// 	return
			// }
			// if (this.firstStepForm.value.farmer_master_valid_upto == "" || this.firstStepForm.value.farmer_master_valid_upto == undefined || this.firstStepForm.value.farmer_master_valid_upto == null) {
			// 	alert('All fields are mandatory')
			// 	return
			// }
		}
		if (this.isShowLiftIrri == true) {
			if (this.firstStepForm.value.farmer_master_lift_irregation_permission_no == "" || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == undefined || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			if (this.firstStepForm.value.farmer_master_valid_from == "" || this.firstStepForm.value.farmer_master_valid_from == undefined || this.firstStepForm.value.farmer_master_valid_from == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			if (this.firstStepForm.value.farmer_master_valid_upto == "" || this.firstStepForm.value.farmer_master_valid_upto == undefined || this.firstStepForm.value.farmer_master_valid_upto == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			if (new Date(this.firstStepForm.value.farmer_master_valid_from).setHours(0, 0, 0, 0) > new Date(this.firstStepForm.value.farmer_master_valid_upto).setHours(0, 0, 0, 0)) {
				this.sendAlert('Valid from date in not less then valid upto date')
				return
			}
		}
		var newData: any = JSON.stringify(this.newGutDetailsArray)
		this.firstStepForm.value.farmer_gut_details = newData
		const body = new HttpParams({
			fromObject: this.firstStepForm.value
		});
		// console.log(this.contentEditable);
		// return
		this.http.addFarmerMaster(body).subscribe((resolve: any) => {
			//console.log(resolve);
			if (this.language == "mr") {
				alert("सदर माहिती जतन करण्यात आली आहे")
			}
			else {
				if (resolve.status) {
					this.sendAlert(resolve.message);
				}
				else {
					this.sendAlert(resolve.message)
					return;
				}
			}


			if (this.contentEditable) {
				// this.ngOnInit()
			}
			else {
				// this.router.navigate(["/addFarmer"])
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
					this.router.navigate(['/addFarmer'])
				);
			}
			//this.router.navigate(["/ViewFarmer"])

		})
	}
	addRow() {

		this.gutDetailsArray = []
		this.totalTr.push(1);
		//console.log(this.totalTr)
		this.reqData.farmer_master_gut_no[this.totalTr.length - 1] = '';
		this.reqData.farmer_master_part_no[this.totalTr.length - 1] = '';
		this.reqData.farmer_master_total_area_gut[this.totalTr.length - 1] = '';
		this.reqData.farmer_master_owned_area_exclude_potkharab[this.totalTr.length - 1] = '';
		this.reqData.farmer_master_potkharab_area[this.totalTr.length - 1] = '';
		this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[this.totalTr.length - 1] = '';
		let arr = [];
		arr = this.reqData.farmer_master_gut_no.map((e: any, i: any) => [{
			farmer_master_gut_no: e,
			farmer_master_part_no: this.reqData.farmer_master_part_no[i],
			farmer_master_total_area_gut: this.reqData.farmer_master_total_area_gut[i],
			farmer_master_owned_area_exclude_potkharab: this.reqData.farmer_master_owned_area_exclude_potkharab[i],
			farmer_master_potkharab_area: this.reqData.farmer_master_potkharab_area[i],
			farmer_master_whether_said_gutnumber_notified_command_area: this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[i],
		}]);

		const merged = [].concat.apply([], arr);
		this.gutDetailsArray = merged
		// console.log(this.gutDetailsArray);

	}
	removeRow(i) {
		this.gutDetailsArray = []
		if (this.totalTr.length == 1) {
			this.totalTr = [];
			this.totalTr.push(1);
			this.reqData.farmer_master_gut_no[0] = '';
			this.reqData.farmer_master_part_no[0] = '';
			this.reqData.farmer_master_total_area_gut[0] = '';
			this.reqData.farmer_master_owned_area_exclude_potkharab[0] = '';
			this.reqData.farmer_master_potkharab_area[0] = '';
			this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[0] = '';
			this.myArr[0] = '';
		} else {
			this.totalTr.splice(i, 1);
			this.reqData.farmer_master_gut_no.splice(i, 1);
			this.reqData.farmer_master_part_no.splice(i, 1);
			this.reqData.farmer_master_total_area_gut.splice(i, 1);
			this.reqData.farmer_master_owned_area_exclude_potkharab.splice(i, 1);
			this.reqData.farmer_master_potkharab_area.splice(i, 1);
			this.reqData.farmer_master_whether_said_gutnumber_notified_command_area.splice(i, 1);
			this.myArr.splice(i, 1);

		}
		// console.log(this.reqData);
		let arr = [];
		arr = this.reqData.farmer_master_gut_no.map((e: any, i: any) => [{
			farmer_master_gut_no: e, farmer_master_part_no: this.reqData.farmer_master_part_no[i],
			farmer_master_total_area_gut: this.reqData.farmer_master_total_area_gut[i],
			farmer_master_owned_area_exclude_potkharab: this.reqData.farmer_master_owned_area_exclude_potkharab[i],
			farmer_master_potkharab_area: this.reqData.farmer_master_potkharab_area[i],
			farmer_master_whether_said_gutnumber_notified_command_area: this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[i],
		}]);

		const merged = [].concat.apply([], arr);
		// console.log(merged);
		this.gutDetailsArray = merged
		// console.log(this.gutDetailsArray);

	}



	forFamerTaluka(data) {
		// console.log(data);
		// console.log(this.getDistArray)
		var item = this.getDistArray.find(abcd => abcd.district_code == data);
		// console.log(item);
		this.farmerDistrictCode = data
		this.getFarmerDistructName = item.district_name
		this.getFarmerDistructNameMarathi = item.district_name_marathi

		this.firstStepForm.patchValue({ farmer_master_farmer_district_id: item.district_code })
		const body = new HttpParams({
			fromObject: {
				district_code: item.district_code
			}
		});
		this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.getFarmerTaluka = resolve.data
			if (this.route.snapshot.params.id) {
				this.forarmerVillage(this.getAllFarmerDetails.farmer_master_farmer_taluka)
			}
		})
	}
	forarmerVillage(data) {
		// console.log(data);

		var item = this.getFarmerTaluka.find(abcd => abcd.taluka_code == data);
		// console.log(item);
		this.farmerTallukaCode = data
		this.farmerTallukaName = item.taluka_name
		this.farmerTallukaNameMarathi = item.taluka_name_marathi
		this.firstStepForm.patchValue({ farmer_master_farmer_taluka: data })
		const body = new HttpParams({
			fromObject: {
				district_code: this.farmerDistrictCode,
				taluka_code: data
			}
		});
		this.http.getTalukaVillage(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.getFarmerVillage = resolve.data
			if (this.route.snapshot.params.id) {



				//console.log(this.getAllFarmerDetails.farmer_master_farmer_village);

				//	console.log("=======================");
				this.getAllVillage(this.getAllFarmerDetails.farmer_master_farmer_village)
			}

		})
	}
	getAllVillage(data) {

		//console.log( abcd.village_code);

		//	console.log(data,"ddat");
		var item = this.getFarmerVillage.find(abcd => abcd.village_code == data);
		//console.log(item.village_name+'-----');
		this.farmerVillageName = item.village_name
		this.farmerVillageNameMarathi = item.village_name_marathi
		this.farmerVillageCode = data

	}
	translateMarathi(evt, type) {
		if (!evt.target.value) {
			return
		}
		const body = new HttpParams({
			fromObject: {
				text: evt.target.value,
			}
		});
		this.http.englishToMarathi(body).subscribe((data: any) => {
			// console.log(data)
			if (type == 'fname') {
				console.log(body)
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_name: data.data })
			}
			else if (type == 'add1') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address1: data.data })
			}
			else if (type == 'add2') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address2: data.data })
			}
			else if (type == 'add3') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address3: data.data })
			}
			else if (type == 'sugerName') {
				this.firstStepForm.patchValue({ farmer_master_sugar_factory_name_marathi: data.data })
			}
			else if (type == 'cultivatorName') {
				this.firstStepForm.patchValue({ farmer_master_name_of_cultivator_marathi: data.data });
			}
		})
	}


	pushGutDetails() {


		//console.log(firstStepForm);
		if (this.firstStepForm.controls['farmer_master_total_area_gut'].invalid) {
			this.sendAlert('Total Area GUT (Ha) Not valid');
			return;
		}

		if (this.firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].invalid) {
			this.sendAlert('Owned Area EXCL POT KHARAB (Ha) Not valid');
			return;
		}

		if (this.firstStepForm.controls['farmer_master_potkharab_area'].invalid) {
			this.sendAlert('Pot KHARAB Area (Ha)* Not valid');
			return;
		}


		if (this.reqData.farmer_master_gut_no == "" || this.reqData.farmer_master_gut_no == undefined || this.reqData.farmer_master_gut_no == null) {

			this.sendAlert('Please fillout all  details to proceed further!')
			return;

		}
		if (this.reqData.farmer_master_part_no == "" || this.reqData.farmer_master_part_no == undefined || this.reqData.farmer_master_part_no == null) {

			this.sendAlert('Please fillout all  details to proceed further!')
			return;
		}
		if (this.reqData.farmer_master_total_area_gut == "" || this.reqData.farmer_master_total_area_gut == undefined || this.reqData.farmer_master_total_area_gut == null) {
			this.sendAlert('Please fillout all details to proceed further!')
			return;
		}
		if (this.reqData.farmer_master_owned_area_exclude_potkharab == "" || this.reqData.farmer_master_owned_area_exclude_potkharab == undefined || this.reqData.farmer_master_owned_area_exclude_potkharab == null) {
			this.sendAlert('Please fillout all details to proceed further!')
			return;
		}
		if (this.reqData.farmer_master_potkharab_area == "" || this.reqData.farmer_master_potkharab_area == undefined || this.reqData.farmer_master_potkharab_area == null) {
			this.sendAlert('Please fillout all details to proceed further!')
			return;
		}
		if (this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == "" || this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == undefined || this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == null) {
			this.sendAlert('Please fillout all details to proceed further!')
			return;
		}

		if (this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == "" || this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == undefined || this.reqData.farmer_master_whether_said_gutnumber_notified_command_area == null) {
			this.sendAlert('Please fillout all details to proceed further!')
			return;
		}




		//var val = this.reqData.farmer_master_gut_no+
		let post_user_id = localStorage.getItem('post_user_id');
		this.reqData.user_post_id = post_user_id
		this.reqData.is_deleted = 0;

		var gut = this.newGutDetailsArray.filter(order => (order.farmer_master_total_area_gut === this.reqData.farmer_master_total_area_gut));
		var gut1 = this.newGutDetailsArray.filter(order => (order.farmer_master_owned_area_exclude_potkharab === this.reqData.farmer_master_owned_area_exclude_potkharab));
		var gut2 = this.newGutDetailsArray.filter(order => (order.farmer_master_potkharab_area === this.reqData.farmer_master_potkharab_area));
		var gut3 = this.newGutDetailsArray.filter(order => (order.farmer_master_gut_no === this.reqData.farmer_master_gut_no));
		var gut4 = this.newGutDetailsArray.filter(order => (order.farmer_master_part_no === this.reqData.farmer_master_part_no));
		var gut5 = this.newGutDetailsArray.filter(order => (order.farmer_master_whether_said_gutnumber_notified_command_area === this.reqData.farmer_master_whether_said_gutnumber_notified_command_area));



		if (gut.length && gut1.length && gut2.length && gut3.length && gut4.length && gut5.length) {


			alert("Duplicate Gut Details");
			return;
		}

		this.newGutDetailsArray.push(this.reqData);
		// console.log(this.newGutDetailsArray);

		// console.log(this.newGutDetailsArray);



		this.reqData = {}

	}
	deleteGutDetails(i) {
		this.newGutDetailsArray[i].is_deleted = 1;
		console.log(this.newGutDetailsArray);
		//	console.log(this.newGutDetailsArray)

	}
	ischangesuger(evt, type) {
		if (type == 'Yes') {
			this.showSugar = true;
			this.firstStepForm.controls.farmer_master_sugar_factory_name.enable()
			this.firstStepForm.controls.farmer_master_sugar_factory_email.enable()
			this.firstStepForm.controls.farmer_master_sugar_factory_name_marathi.enable()
		} else {
			this.showSugar = false;
			this.firstStepForm.controls.farmer_master_sugar_factory_name.disable()
			this.firstStepForm.controls.farmer_master_sugar_factory_email.disable()
			this.firstStepForm.controls.farmer_master_sugar_factory_name_marathi.disable()
			this.firstStepForm.patchValue({ farmer_master_sugar_factory_name: null })
			this.firstStepForm.patchValue({ farmer_master_sugar_factory_name_marathi: null })
			this.firstStepForm.patchValue({ farmer_master_sugar_factory_email: null })
		}
	}

	showCurentPage(event) {
		if (event.target.checked) {
			this.contentEditable = true;
		} else {
			this.contentEditable = false;
		}
	}

	updateFarmer() {
		// console.log(this.firstStepForm.value)
		// return;
		//console.log(this.isShown)
		// console.log("this is farmer_id" + farmerId)
		// this.firstStepForm.value.farmer_master_id = this.farmer_id
		this.firstStepForm.patchValue({
			farmer_master_farmer_village: this.farmerVillageCode,
			farmer_master_dam_id: this.getDamId,
			farmer_master_id: this.farmer_id
		})
		// console.log(this.firstStepForm.value.farmer_master_id)
		//console.log(this.firstStepForm, this.isShown)
		console.log(this.firstStepForm);
		// return;
		if (this.firstStepForm.status == "INVALID") {
			this.sendAlert('All fields are mandatory')
			return
		}

		if (this.isShowLiftIrri == true) {
			if (this.firstStepForm.value.farmer_master_lift_irregation_permission_no == "" || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == undefined || this.firstStepForm.value.farmer_master_lift_irregation_permission_no == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			if (this.firstStepForm.value.farmer_master_valid_from == "" || this.firstStepForm.value.farmer_master_valid_from == undefined || this.firstStepForm.value.farmer_master_valid_from == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
			if (this.firstStepForm.value.farmer_master_valid_upto == "" || this.firstStepForm.value.farmer_master_valid_upto == undefined || this.firstStepForm.value.farmer_master_valid_upto == null) {
				this.sendAlert('All fields are mandatory')
				return
			}
		}
		var newData: any = JSON.stringify(this.newGutDetailsArray)
		this.firstStepForm.value.farmer_gut_details = newData
		const body = new HttpParams({
			fromObject: this.firstStepForm.value
		});
		// console.log(this.contentEditable);
		// return;
		// console.log(body);
		// return
		this.http.addFarmerMaster(body).subscribe((resolve: any) => {
			// console.log(resolve);
			// 	  if(this.language == 'mr'){

			//     alert("सदर माहिती जतन करण्यात आलेली आहे.");

			//   }else {
			//   alert("This information has been saved");

			//   }
			this.sendAlert('Farmer updated successfully');
			console.log(this.contentEditable);

			if (this.contentEditable) {
				// this.ngOnInit()
			}
			else {
				this.router.navigate(["/addFarmer"])

			}

		})
	}

	addGutDetails() {
		let post_user_id = localStorage.getItem('post_user_id');
		this.reqData.user_post_id = post_user_id
		this.reqData.is_deleted = 0
		this.newGutDetailsArray.push(this.reqData)
		// console.log(this.newGutDetailsArray);
		// console.log(this.newGutDetailsArray);

		// this.reqData = {}
	}
	translateIntoMarathi(text, type) {
		if (!text) {
			return
		}
		// const body1 = new HttpParams({
		// 	fromObject: {
		// 		text: text,
		// 	}
		// });


		// fromEvent(this.userNameRef.nativeElement,"keyup").pipe(
		// 	debounceTime(500),
		// 	switchMap((userName : any) =>{
		//    return this.http.englishToMarathi(body1)
		//  })
		//   ).subscribe(res =>{
		// 	console.log("User Name is :"+ res);

		//   } );
		//   return
		const body = new HttpParams({
			fromObject: {
				text: text,
			}
		});

		this.http.englishToMarathi(body).pipe(debounceTime(2000)).subscribe((data: any) => {
			// console.log(data)
			if (type == 'fname') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_name: data.data })
			} else if (type == 'add1') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address1: data.data })
			} else if (type == 'add2') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address2: data.data })
			} else if (type == 'add3') {
				this.firstStepForm.patchValue({ farmer_master_farmer_marathi_address3: data.data })
			} else if (type == 'sugerName') {
				this.firstStepForm.patchValue({ farmer_master_sugar_factory_name_marathi: data.data })
			} else if (type == 'cultivatorName') {
				this.firstStepForm.patchValue({ farmer_master_name_of_cultivator_marathi: data.data });
			}
		})
	}

	sendAlert(message) {
		switch (this.language) {
			case "mr":
				const body = new HttpParams({
					fromObject: {
						text: message,
					}
				});
				this.http.englishToMarathi(body).subscribe((data: any) => {
					alert(data.data);
				});
				break;
			case "en":
				alert(message);
				break;
		}
	}

	getYesNo() {
		this.http.getYesNo().subscribe((data: any) => {
			// console.log(data)
			if (data) {
				this.gutNumberSaid = data.data
			}
			else {
				return false;
			}
		})
	}

	getProjectLayouts() {
		const body = new HttpParams({
			fromObject: {
				project_id: this.firstStepForm.value.farmer_master_project_code,
				dam_id: this.firstStepForm.value.farmer_master_dam_id,
				bank_id: this.firstStepForm.value.farmer_master_bank_id,
			}
		});

		// console.log(body)

		this.http.getprojectLayout(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (resolve.status)
				this.projectLayoutArray = resolve.data
			else
				console.log(resolve.message)
		});
	}
}
