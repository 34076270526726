<div class="loginpg fullwidth">
    <section class="logincnt">
        <!-- login left start-->
        <div class="loginleft">
        <img src="assets/images/login-logo.png" alt="logo" />	
        </div>
        <!-- login left over-->
        <!-- login right start-->
        <div class="loginright loginfrmbx">
            <form [formGroup]="otpForm" (ngSubmit)="onSubmit(otpForm)"  (keyup.enter)="onEnterKeyDown($event)">
                <!--  loginfull start -->
                <div class="loginfull fullwidth">
                    <label class="loginlabel">Enter OTP</label>
                    <input (keypress)="keyPress($event)" required type="text" formControlName="otp" class="form-control" placeholder="Enter Your OTP"  maxlength="6" >
                </div>
                <div *ngIf="otpForm.get('otp').hasError('required')">
                  <span style="color:white" >Please Provide otp Number</span> 
                </div>

                <div *ngIf="validOtp">
                    <span style="color:white" >OTP Not Valid</span> 
                  </div>
    
               <!-- <div *ngIf="otpForm.controls.mobilenumber.errors.minlength">
                    <span style="color:white" >Enter the Valid Mobile Number</span> 
                  </div>-->
                  
                <!--  loginfull over -->
                <!--  loginfull start -->
                <!--<div class="loginfull fullwidth">
                    <label class="loginlabel">Password</label>
                    <input   formControlName="password" type="password" placeholder="********" class="logininpt" > 
                </div>-->
                <!--  loginfull over -->
                <!--  loginfull start -->
                <!--<div class="loginfull fullwidth logincaptcha">
                    <label class="loginlabel">Captcha</label>
                    <input formControlName="Captcha" type="Password" placeholder="Enter Captcha" class="logininpt" > 
                    <img src="assets/images/logincaptcha.png" alt="captcha" />
                </div>-->
                <!--  loginfull over -->
                <!--  loginfull start -->
               <div class="loginfull rememberbx fullwidth">
                    
                   
                </div>
                <!--  loginfull over -->
                <!--  loginfull start -->
              <div class="loginfull  fullwidth">
                    <!-- <input class="loginsbmtbtn" type="submit" value="Login to your Account"  [disabled]="otpForm.invalid" />
                    --> <div class="row frmrow" > 

                    <div class="col-md-12 inptbx">
                        <input class="stepgreenbtn" type="Submit" value="Login Account"  [disabled]="otpForm.invalid"  />
    
                        <input class="stepgreybtn" type="Submit" value="Resend" (click)="resendOtp()" />
                        
                    </div>
    
                </div>
                </div> 


                
                <!--  loginfull over -->
            </form>
        </div>
        <!-- login right over-->
    </section>
    
    </div>