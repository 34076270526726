<header  #para>
  <!-- <div [ngStyle]="{'font-size': size + 'px'}">
    style using ngStyle
  </div> -->
  <!-- <p #para> hio shreya!!</p> -->
  <div class="logotop logolft"><a href="">
    <img style="" src="{{'logo' | translate}}" alt="logo" />
  </a></div>

  <ul>

    <!--li class="fontsize"><button (click)="changeFont('+')">+</button></li-->
    <li class="fontsize decrease"><span>{{'A' | translate}}<sup>-</sup></span></li>
    <li class="fontsize reset"><span>{{'A' | translate}}</span></li>
    <li class="fontsize increase"><span>{{'A' | translate}}<sup>+</sup></span></li>

    <!-- <button (click)="size = size + 1">+</button>
    <button (click)="size = size - 1">-</button> -->
    <!--li class="fontsize"><button (click)="changeFont('-')">-</button></li-->

    <!-- <li class="colorthem"><span></span></li>-->
   
    <li class="notili">

      <span class="notino">{{userNotificationsCount}}</span>

      <span class="notiicn"><i class="fa fa-bell" (click)="notification_click()"></i></span>

      <div class="notificationbx" *ngIf="showNotificationBlock">

        <h2 class="notihead">Notifications</h2>

        <!-- div start-->

        <div>

          <!-- box time start-->

          <!-- <div class="boxtime">

            <div class="box-date">Today</div>

            <div class="readall"><a href="">Mark all as Read</a></div>

          </div> -->

          <!-- box time over-->

          <!-- noticontent start-->

          <div class="noticontent">

            <ng-container *ngFor="let list of userNotifications;">

              <div class="notititle">{{list.message}}</div>

              <p>{{list.message}} by {{list.from_user_details.user_first_name}}
                {{list.from_user_details.user_middle_name}}

                {{list.from_user_details.user_last_name}}</p>

              <!-- <div class="beforetime"><span>10 mins ago</span></div> -->



            </ng-container>



            <!-- <div class="notititle">This is notification title</div>

            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the

              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and

              scrambled it to make a type specimen book. It has survived not only five centuries</p>

            <div class="beforetime"><span>1 hours ago</span></div>

 

            <div class="notititle">This is notification title</div>

            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the

              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and

              scrambled it to make a type specimen book. It has survived not only five centuries</p>

            <div class="beforetime"><span>2 hours ago</span></div>

 

            <div class="notititle">This is notification title</div>

            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the

              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and

              scrambled it to make a type specimen book. It has survived not only five centuries</p>

            <div class="beforetime"><span>6 hours ago</span></div> -->

          </div>

          <!-- noticontent over-->

        </div>

        <div *ngIf="userNotificationsCount==0">

          No new notification

        </div>



      </div>

    </li>

    <li class="selctlng">

      <select (change)="changeLang($event.target.value)" [value]="selectedlang">

        <option value="en">English</option>

        <option value="mr">Marathi</option>

      </select>

    </li>

    <li class="profilebtn">

      <div class="dropdown">

        <button type="button" class="dropdown-toggle" data-toggle="dropdown">

          <img src="assets/images/profilepic_bl.png" alt="profile pic" /> <span>{{ this.userName }}</span>

        </button>

        <div class="dropdown-menu profilesumbmenu">



          <div class="profileperson"><a href="#"> <span>{{this.userEmail === 'Null' ? 'NA' : this.userEmail}}

              </span></a></div>

          <ul class="profileoptionnav">

            <li><a (click)="goToProfile()"><span><img src="assets/images/drpdownicon1.png"

                    alt="icon" /></span><span>Profile</span></a></li>

            <!-- <li><a href=""><span><img src="assets/images/drpdownicon2.png" alt="icon" /></span><span>Change Password</span></a></li>

                      <li><a href=""><span><img src="assets/images/drpdownicon3.png" alt="icon" /></span><span>Setting</span></a></li>-->

          </ul>

          <div class="profilesbmtbtn" (click)="goToLogOut()">{{'Logout' | translate}}</div>

        </div>

      </div>



    </li>

  </ul>

</header>