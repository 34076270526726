<body class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position">
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- top blue nav over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'Farmer Master' | translate}}</h1>
            </div>
            <div class="mid-content fullwidth formstep">


                <!-- row start-->
                <div class="row">

                    <!-- form step1 start-->
                    <div class="stepfrmcmn stepfrm1">
                        <!-- step row start-->
                        <form [formGroup]="firstStepForm">

                            <!-- new row start-->
                            <div class="row">
                                <!-- new col-md-6 start-->
                                <div class="col-md-6">
                                    <!-- new inner row start-->
                                    <div class="row frmrow">

                                        <!-- col-md-12 start-->
                                        <div class="col-md-12 recentlistbx top30">
                                            <h2 class="smlheadh2 col-md-12"><span>{{'General' | translate}}</span></h2>
                                        </div>
                                        <!-- col-md-12 over-->
                                        <!--main col-md-6 start-->
                                        <!--<div class="col-md-12">-->
                                        <div class="col-md-4 inptbx">
                                            <label for="project" class="steplbl">{{'ProjectName' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <select class="stepinpt" formControlName="farmer_master_project_code"
                                                (change)="getDamList($event.target.value)">
                                                <option *ngFor="let projects of projectSelect;"
                                                    [value]="projects.project_id">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{projects.project_name_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{projects.project_name}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_project_code'].invalid &&
                                (firstStepForm.controls['farmer_master_project_code'].dirty || firstStepForm.controls['farmer_master_project_code'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_project_code'].errors.required">
                                                    {{'please select project first!' | translate}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'DamName' | translate}} <span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <select class="stepinpt" formControlName="farmer_master_dam_id"
                                                (change)="getDistrictList($event.target.value)" id="trig">

                                                <!-- <option value=" ">{{'Please Select1' | translate}}</option> -->
                                                <option *ngFor="let dams of damSelect;" [value]="dams.dam_id">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{dams.dam_name_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{dams.dam_name}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                            <!-- {{firstStepForm.value.farmer_master_dam_id  | json}} -->
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_dam_id'].invalid &&
                                (firstStepForm.controls['farmer_master_dam_id'].dirty || firstStepForm.controls['farmer_master_dam_id'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_dam_id'].errors.required">
                                                    {{'please select dam!' | translate}}
                                                </p>

                                            </div>
                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'District_Situated' | translate}} <span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <select class="stepinpt"
                                                formControlName="farmer_master_district_id_in_which_dam_situated">

                                                <!-- <option value=" ">{{'Please Select1' | translate}}</option> -->
                                                <option *ngFor="let district of districtSelect;"
                                                    [value]="district.district_id">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{district.district_name_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{district.district_name}}
                                                    </ng-template>
                                                </option>

                                            </select>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_district_id_in_which_dam_situated'].invalid &&
                                (firstStepForm.controls['farmer_master_district_id_in_which_dam_situated'].dirty || firstStepForm.controls['farmer_master_district_id_in_which_dam_situated'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_district_id_in_which_dam_situated'].errors.required">
                                                    {{'please select dam district!' | translate}}
                                                </p>
                                            </div>
                                        </div>


                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'Irrigation_Source' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <select class="stepinpt" formControlName="farmer_master_irregation_source"
                                                (change)="toggleShow($event.target.value)">

                                                <!-- <option value=" ">{{'Please Select1' | translate}}</option> -->

                                                <option *ngFor="let irrigation of irrigationSelect;"
                                                    [value]="irrigation.irrigation_source_id">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{irrigation.irrigation_source_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{irrigation.irrigation_source}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_irregation_source'].invalid &&
                                (firstStepForm.controls['farmer_master_irregation_source'].dirty || firstStepForm.controls['farmer_master_irregation_source'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_irregation_source'].errors.required">
                                                    {{'please select irrigation source!' | translate}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 inptbx" *ngIf='showbankType'>
                                            <label class="steplbl">{{'Canal Type' | translate}}<span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <select class="stepinpt" (change)="getMainCanel()"
                                                formControlName="farmer_master_bank_id">

                                                <!-- <option value=" ">{{'Please Select1' | translate}}</option> -->
                                                <option *ngFor="let list of bankArray;" [value]="list.bank_id">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{list.bank_name_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{list.bank_name}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_bank_id'].invalid &&
                                (firstStepForm.controls['farmer_master_bank_id'].dirty || firstStepForm.controls['farmer_master_bank_id'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_bank_id'].errors.required">
                                                    please select bank type!
                                                </p>
                                            </div>
                                        </div>


                                        <!--</div>-->
                                        <!--main col-md-6 over-->

                                    </div>
                                    <!-- new inner row over-->
                                </div>
                                <!-- new col-md-6 over-->
                                <!-- new col-md-6 start-->
                                <div class="col-md-6">
                                    <!--new inner row start-->
                                    <div class="col-md-12 recentlistbx">
                                        <h2 class="smlheadh2 col-md-12"><span>{{'WUA_Details' | translate}}</span></h2>
                                    </div>
                                    <!-- col-md-12 over-->
                                    <!-- step row start-->
                                    <div class="row frmrow">
                                        <div class="col-md-6 inptbx">
                                            <label class="steplbl">{{'Name_WUA' | translate}} <span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <select class="stepinpt" formControlName="farmer_master_wua_id">
                                                <!-- <option value=" ">{{'Please Select1' | translate}}</option> -->


                                                <!-- <option value="Individual Beneficial Farmer ">Individual Beneficial Farmer </option> -->

                                                <ng-container *ngIf="language == 'mr';else elseBlock1">
                                                    <option value="4326">वैयक्तिक लाभधारक शेतकरी</option>
                                                </ng-container>
                                                <ng-template #elseBlock1>
                                                    <option value="4326">Individual Beneficial Farmer </option>
                                                </ng-template>

                                                <option *ngFor="let wua of wuaArray;" value="{{wua.wua_id}}">


                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{wua.wua_name_marathi}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{wua.wua_name}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_wua_id'].invalid &&
                                (firstStepForm.controls['farmer_master_wua_id'].dirty || firstStepForm.controls['farmer_master_wua_id'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_wua_id'].errors.required">
                                                    {{'please select wua!' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- step row over-->
                                    <!--new inner row over-->




                                </div>
                                <!-- new col-md-6 over-->
                            </div>
                            <!-- new row over-->


                            <div *ngIf="showcanal">
                                <div class="col-md-12 recentlistbx">
                                    <h2 class="smlheadh2 col-md-12"><span>{{'CanalDetails' | translate}}</span></h2>
                                </div>

                                <div class="row frmrow">

                                    <!-- <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Main_Canal' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="farmer_master_canal_id"
                                            (change)="getBranchCanalList($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseMainNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseMainNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>

                                            <option *ngFor="let corporation of mainCanelArray;"
                                                [value]="corporation.main_canal_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{corporation.main_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{corporation.main_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Branch_Canal' | translate}}<span
                                                aria-hidden="true" style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="farmer_master_canal_branch_id"
                                            (change)="getDistributoryList($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseBranchNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseBranchNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let region of branchCanelArray;"
                                                [value]="region.branch_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{region.branch_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{region.branch_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Distributary_Canal' | translate}}<span
                                                aria-hidden="true" style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="farmer_master_canal_distributary_id"
                                            (change)="getMinorList($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseDistributoryNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseDistributoryNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let circle of distributoryArray;"
                                                [value]="circle.distributory_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{circle.distributory_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{circle.distributory_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Minor' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="farmer_master_minor_id"
                                            (change)="getSubMinorList($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseMinorNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseMinorNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let division of minorCanelArray;"
                                                [value]="division.minor_canal_master_id">
                                                <ng-container *ngIf="language == 'en';else elseBlock">
                                                    {{division.minor_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{division.minor_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_SUB_Minor' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="farmer_master_subminor_id"
                                            (change)="getOutletList($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseSubMinorNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseSubMinorNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let regionSub of subMinorCanelArray;"
                                                [value]="regionSub.subminor_canal_master_id">
                                                {{regionSub.subminor_canal_name}}
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{regionSub.subminor_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{regionSub.subminor_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name of Outlet' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>

                                        <select class="stepinpt" formControlName="farmer_master_outlet_id">
                                            <ng-container *ngIf="language == 'en';else elseOutletNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseOutletNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let canal of getOutlistingArray;"
                                                [value]="canal.outlet_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{canal.outlet_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{canal.outlet_canal_name}}
                                                </ng-template>
                                            </option>
                                        </select>
                                    </div> -->
                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Project_Layout' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>

                                        <select class="stepinpt" formControlName="farmer_master_project_layout_id"
                                            (change)="onProjectLayoutChange($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseOutletNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseOutletNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let canal of projectLayoutArray;"
                                                [value]="canal.project_layout_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{canal.mwrd_main_canal_master?canal.mwrd_main_canal_master.main_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_branch_canal_master?canal.mwrd_branch_canal_master.branch_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_distributory_canal_master?canal.mwrd_distributory_canal_master.distributory_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_minor_canal_master?canal.mwrd_minor_canal_master.minor_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_subminor_canal_master?canal.mwrd_subminor_canal_master.subminor_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_outlet_canal_master?canal.mwrd_outlet_canal_master.outlet_canal_name_marathi:'-'}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{canal.mwrd_main_canal_master?canal.mwrd_main_canal_master.main_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_branch_canal_master?canal.mwrd_branch_canal_master.branch_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_distributory_canal_master?canal.mwrd_distributory_canal_master.distributory_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_minor_canal_master?canal.mwrd_minor_canal_master.minor_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_subminor_canal_master?canal.mwrd_subminor_canal_master.subminor_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_outlet_canal_master?canal.mwrd_outlet_canal_master.outlet_canal_name:'-'}}
                                                </ng-template>
                                            </option>
                                        </select>
                                    </div>

                                </div>

                                <!-- <div class="col-md-4 inptbx">
                                <label class="steplbl">{{'Outlet_Number' | translate}}</label>
                                <input class="stepinpt" formControlName="farmer_master_outlet_number">
                            </div>
                            <div class="col-md-4 inptbx">
                                <label class="steplbl">{{'Side_of_outlet' | translate}}</label>
                                <select class="stepinpt" formControlName="farmer_master_outlet_side">
                                    <option value="Left">Left</option>
                                    <option value="Right">Right</option>


                                </select>
                            </div> -->

                            </div>


                            <!-- col-md-12 start-->
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Details of Farmer' | translate}}</span></h2>
                            </div>
                            <!-- col-md-12 over-->

                            <!-- step row start-->
                            <div class="row frmrow">


                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Farmer_Name' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" #userNameRef
                                        formControlName="farmer_master_farmer_name"
                                        (blur)="translateIntoMarathi(this.firstStepForm.value.farmer_master_farmer_name, 'fname')">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_name'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_name'].dirty || firstStepForm.controls['farmer_master_farmer_name'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_name'].errors.required">
                                            {{'Farmer Name is required!' | translate}}
                                        </p>
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_name'].errors.maxLength">
                                            Max 45 characters allowed!
                                        </p>
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_name'].errors.pattern">
                                            Only characters are allowed!
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Farmer_Name_Marathi" | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="farmer_master_farmer_marathi_name" maxlength="40">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_name'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_marathi_name'].dirty || firstStepForm.controls['farmer_master_farmer_marathi_name'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_name'].errors.required">
                                            {{'Farmer Marathi Name is required!' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name of the Cultivator (English)' | translate}}<span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" #userNameRef
                                        formControlName="farmer_master_name_of_cultivator"
                                        (blur)="translateIntoMarathi(this.firstStepForm.value.farmer_master_name_of_cultivator, 'cultivatorName')">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator'].invalid &&
                            (firstStepForm.controls['farmer_master_name_of_cultivator'].dirty || firstStepForm.controls['farmer_master_name_of_cultivator'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator'].errors.required">
                                            {{'Cultivator Name (English ) is required!' | translate}}
                                        </p>
                                        <!-- <p class="error-msg-ab" style="color: brown;"
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_name'].errors.maxLength">
                                        Max 45 characters allowed!
                                    </p> -->
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator'].errors.pattern">
                                            Only characters are allowed!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name of the Cultivator (Marathi)' | translate}}<span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="farmer_master_name_of_cultivator_marathi">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator_marathi'].invalid &&
                            (firstStepForm.controls['farmer_master_name_of_cultivator_marathi'].dirty || firstStepForm.controls['farmer_master_name_of_cultivator_marathi'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator_marathi'].errors.required">
                                            {{'Cultivator Name (Marathi) is required!' | translate}}
                                        </p>
                                        <!-- <p class="error-msg-ab" style="color: brown;"
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_name'].errors.maxLength">
                                        Max 45 characters allowed!
                                    </p> -->
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_name_of_cultivator_marathi'].errors.pattern">
                                            Only characters are allowed!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"farmer_costake_holder" | translate}}<span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="farmer_costake_holder">
                                        <!-- <option value="0">Please Select</option> -->
                                        <option value="1">1</option>
                                        <option value="1">2</option>
                                        <option value="1">3</option>
                                        <option value="1">4</option>
                                        <option value="1">5</option>
                                        <option value="1">6</option>
                                        <option value="1">7</option>
                                        <option value="1">8</option>
                                        <option value="1">9</option>
                                        <option value="1">10</option>
                                    </select>
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_name'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_marathi_name'].dirty || firstStepForm.controls['farmer_master_farmer_marathi_name'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_name'].errors.required">
                                            {{'Farmer Name (Marathi) is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Farmer_Gender' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="farmer_master_farmer_gender">
                                        <option value="1">{{"Male" | translate }}</option>
                                        <option value="2">{{"Female" | translate}}</option>
                                        <option value="3">{{"Transgender" | translate }}</option>
                                    </select>
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_gender'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_gender'].dirty || firstStepForm.controls['farmer_master_farmer_gender'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_gender'].errors.required">
                                            {{'Please select gender!' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Farmer_Mobile_number' | translate}}<span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="farmer_master_farmer_mobile_no"
                                        maxlength="10">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_mobile_no'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_mobile_no'].dirty || firstStepForm.controls['farmer_master_farmer_mobile_no'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_mobile_no'].errors.required">
                                            {{'Mobile no is required!' | translate}}
                                        </p>
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_mobile_no'].errors.pattern">
                                            Mobile no must be of 10 digits!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Farmer_Whatsapp_Number' | translate}}
                                        <!--   <span aria-hidden="true" style="color: red;">*</span>-->
                                    </label>
                                    <input type="text" class="stepinpt" maxlength="10"
                                        formControlName="farmer_master_farmer_whatsapp_no">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_whatsapp_no'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_whatsapp_no'].dirty || firstStepForm.controls['farmer_master_farmer_whatsapp_no'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_whatsapp_no'].errors.pattern">
                                            Whatsapp no must be of 10 digits!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Farmer_Email' | translate}}
                                        <!--<span aria-hidden="true"
                                            style="color: red;">*</span>-->
                                    </label>
                                    <input type="text" class="stepinpt" formControlName="farmer_master_farmer_email">
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_email'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_email'].dirty || firstStepForm.controls['farmer_master_farmer_email'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_email'].errors.pattern">
                                            Invalid Email!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Address1" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="farmer_master_farmer_address1"
                                        maxlength="100"
                                        (blur)="translateIntoMarathi(this.firstStepForm.value.farmer_master_farmer_address1, 'add1')" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_address1'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_address1'].dirty || firstStepForm.controls['farmer_master_farmer_address1'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_address1'].errors.pattern">
                                            {{'address is required!' | translate}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Address1 Marathi" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="farmer_master_farmer_marathi_address1" maxlength="100" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address1'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_marathi_address1'].dirty || firstStepForm.controls['farmer_master_farmer_marathi_address1'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address1'].errors.pattern">
                                            {{'Marathi Address required!' | translate}}
                                        </p>
                                    </div>
                                </div>


                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{"Address2" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="farmer_master_farmer_address2" class="stepinpt"
                                        maxlength="100"
                                        (keyup)="translateIntoMarathi(this.firstStepForm.value.farmer_master_farmer_address2, 'add2')" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_address2'].invalid &&
                                      (firstStepForm.controls['farmer_master_farmer_address2'].dirty || firstStepForm.controls['farmer_master_farmer_address2'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_address2'].errors.pattern">
                                            Address2 required!
                                        </p>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{"Address2 Marathi" | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="farmer_master_farmer_marathi_address2"
                                        class="stepinpt" maxlength="100" (blur)="translateMarathi($event, 'add2')" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address2'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_marathi_address2'].dirty || firstStepForm.controls['farmer_master_farmer_marathi_address2'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address2'].errors.pattern">
                                            Marathi Address2 required!
                                        </p>
                                    </div>
                                </div> -->

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{"Address3" |translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="farmer_master_farmer_address3" class="stepinpt"
                                        maxlength="100"
                                        (keyup)="translateIntoMarathi(this.firstStepForm.value.farmer_master_farmer_address3, 'add3')" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_address3'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_address3'].dirty || firstStepForm.controls['farmer_master_farmer_address3'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_address3'].errors.pattern">
                                            Address3 required!
                                        </p>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{"Address3 Marathi" | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="farmer_master_farmer_marathi_address3"
                                        class="stepinpt" maxlength="100" (blur)="translateMarathi($event, 'add3')" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address3'].invalid &&
                                    (firstStepForm.controls['farmer_master_farmer_marathi_address3'].dirty || firstStepForm.controls['farmer_master_farmer_marathi_address3'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_marathi_address3'].errors.pattern">
                                            Marathi Address3 required!
                                        </p>
                                    </div>
                                </div> -->

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Location" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <span><i class="fa fa-chevron-right stepinpt" aria-hidden="true" data-toggle="modal"
                                            data-target="#myModal">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                <!-- {{getFarmerDistructNameMarathi}} -->
                                                {{getFarmerDistructNameMarathi}} | {{farmerTallukaNameMarathi}} |
                                                {{farmerVillageNameMarathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{getFarmerDistructName}} | {{farmerTallukaName}} |
                                                {{farmerVillageName}}
                                            </ng-template>
                                        </i> </span>


                                    <!-- <input type="text" class="stepinpt" placeholder="Location" formControlName="test"
                                            data-toggle="modal" data-target="#myModal" /> -->
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Farmer_Ledger_Number" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>

                                    <input type="text" formControlName="farmer_master_farmer_ledger_no" class="stepinpt"
                                        [pattern]="unamePattern" />
                                    <div
                                        *ngIf="firstStepForm.controls['farmer_master_farmer_ledger_no'].invalid &&
                                (firstStepForm.controls['farmer_master_farmer_ledger_no'].dirty || firstStepForm.controls['farmer_master_farmer_ledger_no'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_ledger_no'].errors.required">
                                            {{'Farmer Ledger Number required!' | translate}}
                                        </p>
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="firstStepForm.controls['farmer_master_farmer_ledger_no'].errors.pattern">
                                            Farmer Ledger Number not valid.
                                        </p>
                                    </div>

                                    <!-- <div *ngIf="username.errors?.pattern">
                                        <p class="error-msg-ab" style="color: red;">Farmer Ledger Number not valid.</p>
                                    </div> -->
                                </div>

                            </div>

                            <!-- step row over-->
                            <!--new row start-->
                            <div class="row">
                                <!--new col-md-6 start-->
                                <div class="col-md-6">
                                    <!-- new inner row start-->


                                    <!-- step row start-->
                                    <div class="row frmrow">

                                        <!-- col-md-12 start-->
                                        <div class="col-md-12 recentlistbx top30">
                                            <h2 class="smlheadh2 col-md-12">
                                                <span>{{'Lift_irrigationPermission_Details' | translate}}</span>
                                            </h2>
                                            <!-- <div class="editicon"><span class="fa fa-pencil-square"></span></div> -->
                                        </div>
                                        <!-- col-md-12 over-->

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'Lift_irrigationPermission_Number' | translate}}
                                                <span aria-hidden="true" style="color: red;">*</span>
                                            </label>
                                            <input type="text"
                                                formControlName="farmer_master_lift_irregation_permission_no" id="vfrom"
                                                class=" stepinpt" maxlength="30">
                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'ValidFrom' | translate}}<span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <input type="date" formControlName="farmer_master_valid_from" id="vfrom"
                                                class=" stepinpt">
                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'ValidUpto' | translate}} <span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <input type="date" formControlName="farmer_master_valid_upto" id="vupto"
                                                class=" stepinpt">
                                        </div>

                                    </div>
                                    <!-- new inner row over-->
                                </div>
                                <!--new col-md-6 over-->
                                <!--new col-md-6 start-->
                                <div class="col-md-6">

                                    <!-- step row start-->
                                    <div class="row frmrow">
                                        <!-- new inner row start-->
                                        <div class="col-md-12 recentlistbx top30">
                                            <h2 class="smlheadh2 col-md-12"><span>{{'Sugar Factory' | translate}}</span>
                                            </h2>
                                            <!-- <div class="editicon"><span class="fa fa-pencil-square"></span></div> -->
                                        </div>
                                        <!-- col-md-12 over-->

                                        <div class="col-md-12 inptbx radioinpt " style="height: 35px;">
                                            <label class="steplbl">{{"Yes" | translate}}</label>
                                            <input type="radio" class="stepinpt" value="Yes"
                                                formControlName="isSugerFactory"
                                                (change)="ischangesuger($event, 'Yes')">

                                            <label class="steplbl">{{"No" | translate}}</label>
                                            <input type="radio" class="stepinpt" value="No"
                                                formControlName="isSugerFactory" (change)="ischangesuger($event, 'No')">
                                        </div>
                                    </div>
                                    <!-- step row over-->

                                    <!-- step row start-->
                                    <div class="col-md-12 inptbx radioinp row frmrow" *ngIf="showSugar">

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{ 'Sugar_Factory_Name' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <input type="text" formControlName="farmer_master_sugar_factory_name"
                                                class="stepinpt" maxlength="100"
                                                (blur)="translateIntoMarathi(this.firstStepForm.value.farmer_master_sugar_factory_name, 'sugerName')">
                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{ 'Sugar_Factory_name_marathi' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <input type="text"
                                                formControlName="farmer_master_sugar_factory_name_marathi"
                                                class="stepinpt" maxlength="100">
                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{'Sugar_Factory_Email' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <input type="text" formControlName="farmer_master_sugar_factory_email"
                                                class="stepinpt">
                                        </div>


                                    </div>
                                    <!-- new inner row over-->
                                </div>
                                <!--new col-md-6 over-->
                            </div>
                            <!--new row start-->







                            <div class="col-md-12 recentlistbx gutblkaddblock">
                                <h2 class="smlheadh2 col-md-12"><span>{{'GutDetails' | translate}} </span></h2>

                            </div>

                            <!-- <div class="row frmrow ">
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">GUT No.</label>
                                    <input type="text" class="stepinpt">

                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Part No</label>
                                    <input type="text" class="stepinpt">
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Total Area GUT</label>
                                    <input type="text" class="stepinpt">
                                </div>

                            </div>
                            <div class="row frmrow">

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Owned Area EXCL POT KHARAB </label>
                                    <input type="text" class="stepinpt">
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Pot KHARAB Area </label>
                                    <input type="text" class="stepinpt">
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">Whether said gut number is in notified command area
                                        or
                                        not? </label>
                                    <select class="stepinpt">
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                            </div> -->
                            <!-- gutbx start-->
                            <!-- <div class="gutbx">
                                <div class="gutbxmain">

                                    <hr>

                                </div>

                            </div> -->
                            <div class="">
                                <div class="gutbxmain">
                                    <div class="row frmrow ">
                                        <div class="col-md-1 inptbx">
                                            <label class="steplbl">{{'GUTNo' | translate}}<span aria-hidden="true"
                                                    style="color: red;">*</span> </label>
                                            <input type="text" class="stepinpt" numbersOnly (keyup)="IsNumeric($event)"
                                                [(ngModel)]="reqData.farmer_master_gut_no"
                                                [ngModelOptions]="{standalone: true}" maxlength="10"
                                                pattern="\d{1,2}(\.\d{0,4})?">

                                        </div>

                                        <!-- <div *ngIf = "notGutValid">
                                            <p>GUT No. Not Valid</p>

                                        </div> -->

                                        <div class="col-md-1 inptbx">
                                            <label class="steplbl">{{'PartNo' | translate}}<span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <input type="text" class="stepinpt"
                                                (keypress)="validationService.isAlphaNumeric($event)"
                                                [(ngModel)]="reqData.farmer_master_part_no"
                                                [ngModelOptions]="{standalone: true}" maxlength="20">

                                            <div>
                                                <p class="error-msg-ab" id="errrmsgAlphaNum"
                                                    style="color: red; display: none;">Part No* Only Alphabets and
                                                    Numbers
                                                    allowed.</p>
                                            </div>
                                        </div>





                                        <div class="col-md-2 inptbx">
                                            <label class="steplbl">{{'Total_GUT' | translate}}<span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <input type="text" class="stepinpt"
                                                formControlName="farmer_master_total_area_gut"
                                                [(ngModel)]="reqData.farmer_master_total_area_gut"
                                                pattern="^[0-9]{1,4}(\.[0-9][0-9])?$">
                                            <div>
                                                <p class="error-msg-ab" id="errrmsgDecNum"
                                                    style="color: red; display: none;">Decimal Only
                                                    allowed.</p>
                                            </div>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_total_area_gut'].invalid &&
                                             (firstStepForm.controls['farmer_master_total_area_gut'].dirty || firstStepForm.controls['farmer_master_total_area_gut'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_total_area_gut'].errors.pattern">
                                                    Maximum four digits allowed before decimal & 2 after decimal!
                                                </p>
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_total_area_gut'].errors.maxLength">
                                                    6 digit number allowed!
                                                </p>
                                            </div>

                                        </div>


                                        <div class="col-md-3 inptbx">
                                            <label class="steplbl">{{'Owned_Area_EXCL_POT_KHARAB' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <input type="text" class="stepinpt"
                                                formControlName="farmer_master_owned_area_exclude_potkharab"
                                                [(ngModel)]="reqData.farmer_master_owned_area_exclude_potkharab"
                                                pattern="^[0-9]{1,4}(\.[0-9][0-9])?$">

                                            <div>
                                                <p class="error-msg-ab" id="errrmsgDecNum"
                                                    style="color: red; display: none;">Decimal Only
                                                    allowed.</p>
                                            </div>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].invalid &&
                                                 (firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].dirty || firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].errors.pattern">
                                                    Maximum four digits allowed before decimal & 2 after decimal!
                                                </p>
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_owned_area_exclude_potkharab'].errors.maxLength">
                                                    6 digit number allowed!
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-md-2 inptbx">
                                            <label class="steplbl">{{'Pot_KHARAB_Area' | translate}}<span
                                                    aria-hidden="true" style="color: red;">*</span></label>
                                            <input type="text" class="stepinpt"
                                                formControlName="farmer_master_potkharab_area"
                                                [(ngModel)]="reqData.farmer_master_potkharab_area"
                                                pattern="^[0-9]{1,4}(\.[0-9][0-9])?$">
                                            <div>
                                                <p class="error-msg-ab" id="errrmsgDecNum"
                                                    style="color: red; display: none;">Decimal Only
                                                    allowed.</p>
                                            </div>
                                            <div
                                                *ngIf="firstStepForm.controls['farmer_master_potkharab_area'].invalid &&
                                                 (firstStepForm.controls['farmer_master_potkharab_area'].dirty || firstStepForm.controls['farmer_master_potkharab_area'].touched)">
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_potkharab_area'].errors.pattern">
                                                    Maximum four digits allowed before decimal & 2 after decimal!
                                                </p>
                                                <p class="error-msg-ab" style="color: brown;"
                                                    *ngIf="firstStepForm.controls['farmer_master_potkharab_area'].errors.maxLength">
                                                    6 digit number allowed!
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-3 inptbx">
                                            <label class="steplbl">{{'Whether' | translate}} <span aria-hidden="true"
                                                    style="color: red;">*</span></label>
                                            <select class="stepinpt"
                                                [(ngModel)]="reqData.farmer_master_whether_said_gutnumber_notified_command_area"
                                                [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let list of gutNumberSaid"
                                                    value="{{list.master_common_id}}">
                                                    <ng-container *ngIf="language == 'mr';else elseBlock">
                                                        {{list.common_marathi_name}}
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        {{list.common_name}}
                                                    </ng-template>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!--<hr>-->
                                    <div class="row frmrow">
                                        <div class="col-md-12 inptbx">
                                            <input class="stepgreenbtn" type="button" value="{{'Add_Gut' | translate }}"
                                                (click)="pushGutDetails()">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- step row over-->
                            <!-- step row start-->
                            <!-- <div class="row frmrow">
                                <div class="col-md-12 inptbx">
                                    <input class="stepgreenbtn" type="button" value="Add">
                                </div>
                            </div> -->



                        </form>
                    </div>
                </div>

                <div class="frmtable">
                    <table class="tablerecordpg">
                        <thead>
                            <tr>
                                <th class="bgclr">{{'GUTNo' | translate}}</th>
                                <th class="bgclr">{{'PartNo' | translate}}</th>
                                <th class="bgclr">{{'Total_GUT'| translate}}</th>
                                <th class="bgclr">{{'Owned_Area_EXCL_POT_KHARAB'| translate}}</th>
                                <th class="bgclr">{{'Pot_KHARAB_Area'| translate}}</th>
                                <th class="bgclr">{{'Whether' | translate}}</th>
                                <th class="bgclr">{{"Action" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of newGutDetailsArray; let i = index">
                                <ng-container *ngIf="list.is_deleted==0">
                                    <td>{{list.farmer_master_gut_no}}</td>
                                    <td>{{list.farmer_master_part_no}}</td>
                                    <td>{{list.farmer_master_total_area_gut}}</td>
                                    <td>{{list.farmer_master_owned_area_exclude_potkharab}}</td>
                                    <td>{{list.farmer_master_potkharab_area}}</td>
                                    <td>
                                        <ng-container
                                            *ngIf="list.farmer_master_whether_said_gutnumber_notified_command_area==1;else elseBlock">
                                            <ng-container *ngIf="language == 'mr';else elseYesBlock">
                                                होय
                                            </ng-container>
                                            <ng-template #elseYesBlock>
                                                Yes
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            <ng-container *ngIf="language == 'mr';else elseNoBlock">
                                                नाही
                                            </ng-container>
                                            <ng-template #elseNoBlock>
                                                No
                                            </ng-template>
                                        </ng-template>
                                    </td>
                                    <td><span (click)="deleteGutDetails(i)"><i class="fa fa-trash"></i></span></td>
                                </ng-container>

                            </tr>
                        </tbody>


                    </table>
                    <br />
                    <br />

                </div>
                <div class="clearfix"></div>
                <div class="row frmrow clearfix">
                    <div class="col-md-12 inptbx create-anthr">
                        <input class="" type="checkbox" (change)="showCurentPage($event)">
                        <span>{{'Copy this information' | translate}}</span>

                        <ng-container *ngIf="farmer_id==-1; else: elseEditBlock">
                            <!-- <input class="stepgreenbtn" type="button" value="{{'Save' | translate}}"> -->
                            <input class="stepgreenbtn" type="Submit" value="{{'submit_farmer' | translate}}"
                                (click)="finalSubmit()">
                            <!-- <input class="stepgreybtn" type="reset" value="{{'Reset' | translate}}"> -->
                        </ng-container>
                        <ng-template #elseEditBlock>
                            <input class="stepgreenbtn" type="Submit" value="{{'Update' | translate}}"
                                (click)="updateFarmer()">
                        </ng-template>


                    </div>
                </div>
                <!-- mid content over-->
            </div>
        </div>
    </div>
    <!-- dashboard right panel over-->
    <!-- footer start-->
    <footer class="dboardfooter fullwidth">
        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
    </footer>
    <!-- footer over-->
</body>

<!-- Modal -->
<div class="modal fade" id="myModal" role="dialog" style="width: 100%;">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{"Location"| translate}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="row frmrow">
                    <div class="col-md-8 inptbx">
                        <label class="steplbl">{{'Farmer_District' | translate}} <span aria-hidden="true"
                                style="color: red;">*</span></label>
                        <select class="stepinpt" [(ngModel)]="farmerDistrict"
                            (change)="forFamerTaluka($event.target.value)">
                            <option *ngFor="let list of getDistArray" [value]="list.district_code">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                    {{list.district_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.district_name}}
                                </ng-template>
                            </option>
                        </select>
                    </div>

                </div>
                <div class="row frmrow">
                    <div class="col-md-8 inptbx">
                        <label class="steplbl">{{'Farmer_Taluka' | translate}} <span aria-hidden="true"
                                style="color: red;">*</span></label>
                        <select class="stepinpt" [(ngModel)]="farmerTalluka"
                            (change)="forarmerVillage($event.target.value)">
                            <option *ngFor="let list of getFarmerTaluka" [value]="list.taluka_code">
                                <!-- {{list.taluka_name}} -->
                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                    {{list.taluka_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.taluka_name}}
                                </ng-template>
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row frmrow">
                    <div class="col-md-8 inptbx">
                        <label class="steplbl">{{'Farmer_Village' | translate}}<span aria-hidden="true"
                                style="color: red;">*</span></label>
                        <select class="stepinpt" [(ngModel)]="farmerVillage"
                            (change)="getAllVillage($event.target.value)">
                            <option *ngFor="let list of getFarmerVillage" [value]="list.village_code">
                                <!-- {{list.village_name}} -->
                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                    {{list.village_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.village_name}}
                                </ng-template>
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="stepgreenbtn" data-dismiss="modal">{{'Submit' | translate}}</button>
                &nbsp;
                <!--<button type="button" class="btn btn-default" data-dismiss="modal">{{'Close' | translate}}</button>-->
            </div>
        </div>

    </div>
</div>