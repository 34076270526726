import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { ValidationService } from '../_service/validation.service';
import { switchMap, debounceTime, map } from 'rxjs/operators';
import { resolve } from 'dns';
//import { ConsoleReporter } from 'jasmine';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-dam-master',
  templateUrl: './dam-master.component.html',
  styleUrls: ['./dam-master.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DamMasterComponent implements OnInit {
  clickEventsubscription: Subscription;
  language: string;
  dammasterForm: FormGroup;
  selectedlang = localStorage.getItem('selected_lang');
  getdamTypeArr: any;
  getdamPurposesArr: any;
  getdamStorageseArr: any;
  getDistArray: any;
  getFarmerTaluka: any;
  farmerDistrictCode: string | readonly string[];
  getFarmerVillage: any;
  getdamBasinsArray: any;
  getdamSubBasinsArray: any;
  office_details_en: any = {};
  office_details_mr: any = {};
  divTextshow: boolean = false;
  divselectshow: boolean = false;
  primary_Region: any;
  primary_Circle: any;
  primary_Division: any;
  primary_SubDivision: any;
  primary_Section: any;
  FirstKey: string;
  selectedSecondaryCorporationIds: any = [];
  selectedSecondaryRegionIds: any = [];
  selectedSecondaryCircleIds: any = [];
  selectedSecondaryDivisionIds: any = [];
  selectedSecondarySubDivisionIds: any = [];
  selectedSecondarySectionIds: any = [];
  secondary_corporation: any;
  secondary_Region: any;
  secondary_Circle: any;
  secondary_Division: any;
  secondary_SubDivision: any;
  secondary_Section: any;
  dropdownSettings: any = {};
  dropdownSettingsForDamPurpose: any = {};
  projectSelect: any
  dropdownSettingsForDamType: any
  selectedDamType: any = [];
  selectedDamPurpose: any = [];
  dam_id = 0;
  aDamDetail: any
  CorporatesEditArray: any;
  SubDivEditArray: any;
  regionEditArray: any;
  circleEditArray: any;
  DivEditArray: any;
  SecEditArray: any;
  select = this.selectedlang == 'en' ? 'Select' : 'निवडा'


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private sharedService: SharedService,
    public validationService: ValidationService,


  ) {

    this.dammasterForm = this.fb.group({
      dam_project_primary_id: new FormControl(null, [Validators.required]),
      district: new FormControl(null),
      dam_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 ']*$"), Validators.maxLength(80)]),
      dam_name_marathi: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      dam_purpose: new FormControl(null, [Validators.required]),
      dam_type: new FormControl(null, [Validators.required]),
      dam_storage_creation_status: new FormControl(null, [Validators.required]),
      dam_district_code: new FormControl(null, [Validators.required]),
      dam_taluka_code: new FormControl(null, [Validators.required]),
      dam_village_code: new FormControl(null, [Validators.required]),

      dam_corporation_code: new FormControl(null),
      dam_secondary_corporation_id: new FormControl(null, [Validators.required]),
      dam_region_code: new FormControl(null),
      dam_secondary_region_id: new FormControl(null, [Validators.required]),
      dam_circle_code: new FormControl(null),
      dam_secondary_circle_id: new FormControl(null, [Validators.required]),
      dam_division_code: new FormControl(null),
      dam_secondary_division_id: new FormControl(null, [Validators.required]),
      dam_subdivision_code: new FormControl(null),
      dam_secondary_subdivision_id: new FormControl(null, [Validators.required]),
      dam_section_code: new FormControl(null),
      dam_secondary_section_id: new FormControl(null, [Validators.required]),


      secondaryTaluka: new FormControl(null),
      secondaryDistrict: new FormControl(null),
      stateConrol: new FormControl(null),
      taluka: new FormControl(null),
      village: new FormControl(null),
      dam_basin_code: new FormControl(null, [Validators.required]),
      dam_subbasin_code: new FormControl(null, [Validators.required]),

      dam_designed_live_storage_mcum: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      dam_designed_live_storage_tmc: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      dam_designed_dead_storage_mcum: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      dam_designed_dead_storage_tmc: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      dam_designed_gross_storage_mcum: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      dam_designed_gross_storage_tmc: new FormControl(null, [Validators.required, Validators.pattern('^([0-9]{1,10})(.[0-9]{0,2})?$')]),
      post_user_id: new FormControl(null),
      dam_added_by_user_office_type: new FormControl(null),
      dam_user_type: new FormControl(1),

    });



    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      this.selectedlang = localStorage.getItem('selected_lang');

      this.ngOnInit();
    })
  }
  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    //alert(this.selectedlang);
    this.select = this.selectedlang == 'en' ? 'Select' : 'निवडा'
    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));



    //alert(this.selectedlang);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'office_id',
      textField: this.selectedlang == 'en' ? 'office_name' : 'office_name_marathi',
      enableCheckAll: false,
      allowSearchFilter: true,
      selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
      unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
      searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
      noDataAvailablePlaceholderText: this.selectedlang == 'en' ? 'Search' : 'माहिती उपलब्ध नाही',

    };
    this.dropdownSettingsForDamPurpose = {
      singleSelection: false,
      idField: 'dam_purpose_id',
      textField: this.selectedlang == 'en' ? 'purpose_name' : 'purpose_marathi_name',
      enableCheckAll: false,
      allowSearchFilter: true,
      selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
      unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
      searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
      noDataAvailablePlaceholderText: this.selectedlang == 'en' ? 'No data available' : 'माहिती उपलब्ध नाही',
    }
    this.dropdownSettingsForDamType = {
      singleSelection: false,
      idField: 'dam_type_id',
      textField: this.selectedlang == 'en' ? 'type_name' : 'type_marathi_name',
      enableCheckAll: false,
      allowSearchFilter: true,
      selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
      unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
      searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
      noDataAvailablePlaceholderText: this.selectedlang == 'en' ? 'Search' : 'माहिती उपलब्ध नाही',
    }

    if (this.route.snapshot.params['id']) {
      this.dam_id = this.route.snapshot.params['id'];
      this.getSingleDamDetail(this.dam_id)
    }
    this.getdamType();
    this.getdamPurposes();
    this.getdamStorages();
    this.getDistrict();
    this.getBasins();
    this.getUserOfficeDetails();
    this.getCorporateOffices('noEdit')
    this.getDamMasterApprovedProjects()

  }

  getSingleDamDetail(damId) {
    const body = new HttpParams({
      fromObject: {
        dam_id: damId,
      }
    });
    this.http.getSingleDamMaster(body).subscribe(
      (resolve: any) => this.editDamMaster(resolve.data),
      (err: any) => console.log(err)
    );
  }

  editDamMaster(damDetails: any) {
    this.aDamDetail = damDetails

    this.forFamerTaluka(damDetails.dam_district_code)
    this.forarmerVillage(damDetails.dam_taluka_code)
    this.getSubBasins(damDetails.dam_basin)

    this.selectedSecondaryCorporationIds = JSON.parse(damDetails.dam_secondary_corporation_id)

    this.selectedSecondaryRegionIds = JSON.parse(damDetails.dam_secondary_region_id)

    this.selectedSecondaryCircleIds = JSON.parse(damDetails.dam_secondary_circle_id)

    this.selectedSecondaryDivisionIds = JSON.parse(damDetails.dam_secondary_division_id)

    this.selectedSecondarySubDivisionIds = JSON.parse(damDetails.dam_secondary_subdivision_id)

    this.selectedSecondarySectionIds = JSON.parse(damDetails.dam_secondary_section_id)

    this.getCorporateOffices('edit');

    this.dammasterForm.patchValue({
      dam_project_primary_id: damDetails.dam_project_primary_id,
      dam_name: damDetails.dam_name,
      dam_name_marathi: damDetails.dam_name_marathi,
      dam_purpose: damDetails.dam_purpose,
      dam_type: damDetails.dam_type,
      dam_storage_creation_status: damDetails.dam_storage_creation_status,
      dam_district_code: damDetails.dam_district_code,
      dam_taluka_code: damDetails.dam_taluka_code,
      dam_village_code: damDetails.dam_village_code,
      dam_basin_code: damDetails.dam_basin,
      dam_subbasin_code: damDetails.dam_subbasin,



      dam_designed_live_storage_mcum: damDetails.dam_designed_live_storage_mcum,
      dam_designed_live_storage_tmc: damDetails.dam_designed_live_storage_tmc,
      dam_designed_dead_storage_mcum: damDetails.dam_designed_dead_storage_mcum,
      dam_designed_dead_storage_tmc: damDetails.dam_designed_dead_storage_tmc,
      dam_designed_gross_storage_mcum: damDetails.dam_designed_gross_storage_mcum,
      dam_designed_gross_storage_tmc: damDetails.dam_designed_gross_storage_tmc,
    });
  }

  translateIntoMarathi(text) {
    if (!text) {
      return
    }

    const body = new HttpParams({
      fromObject: {
        text: text,
      }
    });

    this.http.englishToMarathi(body).pipe(debounceTime(2000)).subscribe((data: any) => {
      //	console.log(data)

      this.dammasterForm.patchValue({ dam_name_marathi: data.data })

    })
  }

  getdamType() {
    this.http.getdamType().subscribe((resolve: any) => {
      this.getdamTypeArr = resolve.data

      //  console.log(resolve.data);
    })

  }

  getdamPurposes() {
    this.http.getdamPurposes().subscribe((resolve: any) => {
      this.getdamPurposesArr = resolve.data;
      //   console.log(resolve.data);
    })

  }


  getdamStorages() {
    this.http.getdamStorages().subscribe((resolve: any) => {
      this.getdamStorageseArr = resolve.data;
      //  console.log(resolve.data);
    })

  }

  getDistrict() {
    this.http.getDistricts().subscribe((resolve: any) => {
      this.getDistArray = resolve.data
      // if (this.route.snapshot.params['id']) {
      //   this.forFamerTaluka(this.aDamDetail.dam_district_code)
      // }
    })


  }

  forFamerTaluka(data) {

    this.farmerDistrictCode = data
    const body = new HttpParams({
      fromObject: {
        district_code: data
      }
    });
    this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
      this.getFarmerTaluka = resolve.data
    })
  }

  forarmerVillage(data) {
    const body = new HttpParams({
      fromObject: {
        district_code: this.farmerDistrictCode,
        taluka_code: data
      }
    });
    this.http.getTalukaVillage(body).subscribe((resolve: any) => {
      this.getFarmerVillage = resolve.data


    })
  }

  getBasins() {
    this.http.getdamBasins().subscribe((resolve: any) => {
      this.getdamBasinsArray = resolve.data;
      // console.log(resolve.data,"====");
    })

  }

  getSubBasins(data) {

    // console.log(data,"==");
    const body = new HttpParams({
      fromObject: {
        subbasin_basin_id: data,
      }
    });
    this.http.getSubbasins(body).subscribe((resolve: any) => {
      this.getdamSubBasinsArray = resolve.data
      //console.log(resolve.data,"====");
    })

  }



  getDamMasterApprovedProjects() {
    this.http.getDamMasterProject().subscribe((resolve: any) => {
      this.projectSelect = resolve.data
      // console.log(this.projectSelect)
    })
  }

  getOfficesPrimary(parent_id, type) {
    const body = new HttpParams({
      fromObject: {
        parent_id: parent_id,
        office_type: type
      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve
      if (data1.status == "1") {
        if (type == "Division") {
          this.primary_Division = data1.data;
          console.log(this.primary_Division, "==lol")
        }
        else if (type == "Sub Division") {
          this.primary_SubDivision = data1.data
        }
        else if (type == "Section") {
          this.primary_Section = data1.data
        }
      }
    })
  }

  onItemDeselectType(event) {
    // console.log(event.dam_type_id)
    const index = this.selectedDamType.indexOf((event.dam_type_id).toString())
    this.selectedDamType.splice(index, 1)
    // console.log(this.selectedDamType)
  }

  onItemSelectType(event) {
    this.selectedDamType.push((event.dam_type_id).toString())
    // console.log(this.selectedDamType)
  }

  onItemSelectPurpose(event) {
    this.selectedDamPurpose.push((event.dam_purpose_id).toString())
  }

  onItemDeselectPurpose(event) {
    const index = this.selectedDamPurpose.indexOf((event.dam_purpose_id).toString())
    this.selectedDamPurpose.splice(index, 1)
  }

  getUserOfficeDetails() {

    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });

    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      // console.log(resolve);
      //Mantralaya.office_id


      for (let key in resolve.data.userDetail.user_office_detail) {

        this.FirstKey = key;
        //   console.log(key)
        break;
      }
      // console.log(this.FirstKey, "===ss");

      if (this.FirstKey == 'Circle') {

        this.divTextshow = false;
        this.divselectshow = true;
        this.dammasterForm.patchValue({
          dam_corporation_code: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "",
          dam_region_code: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "",
          dam_circle_code: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "",

        })
        this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Circle.office_id, 'Division')

      } else if (this.FirstKey == 'Division') {
        // console.log(this.divTextshow + "asasssssssssssssssss")
        this.dammasterForm.patchValue({
          dam_corporation_code: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "",
          dam_region_code: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "",
          dam_circle_code: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "",
          dam_division_code: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "",
        });
        this.divTextshow = true;
        this.divselectshow = false;

        ///console.log(resolve.data.userDetail.user_office_detail.Division.office_id,"====")

        this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Division.office_id, 'Sub Division')






      }
      if (data1.status == 0) {
        return false;

      } else {
        if (resolve.data) {

          // console.log(resolve.data.userDetail.user_office_detail.Corporation, "===");
          console.log(resolve.data);
          const subDivision = "Sub Division";
          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "",
            //subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : " ",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : " ",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : " ",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "",
            //  Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : ""
          }

          this.office_details_mr = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "",
            //subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : " ",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : " ",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : " ",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "",
            // Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : ""
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }

  onItemSelect(evt, type) {
    // console.log(evt.office_id + "" + type)
    if (type == "Region") {
      this.selectedSecondaryRegionIds.push((evt.office_id).toString())
      // console.log(this.selectedSecondaryRegionIds)
      this.getOffices(this.selectedSecondaryRegionIds, "Circle", "noedit")
    }
    else if (type == "Circle") {
      this.selectedSecondaryCircleIds.push((evt.office_id).toString())
      this.getOffices(this.selectedSecondaryCircleIds, "Division", "noedit")
    }
    else if (type == "Division") {
      this.selectedSecondaryDivisionIds.push((evt.office_id).toString())
      this.getOffices(this.selectedSecondaryDivisionIds, "Sub Division", "noedit")

    }
    else if (type == "Sub Division") {
      this.selectedSecondarySubDivisionIds.push((evt.office_id).toString())
      this.getOffices(this.selectedSecondarySubDivisionIds, "Section", "noedit")
    }
    else if (type == "Section") {
      this.selectedSecondarySectionIds.push((evt.office_id).toString())
    }
    else if (type == "Corporation") {
      this.selectedSecondaryCorporationIds.push((evt.office_id).toString())
      this.getOffices(this.selectedSecondaryCorporationIds, "Region", "noedit");
    }
  }

  onItemDeselect(evt, type) {
    console.log(evt);

    if (type == "Region") {
      const index = this.selectedSecondaryRegionIds.indexOf((evt.office_id).toString())
      this.selectedSecondaryRegionIds.splice(index, 1)
      this.callAllSecondaryArrReload()
    }
    else if (type == "Circle") {
      const index = this.selectedSecondaryCircleIds.indexOf((evt.office_id).toString())
      this.selectedSecondaryCircleIds.splice(index, 1)
      this.callAllSecondaryArrReload()
    }
    else if (type == "Division") {
      const index = this.selectedSecondaryDivisionIds.indexOf((evt.office_id).toString())
      this.selectedSecondaryDivisionIds.splice(index, 1)
      this.callAllSecondaryArrReload()
    }
    else if (type == "Sub Division") {
      const index = this.selectedSecondarySubDivisionIds.indexOf((evt.office_id).toString())
      this.selectedSecondarySubDivisionIds.splice(index, 1)
      this.callAllSecondaryArrReload()
    }
    else if (type == "Section") {
      const index = this.selectedSecondarySectionIds.indexOf((evt.office_id).toString())
      this.selectedSecondarySectionIds.splice(index, 1)
      console.log(this.selectedSecondarySectionIds);

    }
    else if (type == "Corporation") {
      const index = this.selectedSecondaryCorporationIds.indexOf((evt.office_id).toString())
      this.selectedSecondaryCorporationIds.splice(index, 1)
      console.log(this.selectedSecondaryCorporationIds);

      this.callAllSecondaryArrReload()

    }


  }

  callAllSecondaryArrReload() {
    this.selectedSecondaryCorporationIds.length > 0 ? this.getOffices(this.selectedSecondaryCorporationIds, "Region", "noedit") : this.secondary_Region = null

    this.selectedSecondaryRegionIds.length > 0 ? this.getOffices(this.selectedSecondaryRegionIds, "Circle", "noedit") : this.secondary_Circle = null

    this.selectedSecondaryCircleIds.length > 0 ? this.getOffices(this.selectedSecondaryCircleIds, "Divison", "noedit") : this.secondary_Division = null

    this.selectedSecondaryDivisionIds.length > 0 ? this.getOffices(this.selectedSecondaryDivisionIds, "Sub Division", "noedit") : this.secondary_SubDivision = null

    this.selectedSecondarySubDivisionIds.length > 0 ? this.getOffices(this.selectedSecondarySubDivisionIds, "Section", "noedit") : this.secondary_SubDivision = null
  }

  getOffices(parent_id, type, isEdit) {
    const body = new HttpParams({
      fromObject: {
        parent_id: parent_id,
        office_type: type
      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve
      if (data1.status == "1") {
        if (type == "Region") {
          this.secondary_Region = data1.data;

          if (isEdit == 'edit') {

            if (this.route.snapshot.params['id']) {
              var mySecondaryRegions = [];
              var selectedSecondaryRegion = []
              mySecondaryRegions = JSON.parse(this.aDamDetail.dam_secondary_region_id)
              for (let i = 0; i < resolve.data.length; i++) {
                for (let j = 0; j < mySecondaryRegions.length; j++) {
                  if (resolve.data[i].office_id == mySecondaryRegions[j]) {
                    selectedSecondaryRegion.push(resolve.data[i])
                  }
                }
              }

              //	console.log(selectedSecondaryCorporates);
              this.regionEditArray = selectedSecondaryRegion;
              this.getOffices(mySecondaryRegions, "Circle", 'edit')
            }



          }


          // console.log(this.secondary_Region);
        }
        else if (type == "Circle") {
          this.secondary_Circle = data1.data

          if (isEdit == 'edit') {

            if (this.route.snapshot.params['id']) {
              var mySecondaryCircles = [];
              var selectedSecondaryCircles = []
              mySecondaryCircles = JSON.parse(this.aDamDetail.dam_secondary_circle_id)
              for (let i = 0; i < resolve.data.length; i++) {
                for (let j = 0; j < mySecondaryCircles.length; j++) {
                  if (resolve.data[i].office_id == mySecondaryCircles[j]) {
                    selectedSecondaryCircles.push(resolve.data[i])
                  }
                }
              }

              //	console.log(selectedSecondaryCorporates);
              this.circleEditArray = selectedSecondaryCircles;

              console.log(this.circleEditArray);
              this.getOffices(mySecondaryCircles, "Division", 'edit')
            }



          }
        }
        else if (type == "Division") {
          this.secondary_Division = data1.data;


          if (isEdit == 'edit') {

            if (this.route.snapshot.params['id']) {
              var mySecondaryDivisions = [];
              var selectedSecondaryDivisions = []
              mySecondaryDivisions = JSON.parse(this.aDamDetail.dam_secondary_division_id)
              for (let i = 0; i < resolve.data.length; i++) {
                for (let j = 0; j < mySecondaryDivisions.length; j++) {
                  if (resolve.data[i].office_id == mySecondaryDivisions[j]) {
                    selectedSecondaryDivisions.push(resolve.data[i])
                  }
                }
              }
              this.DivEditArray = selectedSecondaryDivisions;
              this.getOffices(mySecondaryDivisions, "Sub Division", 'edit')
            }



          }
        }
        else if (type == "Sub Division") {
          this.secondary_SubDivision = data1.data;

          if (isEdit == 'edit') {

            if (this.route.snapshot.params['id']) {
              var mySecondarySubDivisions = [];
              var selectedSecondarySubDivisions = []
              mySecondarySubDivisions = JSON.parse(this.aDamDetail.dam_secondary_subdivision_id)
              for (let i = 0; i < resolve.data.length; i++) {
                for (let j = 0; j < mySecondarySubDivisions.length; j++) {
                  if (resolve.data[i].office_id == mySecondarySubDivisions[j]) {
                    selectedSecondarySubDivisions.push(resolve.data[i])
                  }
                }
              }
              this.SubDivEditArray = selectedSecondarySubDivisions;
              this.getOffices(mySecondarySubDivisions, "Section", 'edit')
            }



          }
        }
        else if (type == "Section") {
          this.secondary_Section = data1.data;



          if (isEdit == 'edit') {

            if (this.route.snapshot.params['id']) {
              var mySecondarySections = [];
              var selectedSecondarySections = []
              mySecondarySections = JSON.parse(this.aDamDetail.dam_secondary_section_id)
              for (let i = 0; i < resolve.data.length; i++) {
                for (let j = 0; j < mySecondarySections.length; j++) {
                  if (resolve.data[i].office_id == mySecondarySections[j]) {
                    selectedSecondarySections.push(resolve.data[i])
                  }
                }
              }
              this.SecEditArray = selectedSecondarySections;
              //this.getOffices(mySecondarySubDivisions,"Section",'edit')
            }
          }

        }
      }
    })
  }

  getCorporateOffices(val) {



    let parent_id = '1'
    const body = new HttpParams({
      fromObject: {
        parent_id: parent_id,
        office_type: 'Corporation'
      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;


      if (data1.status == "1") {

        this.secondary_corporation = data1.data;

        if (val == 'edit') {
          if (this.route.snapshot.params['id']) {
            var mySecondaryCorporates = [];
            var selectedSecondaryCorporates = []
            mySecondaryCorporates = JSON.parse(this.aDamDetail.dam_secondary_corporation_id)
            for (let i = 0; i < resolve.data.length; i++) {
              for (let j = 0; j < mySecondaryCorporates.length; j++) {
                if (resolve.data[i].office_id == mySecondaryCorporates[j]) {
                  selectedSecondaryCorporates.push(resolve.data[i])
                }
              }
            }

            //console.log(selectedSecondaryCorporates);
            this.CorporatesEditArray = selectedSecondaryCorporates;
            this.getOffices(mySecondaryCorporates, "Region", 'edit')
          }
        }





      }
      else {
      }

    });
  }

  finalSubmit() {


    var secondaryCorporations: any = JSON.stringify(this.selectedSecondaryCorporationIds);
    var secondaryDivision: any = JSON.stringify(this.selectedSecondaryDivisionIds);
    var secondaryCircle: any = JSON.stringify(this.selectedSecondaryCircleIds);
    var secondaryRegionIds: any = JSON.stringify(this.selectedSecondaryRegionIds);
    var secondarySectionIds: any = JSON.stringify(this.selectedSecondarySectionIds);
    var secondarySubDivisionIds: any = JSON.stringify(this.selectedSecondarySubDivisionIds);
    var damType: any = JSON.stringify(this.selectedDamType)
    var damPurposes: any = JSON.stringify(this.selectedDamPurpose)

    this.dammasterForm.patchValue({
      dam_secondary_corporation_id: secondaryCorporations,
      dam_secondary_region_id: secondaryDivision,
      dam_secondary_circle_id: secondaryCircle,
      dam_secondary_division_id: secondaryRegionIds,
      dam_secondary_subdivision_id: secondarySectionIds,
      dam_secondary_section_id: secondarySubDivisionIds,
      post_user_id: localStorage.getItem('post_user_id'),
      dam_added_by_user_office_type: this.FirstKey,
      dam_type: damType,
      dam_purpose: damPurposes,
      dam_user_type: localStorage.getItem('user_type')
    })
    // console.log(this.dammasterForm)
    // return;
    if (this.dammasterForm.invalid) {

      if (this.selectedlang == 'mr') {

        alert("सर्व माहिती भरणे अनिवार्य आहे.");

      } else {
        alert("All Fields are Mandatory!");

      }

      return
    }
    else {
      //this.dammasterForm.value
      const body = new HttpParams({
        fromObject: this.dammasterForm.value
      });
      this.http.createDamMaster(body).subscribe((resolve: any) => {
        if (resolve.data) {

          if (this.selectedlang == 'mr') {

            alert("सदर माहिती जतन करण्यात आलेली आहे.");

          } else {
            alert("This information has been saved");

          }
          //  this.sendAlert("Dam added successfully!")
          this.router.navigateByUrl('/viewdamMaster')
        }
        else {
          this.sendAlert(resolve.message)
        }
      })
    }
  }

  sendAlert(message) {
    switch (this.selectedlang) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          // alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }

}
