<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->


        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'WUA Master'| translate}}</h1>
            </div>
            <!-- row filter start-->
            <div class="row tablefilter">
                <!-- <div class="col-sm-6 leftfilter">Show <select><option>10</option></select> enteries</div> -->
                <div class="col-sm-6 rightfilter">

                    <!-- <input (click)="Add()" class="stepgreenbtn"  value="Add" type="button"> -->
                    <!--<select><option>Filter</option></select>-->
                    <!--
<form><input class="filtersearch" type="search"placeholder="{{'search' |translate}}" ><input class="filtersubmit" type="submit" /></form>
               -->


                </div>
            </div>
            <!-- row filter over-->

            <!-- dashboardbnnr over-->
            <!-- mid content start-->
            <div class="mid-content fullwidth tablerecordpg">
                <div class="viewpro-table">
                    <div class="viewpro-table-ab viewpro-table-ab-change">
                        <ng-container *ngIf="enableAddOption">
                            <div class="page-top-btn inline-div">

                                <input (click)="Add()" class="stepgreenbtn" type="button"
                                    value="{{'Add'| translate}}" />
                                <input (click)="exportAsXLSX()" class="topgreenbtn" type="button"
                                    value="{{'Export' | translate}}" />
                            </div>
                        </ng-container>
                        <!-- <ng-container>
                        <div class="page-top-btn inline-div"> 
                              
                            <input (click)="Add()" class="stepgreenbtn" type="button" value="{{'Add'| translate}}" />
                            <input (click)="exportAsXLSX()" class="topgreenbtn" type="button" value="{{'Export' | translate}}" />
                        </div>
                    </ng-container> -->
                    </div>

                    <!-- table datat start-->
                    <!-- <div class="viewpro-table"> -->
                    <!-- <ng-container *ngIf="enableAddOption">
                        <input (click)="Add()" class="stepgreenbtn" type="button" value="{{'Add'| translate}}" />
                        <input (click)="exportAsXLSX()" class="topgreenbtn" type="button" value="{{'Export' | translate}}" />
                    </ng-container> -->
                    <table width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <!-- <th>{{'WUA Code' | translate}}</th> -->
                                <th>{{'WUA Names' | translate}}</th>
                                <th>{{'District' | translate}}</th>
                                <th>{{'Project Name' | translate}}</th>
                                <th>{{'Dam Name' | translate}}</th>
                                <th>{{'WUA Approved' | translate}}</th>
                                <th>{{'Action' |translate}}</th>
                            </tr>
                        </thead>

                        <tfoot>
                            <tr>

                                <!-- <th><input type="text"placeholder="{{'search' |translate}}" name="search-project_name"/></th> -->
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-first-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Wua-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-District" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Taluka-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>

                                <th></th>

                            </tr>
                        </tfoot>

                        <tbody>
                            <tr *ngFor="let wuaData of wuaDataAgainstProjectCode">
                                <!-- <td>{{wuaData.wau_main_id}}</td> -->
                                <td>

                                    <ng-container *ngIf="selectedlang == 'mr';else elseWuaNameListBlock">
                                        {{wuaData.wua_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseWuaNameListBlock>
                                        {{wuaData.wua_name}}
                                    </ng-template>
                                </td>
                                <td>


                                    <ng-container *ngIf="selectedlang == 'mr';else elseDistrictNameListBlock">
                                        {{wuaData.mwrd_district_master?.district_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseDistrictNameListBlock>
                                        {{wuaData.mwrd_district_master?.district_name}}
                                    </ng-template>
                                </td>
                                <td>



                                    <ng-container *ngIf="selectedlang == 'mr';else elseProjectListBlock">
                                        {{wuaData.mwrd_project_master?.project_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectListBlock>
                                        {{wuaData.mwrd_project_master?.project_name}}
                                    </ng-template>
                                </td>
                                <td>

                                    <ng-container *ngIf="selectedlang == 'mr';else elseDamNameListBlock">
                                        {{wuaData.mwrd_dam_master?.dam_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseDamNameListBlock>
                                        {{wuaData.mwrd_dam_master?.dam_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'en';else elseApprovedBlock">
                                        {{wuaData.wua_is_approved=='0'?'No':'Yes'}}
                                    </ng-container>
                                    <ng-template #elseApprovedBlock>
                                        {{wuaData.wua_is_approved=='0'?'नाही':'होय'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ul class="tbaction">
                                        <ng-container
                                            *ngIf="wuaData.wua_is_approved=='0' && ((wuaData.wua_added_by_user_office_type=='Circle' && UserType==0) || (wuaData.dam_added_by_user_office_type == 'Division' && userOfficeType == 'Circle' && userPosition.includes('Superintending Engineer')))">
                                            <li>
                                                <i (click)="approveWua(wuaData.wua_id)" class="fa fa-check">
                                                </i>
                                            </li>
                                        </ng-container>
                                        <!--li  title="View"><i>View</i></li-->
                                        <ng-container *ngIf="wuaData.wua_is_approved=='0' && enableEditOption">
                                            <li title="Edit">
                                                <i class="" (click)=editWua(wuaData.wua_id)>
                                                    {{ 'Edit' | translate }}</i>
                                            </li>
                                        </ng-container>
                                        <li title="View">
                                            <i class="" aria-hidden="true" (click)="enableViewModel(wuaData.wua_id)">
                                                {{ 'View' | translate }}
                                            </i>
                                        </li>
                                    </ul>
                                </td>

                            </tr>
                        </tbody>



                    </table>
                </div>
                <!-- table datat over-->


            </div>
            <!-- mid content over-->

        </div>
        <!-- dashboard right panel over-->


        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                        <h2>{{'View Wua Details' | translate}}</h2>
                        <br />
                        <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                    </div>
                    <div class="modal-body">




                        <ul class="pojectmstrbx">
                            <li class="prjhead">{{'General' | translate}}</li>
                            <!-- <li>
                            <label>{{'Name of Project'|translate}}</label>
                            <div>
                                <p>{{aWuaDetails?.data?.mwrd_project_master.project_name}}</p>
                            </div>
                        </li>
                        <li>
                            <label>{{'Name of Project Marathi'|translate}}</label>
                            <div>
                                <p>{{aWuaDetails?.data?.mwrd_project_master.project_name_marathi}}</p>
                            </div>
                        </li> -->


                            <li>
                                <label>{{'Project Name' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectNameBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_project_master.project_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectNameBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_project_master.project_name}}</p>
                                    </ng-template>

                                </div>
                            </li>
                            <li>
                                <label>{{'DamName' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDamBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_dam_master.dam_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectDamBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_dam_master.dam_name}}</p>
                                    </ng-template>

                                </div>
                            </li>


                            <li class="prjhead">{{'WUA Details' | translate}}</li>

                            <li>
                                <label>{{'WUA Name' | translate}}</label>
                                <div>
                                    <p>{{aWuaDetails?.data?.wua_name}}</p>

                                </div>
                            </li>
                            <li>
                                <label>{{'WUA Marathi Name' | translate }}</label>
                                <div>
                                    <p>{{aWuaDetails?.data?.wua_name_marathi}}</p>

                                </div>
                            </li>
                            <li>
                                <label>{{'Address Of WUA' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCircleBlock;">
                                        <p>{{aWuaDetails?.data?.wua_address}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectCircleBlock>
                                        <p>{{aWuaDetails?.data?.wua_address}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Address Of WUA Marathi' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDivisionBlock;">
                                        <p>{{aWuaDetails?.data?.wua_address_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectDivisionBlock>
                                        <p>{{aWuaDetails?.data?.wua_address_marathi}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Location'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSubdivisionBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_district_master.district_name_marathi }}
                                            | {{aWuaDetails?.data?.mwrd_taluka_master.taluka_name_marathi }}
                                            | {{aWuaDetails?.data?.mwrd_village_master.village_name_marathi }}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSubdivisionBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_district_master.district_name }}
                                            | {{aWuaDetails?.data?.mwrd_taluka_master.taluka_name }}
                                            | {{aWuaDetails?.data?.mwrd_village_master.village_name }}
                                        </p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Pin Code'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elsePinCodeBlock;">
                                        <p>{{aWuaDetails?.data?.wua_pincode}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elsePinCodeBlock>
                                        <p>{{aWuaDetails?.data?.wua_pincode}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'WUA Type'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseWuaType;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_type_master.wua_type_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseWuaType>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_type_master.wua_type}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'WUA Category'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elsewuaCategory;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_category_master.wua_category_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elsewuaCategory>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_category_master.wua_category}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'Territorial Reach'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseTeritorialnBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_territorial_master.wua_territorial_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseTeritorialnBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_territorial_master.wua_territorial}}</p>
                                    </ng-template>
                                </div>
                            </li>


                            <li>
                                <label>{{'WUA Under MMISF Act/Co-operative soceity'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseWuaMisfBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_act_master.wua_act_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseWuaMisfBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_act_master.wua_act}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'Registration No' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSectionBlock1;">
                                        <!-- <p>{{aWuaDetails.data.wua_registration_no}}
                                    </p> -->
                                        <p>{{aWuaDetails?.data?.wua_registration_no}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectSectionBlock1>
                                        <p>{{aWuaDetails?.data?.wua_registration_no}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'Registration Date'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseRegistrationDateBlock;">

                                        <p>{{aWuaDetails?.data?.wua_registration_date}}</p>
                                    </ng-container>
                                    <ng-template #elseRegistrationDateBlock>
                                        <p>{{aWuaDetails?.data?.wua_registration_date}}</p>

                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'Status'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseStatusBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_status_master.wua_status_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseStatusBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_status_master?.wua_status}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li>
                                <label>{{'Measuring Device Installed'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseMeasuringBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_wua_measuring_master.wua_measuring_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseMeasuringBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_wua_measuring_master.wua_measuring}}</p>
                                    </ng-template>
                                </div>
                            </li>



                            <li class="prjhead">{{'Irrigation Details' | translate}}</li>

                            <li class="prjbglist">
                                <label>{{'Irrigation Source'|translate}}</label>
                                <!-- <div *ngIf="aWuaDetails?.project_secondary_corporations"> -->
                                <!-- <p *ngFor="let list of aWuaDetails.project_secondary_corporations"> -->
                                <ng-container *ngIf="selectedlang=='mr';else elseIrrigationBlock;">
                                    <p>{{aWuaDetails?.data?.mwrd_irrigation_source_master?.irrigation_source_marathi}}
                                    </p>
                                </ng-container>
                                <ng-template #elseIrrigationBlock>
                                    <p>{{aWuaDetails?.data?.mwrd_irrigation_source_master?.irrigation_source}}</p>
                                </ng-template>
                                <!-- </p> -->

                                <!-- </div> -->
                            </li>
                            <ng-container *ngIf="aWuaDetails?.data?.wua_irregation_source<=2">
                                <li class="prjbglist">
                                    <label>{{'Canal Type'|translate}}</label>
                                    <ng-container *ngIf="selectedlang=='mr';else elseCanalTypeBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_bank_master?.bank_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseCanalTypeBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_bank_master?.bank_name}}</p>
                                    </ng-template>
                                </li>

                                <li class="prjbglist">
                                    <label>{{'Name_Main_Canal'|translate}}</label>
                                    <!-- <div *ngIf="aWuaDetails?.project_secondary_circles"> -->
                                    <!-- <p *ngFor="let list of aWuaDetails.project_secondary_circles"> -->
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCirclesBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_main_canal_master?.main_canal_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectSecCirclesBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_main_canal_master?.main_canal_name}}</p>
                                    </ng-template>
                                    <!-- </p> -->

                                    <!-- </div> -->
                                </li>

                                <li class="prjbglist">
                                    <label>{{'Name_Branch_Canal'|translate}}</label>
                                    <!-- <div *ngIf="aWuaDetails?.project_secondary_divisions"> -->
                                    <!-- <p *ngFor="let list of aWuaDetails.project_secondary_divisions"> -->
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecDivisionsBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_branch_canal_master?.branch_canal_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSecDivisionsBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_branch_canal_master?.branch_canal_name}}</p>
                                    </ng-template>
                                    <!-- </p> -->

                                    <!-- </div> -->
                                </li>

                                <li class="prjbglist">
                                    <label>{{'Name_Distributary_Canal'|translate}}</label>
                                    <!-- <div *ngIf="aWuaDetails?.project_secondary_subdivisions"> -->
                                    <!-- <p *ngFor="let list of aWuaDetails.project_secondary_subdivisions"> -->
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSubDivisionsBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_distributory_canal_master?.distributory_canal_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSecSubDivisionsBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_distributory_canal_master?.distributory_canal_name}}
                                        </p>
                                    </ng-template>
                                    <!-- </p> -->

                                    <!-- </div> -->
                                </li>

                                <li class="prjbglist">
                                    <label>{{'Name_Minor'|translate}}</label>
                                    <!-- <div *ngIf="aWuaDetails?.project_secondary_sections"> -->
                                    <!-- <p *ngFor="let list of aWuaDetails.project_secondary_sections"> -->
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSectionsBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_minor_canal_master?.minor_canal_name_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectSecSectionsBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_minor_canal_master?.minor_canal_name}}</p>
                                    </ng-template>
                                    <!-- </p> -->

                                    <!-- </div> -->
                                </li>




                                <li class="prjbglist">
                                    <label>{{'Name_SUB_Minor'|translate}}</label>
                                    <!-- <div *ngIf="aWuaDetails?.project_secondary_sections"> -->
                                    <!-- <p *ngFor="let list of aWuaDetails.project_secondary_sections"> -->
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSectionsBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_subminor_canal_master?.subminor_canal_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSecSectionsBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_subminor_canal_master?.subminor_canal_name}}</p>
                                    </ng-template>
                                    <!-- </p> -->

                                    <!-- </div> -->
                                </li>


                                <li class="prjbglist">
                                    <label>{{'Name of Outlet'|translate}}</label>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectOutletSingleBlock;">
                                        <p>{{aWuaDetails?.data?.mwrd_outlet_canal_master?.outlet_canal_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectOutletSingleBlock>
                                        <p>{{aWuaDetails?.data?.mwrd_outlet_canal_master?.outlet_canal_name}}
                                    </ng-template>

                                </li>
                            </ng-container>



                            <!-- <li class="prjhead">Benefitted Area Details</li>

                        <li class="prjbglist">
                            <label>{{'Location'|translate}}</label>
                            <div *ngIf="aWuaDetails?.project_secondary_corporations">
                                <p *ngFor="let list of aWuaDetails.project_secondary_corporations">
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCorporationsBlock;">
                                        {{list?.office_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectSecCorporationsBlock>
                                        {{list?.office_name}}
                                    </ng-template>
                                </p>

                            </div>
                        </li>

                        <li class="prjbglist">
                            <label>{{'Delineation Area (Ha)'|translate}}</label>
                            <div *ngIf="aWuaDetails?.project_secondary_regions">
                                <p *ngFor="let list of aWuaDetails.project_secondary_regions">
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecRegionsBlock;">
                                        {{list?.office_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectSecRegionsBlock>
                                        {{list?.office_name}}
                                    </ng-template>
                                </p>

                            </div>
                        </li>

                        <li class="prjbglist">
                            <label>{{'Area of Village (Ha)'|translate}}</label>
                            <div *ngIf="aWuaDetails?.project_secondary_circles">
                                <p *ngFor="let list of aWuaDetails.project_secondary_circles">
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCirclesBlock;">
                                        {{list?.office_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectSecCirclesBlock>
                                        {{list?.office_name}}
                                    </ng-template>
                                </p>

                            </div>
                        </li>

                        <li class="prjbglist">
                            <label>{{'No. of Beneficiaries'|translate}}</label>
                            <div *ngIf="aWuaDetails?.project_secondary_divisions">
                                <p *ngFor="let list of aWuaDetails.project_secondary_divisions">
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSecDivisionsBlock;">
                                        {{list?.office_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectSecDivisionsBlock>
                                        {{list?.office_name}}
                                    </ng-template>
                                </p>

                            </div>
                        </li> -->

                            <li class="prjhead">{{'WUA Bank Details' | translate}}</li>

                            <li class="prjbglist">
                                <label>{{'Name Of bank' | translate}}</label>
                                <!-- <div *ngIf="aWuaDetails?.project_secondary_corporations"> -->
                                <!-- <p *ngFor="let list of aWuaDetails.project_secondary_corporations"> -->
                                <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCorporationsBlock;">
                                    <p>{{aWuaDetails?.data?.mwrd_indian_bank_master?.mwrd_indian_bank_marathi_name}}</p>
                                </ng-container>
                                <ng-template #elseProjectSecCorporationsBlock>
                                    <p>{{aWuaDetails?.data?.mwrd_indian_bank_master?.mwrd_indian_bank_name}}</p>
                                </ng-template>
                                <!-- </p>

                            </div> -->
                            </li>

                            <li class="prjbglist">
                                <label>{{'Name of branch' | translate}}</label>
                                <!-- <div *ngIf="aWuaDetails?.project_secondary_regions"> -->
                                <!-- <p *ngFor="let list of aWuaDetails.project_secondary_regions"> -->
                                <ng-container *ngIf="selectedlang=='mr';else elseProjectSecRegionsBlock;">
                                    <p>{{aWuaDetails?.data?.wua_bank_branch_name}}</p>
                                </ng-container>
                                <ng-template #elseProjectSecRegionsBlock>
                                    <p>{{aWuaDetails?.data?.wua_bank_branch_name}}</p>
                                </ng-template>
                                <!-- </p>

                            </div> -->
                            </li>

                            <li class="prjbglist">
                                <label>{{'Bank Account No' | translate}}</label>
                                <!-- <div *ngIf="aWuaDetails?.project_secondary_circles">
                                <p *ngFor="let list of aWuaDetails.project_secondary_circles"> -->
                                <ng-container *ngIf="selectedlang=='mr';else elseBankAccountNumber;">
                                    <p>{{aWuaDetails?.data?.wua_bank_ac_no}}</p>
                                </ng-container>
                                <ng-template #elseBankAccountNumber>
                                    <p>{{aWuaDetails?.data?.wua_bank_ac_no}}</p>
                                </ng-template>
                                <!-- </p>

                            </div> -->
                            </li>







                        </ul>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>