<!-- <script src="../../assets/js/easySelect.js"></script> -->



<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <div class="dashbordleft">
            <a class="dashbordlogo fullwidth" href=""><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
         
        </div> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <!-- header over-->
            <!-- dashboardbnnr start-->

            <!-- dashboardbnnr over-->
            <!-- mid content start-->

            <div class="greenstrip fullwidth">
                <h1>{{data.welcome_string}}</h1>

            </div>

            <div class="mid-content fullwidth formstep verify-detail-page">
                <!-- row start-->
                <div class="row">

                    <div class="ofc-detial">

                        <!-- col-md-12 start-->





                        <div class="col-md-12 recentlistbx">
                            <h2 class="smlheadh2 col-md-12"><span>{{data.title_string}}</span></h2>

                        </div>




                        <div class="stepfrmcmn stepfrm1">
                            <div>
                                <!--form-->
                                <form [formGroup]="projectMasterForm" (ngSubmit)="onSubmit(projectMasterForm)">
                                    <!-- step row start-->





                                    <div class="row frmrow">
                                        <div class="col-md-4 inptbx multislct">

                                            <label class="steplbl">{{data.name_string}}</label>



                                            <ng-multiselect-dropdown class="input-permissions" name="city"
                                                [placeholder]="data.select" [settings]="dropdownSettings"
                                                [data]="cities" formControlName="city" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot2" (onSelect)="onItemSelect2($event)"
                                                (onDeSelect)="OnItemDeSelect2($event)"
                                                (onSelectAll)="onSelectAll2($event)"
                                                (onDeSelectAll)="onDeSelectAll2($event)">
                                            </ng-multiselect-dropdown>

                                        </div>

                                        <div style="color: red;" *ngIf="errorDiv">
                                            {{data.err}}
                                        </div>

                                        <div class="col-md-4 inptbx">

                                            <label class="steplbl">{{data.type_string}}</label>




                                            <!--<mat-form-field class="input-permissions">
                                            <mat-select placeholder="" multiple #mySelect
                                            (selectionChange)='onOptionsSelected($event.value)'>
                                                <mat-select-filter [array]="permissions"  (filteredReturn)="filteredList=$event" [displayMember]="'value'" >
                                                </mat-select-filter>
                                                <mat-option *ngFor="let permission of permissions" [value]="permission"
                                                    [class.hide]="!isFiltered(permission)">
                                                    {{permission.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>-->


                                            <ng-multiselect-dropdown class="input-permissions" name="city1"
                                                [placeholder]="data.select" [settings]="dropdownSettings"
                                                [data]="proSType" formControlName="city1" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot" (onSelect)="onItemSelect($event)"
                                                (onDeSelect)="OnItemDeSelect($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)">
                                            </ng-multiselect-dropdown>




                                        </div>

                                        <div class="col-md-4 inptbx">
                                            <label class="steplbl">{{data.status_type_string}}</label>
                                            <!-- <mat-form-field class="input-permissions">
                                        <mat-select placeholder="" multiple (selectionChange)='onProjectTypeSelected($event.value)'>
                                            <mat-select-filter [array]="permissions1" (filteredReturn)="filteredList=$event" [displayMember]="'value'">
                                            </mat-select-filter>
                                            <mat-option *ngFor="let permission1 of permissions1" [value]="permission1"
                                                [class.hide]="!isFiltered1(permission1)">
                                                {{permission1.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>-->

                                            <ng-multiselect-dropdown class="input-permissions" name="city2"
                                                [placeholder]="data.select" [settings]="dropdownSettings"
                                                [data]="proType" formControlName="city2" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot1" (onSelect)="onItemSelect1($event)"
                                                (onDeSelect)="OnItemDeSelect1($event)"
                                                (onSelectAll)="onSelectAll1($event)"
                                                (onDeSelectAll)="onDeSelectAll1($event)">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <!-- <div class="col-md-4 inptbx multislct">

                                            <label class="steplbl">{{data.name_string}}</label>



                                            <ng-multiselect-dropdown class="input-permissions" name="city"
                                                [placeholder]="data.select" [settings]="dropdownSettings"
                                                [data]="cities" formControlName="city" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot2" (onSelect)="onItemSelect2($event)"
                                                (onDeSelect)="OnItemDeSelect2($event)"
                                                (onSelectAll)="onSelectAll2($event)"
                                                (onDeSelectAll)="onDeSelectAll2($event)">
                                            </ng-multiselect-dropdown>

                                        </div>

                                        <div style="color: red;" *ngIf="errorDiv">
                                            Please select the project for coresponding WUA!!!
                                        </div> -->

                                    </div>

                                    <div class="clear clerfix"></div>
                                    <div class="row frmrow projectlistsec">
                                        <!-- <div class="recentlistbx fullwidth">
                                            <h2 class="smlheadh2 fullwidth"><span>{{data.name_string}}</span></h2>
                                        </div> -->
                                        <!--prject list bx start-->


                                        <div class="projectlisttbl col-md-12 vertiscrolltbl position-sticky">
                                            <table>
                                                <tr>
                                                    <th></th>
                                                    <th style="width: 275px;">{{data.name_string}}</th>
                                                    <th>{{data.status_string}}</th>
                                                    <th>{{data.type_string}}</th>
                                                    <th *ngIf="wuaRadioShow" style="width: 275px;">{{data.isWua}} </th>
                                                    <th *ngIf="showDrop"> WUA </th>
                                                </tr>

                                                <tr *ngFor="let details of projectList1; let i = index;">
                                                    <td><input [(ngModel)]="details.isSelected"
                                                            id="prochk{{details.project_id}}"
                                                            (change)="getProjectId(details.isSelected,details.project_id,i)"
                                                            formControlName="projectId" type="checkbox"
                                                            value="{{details.project_id}}" /></td>
                                                    <td *ngIf="this.selectedlang === 'en'">{{details.project_name}}</td>
                                                    <td *ngIf="this.selectedlang === 'en'">
                                                        {{details.mwrd_project_status_master.project_status}}</td>
                                                    <td *ngIf="this.selectedlang === 'en'">
                                                        {{details.mwrd_project_type_master.project_type}}</td>
                                                    <td *ngIf="this.selectedlang === 'mr'">
                                                        {{details.project_name_marathi}}</td>
                                                    <td *ngIf="this.selectedlang === 'mr'">
                                                        {{details.mwrd_project_status_master.project_status_marathi}}
                                                    </td>
                                                    <td *ngIf="this.selectedlang === 'mr'">
                                                        {{details.mwrd_project_type_master.project_type_marathi}}</td>
                                                    <td id="{{'radioshow'+details.project_id}}" style="display: none;">

                                                        <input type="radio" id="No{{details.project_id}}"
                                                            name="isWUA{{details.project_id}}" value="No"
                                                            (click)="getWUASelect(0,details.project_id,details.wua_id)">
                                                        <label for="No">{{data.No}}</label>
                                                        <input type="radio" id="Yes{{details.project_id}}"
                                                            name="isWUA{{details.project_id}}" value="Yes"
                                                            (click)="getWUASelect(1,details.project_id,details.wua_id)">
                                                        <label for="Yes">{{data.Yes}}</label><br>

                                                        <br>
                                                    </td>
                                                    <!--id="{{ 'show' + details.project_id }}"-->

                                                    <!--  <td id="{{'show'+details.project_id}}" style="display: none;">
                                                        <select required id="{{'wuaSelect'+details.project_id}}"
                                                            formControlName="wuaList" class="input-permissions"
                                                            multiple   (change)="getwuaId(details.project_id)">
                    
                                                        </select>
                                                    </td>-->
                                                </tr>



                                            </table>
                                        </div>

                                        <!--prject list bx over-->
                                        <div class="modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
                                            aria-labelledby="myModalLabel" aria-hidden="true"
                                            style="overflow: auto!important;">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h2>{{data.WUAList}}</h2><br />


                                                        <button type="button" class="close" data-dismiss="modal"
                                                            (click)="close('mainCanel')">&times;
                                                        </button>


                                                    </div>
                                                    <div class="modal-body popupcheckbox">
                                                        <!-- [formGroup]="PopLayoutForm" -->
                                                        <form [formGroup]="projectMasterForm">

                                                            {{data.Filter}} :

                                                            <ng-container *ngIf="this.selectedlang === 'en'">
                                                                <input #filterWualistText
                                                                    (keyup)="filterWualList(filterWualistText.value)"
                                                                    type="text" class="clear">

                                                            </ng-container>

                                                            <ng-container *ngIf="this.selectedlang === 'mr'">
                                                                <input #filterWualistText
                                                                    (keyup)="filterWualListMarathi(filterWualistText.value)"
                                                                    type="text" class="clear">

                                                            </ng-container>
                                                            <ul>

                                                                <ng-container *ngIf="this.selectedlang === 'mr'">

                                                                    <li
                                                                        *ngFor="let wua of filterWuaList; let i = index;">
                                                                        <!-- <label class="label"> -->
                                                                        <!-- Name -->
                                                                        <!-- </label> -->
                                                                        <input class="input-box" name="SelectedEntity"
                                                                            id="chk{{wua.wua_id}}"
                                                                            formControlName="wuaId"
                                                                            [(ngModel)]="wua.isSelected1"
                                                                            type="checkbox"
                                                                            (change)="getwuaId(wua.isSelected1,wua.wua_id,wua.wua_project_primary_id)"
                                                                            value="{{wua.wua_id}}">{{wua.wua_name_marathi}}<br />
                                                                        <br />
                                                                    </li>
                                                                </ng-container>


                                                                <ng-container *ngIf="this.selectedlang === 'en'">
                                                                    <li
                                                                        *ngFor="let wua of filterWuaList; let i = index;">
                                                                        <!-- <label class="label"> -->
                                                                        <!-- Name -->
                                                                        <!-- </label> -->
                                                                        <input class="input-box" name="SelectedEntity"
                                                                            id="chk{{wua.wua_id}}"
                                                                            formControlName="wuaId"
                                                                            [(ngModel)]="wua.isSelected1"
                                                                            type="checkbox"
                                                                            (change)="getwuaId(wua.isSelected1,wua.wua_id,wua.wua_project_primary_id)"
                                                                            value="{{wua.wua_id}}">{{wua.wua_name}}<br />
                                                                        <br />
                                                                    </li>
                                                                </ng-container>

                                                            </ul>
                                                            <input (click)="close('mainCanel')" class="stepgreenbtn"
                                                                type="button" value="{{data.save}}" />

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- col-md-12 over-->
                                    </div>

                                    <div class="row frmrow">




                                    </div>


                                    <div class="row frmrow">



                                    </div>

                                    <div class="row frmrow">

                                        <div class="col-md-12 inptbx">
                                            <input class="stepgreenbtn" type="Submit" value="{{data.confirm_string}}" />
                                            <input (click)="back()" class="stepgreenbtn" type="Submit"
                                                value="{{data.back_string}}" />
                                            <!-- <input  (click)="skip()" class="stepgreenbtn" type="Skip" value="{{data.skip}}" /> -->
                                            <span *ngIf="skipDiv">
                                                <input (click)="skip()" class="stepgreenbtn" type="Skip"
                                                    value="{{data.skip}}" />
                                            </span>
                                            <div></div>

                                            <!-- <input (click)="reset()" class="stepgreenbtn" type="button" value="{{data.reset_string}}" /> -->


                                            <!-- <button type=button class="btn btn-primary"  (click)="clickMethod('name')">Confirm Submit</button>-->



                                        </div>

                                    </div>
                                    <!-- step row over-->
                                </form>
                            </div>

                        </div>


                    </div>


                    <!-- row over-->

                    <!-- footer start-->
                    <footer class="dboardfooter fullwidth">
                        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                    </footer>
                    <!-- footer over-->

                </div>
                <!-- mid content over-->

            </div>
            <!-- dashboard right panel over-->
        </div>
    </div>