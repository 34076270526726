import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { SharedService } from '../_service/shared.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verify-details',
  templateUrl: './verify-details.component.html',
  styleUrls: ['./verify-details.component.css']
})
export class VerifyDetailsComponent implements OnInit {


  userName = localStorage.getItem('userName');
  selectedlang = localStorage.getItem('selected_lang');
  test: any
  post_user_id: any
  circle: any
  subDivis: any
  Mantralaya: any
  Region: any
  Division: any
  Corporation: any;
  cirShow: boolean;
  subShow: boolean;
  manShow: boolean;
  regShow: boolean;
  divShow: boolean;
  corShow: boolean;
  isDisabled1: boolean = false;
  isDisabled2: boolean = false;
  isDisabled3: boolean = false;
  isDisabled4: boolean = false;
  isDisabled5: boolean = false;
  isDisabled6: boolean = false;
  mantralyaModel: any;
  DivisioneEx: any;
  subDivisEx: any;
  MantralayaEx: any;
  RegionEx: any;
  circleEx: any;
  CorporationEx: any;
  show: boolean = true;
  MantraId: any;
  MantralayaArr: any;
  CoprationArr: any = [];
  mySelect: any;
  RegionArr: any;
  CircleArr: any;
  DivisionArr: any;
  SubDivArr: any;
  mySelect1: any;
  mySelect2: any;
  secShow: boolean;
  Section: any;
  mySelect3: any;
  sectionShow: boolean;
  SectionArr: any;
  circleId: any;
  subDivisId: any;
  RegionId: any;
  DivisionId: any;
  CorporationId: any;
  SectionId: any;
  mySelect4: any;
  disabled: any = true;
  ifEdit = false;
  CorporationNew: any;
  RegionNew: any;
  circleNew: any;
  DivisionNew: any;
  subDivisNew: any;
  clickEventsubscription: Subscription;
  data: any = {};
  en_data = {
    title: this.userName + "Welcome to MWRD's Farmer Master Database",
    offc_string: "Office Details",
    form_string: "From the data filled in e-service book, your offices are as below",
    request_string: "Your request has been submitted,it's under process",
    mantralaya_string: "Mantralaya",
    corporation_string: "Corporation",
    region_string: "Region",
    circle_string: "Circle",
    division_string: "Division",
    sub_division_string: "Sub-Division",
    section_string: "Section",
    confirm: "Confirm",
    edit_string: "Edit",
    cancel_string: "Cancel",
    confirm_string: "Are you sure to submit details ",
    save: "Save",
    Ok: "OK",
    please_select: "Please Select"
  }
  mr_data = {
    title: this.userName + " यांचे जलसंपदा विभाग, महाराष्ट्र शासन यांच्या मास्टर डेटाबेस प्रणालीमध्ये स्वागत आहे.",
    dams_string: "धरण",
    offc_string: "कार्यालयाची माहिती",
    form_string: "ई-सर्व्हीस बुक मधिल नोंदीनुसार आपल्या कार्यालयाची माहिती खालीलप्रमाणे आहे",
    request_string: "आपली विनंती सबमिट केली गेली आहे, त्यावर प्रक्रिया चालू आहे",
    mantralaya_string: "मंत्रालय",
    corporation_string: "महामंडळ",
    region_string: "प्रादेशिक कार्यालय",
    circle_string: "मंडळ",
    division_string: "विभाग",
    sub_division_string: "उपविभाग",
    section_string: "शाखा कार्यालय",
    confirm: "जतन करा",
    edit_string: "दुरुस्त करणे",
    cancel_string: "नाही",
    confirm_string: "सदर माहिती जतन करणेबाबत आपली खात्री आहे का?",
    save: "जतन करा",
    Ok: "होय",
    please_select: "निवडा"
  }
  office_details_en: any = {};
  office_details_mr: any = {};
  mySelect5: any;
  sectionNew: any;
  userOfficeType: string;
  userPosition: string;

  close() {
    $("#preModal").hide();
  }

  editValue(id) {
    $("#preModal").show()
    if (id == 1) {
      this.isDisabled1 = false;

    } else if (id == 2) {
      this.isDisabled2 = false;

    } else if (id == 3) {
      this.isDisabled3 = false;

    } else if (id == 4) {
      this.isDisabled4 = false;

    }
    else if (id == 5) {
      this.isDisabled5 = false;
    } else {
      this.isDisabled6 = false;

    }




  }

  noteditValue(id) {

    if (id == 1) {
      this.isDisabled1 = false;

    } else if (id == 2) {
      this.isDisabled2 = false;

    } else if (id == 3) {
      this.isDisabled3 = false;

    } else if (id == 4) {
      this.isDisabled4 = false;

    }
    else if (id == 5) {

      this.isDisabled5 = false;
    } else {
      this.isDisabled6 = false;

    }



  }

  getDashboard() {
    this.router.navigate(['verifyDetails']);

  }
  cancelupdate() {

    $("#preModal1").hide();

  }

  okupdate() {

    this.disabled = !this.disabled;
    this.router.navigate(['verifyProject']);

  }
  closeUp() {
    $("#preModal1").hide();

  }
  NextprojectScreen(name: string) {


    // $()
    $("#preModal1").show();


    // if (confirm(this.data.label.confirm_string)) {
    //   this.disabled = !this.disabled;
    //   this.router.navigate(['verifyProject']);
    // }

  }
  cancel() {
    this.show = true;
    this.ifEdit = false;
    this.ngOnInit();
    this.router.navigate(['verifyDetails']);
  }
  clickMethod(name: string) {
    this.doUpdateOfficeDetails()
  }


  //   test(a) {
  //     var x = (a.value || a.options[a.selectedIndex].value);  //crossbrowser solution =)
  //     alert(x);
  // }
  getChangeOfficeDetails(id, value) {
    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });




    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }
          if (value == 'Corporation') {
            //    this.CoprationArr = [];
            this.CoprationArr = office_data;

          } if (value == 'Region')

            this.RegionArr = office_data;
          if (value == 'Circle')

            this.CircleArr = office_data;
          if (value == 'Division')

            this.DivisionArr = office_data;
          if (value == 'Sub Division')

            this.SubDivArr = office_data;

          if (value == 'Section')
            this.SectionArr = office_data;

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }
  onChangeValueForOffice(region, $event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    this.RegionArr = [];
    this.CircleArr = [];
    this.DivisionArr = [];
    this.SubDivArr = [];
    this.SectionArr = [];

    let id = this.mySelect;
    this.CorporationNew = id;
    this.getChangeOfficeDetails(id, region);

  }

  onChangeValueForRegion(region, $event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    let id = this.mySelect1;
    this.RegionNew = id;
    this.getChangeOfficeDetails(id, region);

  }


  onChangeValueForCircle(region, $event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    //this.circle = text;

    let id = this.mySelect2;
    ///  console.log(id);
    this.circleNew = id;
    this.getChangeOfficeDetails(id, region);

  }


  onChangeValueForDiv(region, $event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    let id = this.mySelect4;
    this.DivisionNew = id;
    this.getChangeOfficeDetails(id, region);

  }
  onChangeValueForSection(region, $event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    let id = this.mySelect3;
    this.subDivisNew = id;
    this.getChangeOfficeDetails(id, region);


  }

  onChangeValueLast($event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    let id = this.mySelect5;
    this.sectionNew = id;



  }



  EditMethod(name: string) {

    console.log(this.userOfficeType);

    //  this.userOfficeType ='Region';

    if (this.userOfficeType == 'Division') {

      // console.log("jhjj")


      this.cirShow = true;
      this.subShow = false;
      this.manShow = true;
      this.regShow = true;
      this.divShow = true;
      this.corShow = true;
      this.ifEdit = true;
      this.show = false;
      this.sectionShow = false;


    } else if (this.userOfficeType == 'Circle') {

      this.cirShow = true;
      this.subShow = false;
      this.manShow = true;
      this.regShow = true;
      this.divShow = false;
      this.corShow = true;
      this.ifEdit = true;
      this.show = false;
      this.sectionShow = false;
    }
    else if (this.userOfficeType == 'Region') {

      this.cirShow = false;
      this.subShow = false;
      this.manShow = true;
      this.regShow = true;
      this.divShow = false;
      this.corShow = true;
      this.ifEdit = true;
      this.show = false;
      this.sectionShow = false;

    }
    else if (this.userOfficeType == 'Sub Division') {

      this.cirShow = true;
      this.subShow = true;
      this.manShow = true;
      this.regShow = true;
      this.divShow = true;
      this.corShow = true;
      this.ifEdit = true;
      this.show = false;
      this.sectionShow = false;

    }
    else if (this.userOfficeType == 'Section') {


      this.cirShow = true;
      this.subShow = true;
      this.manShow = true;
      this.regShow = true;
      this.divShow = true;
      this.corShow = true;
      this.ifEdit = true;
      this.sectionShow = true;
      this.show = false;

    }
    else if (this.userOfficeType == 'Corporation') {

      this.cirShow = false;
      this.subShow = false;
      this.manShow = true;
      this.regShow = false;
      this.divShow = false;
      this.corShow = true;
      this.sectionShow = false;
      this.ifEdit = true;
      this.show = false;



    }





    // this.cirShow = true;
    // this.subShow = true;
    // this.manShow = true;
    // this.regShow = true;
    // this.divShow = true;
    // this.corShow = true;
    // this.ifEdit = true;
    // this.show = false;

    this.getChangeOfficeDetails(this.MantraId, 'Corporation');
  }
  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    private sharedService: SharedService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      this.selectedlang = localStorage.getItem('selected_lang');
      this.getDataOnChangeMarathi();

      //this.getChangeOfficeDetails(this.MantraId, 'Corporation');
    })
  }


  oknextupdate() {


    this.router.navigate(['verifyDetails']);
    this.show = true
    this.ifEdit = false
    this.disabled = !this.disabled;
    $("#preModal").hide();

  }
  doUpdateOfficeDetails() {





    if (this.userOfficeType == 'Division') {


      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else if (!this.RegionNew || this.RegionNew == 'Please Select' || this.RegionNew == 'निवडा') {

        // alert("Select Region ");

        if (this.selectedlang == 'en')
          alert("Please Select Region Office");
        else
          alert("कृपया प्रादेशिक कार्यालय निवडा")
        return false;


      } else if (!this.circleNew || this.circleNew == 'Please Select' || this.circleNew == 'निवडा') {

        // alert("data.datacount");

        if (this.selectedlang == 'en')
          alert("Please data.datacountOffice");
        else
          alert("कृपया मंडळ कार्यालय निवडा")
        return false;


      } else if (!this.DivisionNew || this.DivisionNew == 'Please Select' || this.DivisionNew == 'निवडा') {

        // alert("Select Division ");
        if (this.selectedlang == 'en')
          alert("Please Select Division Office");
        else
          alert("कृपया विभागीय कार्यालय निवडा")
        return false;



      } else {
        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }




    } else if (this.userOfficeType == 'Circle') {



      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else if (!this.RegionNew || this.RegionNew == 'Please Select' || this.RegionNew == 'निवडा') {

        // alert("Select Region ");

        if (this.selectedlang == 'en')
          alert("Please Select Region Office");
        else
          alert("कृपया प्रादेशिक कार्यालय निवडा")
        return false;


      } else if (!this.circleNew || this.circleNew == 'Please Select' || this.circleNew == 'निवडा') {

        // alert("data.datacount");

        if (this.selectedlang == 'en')
          alert("data.datacount");
        else
          alert("निवडा मंडळ")
        return false;


      }
      else {

        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }


    }
    else if (this.userOfficeType == 'Region') {



      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else if (!this.RegionNew || this.RegionNew == 'Please Select' || this.RegionNew == 'निवडा') {

        // alert("Select Region ");

        if (this.selectedlang == 'en')
          alert("Please Select Region Office");
        else
          alert("कृपया प्रादेशिक कार्यालय निवडा")
        return false;


      } else {
        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }



    }
    else if (this.userOfficeType == 'Sub Division') {



      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else if (!this.RegionNew || this.RegionNew == 'Please Select' || this.RegionNew == 'निवडा') {

        // alert("Select Region ");

        if (this.selectedlang == 'en')
          alert("Please Select Region Office");
        else
          alert("कृपया प्रादेशिक कार्यालय निवडा")
        return false;


      } else if (!this.circleNew || this.circleNew == 'Please Select' || this.circleNew == 'निवडा') {

        // alert("data.datacount");

        if (this.selectedlang == 'en')
          alert("Please Select Circle Office");
        else
          alert("कृपया मंडळ कार्यालय निवडा")
        return false;


      } else if (!this.DivisionNew || this.DivisionNew == 'Please Select' || this.DivisionNew == 'निवडा') {

        // alert("Select Division ");
        if (this.selectedlang == 'en')
          alert("Please Select Division Office");
        else
          alert("कृपया विभागीय कार्यालय निवडा")
        return false;



      } else if (!this.subDivisNew || this.subDivisNew == 'Please Select' || this.subDivisNew == 'निवडा') {

        // alert("Select SubDivision ");
        if (this.selectedlang == 'en')
          alert("Please Select Sub Division Office");
        else
          alert("कृपया उपविभागीय कार्यालय निवडा")
        return false;


      }

      else {
        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }


    }
    else if (this.userOfficeType == 'Section') {



      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else if (!this.RegionNew || this.RegionNew == 'Please Select' || this.RegionNew == 'निवडा') {

        // alert("Select Region ");

        if (this.selectedlang == 'en')
          alert("Please Select Region Office");
        else
          alert("कृपया प्रादेशिक कार्यालय निवडा")
        return false;


      } else if (!this.circleNew || this.circleNew == 'Please Select' || this.circleNew == 'निवडा') {

        // alert("data.datacount");

        if (this.selectedlang == 'en')
          alert("Please Select Circle Office");
        else
          alert("कृपया मंडळ कार्यालय निवडा")
        return false;


      } else if (!this.DivisionNew || this.DivisionNew == 'Please Select' || this.DivisionNew == 'निवडा') {

        // alert("Select Division ");
        if (this.selectedlang == 'en')
          alert("Please Select Division Office");
        else
          alert("कृपया विभागीय कार्यालय निवडा")
        return false;



      } else if (!this.subDivisNew || this.subDivisNew == 'Please Select' || this.subDivisNew == 'निवडा') {

        // alert("Select SubDivision ");
        if (this.selectedlang == 'en')
          alert("Please Select Sub Division Office");
        else
          alert("कृपया उपविभागीय कार्यालय निवडा")
        return false;


      }
      else if ((!this.sectionNew || this.sectionNew == 'Please Select' || this.sectionNew == 'निवडा') && this.sectionShow == true) {

        if (this.selectedlang == 'en')
          alert("Please Select Section Office");
        else
          alert("कृपया शाखा कार्यालय निवडा")

        return false;

      } else {
        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }



    }
    else if (this.userOfficeType == 'Corporation') {




      if (!this.CorporationNew || this.CorporationNew == 'Please Select' || this.CorporationNew == 'निवडा') {
        if (this.selectedlang == 'en')
          alert("Please Select Corporation Office");
        else
          alert("कृपया महामंडळ कार्यालय निवडा")
        return false;
      } else {
        let post_user_id = localStorage.getItem('post_user_id');
        const body = new HttpParams({
          fromObject: {

            post_user_id: post_user_id,
            existing_mantralaya_office: this.MantraId,
            existing_corporation_office: this.CorporationId,
            existing_region_office: this.RegionId,
            existing_circle_office: this.circleId,
            existing_division_office: this.DivisionId,
            existing_subdivision_office: this.subDivisId,
            existing_section_office: this.SectionId,

            modified_mantralaya_office: this.MantraId,
            modified_corporation_office: this.CorporationNew,
            modified_region_office: this.RegionNew,
            modified_circle_office: this.circleNew,
            modified_division_office: this.DivisionNew,
            modified_subdivision_office: this.subDivisNew,
            modified_section_office: this.sectionNew,

          }
        });




        this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

          let data1 = resolve;
          if (data1.status == 0) {
            return false;
          } else {

            $("#preModal").show();

            //alert(this.data.label.request_string)

            // this.router.navigate(['verifyDetails']);
            // this.show = true
            // this.ifEdit = false
            // this.disabled = !this.disabled;
          }

        },
          (err) => {

            console.log(err);


          }
        )
      }







    } else {


      // let post_user_id = localStorage.getItem('post_user_id');
      // const body = new HttpParams({
      //   fromObject: {

      //     post_user_id: post_user_id,
      //     existing_mantralaya_office: this.MantraId,
      //     existing_corporation_office: this.CorporationId,
      //     existing_region_office: this.RegionId,
      //     existing_circle_office: this.circleId,
      //     existing_division_office: this.DivisionId,
      //     existing_subdivision_office: this.subDivisId,
      //     existing_section_office: this.SectionId,

      //     modified_mantralaya_office: this.MantraId,
      //     modified_corporation_office: this.CorporationNew,
      //     modified_region_office: this.RegionNew,
      //     modified_circle_office: this.circleNew,
      //     modified_division_office: this.DivisionNew,
      //     modified_subdivision_office: this.subDivisNew,
      //     modified_section_office: this.sectionNew,

      //   }
      // });




      // this.http.UpdateOfficeDetails(body).subscribe((resolve: any) => {

      //   let data1 = resolve;
      //   if (data1.status == 0) {
      //     return false;
      //   } else {

      //     $("#preModal").show();

      //     //alert(this.data.label.request_string)

      //     // this.router.navigate(['verifyDetails']);
      //     // this.show = true
      //     // this.ifEdit = false
      //     // this.disabled = !this.disabled;
      //   }

      // },
      //   (err) => {

      //     console.log(err);


      //   }
      // )
    }
  }

  getDataOnChangeMarathi() {


    this.CoprationArr = [];
    this.RegionArr = [];
    this.CircleArr = [];
    this.DivisionArr = [];
    this.SubDivArr = [];
    this.SectionArr = [];
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        for (let key in resolve.data.userDetail.user_office_detail) {
        }
        if (resolve.data) {


          if (resolve.data.userDetail.user_office_change_request) {

            this.disabled = !this.disabled;



          } else {
            this.disabled = this.disabled;


          }
          const subDivision = "Sub Division";

          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : " ",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : " ",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : " ",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : ""
          }

          this.office_details_mr = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : " ",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : " ",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : " ",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : ""
          }



          this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
          this.circleId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
          this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
          this.RegionId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
          this.DivisionId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
          this.CorporationId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
          this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";
          switch (this.selectedlang) {
            case "en":
              this.data.label = this.en_data;
              this.data.office_detail = this.office_details_en;
              break;
            case "mr":
              this.data.label = this.mr_data;
              this.data.office_detail = this.office_details_mr;
              break;
          }

          this.getChangeOfficeDetails(this.MantraId, 'Corporation');

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }


  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post

      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division
      if (this.userOfficeType == "Division" || this.userOfficeType == "Circle" || this.userOfficeType == "Mantralaya" || this.userOfficeType == "Region" || this.userOfficeType == "Corporation") {



      }
      else {

      }


    });
  }

  skip() {
    this.router.navigate(['verifyProject']);
  }
  ngOnInit(): void {

    this.getUserDetails();

    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });


    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;

      } else {
        for (let key in resolve.data.userDetail.user_office_detail) {
          // console.log(key)
          //  return false
        }
        if (resolve.data) {
          if (resolve.data.userDetail.user_office_change_request) {
            this.disabled = !this.disabled;
          } else {
            this.disabled = this.disabled;

          }
          const subDivision = "Sub Division";

          this.cirShow = false;
          this.subShow = false;
          this.manShow = false;
          this.regShow = false;
          this.divShow = false;
          this.corShow = false;
          this.sectionShow = false;

          //  console.log(resolve.data.userDetail.user_office_detail.Circle.office_name);

          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : "",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : "",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : "",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : ""
          }

          this.office_details_mr = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : "",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : "",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : "",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : ""
          }


          this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
          this.circleId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
          this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
          this.RegionId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
          this.DivisionId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
          this.CorporationId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
          this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";


          switch (this.selectedlang) {
            case "en":
              this.data.label = this.en_data;
              this.data.office_detail = this.office_details_en;
              break;
            case "mr":
              this.data.label = this.mr_data;
              this.data.office_detail = this.office_details_mr;
              break;
          }

          if (this.data.office_detail.Corporation) {
            this.corShow = true;

          }

          if (this.data.office_detail.circle) {

            this.cirShow = true;

          }
          if (this.data.office_detail.subDivis) {
            this.subShow = true;

          }
          if (this.data.office_detail.Mantralaya) {
            this.manShow = true;
          }
          if (this.data.office_detail.Region) {
            this.regShow = true;

          }
          if (this.data.office_detail.Division) {

            //  console.log(this.data.office_detail.Division,'====');
            this.divShow = true;

          }

          if (this.SectionId) {
            this.sectionShow = true;

            this.data.office_detail.Section = this.data.office_detail.Section

          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }

}

