import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../_service/shared.service';
import { HttpRequestService } from '../_service/http-request.service';
import { ToastrService } from 'ngx-toastr';
import Pusher from 'pusher-js';
import { resolve } from 'dns';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  fontSize = 14;
  color = 'pink';
  size = 16;
  FontIncreseDecrese: Subscription;
  @ViewChild('para', { static: true }) para: ElementRef;
  userName = localStorage.getItem('userName');
  userEmail = localStorage.getItem('userEmail');
  langList = ["Marathi", "English"];
  selectedlang: any = null;
  selectedLangOld;
  clickEventsubscription: Subscription;
  language: string;
  pusherClient: Pusher
  showNotificationBlock: boolean = false
  userNotificationsCount: Number = 0
  userNotifications: any;
  public static marathi_datatables = {
    processing: "प्रक्रिया करीत आहे...",
    search: "शोधा:",
    lengthMenu: "नोंदी _MENU_ दाखवा",
    info: "नोंदी _START_ करण्यासाठी _END_ च्या _TOTAL_ दाखवा",
    infoEmpty: "नोंदी 0 करण्यासाठी 0 च्या 0 दाखवा",
    infoFiltered: "(फिल्टर केलेले _MAX_ एकूण घटक)",
    infoPostFix: "",
    loadingRecords: "रेकॉर्ड लोड करीत आहे...",
    zeroRecords: "कोणतीही रेकॉर्ड आढळली नाहीत",
    emptyTable: "टेबलमध्ये कोणताही डेटा उपलब्ध नाही",
    paginate: {
      first: "पहिला",
      previous: "मागील",
      next: "पुढे",
      last: "शेवटचा"
    },
    aria: {
      sortAscending: ": चढत्या क्रमाने टेबलची क्रमवारी लावण्यासाठी सक्रिय करा",
      sortDescending: ":उतरत्या क्रमाने सारणीची क्रमवारी लावण्यासाठी सक्रिय करा"
    }
  }

  public static english_datatables = {
    "emptyTable": "No data available in table",
    "info": "Showing _START_ to _END_ of _TOTAL_ entries",
    "infoEmpty": "Showing 0 to 0 of 0 entries",
    "infoFiltered": "(filtered from _MAX_ total entries)",
    "infoThousands": ",",
    "lengthMenu": "Show _MENU_ entries",
    "loadingRecords": "Loading...",
    "processing": "Processing...",
    "search": "Search:",
    "zeroRecords": "No matching records found",
    "thousands": ",",
    "paginate": {
      "first": "First",
      "last": "Last",
      "next": "Next",
      "previous": "Previous"
    },
    "aria": {
      "sortAscending": ": activate to sort column ascending",
      "sortDescending": ": activate to sort column descending"
    },
    "autoFill": {
      "cancel": "Cancel",
      "fill": "Fill all cells with <i>%d<\/i>",
      "fillHorizontal": "Fill cells horizontally",
      "fillVertical": "Fill cells vertically"
    },
    "buttons": {
      "collection": "Collection <span class='ui-button-icon-primary ui-icon ui-icon-triangle-1-s'\/>",
      "colvis": "Column Visibility",
      "colvisRestore": "Restore visibility",
      "copy": "Copy",
      "copyKeys": "Press ctrl or u2318 + C to copy the table data to your system clipboard.<br><br>To cancel, click this message or press escape.",
      "copySuccess": {
        "1": "Copied 1 row to clipboard",
        "_": "Copied %d rows to clipboard"
      },
      "copyTitle": "Copy to Clipboard",
      "csv": "CSV",
      "excel": "Excel",
      "pageLength": {
        "-1": "Show all rows",
        "1": "Show 1 row",
        "_": "Show %d rows"
      },
      "pdf": "PDF",
      "print": "Print"
    },
    "searchBuilder": {
      "add": "Add Condition",
      "button": {
        "0": "Search Builder",
        "_": "Search Builder (%d)"
      },
      "clearAll": "Clear All",
      "condition": "Condition",
      "conditions": {
        "date": {
          "after": "After",
          "before": "Before",
          "between": "Between",
          "empty": "Empty",
          "equals": "Equals",
          "not": "Not",
          "notBetween": "Not Between",
          "notEmpty": "Not Empty"
        },
        "moment": {
          "after": "After",
          "before": "Before",
          "between": "Between",
          "empty": "Empty",
          "equals": "Equals",
          "not": "Not",
          "notBetween": "Not Between",
          "notEmpty": "Not Empty"
        },
        "number": {
          "between": "Between",
          "empty": "Empty",
          "equals": "Equals",
          "gt": "Greater Than",
          "gte": "Greater Than Equal To",
          "lt": "Less Than",
          "lte": "Less Than Equal To",
          "not": "Not",
          "notBetween": "Not Between",
          "notEmpty": "Not Empty"
        },
        "string": {
          "contains": "Contains",
          "empty": "Empty",
          "endsWith": "Ends With",
          "equals": "Equals",
          "not": "Not",
          "notEmpty": "Not Empty",
          "startsWith": "Starts With"
        }
      },
      "data": "Data",
      "deleteTitle": "Delete filtering rule",
      "leftTitle": "Outdent Criteria",
      "logicAnd": "And",
      "logicOr": "Or",
      "rightTitle": "Indent Criteria",
      "title": {
        "0": "Search Builder",
        "_": "Search Builder (%d)"
      },
      "value": "Value"
    },
    "searchPanes": {
      "clearMessage": "Clear All",
      "collapse": {
        "0": "SearchPanes",
        "_": "SearchPanes (%d)"
      },
      "count": "{total}",
      "countFiltered": "{shown} ({total})",
      "emptyPanes": "No SearchPanes",
      "loadMessage": "Loading SearchPanes",
      "title": "Filters Active - %d"
    },
    "select": {
      "1": "%d row selected",
      "_": "%d rows selected",
      "cells": {
        "1": "1 cell selected",
        "_": "%d cells selected"
      },
      "columns": {
        "1": "1 column selected",
        "_": "%d columns selected"
      }
    }
  }

  constructor(private router: Router, private commonService: CommonService, public translate: TranslateService, private sharedService: SharedService, private cdr: ChangeDetectorRef, private toastr: ToastrService, private http: HttpRequestService,
    private ngxService: NgxUiLoaderService) {



    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      this.selectedlang = localStorage.getItem('selected_lang');

      this.ngOnInit();
    })

    // this.FontIncreseDecrese =  this.sharedService.getFontChangeEvent().subscribe((fontSize) => {

    //   (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${fontSize}px`;




    // });

    // console.log(localStorage.getItem('userEmail'));
    this.selectedLangOld = localStorage.getItem('selected_lang');
    //  console.log(this.selectedLangOld);
    if (this.selectedLangOld === undefined || this.selectedLangOld == null) {
      this.selectedlang = "mr"; //this.langList[0];
      localStorage.setItem('selected_lang', 'mr');
    } else {
      this.selectedlang = this.selectedLangOld;
    }
    Pusher.logToConsole = true;
    // this.pusherClient = new Pusher('136fba6f5c6d3a667ff7', { cluster: 'ap2' });
    // var channel = this.pusherClient.subscribe('my-channel');
    // var thisss = this
    // channel.bind('my-event', function (data) {
    //   // console.log('herere', data.message);
    //   thisss.toastr.error(data.message, 'Alert', { timeOut: 300000 })
    // });
  }

  ngOnInit(): void {

    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));

    // console.log(localStorage.getItem('userEmail'));
    let selectedLangOld = localStorage.getItem('selected_lang');
    //  console.log(selectedLangOld);
    if (selectedLangOld === undefined) {
      this.selectedlang = "mr"; //this.langList[0];
      localStorage.setItem('selected_lang', 'mr');
      this.ngxService.stop();
    } else {
      this.selectedlang = selectedLangOld;
      this.ngxService.stop();

    }

    //this.ngxService.stop();
    this.getNotificationCount()
    this.getNotifications()
    this.changeFontSize();
  }

  getNotificationCount() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });
    this.http.getUserNotificationCount(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.userNotificationsCount = resolve.data
    })
  }

  getNotifications() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });

    this.http.getUserNotifications(body).subscribe((resolve: any) => {
      // console.log(resolve);
      this.userNotifications = resolve.data
    })
  }

  updateNotification() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });

    this.http.updateNotifications(body).subscribe((resolve: any) => {
      // console.log(resolve);
      // this.userNotifications = resolve.data
    })
  }


  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    this.cdr.markForCheck();
  }
  goToProfile() {
    this.router.navigate(['profile']);

  }

  goToLogOut() {
    localStorage.removeItem('userLoggedIn');
    localStorage.removeItem('farmer_master_id');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('user_type');
    localStorage.removeItem('AadharNumber');
    localStorage.removeItem('Gender');
    localStorage.removeItem('selected_lang');
    localStorage.removeItem('mobile');
    localStorage.removeItem('post_user_id');
    localStorage.removeItem('user_office_id');
    localStorage.removeItem('user_office_type');
    localStorage.removeItem('user_post');
    localStorage.removeItem('project_types');
    localStorage.removeItem('project_statuses');
    localStorage.removeItem('project_gis_no');
    localStorage.removeItem('Token')
    this.router.navigate(['/']);
  }

  changeLang(lng) {
    //console.log(lng);
    var langji
    if (lng == "en") {
      langji = 'English'
    } else if (lng == "mr") {
      langji = 'Marathi'
    }
    localStorage.setItem('selected_lang', lng);
    //  alert(window.location.pathname);
    let url_string = window.location.pathname;
    // if(url_string.includes("verifyDetails")|| url_string.includes("verifyProject") || url_string.includes("dams")){

    this.selectedlang = lng;
    // console.log("location ", window.location);
    window.location.reload();
    this.clickMe();
    //   }else{
    //     console.log("test test")
    //   this.commonService.language$.next(langji);
    //   this.translate.use(lng)
    // }
  }


  clickMe() {
    this.ngxService.stop();
    this.sharedService.sendClickEvent();
  }


  notification_click() {
    this.showNotificationBlock = this.showNotificationBlock ? false : true;
    if (this.userNotificationsCount > 0) {
      this.updateNotification();
      this.getNotificationCount();
    }

  }
  // changeFont(operator) {
  //   console.log(this.para)
  //   operator === '+' ? this.fontSize++ : this.fontSize--;
  //   (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;


  // }

  changeFont(operator) {
    // console.log(this.para)
    // operator === '+' ? this.fontSize++ : this.fontSize--;
    this.sharedService.changeFont(operator);
    // (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;

  }



  changeFontSize() {
    var y = 2;
    $("#ddmenu").removeClass("menuFontClass");
    $('body').delegate('.increase', 'click', function (e) {
      $("#ddmenu").removeClass("menuFontClass");
      $("#ddmenu").addClass("menuFontClass");

      if (y < 4) {
        $('*').each(function () {
          var x = parseInt($(this).css("font-size"));
          x = x + 1;
          $(this).css("font-size", x);
        });
        y = y + 1;
      }
    });
    $('body').delegate('.decrease', 'click', function (e) {
      if (y > 0) {
        $('*').each(function () {
          var x = parseInt($(this).css("font-size"));
          x = x - 1;
          $(this).css("font-size", x);
        });
        y = y - 1;
      }
    });
    $('body').delegate('.reset', 'click', function (e) {
      $("#ddmenu").removeClass("menuFontClass");
      $('*').each(function () {
        $(this).css("font-size", "");
      });
      y = 2;
    });

    $("#ddmenu").click(function () {
      $("#ddmenu").addClass("menuClass");
    });

  }
}