import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;


import { IDropdownSettings } from 'src/ng-multiselect-dropdown/src';
// import { $ } from 'protractor';
import { ThrowStmt } from '@angular/compiler';

@Component({
	selector: 'app-project-layout',
	templateUrl: './project-layout.component.html',
	styleUrls: ['./project-layout.component.css']
})
export class ProjectLayoutComponent implements OnInit {
	LayoutForm: FormGroup;
	PopLayoutForm: FormGroup;
	branchPopup: FormGroup;
	distruPopupForm: FormGroup;
	minorPopupForm: FormGroup;
	subMinorPopupForm: FormGroup;
	outletForm: FormGroup;
	selectedItems: any = [];
	dropdownSettings: any = {};
	proSType: any = [];
	typeArr: any = [];
	typeArrValues: string;
	selectedItemsRoot: any[];
	projectList: any;
	//For DAM 
	damSType: any = [];
	damArr: any = [];
	damArrValues: string;
	projectSelect: any = [];
	damsSelect: any = [];
	project_gis_no: any;
	canalSelect: any = [];
	show: boolean = false;
	dam_unique: any;
	canelNewdata: any = {}
	NA = { id: 1, name: 'NA' }
	getCanlBranchArray = []
	getDistubutoryArray = []
	getMinorCanalArray = []
	getSubMinorCanalArray = []
	isMainBranchShow: boolean = false
	isBranchCanelShow: boolean = false
	isDistubutoryShow: boolean = false
	isMinorCanalShow: boolean = false
	isSubMinorCanalShow: boolean = false
	isLastSubmitShow: boolean = false

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpRequestService,
		private common: CommonService,) { }

	ngOnInit(): void {

		this.LayoutForm = this.fb.group({
			"projectName": new FormControl('', [Validators.required]),
			"damName": new FormControl('', [Validators.required]),
			"canalName": new FormControl('', [Validators.required]),

		});


		this.PopLayoutForm = this.fb.group({
			// main_canal_code: new FormControl('', [Validators.required]),
			main_canal_name: new FormControl('', [Validators.required]),
			main_canal_name_marathi: new FormControl('', [Validators.required]),
			main_canal_type: new FormControl('', [Validators.required]),
			// main_canal_main_type: new FormControl('', [Validators.required]),
			main_canal_ica: new FormControl('', [Validators.required]),
			main_canal_cca: new FormControl('', [Validators.required]),
			main_canal_gca: new FormControl('', [Validators.required]),
			// main_canal_outlet: new FormControl('', [Validators.required]),
			abcd: new FormControl(''),
			project_unique_gis: new FormControl('',),
			dam_unique_code: new FormControl('',),
			main_canal_id: new FormControl('',),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))

		});
		this.branchPopup = this.fb.group({
			project_unique_gis: new FormControl('',),
			dam_unique_code: new FormControl('',),
			main_canal_id: new FormControl('',),
			// branch_canal_code: new FormControl('', [Validators.required]),
			branch_canal_name: new FormControl('', [Validators.required]),
			branch_canal_name_marathi: new FormControl('', [Validators.required]),
			// branch_canal_structure: new FormControl('', [Validators.required]),
			branch_canal_chainage_km: new FormControl('', [Validators.required]),
			// branch_canal_chainage_m: new FormControl('', [Validators.required]),
			branch_canal_ica: new FormControl('', [Validators.required]),
			branch_canal_cca: new FormControl('', [Validators.required]),
			branch_canal_gca: new FormControl('', [Validators.required]),
			branch_canal_master_id: new FormControl('',),
			abcd: new FormControl('',),
			post_user_id: new FormControl(localStorage.getItem('post_user_id')),
		});
		this.distruPopupForm = this.fb.group({
			project_unique_gis: new FormControl('',),
			dam_unique_code: new FormControl('',),
			main_canal_id: new FormControl('',),
			branch_canal_id: new FormControl('',),
			abcd: new FormControl('',),
			distributory_canal_id: new FormControl('',),
			// distributory_canal_code: new FormControl('', [Validators.required]),
			distributory_canal_name: new FormControl('', [Validators.required]),
			distributory_canal_name_marathi: new FormControl('', [Validators.required]),
			// distributory_canal_structure: new FormControl('', [Validators.required]),
			distributory_canal_chainage_km: new FormControl('', [Validators.required]),
			// distributory_canal_chainage_m: new FormControl('', [Validators.required]),
			distributory_canal_ica: new FormControl('', [Validators.required]),
			distributory_canal_cca: new FormControl('', [Validators.required]),
			distributory_canal_gca: new FormControl('', [Validators.required]),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))
		});
		this.minorPopupForm = this.fb.group({
			project_unique_gis: new FormControl('',),
			dam_unique_code: new FormControl('',),
			main_canal_id: new FormControl('',),
			branch_canal_id: new FormControl('',),
			abcd: new FormControl('',),
			minor_canal_id: new FormControl('',),
			distributory_canal_id: new FormControl('',),
			// minor_canal_code: new FormControl('', [Validators.required]),
			minor_canal_name: new FormControl('', [Validators.required]),
			minor_canal_name_marathi: new FormControl('', [Validators.required]),
			// minor_canal_structure: new FormControl('', [Validators.required]),
			minor_canal_chainage_km: new FormControl('', [Validators.required]),
			minor_canal_ica: new FormControl('', [Validators.required]),
			minor_canal_cca: new FormControl('', [Validators.required]),
			minor_canal_gca: new FormControl('', [Validators.required]),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))
		})
		this.subMinorPopupForm = this.fb.group({
			project_unique_gis: new FormControl('',),
			dam_unique_code: new FormControl('',),
			main_canal_id: new FormControl('',),
			branch_canal_id: new FormControl('',),
			distributory_canal_id: new FormControl('',),
			minor_canal_id: new FormControl('',),
			subminor_canal_id: new FormControl('',),
			abcd: new FormControl('',),
			// subminor_canal_code: new FormControl('', [Validators.required]),
			subminor_canal_name: new FormControl('', [Validators.required]),
			subminor_canal_name_marathi: new FormControl('', [Validators.required]),
			// subminor_canal_structure: new FormControl('', [Validators.required]),
			subminor_canal_chainage_km: new FormControl('', [Validators.required]),
			// subminor_canal_chainage_m: new FormControl('', [Validators.required]),
			subminor_canal_ica: new FormControl('', [Validators.required]),
			subminor_canal_cca: new FormControl('', [Validators.required]),
			subminor_canal_gca: new FormControl('', [Validators.required]),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'))

		})
		this.outletForm = this.fb.group({
			outlet_canal_name: new FormControl('', [Validators.required]),
			outlet_canal_name_marathi: new FormControl('', [Validators.required]),
			outlet_canal_position: new FormControl('', [Validators.required]),
			post_user_id: new FormControl(localStorage.getItem('post_user_id')),
		});

		this.getProjectDetails();
	}
	close(id) {
		if (id == 'mainCanel') {
			$("#preModal").hide();
		} else if (id == "canalBranch") {
			$('#canalBranch').hide();
		} else if (id == "distCanel") {
			$('#distCanel').hide();
		} else if (id == "minorCanel") {
			$('#minorCanel').hide();
		} else if (id == "subMinorCanel") {
			$('#subMinorCanel').hide();
		}

	}
	getProjectDetails() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});
		this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve)
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.projects) {
					this.projectSelect = resolve.projects;
				} else
					return false;
			}
		},
			(err) => {
				console.log(err)
			})
	}
	onChangeforDams(id) {
		let post_user_id = localStorage.getItem('post_user_id');
		this.project_gis_no = id;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_gis_no: id,
			}
		});
		this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve);
			//return false;
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.dams) {
					this.damsSelect = resolve.dams;
					return false;
				} else
					return false;
			}
		},
			(err) => {
				console.log(err);
			})
	}
	getMainCanalDetails(ob) {
		let post_user_id = localStorage.getItem('post_user_id');
		this.dam_unique = ob;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_unique_gis: this.project_gis_no,
				dam_unique_code: ob
			}
		});
		this.http.getCanalDetailLayout(body).subscribe((resolve: any) => {
			this.canalSelect = resolve.data;
			// console.log(resolve);
			return false;
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.dams) {
				} else
					return false;
			}
		},
			(err) => {
				console.log(err);
			})
	}
	myChangeMainCanel(evt) {
		console.log(evt);
		if (evt.name == 'NA') {
			this.isMainBranchShow = false
			$("#preModal").show()
		} else {
			this.isMainBranchShow = true
		}
		this.PopLayoutForm.patchValue({
			main_canal_code: evt.main_canal_code,
			main_canal_name: evt.main_canal_name,
			main_canal_name_marathi: evt.main_canal_name_marathi,
			// main_canal_main_type: evt.main_canal_main_type,
			main_canal_type: evt.main_canal_type,
			main_canal_ica: evt.main_canal_ica,
			main_canal_cca: evt.main_canal_cca,
			main_canal_gca: evt.main_canal_gca,
			main_canal_outlet: evt.main_canal_outlet,
			main_canal_id: evt.main_canal_id
		})
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_id: evt.main_canal_id
			}
		});
		this.http.getBranchesOfCanel(body).subscribe((branchesData: any) => {
			// console.log(branchesData)
			this.getCanlBranchArray = branchesData.data
		})
	}
	submitMainCanalDetails() {
		// console.log(this.PopLayoutForm)
		if(this.PopLayoutForm.status =="INVALID"){
			alert('All feilds are mandatory')
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.PopLayoutForm.value.dam_unique_code = this.dam_unique
		this.PopLayoutForm.value.project_unique_gis = this.project_gis_no
		this.PopLayoutForm.value.main_canal_bank_id = this.LayoutForm.value.bank_id
			body = new HttpParams({
				fromObject: this.PopLayoutForm.value
			});

		this.http.addUpdateMainCanal(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.PopLayoutForm.value.main_canal_id) {
				alert('Details Successfully updated')
			} else {
				alert('Details Successfully added')
				this.canalSelect.push(resolve.data)
				this.LayoutForm.controls["canalName"].setValue('');
				this.LayoutForm.controls["canalName"].setValue(resolve.data);
			}
			this.myChangeMainCanel(resolve.data)
			$("#preModal").hide();
		},
			(err) => {
				console.log(err);
			})
	}
	onChangeCanelBranches(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.isBranchCanelShow = false
			$("#canalBranch").show()
		} else {
			this.isBranchCanelShow = true
		}
		this.branchPopup.patchValue({
			branch_canal_code: evt.branch_canal_code,
			branch_canal_name: evt.branch_canal_name,
			branch_canal_name_marathi: evt.branch_canal_name_marathi,
			branch_canal_structure: evt.branch_canal_structure,
			branch_canal_chainage_km: evt.branch_canal_offtaking_chainage_km,
			branch_canal_chainage_m: evt.branch_canal_chainage_m,
			branch_canal_ica: evt.branch_canal_ica,
			branch_canal_cca: evt.branch_canal_cca,
			branch_canal_gca: evt.branch_canal_gca,
			branch_canal_bank_id: this.LayoutForm.value.bank_id,
		})
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_id: this.PopLayoutForm.value.main_canal_id,
				branch_canal_id: evt.branch_canal_master_id
			}
		});
		this.http.getDistributory(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.getDistubutoryArray = arr.data

		})
	}
	submitBranchCanelDetails() {
		// console.log(this.branchPopup)
		if(this.branchPopup.status =="INVALID"){
			alert('All feilds are mandatory')
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.branchPopup.value.dam_unique_code = this.dam_unique
		this.branchPopup.value.project_unique_gis = this.project_gis_no,
			this.branchPopup.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		body = new HttpParams({
			fromObject: this.branchPopup.value
		});
		this.http.addCanelBranches(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.branchPopup.value.branch_canal_master_id) {
				alert('Details Successfully updated')
			} else {
				alert('Details Successfully added')
				this.getCanlBranchArray.push(resolve.data)
				this.PopLayoutForm.controls["abcd"].setValue('');
				this.PopLayoutForm.controls["abcd"].setValue(resolve.data);
			}
			this.onChangeCanelBranches(resolve.data)
			$("#canalBranch").hide();
		},
			(err) => {

				console.log(err);


			}
		)
	}
	onChangeDistributory(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.isDistubutoryShow = false
			$("#distCanel").show()
		} else {
			this.isDistubutoryShow = true
		}
		this.distruPopupForm.patchValue({
			distributory_canal_code: evt.distributory_canal_code,
			distributory_canal_name: evt.distributory_canal_name,
			distributory_canal_name_marathi: evt.distributory_canal_name_marathi,
			distributory_canal_structure: evt.distributory_canal_structure,
			distributory_canal_chainage_km: evt.distributory_canal_offtaking_chainage_km,
			distributory_canal_chainage_m: evt.distributory_canal_chainage_m,
			distributory_canal_ica: evt.distributory_canal_ica,
			distributory_canal_cca: evt.distributory_canal_cca,
			distributory_canal_gca: evt.distributory_canal_gca,
			distributory_canal_id: evt.distributory_canal_master_id,
			//branch_canal_offtaking_chainage_km
		})
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_id: this.PopLayoutForm.value.main_canal_id,
				branch_canal_id: this.branchPopup.value.branch_canal_master_id,
				distributory_canal_id: evt.distributory_canal_master_id

			}
		});
		this.http.getListOfMinor(body).subscribe(data => {
			// console.log(data)
			var arr: any = data
			this.getMinorCanalArray = arr.data

		})
	}
	submitDistributoryDetails() {
		// console.log(this.distruPopupForm.value)
		if(this.distruPopupForm.status =="INVALID"){
			alert('All feilds are mandatory')
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.distruPopupForm.value.dam_unique_code = this.dam_unique
		this.distruPopupForm.value.project_unique_gis = this.project_gis_no,
			this.distruPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.distruPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.distruPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id
		body = new HttpParams({
			fromObject: this.distruPopupForm.value
		});
		this.http.addUpdateDistributory(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.distruPopupForm.value.distributory_canal_id) {
				alert('Details Successfully updated')
			} else {
				alert('Details Successfully added')
				this.getDistubutoryArray.push(resolve.data)
				this.branchPopup.controls["abcd"].setValue('');
				this.branchPopup.controls["abcd"].setValue(resolve.data);
				this.onChangeDistributory(resolve.data)
			}
			$("#distCanel").hide();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	onChangeMinorCanal(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.isMinorCanalShow = false
			$("#minorCanel").show()
		} else {
			this.isMinorCanalShow = true
		}
		this.minorPopupForm.patchValue({
			minor_canal_code: evt.minor_canal_code,
			minor_canal_name: evt.minor_canal_name,
			minor_canal_name_marathi: evt.minor_canal_name_marathi,
			minor_canal_structure: evt.minor_canal_structure,
			branch_canal_master_id: evt.branch_canal_master_id,
			minor_canal_id: evt.minor_canal_master_id,
			minor_canal_gca: evt.minor_canal_gca,
			minor_canal_ica: evt.minor_canal_ica,
			minor_canal_cca: evt.minor_canal_cca,
			minor_canal_chainage_km: evt.minor_canal_offtaking_chainage_km
		})
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.project_gis_no,
				dam_unique_code: this.dam_unique,
				main_canal_id: this.PopLayoutForm.value.main_canal_id,
				branch_canal_id: this.branchPopup.value.branch_canal_master_id,
				distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
				minor_canal_id: evt.minor_canal_master_id,


			}
		});
		this.http.getListIfSubMinor(body).subscribe((data: any) => {
			this.getSubMinorCanalArray = data.data

		})
	}
	submitMinorCanalDetails() {
		// console.log(this.minorPopupForm)
		if(this.minorPopupForm.status =="INVALID"){
			alert('All feilds are mandatory')
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.minorPopupForm.value.dam_unique_code = this.dam_unique
		this.minorPopupForm.value.project_unique_gis = this.project_gis_no,
			this.minorPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.minorPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.minorPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id

		body = new HttpParams({
			fromObject: this.minorPopupForm.value
		});
		this.http.addUpdateMinor(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.minorPopupForm.value.minor_canal_id) {
				alert('Details Successfully updated')
			} else {
				alert('Details Successfully added')
				this.getMinorCanalArray.push(resolve.data)
				this.distruPopupForm.controls["abcd"].setValue('');
				this.distruPopupForm.controls["abcd"].setValue(resolve.data);
				this.onChangeMinorCanal(resolve.data)
			}
			$("#minorCanel").hide();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	onChangeSubMinorCanal(evt) {
		// console.log(evt);
		if (evt.name == 'NA') {
			this.isSubMinorCanalShow = false
			$("#subMinorCanel").show()
		} else {
			this.isSubMinorCanalShow = true
		}
		this.isLastSubmitShow = true
		this.subMinorPopupForm.patchValue({
			subminor_canal_code: evt.subminor_canal_code,
			subminor_canal_name: evt.subminor_canal_name,
			subminor_canal_name_marathi: evt.subminor_canal_name_marathi,
			subminor_canal_structure: evt.subminor_canal_structure,
			subminor_canal_chainage_km: evt.subminor_canal_offtaking_chainage_km,
			subminor_canal_chainage_m: evt.subminor_canal_chainage_m,
			subminor_canal_ica: evt.subminor_canal_ica,
			subminor_canal_cca: evt.subminor_canal_cca,
			subminor_canal_gca: evt.subminor_canal_gca,
			subminor_canal_id: evt.subminor_canal_master_id,

		})
	}
	submitSubMinorCanalDetails() {
		// console.log(this.subMinorPopupForm)
		if(this.subMinorPopupForm.status =="INVALID"){
			alert('All feilds are mandatory')
			return
		}
		let post_user_id = localStorage.getItem('post_user_id');
		var body
		this.subMinorPopupForm.value.dam_unique_code = this.dam_unique
		this.subMinorPopupForm.value.project_unique_gis = this.project_gis_no,
			this.subMinorPopupForm.value.main_canal_id = this.PopLayoutForm.value.main_canal_id
		this.subMinorPopupForm.value.branch_canal_id = this.branchPopup.value.branch_canal_master_id
		this.subMinorPopupForm.value.distributory_canal_id = this.distruPopupForm.value.distributory_canal_id
		this.subMinorPopupForm.value.minor_canal_id = this.minorPopupForm.value.minor_canal_id

		body = new HttpParams({
			fromObject: this.subMinorPopupForm.value
		});
		this.http.addUpdateSubMinor(body).subscribe((resolve: any) => {
			// console.log(resolve)
			if (this.subMinorPopupForm.value.subminor_canal_id) {
				alert('Details Successfully updated')
			} else {
				alert('Details Successfully added')
				this.getSubMinorCanalArray.push(resolve.data)
				this.minorPopupForm.controls["abcd"].setValue('');
				this.minorPopupForm.controls["abcd"].setValue(resolve.data);
				this.onChangeSubMinorCanal(resolve.data)
			}
			$("#subMinorCanel").hide();
		},
			(err) => {
				console.log(err)
			}
		)
	}
	finalSubmit() {
		
		// console.log(this.outletForm);
		if(this.outletForm.status == "INVALID"){
			alert('All feilds are mandatory')
			return
		}
		
		var body = new HttpParams({
			fromObject: {
				project_layout_project_unique_code: this.project_gis_no,
				project_layout_dam_unique_code: this.dam_unique,
				project_layout_main_canal_id: this.PopLayoutForm.value.main_canal_id,
				project_layout_branch_canal_id: this.branchPopup.value.branch_canal_master_id,
				project_layout_distributory_canal_id: this.distruPopupForm.value.distributory_canal_id,
				project_layout_minor_canal_id: this.minorPopupForm.value.minor_canal_id,
				project_layout_subminor_canal_id: this.subMinorPopupForm.value.subminor_canal_id,
				post_user_id: localStorage.getItem('post_user_id'),
			}
		});
		this.http.addProjectlayoutMaster(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.submitOutletForm()
			// this.router.navigate(['/viewProject'])
		},
			(err) => {
				console.log(err)
			})
	}
	submitOutletForm() {
		var body = new HttpParams({
			fromObject: {
				post_user_id: localStorage.getItem('post_user_id'),
				project_unique_gis:this.project_gis_no,
				dam_unique_code:this.dam_unique,
				main_canal_id:this.PopLayoutForm.value.main_canal_id,
				branch_canal_id:this.branchPopup.value.branch_canal_master_id,
				distributory_canal_id:this.distruPopupForm.value.distributory_canal_id,
				minor_canal_id:this.minorPopupForm.value.minor_canal_id,
				subminor_canal_id:this.subMinorPopupForm.value.subminor_canal_id,
				outlet_canal_name:this.outletForm.value.outlet_canal_name,
				outlet_canal_name_marathi:this.outletForm.value.outlet_canal_name_marathi,
				outlet_canal_position:this.outletForm.value.outlet_canal_position,
			}
		});
		this.http.addUpdateOutletCanel(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.router.navigate(['/viewProject'])
		},(err) => {
				console.log(err)
			})
	}
	reset() {
		this.PopLayoutForm.reset()
		this.branchPopup.reset()
		this.distruPopupForm.reset()
		this.minorPopupForm.reset()
		this.subMinorPopupForm.reset()
		this.LayoutForm.reset()
		this.isMainBranchShow = false
		this.isBranchCanelShow = false
		this.isDistubutoryShow = false
		this.isMinorCanalShow = false
		this.isSubMinorCanalShow = false
	}

}

