<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <div class="dashbordleft">
            <a class="dashbordlogo fullwidth" href=""><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
            <!--  <ul class="dashboardleftnav fullwidth">
                <li><a href="">Add Farmer Master Data</a></li>
                <li><a href="">View Farmer Master Data</a></li>
            </ul>-->
        </div>
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <!-- header over-->
            <!-- dashboardbnnr start-->

            <!-- dashboardbnnr over-->
            <!-- mid content start-->

            <div class="greenstrip fullwidth">
                <h1>Welcome {{this.userName}} to MWRD's Farmer Master Database</h1>

            </div>

            <div class="mid-content fullwidth formstep verify-detail-page">
                <!-- row start-->
                <div class="row">

                    <div class="ofc-detial">

                        <!-- col-md-12 start-->





                        <div class="col-md-12 recentlistbx">
                            <h2 class="smlheadh2 col-md-12"><span>Source of Irrigation </span></h2>

                        </div>




                        <div class="stepfrmcmn stepfrm1">
                            <div>
                                <!--form-->

                                <form [formGroup]="irrigationMasterForm" (ngSubmit)="onSubmit(irrigationMasterForm)">
                                    <!-- step row start-->
                                    <div class="row frmrow">

                                       <!-- <div class="col-md-4 inptbx">-->

                                            <!--<label class="steplbl">Irrigation</label>-->


                                            <!--<mat-form-field class="input-permissions">
                                            <mat-select placeholder="" multiple  formControlName="irrigationSelectname"  (selectionChange)='onIrrigationnameSelected($event.value)'>
                                                <mat-select-filter [array]="permissions"  (filteredReturn)="filteredList=$event" [displayMember]="'value'">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let permission of permissions" [value]="permission"
                                                    [class.hide]="!isFiltered(permission)">
                                                    {{permission.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>-->

                                            <!--<ng-multiselect-dropdown class="input-permissions"
                                                name="irrigationSelectname" [placeholder]="''"
                                                [settings]="dropdownSettings" [data]="permissions"
                                                formControlName="irrigationSelectname" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot"
                                                (onSelect)="onItemSelect($event)"
                                                (onDeSelect)="OnItemDeSelect($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                 (onDeSelectAll)="onDeSelectAll($event)">
                                            </ng-multiselect-dropdown>-->

                                            <div class="fullwidth checkgridbx">
                                                <!--checkgridinbx start-->
                                                <div class="checkgridinbx">
                                                <div class="inputGroup">
                                                    <img src="assets/images/reservior.png" alt="icon" />
                                                    <!--<input  value="Reservoir"  [(ngModel)]="RChecked" (change)="onCheckboxChagen($event, 'Reservoir')" type="checkbox"/>-->
                                                    <input  value="Reservoir"   (change)="onCheckboxChagen($event, 'Reservoir')" type="checkbox"/>
                                                    <label for="option1">Reservoir</label>
                                                  </div>
                                                </div>
                                                <!--checkgridinbx over-->
                                                <!--checkgridinbx start-->
                                                <div class="checkgridinbx">
                                                <div class="inputGroup">
                                                    <img src="assets/images/canal-flow.png" alt="icon" />
                                                    <input value = "Canal Flow"  (change)="onCheckboxChagen($event, 'Canal Flow')" name="option2" type="checkbox"/>
                                                    <label for="option2">Canal Flow</label>
                                                  </div>
                                                </div>
                                                <!--checkgridinbx over-->
                                                <!--checkgridinbx start-->
                                                <div class="checkgridinbx">
                                                <div class="inputGroup">
                                                    <img src="assets/images/canal-lift.png" alt="icon" />
                                                    <input  value = "Canal Lift"   (change)="onCheckboxChagen($event, 'Canal Lift')" name="option3" type="checkbox"/>
                                                    <label for="option3">Canal Lift</label>
                                                  </div>
                                                </div>
                                                <!--checkgridinbx over-->
                                                <!--checkgridinbx start-->
                                                <div class="checkgridinbx">
                                                <div class="inputGroup">
                                                    <img src="assets/images/river..png" alt="icon" />
                                                    <input value = "River-Nalla"   (change)="onCheckboxChagen($event, 'River-Nalla')" name="option4" type="checkbox"/>
                                                    <label for="option4">River-Nalla</label>
                                                  </div>
                                                </div>
                                                <!--checkgridinbx over-->
                                                <!--checkgridinbx start-->
                                                <div class="checkgridinbx">
                                                <div class="inputGroup">
                                                    <img src="assets/images/well.png" alt="icon" />
                                                    <input value = "Well"  (change)="onCheckboxChagen($event, 'Well')"  name="option5" type="checkbox"/>
                                                    <label for="option5">Well</label>
                                                  </div>
                                                </div>
                                                <!--checkgridinbx over-->
                                            </div>

                                        <!--</div>-->

         


                                    </div>

                                    <div class="row frmrow">




                                    </div>


                                    <div class="row frmrow">



                                    </div>

                                    <div class="row frmrow">

                                        <div class="col-md-12 inptbx">
                                            <!--  <input (click)="getDashboard()" class="stepgreenbtn"  type="Submit" value="Back" /> 
                                    <input (click)="getDashboard()" class="stepgreenbtn"  type="Submit" value="Confirm" /> -->

                                            <button (click)="backSubmit()" class="stepgreenbtn" value="Back"> Back
                                            </button>
                                            <button (click)="preview()" type="button" class="stepgreenbtn preview-btn"
                                                data-toggle="modal" data-target="#preModal">Preview</button>
                                            <input class="stepgreenbtn irrigation" type="submit" value="Confirm" />

                                        </div>



                                        <!-- <button type=button class="btn btn-primary"  (click)="clickMethod('name')">Confirm Submit</button>-->



                                    </div>

                                    <div class="row frmrow">
                                        <div class="col-md-12 inptbx">
                                            <input type="button" (click)="finalSubmit()" class="stepylwbtn"
                                                value="Final Submit">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- step row over-->
                        </div>

                        <!--POPUP ADD-->

                        <!-- The Modal -->
                        <div class="modal fade very-pop" id="preModal">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">

                                    <!-- Modal Header -->
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>

                                    <!-- Modal body -->
                                    <div class="modal-body">
                                        <h2>Office Details </h2>
                                        <ul>


                                            <li *ngIf="manShow">

                                                <label>Mantralaya</label>
                                                <span>{{this.Mantralaya}}</span>
                                            </li>

                                            <li *ngIf="corShow">
                                                <label>Corporation</label>
                                                <span>{{this.Corporation}}</span>
                                            </li>

                                            <li *ngIf="regShow">
                                                <label>Region</label>
                                                <span>{{this.Region}}</span>
                                            </li>
                                            <li *ngIf="cirShow">
                                                <label>Circle</label>
                                                <span>{{this.circle}}</span>
                                            </li>
                                            <li *ngIf="divShow">
                                                <label>Division</label>
                                                <span>{{this.Division}}</span>
                                            </li>
                                            <li *ngIf="subShow">
                                                <label>Sub-Division</label>
                                                <span>{{this.SubDivision}}</span>
                                            </li>
                                        </ul>
                                        <h2>Project Details</h2>
                                        <ul>
                                            <li>
                                                <label>Project Name</label>
                                                <span>{{this.projects}}</span>

                                            </li>
                                        </ul>

                                        <h2>Dam Details</h2>
                                        <ul>
                                            <li>
                                                <label>Dams</label>
                                                <span>{{this.dams}}</span>
                                            </li>
                                        </ul>
                                        <h2>Source of Irrigation</h2>
                                        <ul>
                                            <li>
                                                <label>Irrigation</label>
                                                <span>{{this.irrigation}}</span>
                                            </li>
                                        </ul>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <!--POPUP END-->







                    </div>
                </div>




                <!-- row over-->

                <!-- footer start-->
                <footer class="dboardfooter fullwidth">
                    <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                </footer>
                <!-- footer over-->

            </div>
            <!-- mid content over-->

        </div>
        <!-- dashboard right panel over-->
    </div>
</div>