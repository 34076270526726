<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <div class="dashbordleft">
            <a class="dashbordlogo fullwidth" href=""><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
        
        </div> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <!-- header over-->
            <!-- dashboardbnnr start-->

            <!-- dashboardbnnr over-->
            <!-- mid content start-->

            <div class="greenstrip fullwidth">
                <h1>{{data.welcome_string}}</h1>

            </div>

            <div class="mid-content fullwidth formstep verify-detail-page">
                <!-- row start-->
                <div class="row">

                    <div class="ofc-detial">

                        <!-- col-md-12 start-->





                        <div class="col-md-12 recentlistbx">
                            <h2 class="smlheadh2 col-md-12"><span>{{data.dams_string}} </span></h2>

                        </div>




                        <div class="stepfrmcmn stepfrm1">
                            <div>
                                <!--form-->
                                <form [formGroup]="damMasterForm" (ngSubmit)="onSubmit(damMasterForm)">
                                    <!-- step row start-->
                                    <div class="row frmrow">

                                        <div class="col-md-4 inptbx">

                                            <label class="steplbl">{{data.dams_string}} </label>


                                            <!--<mat-form-field class="input-permissions">
                                            <mat-select placeholder="" multiple formControlName="damSelectname" (selectionChange)='onDamsnameSelected($event.value)' >
                                                <mat-select-filter [array]="permissions"  (filteredReturn)="filteredList=$event" [displayMember]="'value'">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let permission of permissions" [value]="permission">
                                                  
                                                    {{permission.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>-->
                                            <ng-multiselect-dropdown class="input-permissions" name="damSelectname"
                                                [placeholder]="data.select"  [data]="dams" [settings]="dropdownSettings"
                                                formControlName="damSelectname" [disabled]="disabled"
                                                [(ngModel)]="selectedItemsRoot" (onSelect)="onItemSelect($event)"
                                                (onDeSelect)="OnItemDeSelect($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)">
                                            </ng-multiselect-dropdown>



                                        </div>
                                        <div style="color: red;" *ngIf="errorDiv">
                                           {{data.err}}
                                        </div>



                                    </div>

                                    <div class="row frmrow">




                                    </div>


                                    <div class="row frmrow">



                                    </div>
                                    <div class="clear clerfix"></div>
                                    <div class="projectlisttbl">

                                        <table>
                                            <tr>
                                                <th></th>
                                                <th style="width: 275px;">{{data.project_name_string}}</th>
                                                <th>{{data.dam_name_string}}</th>
                                                <th>{{data.irrigation_source_string}}</th>
                                            </tr>
                                            ​​​​

                                            <tr *ngFor="let details of damList; let i = index;">
                                                <td><input id="prochk{{details.dam_id}}" 
                                                       
                                                      (click)="getdamId($event,details.dam_id)" 
                                                   
                                                       type="checkbox" value="{{details.dam_id}}" />
                                                    </td>
                                                    <td *ngIf="this.selectedlang === 'en'">{{details.mwrd_project_master.project_name}}</td>
                                                    <td *ngIf="this.selectedlang === 'en'">{{details.dam_name}}</td>
                                                    <td *ngIf="this.selectedlang === 'mr'">{{details.mwrd_project_master.project_name_marathi}}</td>
                                                    <td *ngIf="this.selectedlang === 'mr'">{{details.dam_name_marathi}}</td>
                                                <!-- <td *ngIf="checkIfExists(details.dam_id)"> -->
                                                    <td id="show{{details.dam_id}}" style="display: none;" *ngIf="this.selectedlang === 'en'">
                                                    <div class="tablelabel" *ngFor="let Irrigation of irrigationList; let i = index;">

                                                        <input class="input-box" 
                                                            id="mix{{Irrigation.irrigation_source_id}}_{{details.dam_id}}"
                                                          
                                                            (click)="getProjectId(Irrigation.irrigation_source_id,details.dam_id,$event)"
                                                            name="SelectedEntity" type="checkbox"
                                                            value="{{Irrigation.irrigation_source_id}}">{{Irrigation.irrigation_source}}<br />
                                                        </div>
                                                    </td>
                                                <td id="show{{details.dam_id}}" style="display: none;" *ngIf="this.selectedlang === 'mr'">
                                                    <div class="tablelabel" *ngFor="let Irrigation of irrigationList; let i = index;">
                                                        <input class="input-box" 
                                                            id="mix{{Irrigation.irrigation_source_id}}_{{details.dam_id}}"
                                                            (change)="getProjectId(Irrigation.irrigation_source_id,details.dam_id,$event)"
                                                            name="SelectedEntity" type="checkbox"
                                                            value="{{Irrigation.irrigation_source_id}}">{{Irrigation.irrigation_source_marathi}}<br />

                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </div>

                                    <!-- step row over-->
                                    <input class="stepgreenbtn" type="Submit" value="{{data.confirm_string}}"/>
                                    <input (click)="getDashboard()" class="stepgreenbtn" type="button" value="{{data.back_string}}" />
                                   
                                    <input class="stepgreenbtn" *ngIf="this.isconfirm" (click)="finalSubmit()" type="button" value="{{data.final_sub}}" />
                                    <!-- <input  (click)="skip()" class="stepgreenbtn" type="Skip" value="{{data.skip}}" /> -->

                                    <span *ngIf="skipDiv">
                                        <input  (click)="skip()" class="stepgreenbtn" type="Skip" value="{{data.skip}}" />
                                    </span>
                                    <div class="row frmrow">

                                        <!-- <div class="col-md-12 inptbx">
                                            <input (click)="getDashboard()" class="stepgreenbtn" type="Submit"
                                                value="Back" />
                                            <input class="stepgreenbtn" type="Submit" value="Confirm" />


                                            



                                        </div> -->

                                    </div>

                                </form>
                            </div>
                        </div>

                        <div class="modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <br />
                                    <button type="button" class="close" data-dismiss="modal"
                                        (click)="closeUp()">&times;</button>
                                </div>
                                <div class="modal-body">


                                    <ul style=" height: 22vh;">
                                        <li>
                                            <div>
                                                {{data.sure_details}}

                                            </div>
                                        </li>
                                        <li>
                                            <input class="stepgreenbtn" type="button" (click)="okupdate()"
                                                value="{{data.Ok}}" />

                                                <input class="stepgreenbtn" type="button" (click)="cancelupdate()"
                                                value="{{data.cancel_string}}" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                        <!-- row over-->

                        <!-- footer start-->
                        <footer class="dboardfooter fullwidth">
                            <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                        </footer>
                        <!-- footer over-->

                    </div>
                    <!-- mid content over-->

                </div>
                <!-- dashboard right panel over-->
            </div>
        </div>
