
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl,Validators ,FormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpRequestService} from './../_service/http-request.service';
import {CommonService} from './../_service/common.service';
import { HeaderComponent } from '../header/header.component';
import { HttpClient ,HttpHeaders,HttpParams} from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  clickEventsubscription: Subscription;
  selectedlang = localStorage.getItem('selected_lang');
  language: string;


  

  profileForm: FormGroup;
  userName: string;
  userEmail: string;
  userMobile: string;
  aadhar: string;
  gender: string;
  userOfficeType: string;
  userPosition: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http : HttpRequestService,
    private common : CommonService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,

  ) { 


    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
     
      this.selectedlang = localStorage.getItem('selected_lang');
     // alert(this.selectedlang);
   
;
      this.ngOnInit();
    })
  }

  ngOnInit(): void {
    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));
  this.getUserDetails()
    // console.log(localStorage.getItem('mobile'));
   // console.log(localStorage);
    this.profileForm = this.fb.group({
      mobilenumber: ['']
     });


     this.userName = localStorage.getItem('userName');
     this.userEmail = localStorage.getItem('userEmail');
     this.userMobile = localStorage.getItem('mobile');
     this.aadhar = localStorage.getItem('AadharNumber');
     this.gender = localStorage.getItem('Gender');
     //alert(this.userEmail );
  }


  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    this.cdr.markForCheck();
  }


  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });


    this.http.getUserDetail(body).subscribe((resolve: any) => {

      // console.log(resolve.data.userDetail);
      // console.log("ll")
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division

    });

}
}