import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { TranslateService } from '@ngx-translate/core';
// import { Subscription } from 'rxjs';
// import { SharedService } from '../_service/shared.service';
import { ValidationService } from '../_service/validation.service';
import { HeaderComponent } from '../header/header.component';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ExcelService } from '../_service/excel.service';
// import "rxjs/add/operator/map";


@Component({
  selector: 'app-view-dam-master',
  templateUrl: './view-dam-master.component.html',
  styleUrls: ['./view-dam-master.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewDamMasterComponent implements OnInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dataArray = []
  dtOptions: DataTables.Settings = {};
  clickEventsubscription: Subscription;
  dtTrigger: Subject<any> = new Subject();
  selectedlang = localStorage.getItem('selected_lang');
  language: string;
  userOfficeType: string
  userPosition: string
  enableAddOption: boolean = false
  enableEditOption: boolean = false
  enableApproveOption: boolean = false
  aDamDetails: any
  excelData: any;
  showCir: boolean = false;
  showDiv: boolean = false;
  showSub: boolean = false;
  showSec: boolean = false;
  showReg: boolean = false;
  showCor: boolean = false;

  CoprationArr: any[];
  RegionArr: any[];
  CircleArr: any[];
  DivisionArr: any[];
  SubDivArr: any[];
  SectionArr: any[];
  disabled: boolean;
  office_details_en: any = {};
  office_details_mr: any = {};
  MantraId: any;
  circleId: any;
  subDivisId: any;
  RegionId: any;
  DivisionId: any;
  CorporationId: any;
  SectionId: any;
  UserType: any;
  data: any = {};
  en_data: any = {
  }
  mr_data: any = {
  }
  office_id: string;
  office_type: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,

    private excelService: ExcelService,
    private ngxService: NgxUiLoaderService,

    private sharedService: SharedService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.selectedlang = localStorage.getItem('selected_lang');
      this.ngOnInit();
    })


  }

  ngOnInit(): void {
    this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));
    this.UserType = localStorage.getItem('user_type')
    // alert(this.UserType)
    this.getData(1);
    this.getUserDetails();
    this.getUserDetailFilter();
    this.getDataOnChangeMarathi();
    if (this.selectedlang == 'mr') {
      // console.log('I am in Marathi')
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.marathi_datatables

      };
    } else {
      // console.log('I am in english')
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.english_datatables
      };
    }

    this.getUserDetails()
    // console.log(this.selectedlang);
  }


  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();

              // console.log(that.search(this['value']).draw())
            }
          });
        });

        this.dtTrigger.complete();
      });
    });
  }
  subscribeToLanguageChange(lang) {
    this.language = lang;
    this.translate.use(lang)
    this.cdr.markForCheck();

  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  getData(no) {
    this.ngxService.start();
    this.dataArray = []
    const body = new HttpParams({
      fromObject: {
        office_id: localStorage.getItem('user_office_id'),
        dam_user_type: localStorage.getItem('user_type'),
        user_office_type: localStorage.getItem('user_office_type'),
      }
    });
    this.http.getDamMasterListing(body).subscribe((data: any) => {
      // console.log(data.data)
      this.dataArray = data.data

      this.dtTrigger.next();
      // this.ngOnDestroy();

      this.ngxService.stop();
      // }, 3000);
    })
  }

  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      // console.log(this.userPosition + "==========" + this.userOfficeType);

      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division
      if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (resolve.data.userDetail.user_type == 0)) {
        this.enableAddOption = true
        this.enableEditOption = true
      }

      if (this.userOfficeType == "Circle" || resolve.data.userDetail.user_type == 0) {
        this.enableApproveOption = true
      }
    });
  }

  Add() {
    this.router.navigate(['damMaster']);
  }

  sendAlert(message) {
    switch (this.selectedlang) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }

  approveDam(damId) {
    var body = new HttpParams({
      fromObject: {
        dam_id: damId,
        dam_is_approved: "1",
        post_user_id: localStorage.getItem('post_user_id')
      }
    })
    this.http.approveDamMaster(body).subscribe((resolve: any) => {
      if (resolve.status && resolve.data) {
        this.sendAlert("Dam Approved Successfully!")
        this.ngOnInit()
      }
      else {
        this.sendAlert("Something went wrong, while approving dam")
      }
    })
  }

  editButtonClick(dam_id: Number) {
    console.log(dam_id)
    this.router.navigate(['/edit-dam-master', dam_id])
  }

  enableViewModel(dam_id) {
    this.show()
    this.getSingleDamDetails(dam_id)
  }

  getSingleDamDetails(dam_id) {
    const body = new HttpParams({
      fromObject: {
        dam_id: dam_id,
      }
    });
    this.http.getSingleDamMaster(body).subscribe((resolve: any) => {
      this.aDamDetails = resolve
      console.log(this.aDamDetails)
    });
  }

  close() {
    $("#preModal").hide();
  }

  show() {
    $('#preModal').show()
  }


  exportAsXLSX(): void {

    this.ngxService.start();

    if (this.office_id) {

      this.office_id = this.office_id;
    } else {


      this.office_id = localStorage.getItem('user_office_id');

    }
    if (this.office_type) {

      this.office_type = this.office_type;

    } else {


      this.office_type = localStorage.getItem('user_office_type');
    }
    this.excelData = [];
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        office_id: this.office_id,
        user_office_type: this.office_type,
        dam_user_type: localStorage.getItem('user_type')
      }
    });
    // console.log("here");

    this.http.getDamMasterListing(body).subscribe((data: any) => {
      console.log(data);

      // return 
      this.dataArray = data.data
      for (var i in this.dataArray) {

        if (this.dataArray[i].mwrd_project_master) {
          var projectName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_master.project_name : this.dataArray[i].mwrd_project_master.project_name_marathi
        } else {
          projectName = '';
        }

        if (this.dataArray[i]) {

          var dam_name = this.dataArray[i].dam_name
          var dam_name_marathi = this.dataArray[i].dam_name_marathi
        } else {
          dam_name = '';
          dam_name_marathi = ''

        }

        if (this.dataArray[i].mwrd_dam_storage_creation_master)
          var damstoragecreation = this.selectedlang == 'en' ? this.dataArray[i].mwrd_dam_storage_creation_master.storage_creation_name : this.dataArray[i].mwrd_dam_storage_creation_master.storage_creation_name_marathi
        else
          damstoragecreation = '';

        if (this.dataArray[i].mwrd_project_ownership_master)
          var projectOwnership = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_ownership_master.project_owner_name : this.dataArray[i].mwrd_project_ownership_master.project_marathi_owner_name
        else
          projectOwnership = '';


        if (this.dataArray[i].mwrd_state_master)
          var state = this.selectedlang == 'en' ? this.dataArray[i].mwrd_state_master.state_name : this.dataArray[i].mwrd_state_master.state_marathi_name
        else
          state = '';

        if (this.dataArray[i].mwrd_district_master)
          var district = this.selectedlang == 'en' ? this.dataArray[i].mwrd_district_master.district_name : this.dataArray[i].mwrd_district_master.district_name_marathi
        else
          district = '';


        if (this.dataArray[i].mwrd_taluka_master)
          var taluka = this.selectedlang == 'en' ? this.dataArray[i].mwrd_taluka_master.taluka_name : this.dataArray[i].mwrd_taluka_master.taluka_name_marathi
        else
          taluka = '';

        if (this.dataArray[i].mwrd_village_master)
          var village = this.selectedlang == 'en' ? this.dataArray[i].mwrd_village_master.village_name : this.dataArray[i].mwrd_village_master.village_name_marathi
        else
          village = '';

        if (this.dataArray[i].mwrd_basin_master)
          var basin = this.selectedlang == 'en' ? this.dataArray[i].mwrd_basin_master.basin_name : this.dataArray[i].mwrd_basin_master.basin_name_marathi
        else
          basin = '';

        if (this.dataArray[i].mwrd_subbasin_master)
          var subbasin = this.selectedlang == 'en' ? this.dataArray[i].mwrd_subbasin_master.subbasin_name : this.dataArray[i].mwrd_subbasin_master.subbasin_name_marathi
        else
          subbasin = '';




        if (this.dataArray[i].project_is_interstate) {

          var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय';



        } else {

          var Yes = this.selectedlang == 'en' ? 'No' : 'नाही'

        }

        //  var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय'
        //var No = this.selectedlang == 'en' ? 'No' : 'नाही'
        var dateTo = new Date((this.dataArray[i].farmer_master_valid_from));
        var dateToUpto = new Date((this.dataArray[i].farmer_master_valid_upto));

        // if(dateTo){
        // let dateTo_split = this.datepipe.transform(dateTo, 'dd-MM-yyyy');
        // }else {
        //   let dateTo_split ='';
        // }
        // if(dateToUpto){
        // let dateToUpto_split = this.datepipe.transform(dateToUpto, 'dd-MM-yyyy');
        // }else {
        //   let dateToUpto_split ='';
        // }

        // let dateToUpto_split = this.datepipe.transform(dateTo, 'dd-MM-yyyy');
        let dateTo_split = '';
        let dateToUpto_split = '';
        this.excelData.push(
          {

            'Name of Project': projectName,
            'Name of Dam (English )': dam_name,
            'Name of Dam Marathi': dam_name_marathi,

            // 'Type of Dam': projectStatus,
            // 'Purpose of Dam': projectType,

            'Status of Storage Creation of Dam': damstoragecreation,
            ' District ': district,
            ' Taluka': taluka,
            ' Village': village,


            'Basin': basin,
            'Sub Basin': subbasin,
            'Primary Authorities of Project Corporation': '',
            'Primary Authorities of Project Region': '',
            'Primary Authorities of Project Circle': '',
            'Primary Authorities of Project SuvDivision': '',
            'Primary Authorities of Project Division': '',
            'Primary Authorities of Project Section': '',

            // 'Secondary Authorities of Project Corporation': branchcanalNameMarathi,
            // 'Secondary Authorities of Project Region': branchOfftaking,
            // 'Secondary Authorities of Project Circle': branch_canal_ica,
            // 'Secondary Authorities of Project SuvDivision': branch_canal_cca,
            // 'Secondary Authorities of Project Division': branch_canal_gca,
            // 'Secondary Authorities of Project Section': branch_canal_gca,



          }
        )

      }


      this.excelService.exportAsExcelFile(this.excelData, 'export-to-excel');

      this.ngxService.stop();




    })


  }

  getUserDetailFilter() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      // console.log(this.userPosition + "==========" + this.userOfficeType);

      //this.userOfficeType ='Corporation';
      if (this.userOfficeType == 'Division') {

        var parent_id = resolve.data.userDetail.user_office_detail.Circle.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Sub Division') {


        var parent_id = resolve.data.userDetail.user_office_detail.Division.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');

      } else if (this.userOfficeType == 'Circle') {

        var parent_id = resolve.data.userDetail.user_office_detail.Region.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      else if (this.userOfficeType == 'Region') {

        var parent_id = resolve.data.userDetail.user_office_detail.Corporation.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Corporation') {

        parent_id = '1';
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }
      else if (this.userOfficeType == 'Section') {
        const subDivision = "Sub Division";
        var parent_id = resolve.data.userDetail.user_office_detail.subDivision.office_id;
        this.getChangeOfficeDetailsRefresh(parent_id, this.userOfficeType, '0');
      }

      if (this.userOfficeType == "Division") {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == "Circle") {

        this.showCir = false;

        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;

        this.showReg = false;
        this.showCor = false;

      } else if (this.userOfficeType == "Mantralaya") {

        // this.showCir =false;
        // this.showDiv =false;
        // this.showSub =true;
        // this.showSec =true;
        // this.showReg =false;
        // this.showCor = false;

      } else if (this.userOfficeType == "Region") {


        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;
      } else if (this.userOfficeType == 'Sub Division') {

        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = true;
        this.showReg = false;
        this.showCor = false;


      } else if (this.userOfficeType == 'Corporation') {

        this.showCir = true;
        this.showDiv = true;
        this.showSub = true;
        this.showSec = true;
        this.showReg = true;
        this.showCor = false;

      }
      else {


        this.showCir = false;
        this.showDiv = false;
        this.showSub = false;
        this.showSec = false;
        this.showReg = false;
        this.showCor = false;


      }

    });
  }



  getChangeOfficeDetailsRefresh(id, value, change) {



    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {
            // this.getAnalysisOffice(id);
            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;
            // this.getAnalysisOffice(id);
          } if (value == 'Circle') {
            // this.allProject = false;
            // this.getAnalysisOffice(id);
            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;
            // this.getAnalysisOffice(id);
          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;
            // this.getAnalysisOffice(id);
            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }

  getDataOnChangeMarathi() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {

        post_user_id: post_user_id,
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        for (let key in resolve.data.userDetail.user_office_detail) {
        }
        if (resolve.data) {





          if (resolve.data.userDetail.user_office_change_request) {

            this.disabled = !this.disabled;



          } else {
            this.disabled = this.disabled;


          }


          // alert("jjjjsj");
          const subDivision = "Sub Division";

          this.office_details_en = {
            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : "NA",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "NA",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : "NA",
          }
          // console.log(this.office_details_en);
          this.office_details_mr = {

            circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "NA",
            subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "NA",
            Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : "NA",
            Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : "NA",
            Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : "NA",
            Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "NA",
            Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : "NA",
          }





          this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
          this.circleId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
          this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
          this.RegionId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
          this.DivisionId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
          this.CorporationId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
          this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";

          this.data.office_detail = {};
          switch (this.selectedlang) {
            case "en":
              this.data.label = this.en_data;
              this.data.office_detail = this.office_details_en;
              break;
            case "mr":
              this.data.label = this.mr_data;
              this.data.office_detail = this.office_details_mr;
              break;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }




  getChangeOfficeDetails(id, value, change) {



    const body = new HttpParams({
      fromObject: {

        parent_id: id,
        office_type: value

      }
    });

    this.office_id = '';
    this.office_type = '';

    this.office_id = id;
    this.office_type = value;
    this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
      let data1 = resolve;
      let office_data = [];
      if (data1.status == 0) {

        return false;

      } else {

        if (resolve.data) {
          if (this.selectedlang === "en") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          } else if (this.selectedlang === "mr") {
            for (let i = 0; i < resolve.data.length; i++) {
              office_data.push({
                office_id: resolve.data[i]['office_id'],
                office_name: resolve.data[i]['office_name_marathi'],
                office_parent_id: resolve.data[i]['office_parent_id'],
                office_type: resolve.data[i]['office_type']
              })
            }
          }


          if (value == 'Corporation') {
            // this.getAnalysisOffice(id);
            this.RegionArr = office_data;
            // this.allProject = false;

          } if (value == 'Region') {
            this.CircleArr = office_data;
            // this.allProject = false;
            //  this.getAnalysisOffice(id);
          } if (value == 'Circle') {
            // this.allProject = false;
            // this.getAnalysisOffice(id);
            this.DivisionArr = office_data;
          }
          if (value == 'Division') {

            // this.allProject = false;
            this.SubDivArr = office_data;
            //   this.getAnalysisOffice(id);
          }
          if (value == 'Sub Division') {

            this.SectionArr = office_data;
            //  this.getAnalysisOffice(id);
            // this.allProject = false;
          }

        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )



  }


  damMasterSearchFormSubmit() {

    this.ngxService.start();
    this.dataArray = []

    const body = new HttpParams({
      fromObject: {
        office_id: this.office_id,
        dam_user_type: localStorage.getItem('user_type'),
        user_office_type: this.office_type,
      }
    });
    this.http.getDamMasterListing(body).subscribe((data: any) => {
      // console.log(data.data)
      this.dataArray = data.data

      this.dtTrigger.next();
      // this.ngOnDestroy();

      this.ngxService.stop();
      // }, 3000);
    })
  }

}
