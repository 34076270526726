<div class="dashboardstruck fullwidth unset-position">

    <div class="dashbordright">

        <app-header></app-header>
        <app-sidebar></app-sidebar>

        <div class="greenstrip fullwidth">
            <h1>{{'Project_Layout' | translate}}</h1>
        </div>
        <div class="mid-content fullwidth formstep">
            <!-- new row start-->
            <div class="row">
                <!-- left sidebar start-->
                <!-- <div class="col-sm-3">
                    <div class="leftsidebar overflow-x-auto">

                        
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"> -->
                <!-- This is the tree node template for leaf nodes -->
                <!--  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding> -->
                <!-- use a disabled button to provide padding for tree leaf -->
                <!--  <button mat-icon-button disabled>-</button>
                              {{node.name}}
                            </mat-tree-node> -->
                <!-- This is the tree node template for expandable nodes -->
                <!-- <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                              <button mat-icon-button matTreeNodeToggle
                                      [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                              </button>
                              {{node.name}}
                            </mat-tree-node>
                          </mat-tree>
                          
                    </div>
                </div> -->
                <!-- left sidebar over-->
                <!-- right sidebar start-->
                <!-- <div class="col-sm-9 rightsidebar"> -->
                <div class="col-sm-12 rightsidebar">
                    <!--main right side cut paste start-->
                    <div class="row">
                        <div class="col-md-12 recentlistbx top30">
                            <h2 class="smlheadh2 col-md-12"><span>{{'General' | translate}}</span></h2>

                        </div>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="LayoutForm">
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">

                                    <label class="steplbl">
                                        {{'Name of Project' | translate}}
                                        <span aria-hidden="true" style="color: red;">*</span>
                                        <!--   <span aria-hidden="true" style="color: red;">*</span> -->

                                    </label>

                                    <select class="stepinpt" formControlName="projectName"
                                        (change)="onChangeforDams($event.target.value)">
                                        <option *ngFor="let project of projectSelect;" [value]="project.project_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{project.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{project.project_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Name of Dam' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="damName"
                                        (change)="getMainCanalDetails($event.target.value)">
                                        <option *ngFor="let dam of damsSelect;" [value]="dam.dam_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{dam.dam_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{dam.dam_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Canal Type' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="bank_id"
                                        (change)="forBankCanal($event.target.value)">
                                        <option *ngFor="let list of bankArray;" [value]="list.bank_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.bank_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.bank_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>

                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="PopLayoutForm">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Main Canal Details' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isShowEditMainCanel" class="fa fa-print"
                                        (click)="submitMainCanalDetails()"></span>
                                    <span *ngIf="!isShowEditMainCanel" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('maincanel')"></span>
                                    <span *ngIf="isShowEditMainCanel" class="fa fa-close"
                                        (click)="disableTexxtBox('maincanel')"></span>
                                </div>

                            </div>

                            <div class="row frmrow">
                                <div class="col-md-1 inptbx">
                                    <label class="steplbl">{{'Please Select' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" (ngModelChange)="myChangeMainCanel($event)"
                                        formControlName="main_canal_object">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of mainCanalSelect;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.main_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.main_canal_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{'Name of Canal' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="main_canal_name"
                                        (keyup)="translateMarathi($event, 'maincanel')" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{"Main Canal Marathi Name" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="main_canal_name_marathi" />
                                </div>
                                <div class="col-md-1 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{'Type of Canal' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="main_canal_type">
                                        <option *ngFor="let list of mainCanelType" value="{{list.master_type_id}}">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.master_canal_type_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.master_canal_type_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{'ICA of Main Canal' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="main_canal_ica" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{'CCA of Main canal' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="main_canal_cca" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="mainCanelNaN">
                                    <label class="steplbl">{{'GCA of Main canal' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="main_canal_gca" />
                                </div>


                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="branchPopup">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Branch Details' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isshowEditBranchCanel" class="fa fa-print"
                                        (click)="submitBranchCanelDetails()"></span>
                                    <span *ngIf="!isshowEditBranchCanel" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('canelbranch')"></span>
                                    <span *ngIf="isshowEditBranchCanel" class="fa fa-close"
                                        (click)="disableTexxtBox('canelbranch')"></span>
                                </div>

                            </div>

                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Please Select' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="branch_code_object"
                                        (ngModelChange)="onChangeCanelBranches($event)">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of getCanlBranchArray;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.branch_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.branch_canal_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl"> {{'Name of Branch' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_name"
                                        (keyup)="translateMarathi($event,'branchcanel')" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl">{{"Branch Marathi Name" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_name_marathi"
                                        (keyup)="translateMarathi($event,'branchcanel')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl">{{'Offtaking Chainage of Branch' | translate }} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_chainage_km" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl">{{'ICA of Branch' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_ica" />
                                </div>



                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl">{{'CCA of Branch' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_cca" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="branchNaN">
                                    <label class="steplbl">{{'GCA of Branch' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="branch_canal_gca" />
                                </div>



                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="distruPopupForm">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Distributory Details' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isshowEditDistry" class="fa fa-print"
                                        (click)="submitDistributoryDetails()"></span>
                                    <span *ngIf="!isshowEditDistry" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('distCanel')"></span>
                                    <span *ngIf="isshowEditDistry" class="fa fa-close"
                                        (click)="disableTexxtBox('distCanel')"></span>
                                </div>
                            </div>

                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Please Select' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeDistributory($event)">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of getDistubutoryArray;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.distributory_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.distributory_canal_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{'Name of Distributory' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="distributory_canal_name"
                                        (keyup)="translateMarathi($event, 'distCanel')" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{"Distributory Marathi Name" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="distributory_canal_name_marathi"
                                        (keyup)="translateMarathi($event, 'distCanel')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{'Offtaking Chainage of Distributory' | translate}}
                                        <span aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="distributory_canal_chainage_km" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{'ICA of Distributory' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="distributory_canal_ica" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{'CCA of Distributory' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="distributory_canal_cca" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="distryNaN">
                                    <label class="steplbl">{{'GCA of Distributory' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="distributory_canal_gca" />
                                </div>



                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="minorPopupForm">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Minor Details' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isshowEditMinor" class="fa fa-print"
                                        (click)="submitMinorCanalDetails()"></span>
                                    <span *ngIf="!isshowEditMinor" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('minorCanel')"></span>
                                    <span *ngIf="isshowEditMinor" class="fa fa-close"
                                        (click)="disableTexxtBox('minorCanel')"></span>
                                </div>
                            </div>
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Please Select' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeMinorCanal($event)">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of getMinorCanalArray;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.minor_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.minor_canal_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{'Name of Minor' | translate }} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_name"
                                        (keyup)="translateMarathi($event, 'minorcanel')" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{"Minor Marathi Name" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_name_marathi"
                                        (keyup)="translateMarathi($event, 'minorcanel')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{'Offtaking Chainage of Minor' | translate }} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_chainage_km" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{'ICA of Minor' | translate }} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_ica" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{'CCA of Minor' | translate }} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_cca" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="minorNaN">
                                    <label class="steplbl">{{'GCA of Minor' | translate }} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="minor_canal_gca" />
                                </div>






                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="subMinorPopupForm">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Sub Minor Details' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isshowEditSubMinor" class="fa fa-print"
                                        (click)="submitSubMinorCanalDetails()"></span>
                                    <span *ngIf="!isshowEditSubMinor" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('subMinorCanel')"></span>
                                    <span *ngIf="isshowEditSubMinor" class="fa fa-close"
                                        (click)="disableTexxtBox('subMinorCanel')"></span>
                                </div>
                            </div>
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Please Select' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeSubMinorCanal($event)">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of getSubMinorCanalArray;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.subminor_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.subminor_canal_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{'Name of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_name"
                                        (keyup)="translateMarathi($event, 'subminorcanel')" />
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{"Subminor Marathi Name" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_name_marathi"
                                        (keyup)="translateMarathi($event, 'subminorcanel')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{'Offtaking Chainage (Km) of Subminor' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_chainage_km" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{'ICA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_ica" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{'CCA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_cca" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="subminorNaN">
                                    <label class="steplbl">{{'GCA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="subminor_canal_gca" />
                                </div>






                            </div>
                        </form>
                        <form class="stepfrmcmn stepfrm1" [formGroup]="outletForm">
                            <div class="col-md-12 recentlistbx top30">
                                <h2 class="smlheadh2 col-md-12"><span>{{'OutletDetails' | translate}}</span></h2>
                                <div class="editicon">
                                    <span *ngIf="isShowEditOutlet" class="fa fa-print"
                                        (click)="submitOutletForm()"></span>
                                    <span *ngIf="!isShowEditOutlet" class="fa fa-pencil-square"
                                        (click)="enableTexxtBox('outlet')"></span>
                                    <span *ngIf="isShowEditOutlet" class="fa fa-close"
                                        (click)="disableTexxtBox('outlet')"></span>
                                </div>
                            </div>
                            <div class="row frmrow">
                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{"Please Select" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="abcd"
                                        (ngModelChange)="onChangeOutListing($event)">
                                        <option [ngValue]="NA">{{'Yes' | translate}}</option>
                                        <option [ngValue]="NaN">{{'No' | translate}}</option>
                                        <option *ngFor="let canal of getOutlistingArray;" [ngValue]="canal">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{canal.outlet_canal_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{canal.outlet_canal_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'Name of Outlet' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <input class="stepinpt" formControlName="outlet_canal_name"
                                        (keyup)="translateMarathi($event, 'outlet')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{"Outlet Canal Name Marathi" | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>

                                    <input class="stepinpt" formControlName="outlet_canal_name_marathi"
                                        (keyup)="translateMarathi($event, 'outlet')" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'Side of outlet' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <select class="stepinpt" formControlName="outlet_canal_position">
                                        <option *ngFor="let list of outletCanalSide" value="{{list.master_common_id}}">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.common_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.common_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'Offtaking Chainage (Km) of Subminor' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt"
                                        formControlName="outlet_canal_offtaking_chainage_km" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'ICA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="outlet_canal_ica" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'CCA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="outlet_canal_cca" />
                                </div>
                                <div class="col-md-2 inptbx" *ngIf="outletNaN">
                                    <label class="steplbl">{{'GCA of Subminor' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" class="stepinpt" formControlName="outlet_canal_gca" />
                                </div>

                            </div>

                            <div class="row frmrow">

                                <div class="col-md-12 inptbx">
                                    <!-- <input class="stepgreenbtn" type="button" value="Save" (click)="finalSubmit()"> -->
                                    <input class="stepgreenbtn" value="{{'Submit' |translate}}" type="Submit"
                                        (click)="finalSubmit()">
                                    <input (click)="reset()" class="stepgreybtn" value="{{'Reset' |translate}}"
                                        type="reset" value="Reset">
                                </div>

                            </div>
                        </form>
                    </div>
                    <!--main right side cut paste over-->
                </div>
                <!-- right sidebar over-->
            </div>
            <!-- new row over-->


        </div>
        <footer class="dboardfooter fullwidth">
            <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
        </footer>
    </div>

    <!-- main canal -->
    <div class="modal fade very-pop show" id="preModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>{{'Add Main Canal Details' | translate}} </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('mainCanel')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="PopLayoutForm" class="main-canal">
                        <ul>
                            <!-- <li>
                         <label class="label">Main Canal Code</label>
                         <input class="input-box" formControlName="main_canal_code" type="text"><br /><br />
                     </li> -->
                            <li>
                                <label class="label">{{'Name' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="main_canal_name" type="text"
                                    (blur)="translateMarathi($event, 'maincanel')">
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_name'].invalid &&
                                (PopLayoutForm.controls['main_canal_name'].dirty || PopLayoutForm.controls['main_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name'].errors.required">
                                        Name is required!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name'].errors.pattern">
                                        Only Alpha-Numeric allowed!
                                    </p>
                                </div>
                                <br /><br />

                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="main_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_name_marathi'].invalid &&
                                (PopLayoutForm.controls['main_canal_name_marathi'].dirty || PopLayoutForm.controls['main_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name_marathi'].errors.required">
                                        Main Canal Marathi Name is required!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_name_marathi'].errors.pattern">
                                        Only Alpha-Numeric allowed!
                                    </p>
                                </div>
                            </li>
                            <!-- <li>
                         <label class="label">Main Canal Type</label>
                         <input class="input-box" formControlName="main_canal_type" type="text"><br /><br />
                     </li> -->
                            <li>
                                <label class="label">{{'Type' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <select formControlName="main_canal_type" class="input-box">
                                    <option *ngFor="let list of mainCanelType" value="{{list.master_type_id}}">
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                            {{list.master_canal_type_marathi_name}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{list.master_canal_type_name}}
                                        </ng-template>
                                    </option>
                                </select>
                                <br /><br />
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_type'].invalid &&
                                (PopLayoutForm.controls['main_canal_type'].dirty || PopLayoutForm.controls['main_canal_type'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_type'].errors.required">
                                        {{'Main Canal Type is required!' | translate}}
                                    </p>

                                </div>
                            </li>

                            <li>
                                <label class="label">{{'ICA' | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="main_canal_ica"><br /><br />
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_ica'].invalid &&
                                (PopLayoutForm.controls['main_canal_ica'].dirty || PopLayoutForm.controls['main_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>

                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.ica">
                                        ICA must be less than CCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{'CCA' | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="main_canal_cca"><br /><br />
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_cca'].invalid &&
                                (PopLayoutForm.controls['main_canal_cca'].dirty || PopLayoutForm.controls['main_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_cca'].errors.required">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>

                                </div>
                                <div>

                                    <p style="color: brown;" *ngIf="!this.cca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{'GCA' | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="main_canal_gca"><br /><br />
                                <div
                                    *ngIf="PopLayoutForm.controls['main_canal_gca'].invalid &&
                                (PopLayoutForm.controls['main_canal_gca'].dirty || PopLayoutForm.controls['main_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="PopLayoutForm.controls['main_canal_gca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>





                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.gca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>


                            <!-- <li>
                         <label class="label">Main Canal Outlet</label>
                         <input class="input-box" formControlName="main_canal_outlet" type="text"><br /><br />
                     </li> -->

                            <li>

                                <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                    (click)="submitMainCanalDetails()" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- end main canal -->
    <!-- Canel branch start -->
    <div class="modal fade very-pop show" id="canalBranch" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>{{'Add Branch Canal Details' | translate}} </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('canalBranch')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="branchPopup" class="main-canal">
                        <ul>
                            <li>
                                <label class="label">{{'Name' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="branch_canal_name" type="text"
                                    (blur)="translateMarathi($event,'branchcanel')"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_name'].invalid &&
                                (branchPopup.controls['branch_canal_name'].dirty || branchPopup.controls['branch_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_name'].errors.required">
                                        {{'Branch Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_name'].errors.pattern">
                                        Only Alpha-Numeric allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="branch_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_name_marathi'].invalid &&
                                (branchPopup.controls['branch_canal_name_marathi'].dirty || branchPopup.controls['branch_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_name_marathi'].errors.required">
                                        {{'Branch Marathi Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                </div>



                            </li>
                            <li>
                                <label class="label">{{"Chainage KM" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="branch_canal_chainage_km"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_chainage_km'].invalid &&
                                (branchPopup.controls['branch_canal_chainage_km'].dirty || branchPopup.controls['branch_canal_chainage_km'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_chainage_km'].errors.required">
                                        {{'Chainage KM is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_chainage_km'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>

                            </li>

                            <li>
                                <label class="label">{{"ICA" | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="branch_canal_ica"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_ica'].invalid &&
                                (branchPopup.controls['branch_canal_ica'].dirty || branchPopup.controls['branch_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>


                                <div>
                                    <p style="color: brown;" *ngIf="!this.branchica">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"CCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="branch_canal_cca"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_cca'].invalid &&
                                (branchPopup.controls['branch_canal_cca'].dirty || branchPopup.controls['branch_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_cca'].errors.required">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.branchcca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"GCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input type="text" class="input-box" formControlName="branch_canal_gca"><br /><br />
                                <div
                                    *ngIf="branchPopup.controls['branch_canal_gca'].invalid &&
                                (branchPopup.controls['branch_canal_gca'].dirty || branchPopup.controls['branch_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="branchPopup.controls['branch_canal_gca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.branchgca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>



                            <li>

                                <input class="stepgreenbtn" (click)="submitBranchCanelDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- canel branch end -->
    <!-- dist sart -->
    <div class="modal fade very-pop show" id="distCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>{{'Add Distributory Canal Details' | translate}} </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('distCanel')">&times;</button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="distruPopupForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Distubutory Canal Code</label>
                            <input class="input-box" formControlName="distributory_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">{{'Name' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_name" type="text"
                                    (blur)="translateMarathi($event, 'distCanel')"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_name'].invalid &&
                                (distruPopupForm.controls['distributory_canal_name'].dirty || distruPopupForm.controls['distributory_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_name'].errors.required">
                                        {{'Distributory Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_name'].errors.pattern">
                                        Only Alpha-Numeric allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_name_marathi'].invalid &&
                                (distruPopupForm.controls['distributory_canal_name_marathi'].dirty || distruPopupForm.controls['distributory_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_name_marathi'].errors.required">
                                        {{'Distributory Marathi Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Chainage KM" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_chainage_km"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_chainage_km'].invalid &&
                                (distruPopupForm.controls['distributory_canal_chainage_km'].dirty || distruPopupForm.controls['distributory_canal_chainage_km'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_chainage_km'].errors.required">
                                        {{'Chainage KM is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_chainage_km'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"ICA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_ica"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_ica'].invalid &&
                                (distruPopupForm.controls['distributory_canal_ica'].dirty || distruPopupForm.controls['distributory_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.disica">
                                        ICA must be less than CCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"CCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_cca"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_cca'].invalid &&
                                (distruPopupForm.controls['distributory_canal_cca'].dirty || distruPopupForm.controls['distributory_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_cca'].errors.required">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.discca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"GCA"| translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="distributory_canal_gca"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="distruPopupForm.controls['distributory_canal_gca'].invalid &&
                                (distruPopupForm.controls['distributory_canal_gca'].dirty || distruPopupForm.controls['distributory_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="distruPopupForm.controls['distributory_canal_gca'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.disgca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>



                            <li>

                                <input class="stepgreenbtn" (click)="submitDistributoryDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- dist end -->
    <!-- Minor Popup -->
    <div class="modal fade very-pop show" id="minorCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h2>{{'Add Minor Details' | translate}}</h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('minorCanel')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">


                    <form [formGroup]="minorPopupForm" class="main-canal">
                        <ul>

                            <li>
                                <label class="label">{{'Name' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_name" type="text"
                                    (blur)="translateMarathi($event, 'minorcanel')"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_name'].invalid &&
                                (minorPopupForm.controls['minor_canal_name'].dirty || minorPopupForm.controls['minor_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_name'].errors.required">
                                        Branch Name is required!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_name'].errors.pattern">
                                        Only characters allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_name_marathi'].invalid &&
                                (minorPopupForm.controls['minor_canal_name_marathi'].dirty || minorPopupForm.controls['minor_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_name_marathi'].errors.required">
                                        {{'Minor Marathi Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Chainage KM" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_chainage_km"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_chainage_km'].invalid &&
                                (minorPopupForm.controls['minor_canal_chainage_km'].dirty || minorPopupForm.controls['minor_canal_chainage_km'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_chainage_km'].errors.required">
                                        {{'Chainage KM is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_chainage_km'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"ICA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_ica" type="text"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_ica'].invalid &&
                                (minorPopupForm.controls['minor_canal_ica'].dirty || minorPopupForm.controls['minor_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.minorica">
                                        ICA must be less than CCA!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"CCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_cca" type="text"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_cca'].invalid &&
                                (minorPopupForm.controls['minor_canal_cca'].dirty || minorPopupForm.controls['minor_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_cca'].errors.required">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.minorcca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"GCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="minor_canal_gca" type="text"><br /><br />
                                <div
                                    *ngIf="minorPopupForm.controls['minor_canal_gca'].invalid &&
                                (minorPopupForm.controls['minor_canal_gca'].dirty || minorPopupForm.controls['minor_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="minorPopupForm.controls['minor_canal_gca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.minorgca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>


                            <li>

                                <input class="stepgreenbtn" (click)="submitMinorCanalDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>



            </div>
        </div>
    </div>
    <!-- Minor popup end -->
    <!-- sub minor popup -->
    <div class="modal fade very-pop show" id="subMinorCanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h2>{{'Add Sub minor Details' | translate}} </h2><br />
                    <button type="button" class="close" data-dismiss="modal"
                        (click)="close('subMinorCanel')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">


                    <form [formGroup]="subMinorPopupForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Main Sub Minor Code</label>
                            <input class="input-box" formControlName="subminor_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">{{"Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_name" type="text"
                                    (blur)="translateMarathi($event, 'subminorcanel')"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_name'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_name'].dirty || subMinorPopupForm.controls['subminor_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_name'].errors.required">
                                        {{'Subminor Name (English ) is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_name'].errors.pattern">
                                        Only characters allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_name_marathi'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_name_marathi'].dirty || subMinorPopupForm.controls['subminor_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_name_marathi'].errors.required">
                                        {{'Subminor Marathi Name is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                </div>
                            </li>
                            <!-- <li>
                            <label class="label">Main Sub Minor Structure</label>
                            <input class="input-box" formControlName="subminor_canal_structure" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">{{"Chainage KM" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_chainage_km"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_chainage_km'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_chainage_km'].dirty || subMinorPopupForm.controls['subminor_canal_chainage_km'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_chainage_km'].errors.required">
                                        {{'Chainage KM is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_chainage_km'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"ICA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_ica" type="text"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_ica'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_ica'].dirty || subMinorPopupForm.controls['subminor_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>


                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.subminorica">
                                        ICA must be less than CCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"CCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_cca" type="text"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_cca'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_cca'].dirty || subMinorPopupForm.controls['subminor_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_cca'].errors.required">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.subminorcca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"GCA"| translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="subminor_canal_gca" type="text"><br /><br />
                                <div
                                    *ngIf="subMinorPopupForm.controls['subminor_canal_gca'].invalid &&
                                (subMinorPopupForm.controls['subminor_canal_gca'].dirty || subMinorPopupForm.controls['subminor_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="subMinorPopupForm.controls['subminor_canal_gca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.subminorgca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <input class="stepgreenbtn" (click)="submitSubMinorCanalDetails()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- end subminor -->
    <!-- outlet popup start -->
    <div class="modal fade very-pop show" id="outlet" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h2>{{'Add Outlet Details' | translate}} </h2><br />
                    <button type="button" class="close" data-dismiss="modal" (click)="close('outlet')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">


                    <form [formGroup]="outletForm" class="main-canal">
                        <ul>
                            <!-- <li>
                            <label class="label">Main Sub Minor Code</label>
                            <input class="input-box" formControlName="subminor_canal_code" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">{{'Name' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_name" type="text"
                                    (blur)="translateMarathi($event, 'outlet')"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_name'].invalid &&
                                (outletForm.controls['outlet_canal_name'].dirty || outletForm.controls['outlet_canal_name'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_name'].errors.required">
                                        {{'Outlet Name (English ) is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_name'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_name'].errors.pattern">
                                        Only characters allowed!
                                    </p>
                                </div>
                            </li>
                            <li>
                                <label class="label">{{"Marathi Name" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_name_marathi"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_name_marathi'].invalid &&
                                (outletForm.controls['outlet_canal_name_marathi'].dirty || outletForm.controls['outlet_canal_name_marathi'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_name_marathi'].errors.required">
                                        {{'Outlet Name (Marathi ) is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_name_marathi'].errors.maxlength">
                                        80 characters allowed!
                                    </p>
                                </div>
                            </li>
                            <!-- <li>
                            <label class="label">Main Sub Minor Structure</label>
                            <input class="input-box" formControlName="subminor_canal_structure" type="text"><br /><br />
                        </li> -->
                            <li>
                                <label class="label">{{'Side of outlet' | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <select class="input-box" formControlName="outlet_canal_position">
                                    <option *ngFor="let list of outletCanalSide" value="{{list.master_common_id}}">
                                        <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                            {{list.common_marathi_name}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            {{list.common_name}}
                                        </ng-template>
                                    </option>
                                </select>
                                <br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_position'].invalid &&
                            (outletForm.controls['outlet_canal_position'].dirty || outletForm.controls['outlet_canal_position'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_position'].errors.required">
                                        Outlet Canal Position is required!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"Chainage KM" | translate}}<strong
                                        style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_offtaking_chainage_km"
                                    type="text"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_offtaking_chainage_km'].invalid &&
                                (outletForm.controls['outlet_canal_offtaking_chainage_km'].dirty || outletForm.controls['outlet_canal_offtaking_chainage_km'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_offtaking_chainage_km'].errors.required">
                                        {{'Chainage KM is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_offtaking_chainage_km'].errors.pattern">
                                        Only Alpha-Numeric is allowed!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"ICA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_ica" type="text"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_ica'].invalid &&
                                (outletForm.controls['outlet_canal_ica'].dirty || outletForm.controls['outlet_canal_ica'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_ica'].errors.required">
                                        {{'ICA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_ica'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>
                                <div>
                                    <p style="color: brown;" *ngIf="!this.outletica">
                                        ICA must be less than CCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"CCA" | translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_cca" type="text"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_cca'].invalid &&
                                (outletForm.controls['outlet_canal_cca'].dirty || outletForm.controls['outlet_canal_cca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_cca'].errors.outletForm">
                                        {{'CCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_cca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.outletcca">
                                        CCA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>
                                <label class="label">{{"GCA"| translate}}<strong style="color: red;">*</strong></label>
                                <input class="input-box" formControlName="outlet_canal_gca" type="text"><br /><br />
                                <div
                                    *ngIf="outletForm.controls['outlet_canal_gca'].invalid &&
                                (outletForm.controls['outlet_canal_gca'].dirty || outletForm.controls['outlet_canal_gca'].touched)">
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_gca'].errors.required">
                                        {{'GCA is required!' | translate}}
                                    </p>
                                    <p style="color: brown;"
                                        *ngIf="outletForm.controls['outlet_canal_gca'].errors.pattern">
                                        Only number allowed & 10 digits before decimal & 2 after are allowed!
                                    </p>
                                </div>

                                <div>
                                    <p style="color: brown;" *ngIf="!this.outletgca">
                                        ICA must be less than GCA!
                                    </p>
                                </div>
                            </li>

                            <li>

                                <input class="stepgreenbtn" (click)="submitOutletForm()" type="Submit"
                                    value="{{'Submit' | translate}}" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- outlet popup end -->