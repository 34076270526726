import { Injectable ,ChangeDetectorRef, Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  fontSize = 14;
  @ViewChild('para', { static: true }) para: ElementRef;
  constructor() { }

  private subject = new Subject<any>();
  private font  = new Subject<number>();
sendClickEvent() {
 // console.log("test22")
  this.subject.next();
}
getClickEvent(): Observable<any>{ 
  //console.log("test33")
  return this.subject.asObservable();
}
changeFont(operator) { 
 operator === '+' ? this.fontSize++ : this.fontSize--;
  this.font.next(this.fontSize)
  //return (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;
  
}
getFontChangeEvent(): Observable<any>{ 

  return this.font.asObservable();
}

}
