import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  mobile: any;
  validNumber: number;
  ngOnInit() {
    localStorage.removeItem('farmer_master_id');
    this.loginForm = this.fb.group({
      mobilenumber: ['']
      //mobilenumber: ['', [Validators.required, Validators.minLength(10)]]
    });


  }

  keyPress(event: any) {


    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }


  }

  onEnterKeyDown(event) {

    if (event.keyCode === 13) {

      if (this.loginForm.invalid) {
        return;
      }

      // console.log('Mobile', this.loginForm.value.mobilenumber);

      this.sendOtp()
    }
    // here you can open your confirmation modal if the form is valid
  }
  onSubmit(form: FormGroup) {

    //let status=0;
    if (this.loginForm.invalid) {
      return;
    }

    // console.log('Mobile', this.loginForm.value.mobilenumber);

    this.sendOtp()

  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
  ) { }

  sendOtp() {
    let data = {};

    // data['Mobile_Number'] = this.loginForm.value.mobilenumber ;

    const body = new HttpParams({
      fromObject: {
        mobile_no: this.loginForm.value.mobilenumber,
      }
    });


    //let data = {'Mobile_Number':9720406603};
    this.validNumber = 0;
    console.log(body);
    
    this.http.getLoginValidNumber(body).subscribe((resolve: any) => {
      console.log(resolve);
      let data = resolve;
      //console.log(resolve.status)
      if (data.status == 0) {

        this.validNumber = 1;

        return false;

      } else {
        this.router.navigate(['otp']);
        localStorage.setItem('mobile', this.loginForm.value.mobilenumber);
      }
      //this.common.mobile = resolve;

    },
      (err) => {

        console.log(err);


      }
    )
  }



}
