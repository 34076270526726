<div class="dashboardpg">

    <!-- <p #para> hio shreya!!</p> -->
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <!-- <div [ngStyle]="{'font-size': size + 'px'}">
            style using ngStyle
          </div> -->

        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->

            <!-- <p #para>dsfsdfsdfsdfsd</p> -->
            <div class="greenstrip fullwidth">
                <h1>{{'Project Master' | translate}}</h1>
            </div>

            <div class="mid-content fullwidth tablerecordpg">

                <div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Corporation' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCor" disabled type="text"
                                value="{{this.data.office_detail?.Corporation}}"></span>

                        <select *ngIf="showCor" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Corporation',1)">

                            <option *ngFor="let list of CoprationArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Region' | translate}}</label>
                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Region}}"></span>
                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Region',1)">

                            <option *ngFor="let list of RegionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>
                <div class="row frmrow">
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Circle' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showCir" disabled type="text"
                                value="{{this.data.office_detail?.circle}}"></span>

                        <select *ngIf="showCir" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Circle',1)">

                            <option *ngFor="let list of CircleArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>

                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showDiv" disabled type="text"
                                value="{{this.data.office_detail?.Division}}"></span>


                        <select *ngIf="showDiv" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Division','1')">

                            <option *ngFor="let list of DivisionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Sub Division' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSub" disabled type="text"
                                value="{{this.data.office_detail?.subDivis}}"></span>
                        <select *ngIf="showSub" class="stepinpt"
                            (change)="getChangeOfficeDetails($event.target.value,'Sub Division','1')">

                            <option *ngFor="let list of SubDivArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                    <div class="col-md-3 inptbx">
                        <label class="steplbl">{{'Section' | translate}}</label>

                        <span> <input class="readonly" *ngIf="!showSec" disabled type="text"
                                value="{{this.data.office_detail?.Section}}"></span>


                        <select class="stepinpt" *ngIf="showSec" 
                        (change)="getChangeOfficeDetails($event.target.value,'Section','1')">
                            <option *ngFor="let list of SectionArr" [value]="list.office_id">
                                <!-- {{list.district_name}} -->
                                <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                    {{list.office_name_marathi}}
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{list.office_name}}
                                </ng-template>
                            </option>

                        </select>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-4 col-md-2">
                    <div class="btn btn-default btn-search-advanced btn-block" role="tab" id="headingOne"><a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></a></div>

                <button type="button" class="stepgreenbtn" (click)="projectMasterSearchFormSubmit()" data-dismiss="modal">{{'Filter' | translate}}</button>
                </div>

                <!-- table datat start-->
                <div class="viewpro-table">
                    <div class="viewpro-table-ab">
                        <ng-container *ngIf="enableAddOption">
                            <div class="page-top-btn inline-div">

                                <input (click)="Add()" class="topgreenbtn" type="button"
                                    value="{{'Add' | translate}}" />
                                <input (click)="exportAsXLSX()" class="topgreenbtn" type="button"
                                    value="{{'Export' | translate}}" />
                            </div>
                        </ng-container>
                    </div>


                    <table id='datatables' width="100%" datatable [dtOptions]="this.dtOptions" [dtTrigger]="dtTrigger">

                        <!-- <table width = "100%" datatable > -->
                        <thead>
                            <tr>
                                <th>{{'Project Name project page' | translate}}</th>
                                <th>{{'Type of project' | translate}}</th>
                                <th>{{'Status of project' | translate}}</th>
                                <th>{{'Ownership of the project' | translate}}</th>
                                <th>{{'Interstate' | translate}}</th>
                                <th>{{'Primary District' | translate}}</th>
                                <th>{{'Primary Taluka' | translate}}</th>
                                <th>{{'Project Approved' | translate}}</th>
                                <th>{{'Action' | translate}}</th>
                                <!-- {{data.action}} -->
                            </tr>
                        </thead>



                        <tfoot class="search-tr-top">
                            <tr>

                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-project_name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-first-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Wua-name" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-District" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Taluka-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Village-name" /></th>
                                <th><input type="text" placeholder="{{'search' |translate}}" name="search-Father" />
                                </th>
                                <th><input type="text" placeholder="{{'search' |translate}}"
                                        name="search-Mobile-name" /></th>

                                <th></th>

                            </tr>
                        </tfoot>


                        <tbody>

                            <tr *ngFor="let list of dataArray">
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                        {{list.project_name_marathi}}
                                    </ng-container>
                                    <ng-template #elseProjectBlock>
                                        {{list.project_name}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDamBlock">
                                        {{list.mwrd_project_type_master?.project_type_marathi}}
                                    </ng-container>
                                    <ng-template #elseDamBlock>
                                        {{list.mwrd_project_type_master?.project_type}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBankBlock">
                                        {{list.mwrd_project_status_master?list.mwrd_project_status_master.project_status_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBankBlock>
                                        {{list.mwrd_project_status_master?list.mwrd_project_status_master.project_status:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMainBlock">
                                        {{list.mwrd_project_ownership_master?list.mwrd_project_ownership_master.project_marathi_owner_name:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMainBlock>
                                        {{list.mwrd_project_ownership_master?list.mwrd_project_ownership_master.project_owner_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseBranchBlock">
                                        {{list.mwrd_common_master?list.mwrd_common_master.common_marathi_name:'NA'}}
                                    </ng-container>
                                    <ng-template #elseBranchBlock>
                                        {{list.mwrd_common_master?list.mwrd_common_master.common_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseDistBlock">
                                        {{list.mwrd_district_master?list.mwrd_district_master.district_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseDistBlock>
                                        {{list.mwrd_district_master?list.mwrd_district_master.district_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'mr';else elseMinorBlock">
                                        {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name_marathi:'NA'}}
                                    </ng-container>
                                    <ng-template #elseMinorBlock>
                                        {{list.mwrd_taluka_master?list.mwrd_taluka_master.taluka_name:'NA'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-container *ngIf="selectedlang == 'en';else elseApprovedBlock">
                                        {{list.project_is_approved=='0'?'No':'Yes'}}
                                    </ng-container>
                                    <ng-template #elseApprovedBlock>
                                        {{list.project_is_approved=='0'?'नाही':'होय'}}
                                    </ng-template>
                                </td>
                                <td>
                                    <ul class="tbaction">
                                        <ng-container
                                            *ngIf="list.project_is_approved=='0' && list.project_added_by_user_office_type=='Division' && enableApproveOption">
                                            <li>
                                                <i (click)="approveProject(list.project_id)" class="fa fa-check">
                                                </i>
                                            </li>
                                        </ng-container>
                                        <li title="View">
                                            <i class="" aria-hidden="true" (click)="enableViewModel(list.project_id)">
                                                {{ 'View' | translate }}
                                            </i>
                                        </li>
                                        <ng-container *ngIf="list.project_is_approved=='0' && enableEditOption">
                                            <li title="Edit">
                                                <i class="" (click)="editButtonClick(list.project_id)"
                                                    aria-hidden="true">
                                                    {{ 'Edit' | translate }}
                                                </i>
                                            </li>
                                        </ng-container>

                                    </ul>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <br />

                <!-- table datat over-->

            </div>

            <!-- mid content over-->

        </div>
        <div class="masterpopup modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                        <h2>{{'View Project Details'|translate}}</h2>
                        <br />
                        <button type="button" class="close" data-dismiss="modal" (click)="close()">&times;</button>
                    </div>
                    <div class="modal-body">

                        <ul class="pojectmstrbx">
                            <li class="prjhead">{{'Project Details'|translate}}</li>
                            <li>
                                <label>{{'Name of Project (English )'|translate}}</label>
                                <div>
                                    <p>{{aProjectDetails?.data?.project_name}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Name of Project Marathi'|translate}}</label>
                                <div>
                                    <p>{{aProjectDetails?.data?.project_name_marathi}}</p>
                                </div>
                            </li>
                            <li>
                                <label>{{'Type of project'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectTypeBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_project_type_master?.project_type_marathi}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectTypeBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_project_type_master?.project_type}}</p>
                                    </ng-template>

                                </div>
                            </li>
                            <li>
                                <label>{{'Status of project'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectStatusBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_project_status_master?.project_status_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectStatusBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_project_status_master?.project_status}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Ownership of the project'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectOwnershipBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_project_ownership_master?.project_marathi_owner_name}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectOwnershipBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_project_ownership_master?.project_owner_name}}
                                        </p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Interstate' | translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang == 'en';else elseProjectInterstateBlock">
                                        <p>{{aProjectDetails?.data?.mwrd_common_master?.common_name}}</p>
                                    </ng-container>
                                    <ng-template #elseProjectInterstateBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_common_master?.common_marathi_name}}</p>
                                    </ng-template>
                                    <!-- <p>pta nhi</p> -->
                                </div>
                            </li>
                            <li *ngIf="aProjectDetails?.data?.project_is_interstate=='2'">
                                <label>{{'State'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectOwnershipBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_state_master?.state_marathi_name}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectOwnershipBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_state_master?.state_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Primary District'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDistrictBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_district_master?.district_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectDistrictBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_district_master?.district_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Primary Taluka'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectTalukaBlock;">
                                        <p>{{aProjectDetails?.data?.mwrd_taluka_master?.taluka_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectTalukaBlock>
                                        <p>{{aProjectDetails?.data?.mwrd_taluka_master?.taluka_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li class="prjbglist">
                                <label>{{'Secondary District'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_districts">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_districts">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecDistrictBlock;">
                                            {{list?.district_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecDistrictBlock>
                                            {{list?.district_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>
                            <li>
                                <label>{{'Secondary Taluka'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_talukas">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_talukas">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecTalukaBlock;">
                                            {{list?.taluka_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecTalukaBlock>
                                            {{list?.taluka_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjhead">{{'Primary Authorities of Project'|translate}}</li>

                            <li>
                                <label>{{'Corporation'|translate}} 
                                </label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCorprationBlock;">
                                        <p>{{aProjectDetails?.data?.primary_corporations?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCorprationBlock>
                                        <p>{{aProjectDetails?.data?.primary_corporations?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Region'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectRegionBlock;">
                                        <p>{{aProjectDetails?.data?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectRegionBlock>
                                        <p>{{aProjectDetails?.data?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Circle'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectCircleBlock;">
                                        <p>{{aProjectDetails?.data?.primary_regions?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectCircleBlock>
                                        <p>{{aProjectDetails?.data?.primary_regions?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectDivisionBlock;">
                                        <p>{{aProjectDetails?.data?.primary_division?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectDivisionBlock>
                                        <p>{{aProjectDetails?.data?.primary_division?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Sub Division'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSubdivisionBlock;">
                                        <p>{{aProjectDetails?.data?.primary_subdivision?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSubdivisionBlock>
                                        <p>{{aProjectDetails?.data?.primary_subdivision?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>
                            <li>
                                <label>{{'Section'|translate}}</label>
                                <div>
                                    <ng-container *ngIf="selectedlang=='mr';else elseProjectSectionBlock;">
                                        <p>{{aProjectDetails?.data?.primary_section?.office_name_marathi}}
                                        </p>
                                    </ng-container>
                                    <ng-template #elseProjectSectionBlock>
                                        <p>{{aProjectDetails?.data?.primary_section?.office_name}}</p>
                                    </ng-template>
                                </div>
                            </li>

                            <li class="prjhead">{{'Secondary Authorities of Project'|translate}}</li>

                            <li class="prjbglist">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <div class="pup-more-content">
                                        <p>Executive Director, Maharashtra Krishna Valley Development Corporation - Pune (M Corp.)</p>
                                        <p>Executive Director, Konkan Irrigation Development Corporation - Thane (M Corp.)</p>
                                        <p>Executive Director, Tapi Irrigation Development Corporation - Jalgaon (M Corp.)</p>
                                        <p>Executive Director, Vidarbha Irrigation Development Corporation - Nagpur (M Corp.)</p>
                                    </div>
                                <label>{{'Corporation'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_corporations">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_corporations">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCorporationsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecCorporationsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>{{'Region'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_regions">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_regions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecRegionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecRegionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>{{'Circle'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_circles">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_circles">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecCirclesBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecCirclesBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>{{'Division'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_divisions">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_divisions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecDivisionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecDivisionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>{{'Sub Division'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_subdivisions">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_subdivisions">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSubDivisionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecSubDivisionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                            <li class="prjbglist">
                                <label>{{'Section'|translate}}</label>
                                <div *ngIf="aProjectDetails?.project_secondary_sections">
                                    <p *ngFor="let list of aProjectDetails.project_secondary_sections">
                                        <ng-container *ngIf="selectedlang=='mr';else elseProjectSecSectionsBlock;">
                                            {{list?.office_name_marathi}}
                                        </ng-container>
                                        <ng-template #elseProjectSecSectionsBlock>
                                            {{list?.office_name}}
                                        </ng-template>
                                    </p>

                                </div>
                            </li>

                        </ul>


                    </div>
                </div>
            </div>
        </div>
        <!-- dashboard right panel over-->
    </div>

</div>