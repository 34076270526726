import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { HeaderComponent } from '../header/header.component';
import { DataTableDirective } from 'angular-datatables';
// import "rxjs/add/operator/map";
import { DomSanitizer } from '@angular/platform-browser';
// import * as XLSX from 'xlsx'; 
import { ExcelService } from '../_service/excel.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-demo-datatables',
  templateUrl: './demo-datatables.component.html',
  styleUrls: ['./demo-datatables.component.css']
})
export class DemoDatatablesComponent implements OnInit {

  dataArray = []

  currentPage = 1;
  itemsPerPage = 5;
  pageSize: number;

  dtTrigger: Subject<any> = new Subject();
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  data: any = {};
  en_data: any = {
    farmer: "Farmer Master",
    add: "Add",
    project: "Project",
    dam: "Dam",
    wua: "WUA",
    gut_no: "GUT No.",
    Area_of_gut: " Area of Gut(Ha)",
    owned_area: "Owned Area Excl Pot Kharab (Ha)",
    owned_pot_area: "Owned Pot Kharab Area(Ha)",
    farmer_name: "Farmer Name",
    Farmer_Mobile: "Farmer Mobile Number",
    Farmer_District: "District",
    Farmer_Taluka: "Taluka",
    Farmer_Village: "Village",
    Farmer_project: "Project Name",
    Farmer_dam: "Dam Name",
    Farmer_irrigation: "Irrigation Source",
    Wua_details: "WUA Details",
    Action: "Action",
    select_all: "Select All",
    select: "Select",
    save: "Save",
    Ok: "OK",
    cancel_string: "Cancel",
    searchPlaceholderText: "Search",
    un_select_all: "UnSelect All",
  }
  mr_data: any = {
    farmer: "शेतकऱ्यांची माहिती",
    add: "माहिती भरा",
    farmer_name: "शेतकरी नाव",
    Farmer_Mobile: "शेतकऱ्याचा मोबाईल क्र.",
    Farmer_District: "जिल्हा",
    Farmer_Taluka: "तालुका",
    Farmer_Village: "गाव",
    Farmer_project: "प्रकल्पाचे",
    Farmer_dam: "धरणाचे",
    Farmer_irrigation: "सिंचनाचा",
    Wua_details: "पाणी वापर संस्था",
    Action: "कृती",
    project: "प्रकल्पाचे नाव",
    dam: "धरणाचे नाव",
    wua: "पाणी वापर संस्थेचे  नाव",
    gut_no: "गट क्र. ",
    Area_of_gut: "गट क्षेत्र(हे)",
    owned_area: "स्वतःची मिळकत (पोट खराब वगळून)",
    owned_pot_area: "पोट खराब क्षेत्र(हे)",
    select_all: "सर्व पर्याय निवडा",
    select: "निवडा",
    save: "जतन करा",
    Ok: "होय",
    un_select_all: "निवडलेले सर्व काढा",
    cancel_string: "नाही",
    searchPlaceholderText: "शोधा"
  }
  page: Number
  collectionSize: Number
  selectedItems: any = [];
  clickEventsubscription: Subscription;
  selectedlang = localStorage.getItem('selected_lang');
  userOfficeType
  userPosition
  isAdmin
  enableAddOption
  enableEditOption
  enableApproveOption
  //datatableElement: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    public translate: TranslateService,
    private sharedService: SharedService,
    private ngxService: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private excelService: ExcelService,
    public datepipe: DatePipe,
    public cdr: ChangeDetectorRef,
  ) {
    this.collectionSize = 10;
    this.page = 1
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.selectedlang = localStorage.getItem('selected_lang');
      //  alert(this.selectedlang)
      this.ngOnInit();
    })
  }

  ngOnInit(): void {
    this.getUserDetails()
  }

  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });


    this.http.getUserDetail(body).subscribe((resolve: any) => {

      // console.log(resolve.data.userDetail);
      // console.log("ll")
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division

      if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (resolve.data.userDetail.user_type == 0)) {
        this.isAdmin = resolve.data.userDetail.user_type == 0 ? 0 : 1
        this.enableAddOption = true
        this.enableEditOption = true
      }

      // console.log(this.userOfficeType + "=====" + this.userPosition);


      if (this.userOfficeType == "Circle") {
        this.enableApproveOption = true
      }
    });

    this.getData(1)
  }

  getData(no) {

    this.dataArray = []
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id')
      }
    });
    this.http.getViewProjectsLayout(body).subscribe((data: any) => {
      // setTimeout(() => {
      // console.log(data)
      this.dataArray = data.data;

      this.dtTrigger.next();
      //  this.ngOnDestroy()
      // this.ngAfterViewInit()
      // }, 3000);
    })
  }

  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      //this.dtTrigger.next();
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();

              // console.log(that.search(this['value']).draw())
            }
          });
        });
        this.dtTrigger.complete();
      });
    });
  }

  Add() {

    // this.router.navigate(['StepOne']);
    // this.ngxService.stop();
    this.router.navigate(['addFarmer']);

  }

}
