<body class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position">
        <!-- dashboard left panel start-->
        <!-- <app-sidebar></app-sidebar> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- header over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'WUA Master'| translate}}</h1>
            </div>
            <div class="stepimg fullwidth">
                <!--<img src="assets/images/step3.jpg" alt="step2" />-->
            </div>
            <!-- dashboardbnnr over-->
            <!-- mid content start-->
            <div class="mid-content fullwidth formstep">


                <!-- row start-->
                <div class="row">
                    <div class="stepfrmcmn stepfrm1">
                        <form [formGroup]="wuaForm">
                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'General' | translate}}</span></h2>

                            </div>
                            <div class="row frmrow">
                                <div class="col-md-4 inptbx">
                                    <label for="project" class="steplbl">{{'Project Name' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <!--<select class="stepinpt" formControlName="projectSelectname"  (change)="changeProject($event)" > -->
                                    <select class="stepinpt" formControlName="wua_project_primary_id"
                                        (change)="getDamList($event.target.value)">
                                        <option value="null">{{'please_select_project'|translate}}</option>
                                        <option *ngFor="let list of projectSelect;" [value]="list.project_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{list.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{list.project_name}}
                                            </ng-template>
                                        </option>

                                    </select>

                                    <div
                                        *ngIf="wuaForm.controls['wua_project_primary_id'].invalid &&
                    (wuaForm.controls['wua_project_primary_id'].dirty || wuaForm.controls['wua_project_primary_id'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_project_primary_id'].errors.required">
                                            {{'please select Project!' | translate}}
                                        </p>

                                    </div>
                                </div>


                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'DamName' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_dam_primary_id"
                                        (change)="getDistrictList($event.target.value)">
                                        <option value="null">{{'please_select_dam'|translate}}</option>
                                        <option *ngFor="let dams of damSelect;" [value]="dams.dam_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{dams.dam_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{dams.dam_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_dam_primary_id'].invalid &&
                        (wuaForm.controls['wua_dam_primary_id'].dirty || wuaForm.controls['wua_dam_primary_id'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_dam_primary_id'].errors.required">
                                           {{'please select dam!' | translate}}
                                        </p>

                                    </div>
                                </div>
                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Basin' | translate}}</label>
                                    <select class="stepinpt" formControlName="basin" formControlName="wau_river_basin"
                                        (change)="getSubBasins($event.target.value)">
                                        <option *ngFor="let list of getdamBasinsArray" [value]="list.basin_master_id">

                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.basin_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.basin_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wau_river_basin'].invalid &&
                                (wuaForm.controls['wau_river_basin'].dirty || wuaForm.controls['wau_river_basin'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="wuaForm.controls['wau_river_basin'].errors.required">
                                            Please select Basin!
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Sub-Basin' | translate}}</label>
                                    <select class="stepinpt" formControlName="wua_river_subbasin">
                                      
                                        <option *ngFor="let list of getdamSubBasinsArray"
                                            [value]="list.subbasin_basin_id">

                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.subbasin_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.subbasin_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_river_subbasin'].invalid &&
                                (wuaForm.controls['wua_river_subbasin'].dirty || wuaForm.controls['wua_river_subbasin'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="wuaForm.controls['wua_river_subbasin'].errors.required">
                                            Please select Sub-Basin!
                                        </p>
                                    </div>
                                </div> -->


                            </div>
                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'WUA Details' | translate}}</span></h2>
                            </div>
                            <div class="row frmrow">

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'WUA Name' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" (blur)="translateIntoMarathi($event.target.value,'name')"
                                        formControlName="wua_name" placeholder="" class="stepinpt">
                                    <div *ngIf="wuaForm.controls['wua_name'].invalid &&
                        (wuaForm.controls['wua_name'].dirty || wuaForm.controls['wua_name'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_name'].errors.required">
                                            {{'Please enter WUA Name!' | translate}}
                                        </p>

                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'WUA Marathi Name' | translate }}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_name_marathi" placeholder=""
                                        class="stepinpt">

                                    <div
                                        *ngIf="wuaForm.controls['wua_name_marathi'].invalid &&
                                        (wuaForm.controls['wua_name_marathi'].dirty || wuaForm.controls['wua_name_marathi'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_name_marathi'].errors.required">
                                           {{'Please enter WUA Marathi Name!' | translate}}
                                        </p>

                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Address Of WUA' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_address"
                                        (blur)="translateIntoMarathi($event.target.value,'address')" placeholder=""
                                        class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_address'].invalid &&
                                        (wuaForm.controls['wua_address'].dirty || wuaForm.controls['wua_address'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_address'].errors.required">
                                            {{'Please enter WUA Address!' | translate}}
                                        </p>

                                    </div>

                                </div>
                                <!--/div>
                            <div class="row frmrow"-->

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Address Of WUA Marathi' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_address_marathi" placeholder=""
                                        class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_address_marathi'].invalid &&
                                        (wuaForm.controls['wua_address_marathi'].dirty || wuaForm.controls['wua_address_marathi'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_address_marathi'].errors.required">
                                            {{'Please enter WUA Marathi Address!' | translate}}
                                        </p>

                                    </div>
                                </div>



                                <div class="modal fade" id="myModal1" role="dialog" style="width: 100%;">
                                    <div class="modal-dialog">

                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">{{"Location"| translate}}</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'District' | translate}} <span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <select class="stepinpt"
                                                            [(ngModel)]="reqData.wua_bennefitted_district_code"
                                                            formControlName="wua_bennefitted_district_code"
                                                            (change)="forFamerTaluka1($event.target.value)">
                                                            <option *ngFor="let list of getDistArray1"
                                                                [value]="list.district_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.district_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.district_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'Taluka' | translate}} <span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <select class="stepinpt"
                                                            [(ngModel)]="reqData.wua_bennefitted_taluka_code"
                                                            formControlName="wua_bennefitted_taluka_code"
                                                            (change)="forarmerVillage1($event.target.value)">
                                                            <option *ngFor="let list of getFarmerTaluka1"
                                                                [value]="list.taluka_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.taluka_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.taluka_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'Village' | translate}}<span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <select class="stepinpt"
                                                            [(ngModel)]="reqData.wua_bennefitted_village_code"
                                                            formControlName="wua_bennefitted_village_code"
                                                            (change)="getAllVillage1($event.target.value)">
                                                            <option *ngFor="let list of getFarmerVillage1"
                                                                [value]="list.village_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.village_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.village_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="stepgreenbtn"
                                                    data-dismiss="modal">{{'Submit' | translate}}</button>
                                                &nbsp;
                                                <!--<button type="button" class="btn btn-default" data-dismiss="modal">{{'Close' | translate}}</button>-->
                                            </div>
                                        </div>

                                    </div>




                                </div>


                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Location" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <span><i class="fa fa-chevron-right stepinpt" aria-hidden="true" data-toggle="modal"
                                            data-target="#myModal">
                                            <ng-container *ngIf="language == 'mr';else elseBlock1">
                                                {{getFarmerDistructNameMarathi}} |{{farmerTallukaNameMarathi}} |
                                                {{farmerVillageNameMarathi}}
                                            </ng-container>
                                            <ng-template #elseBlock1>
                                                {{getFarmerDistructName}} | {{farmerTallukaName}} |
                                                {{farmerVillageName}}
                                            </ng-template>
                                        </i> </span>
                                </div>



                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Pin Code" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_pincode" placeholder="" class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_pincode'].invalid &&
                                    (wuaForm.controls['wua_pincode'].dirty || wuaForm.controls['wua_pincode'].touched)">
                                        <p class="error-msg-ab" style="color: red;" *ngIf="wuaForm.controls['wua_pincode'].errors.required">
                                            {{'Pincode is required!' | translate}}
                                        </p>
                                        <p class="error-msg-ab" style="color: red;" *ngIf="wuaForm.controls['wua_pincode'].errors.pattern">
                                            Only number allowed!
                                        </p>
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_pincode'].errors.maxlength || wuaForm.controls['wua_pincode'].errors.minlength">
                                            Pincode must be of 6 digits!
                                        </p>
                                    </div>

                                </div>





                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'WUA Type' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_type">
                                        <option value="null">{{'please_select_wua_type'|translate}}</option>
                                        <option *ngFor="let list of wuaTypeSelect;" [value]="list.wua_type_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.wua_type_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.wua_type}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div *ngIf="wuaForm.controls['wua_type'].invalid &&
                                    (wuaForm.controls['wua_type'].dirty || wuaForm.controls['wua_type'].touched)">
                                        <p class="error-msg-ab" style="color: red;" *ngIf="wuaForm.controls['wua_type'].errors.required">
                                            {{'WUA Type is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'WUA Category' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_category">
                                        <option value="null">{{'please_select_wua_category'|translate}}</option>
                                        <option *ngFor="let list of wuaCategorySelect;" [value]="list.wua_category_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseCategoryBlock">
                                                {{list.wua_category_marathi}}
                                            </ng-container>
                                            <ng-template #elseCategoryBlock>
                                                {{list.wua_category}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_category'].invalid &&
                                    (wuaForm.controls['wua_category'].dirty || wuaForm.controls['wua_category'].touched)">
                                        <p class="error-msg-ab" style="color: red;" *ngIf="wuaForm.controls['wua_category'].errors.required">
                                            {{'WUA Category is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Territorial Reach' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_territorial_reach">
                                        <option value="null">{{'please_select_territorial_reach'|translate}}</option>
                                        <option *ngFor="let list of territorialSelect;"
                                            [value]="list.wua_territorial_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{list.wua_territorial_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.wua_territorial}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_territorial_reach'].invalid &&
                                    (wuaForm.controls['wua_territorial_reach'].dirty || wuaForm.controls['wua_territorial_reach'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_territorial_reach'].errors.required">
                                            {{'WUA Territorial Reach is required!' | translate}}
                                        </p>
                                    </div>
                                </div>


                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'WUA Under MMISF Act/Co-operative soceity' |
                                        translate}}<span aria-hidden="true" style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_under_mmisf_act_cooperative_society">
                                        <option value="null">{{'please_select_wua_under_mmisf_act'|translate}}</option>
                                        <option *ngFor="let list of cooperativeSelect;" [value]="list.wua_act_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseActBlock">
                                                {{list.wua_act_marathi}}
                                            </ng-container>
                                            <ng-template #elseActBlock>
                                                {{list.wua_act}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_under_mmisf_act_cooperative_society'].invalid &&
                                    (wuaForm.controls['wua_under_mmisf_act_cooperative_society'].dirty || wuaForm.controls['wua_under_mmisf_act_cooperative_society'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_under_mmisf_act_cooperative_society'].errors.required">
                                            {{'WUA under MMISF act cooperative society is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Registration No' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_registration_no" placeholder=" "
                                        class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_registration_no'].invalid &&
                                    (wuaForm.controls['wua_registration_no'].dirty || wuaForm.controls['wua_registration_no'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_registration_no'].errors.required">
                                            {{'WUA Registration no. is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Registration Date' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="date" formControlName="wua_registration_date" placeholder=""
                                        class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_registration_date'].invalid &&
                                    (wuaForm.controls['wua_registration_date'].dirty || wuaForm.controls['wua_registration_date'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_registration_date'].errors.required">
                                            {{'WUA Registration Date is required!' | translate}}
                                        </p>
                                    </div>
                                </div>


                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Status' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!--<input type="text"  formControlName="wuaStatus"  placeholder="Status" class="stepinpt" >-->
                                    <select class="stepinpt" formControlName="wua_status">
                                        <option value="null">{{'please_select_wua_status' | translate}}</option>

                                        <option *ngFor="let list of wuaStatusSelect;" [value]="list.wua_status_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseWuaStatusBlock">
                                                {{list.wua_status_marathi}}
                                            </ng-container>
                                            <ng-template #elseWuaStatusBlock>
                                                {{list.wua_status}}
                                            </ng-template>
                                        </option>
                                    </select>

                                    <div *ngIf="wuaForm.controls['wua_status'].invalid &&
                                    (wuaForm.controls['wua_status'].dirty || wuaForm.controls['wua_status'].touched)">
                                        <p class="error-msg-ab" style="color: red;" *ngIf="wuaForm.controls['wua_status'].errors.required">
                                            {{'WUA Status is required!' | translate}}
                                        </p>
                                    </div>

                                </div>



                                <!--/div>

                            <div class="row frmrow"-->

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Measuring Device Installed' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <select formControlName="wua_measuring_installed" placeholder="" class="stepinpt">
                                        <option value="null">{{'please_select_measuing_device_installed' | translate}}
                                        </option>
                                        <option *ngFor="let list of yesOrNo" value="{{list.master_common_id}}">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.common_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.common_name}}
                                            </ng-template>
                                        </option>
                                    </select>

                                    <div
                                        *ngIf="wuaForm.controls['wua_measuring_installed'].invalid &&
                                    (wuaForm.controls['wua_measuring_installed'].dirty || wuaForm.controls['wua_measuring_installed'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_measuring_installed'].errors.required">
                                            WUA Measuring Installed is required!
                                        </p>
                                    </div>

                                </div>
                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Basin | SubBasin' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <span><i class=" stepinpt" aria-hidden="true">
                                            <ng-container *ngIf="language == 'mr';else elseBlockSubBasin">
                                                {{basinMarathiName}} | {{subbasinMarathiName}}
                                            </ng-container>
                                            <ng-template #elseBlockSubBasin>
                                                {{Basin}} | {{SubBasin}}
                                            </ng-template>
                                        </i> </span>
                                </div>

                            </div>

                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Irrigation Details' | translate}}</span></h2>

                            </div>


                            <div class="row frmrow">

                                <div class="col-md-2 inptbx">
                                    <label class="steplbl">{{'Irrigation Source' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="wua_irregation_source"
                                        (change)="toggleShow($event.target.value)">
                                        <option value="null">{{'please_select_irrigation_source' | translate}}</option>
                                        <option *ngFor="let irrigation of irrigationSelect;"
                                            [value]="irrigation.irrigation_source_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlockIrrigation">
                                                {{irrigation.irrigation_source_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlockIrrigation>
                                                {{irrigation.irrigation_source}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>


                                <div class="col-md-2 inptbx" *ngIf='showbankType'>
                                    <label class="steplbl">{{'Canal Type' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" (change)="getMainCanel()"
                                        formControlName="wua_canal_bank_type_id">
                                        <option value="0">{{ 'please_select_canal_type' | translate }}</option>
                                        <option *ngFor="let list of bankArray;" [value]="list.bank_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlockBankType">
                                                {{list.bank_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlockBankType>
                                                {{list.bank_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_canal_bank_type_id'].invalid &&
                        (wuaForm.controls['wua_canal_bank_type_id'].dirty || wuaForm.controls['wua_canal_bank_type_id'].touched)">
                                        <p class="error-msg-ab" style="color: brown;"
                                            *ngIf="wuaForm.controls['wua_canal_bank_type_id'].errors.required">
                                            please select Canal Type!
                                        </p>
                                    </div>
                                </div>



                            </div>

                            <div *ngIf="showcanal">
                                <div class="col-md-12 recentlistbx">
                                    <h2 class="smlheadh2 col-md-12"><span>{{'CanalDetails' | translate}}</span></h2>
                                </div>

                                <div class="row frmrow">
                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Project_Layout' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>

                                        <select class="stepinpt" formControlName="wua_project_layout_id"
                                            (change)="onProjectLayoutChange($event.target.value)">
                                            <ng-container *ngIf="language == 'en';else elseOutletNoBlock">
                                                <option value="0">No</option>
                                            </ng-container>
                                            <ng-template #elseOutletNoBlock>
                                                <option value="0">नाही</option>
                                            </ng-template>
                                            <option *ngFor="let canal of projectLayoutArray;"
                                                [value]="canal.project_layout_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{canal.mwrd_main_canal_master?canal.mwrd_main_canal_master.main_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_branch_canal_master?canal.mwrd_branch_canal_master.branch_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_distributory_canal_master?canal.mwrd_distributory_canal_master.distributory_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_minor_canal_master?canal.mwrd_minor_canal_master.minor_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_subminor_canal_master?canal.mwrd_subminor_canal_master.subminor_canal_name_marathi:'-'}}
                                                    |
                                                    {{canal.mwrd_outlet_canal_master?canal.mwrd_outlet_canal_master.outlet_canal_name_marathi:'-'}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{canal.mwrd_main_canal_master?canal.mwrd_main_canal_master.main_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_branch_canal_master?canal.mwrd_branch_canal_master.branch_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_distributory_canal_master?canal.mwrd_distributory_canal_master.distributory_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_minor_canal_master?canal.mwrd_minor_canal_master.minor_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_subminor_canal_master?canal.mwrd_subminor_canal_master.subminor_canal_name:'-'}}
                                                    |
                                                    {{canal.mwrd_outlet_canal_master?canal.mwrd_outlet_canal_master.outlet_canal_name:'-'}}
                                                </ng-template>
                                            </option>
                                        </select>
                                    </div>
                                    <!-- <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Main_Canal' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="wua_main_canal_id"
                                            (change)="getBranchCanalList($event.target.value)">
                                            <option [value]="null">{{'please_select_main_canal' | translate }}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let corporation of mainCanelArray;"
                                                [value]="corporation.main_canal_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{corporation.main_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{corporation.main_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Branch_Canal' | translate}}<span
                                                aria-hidden="true" style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="wua_branch_id"
                                            (change)="getDistributoryList($event.target.value)">
                                            <option [value]="null">{{'please_select_branch' | translate}}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let region of branchCanelArray;"
                                                [value]="region.branch_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{region.branch_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{region.branch_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Distributary_Canal' | translate}}<span
                                                aria-hidden="true" style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="wua_distributory_id"
                                            (change)="getMinorList($event.target.value)">
                                            <option [value]="null">{{'please_select_distributory'|translate}}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let circle of distributoryArray;"
                                                [value]="circle.distributory_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{circle.distributory_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{circle.distributory_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_Minor' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="wua_minor_id"
                                            (change)="getSubMinorList($event.target.value)">
                                            <option [value]="null">{{'please_select_minor'|translate}}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let division of minorCanelArray;"
                                                [value]="division.minor_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{division.minor_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{division.minor_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>

                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name_SUB_Minor' | translate}}<span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <select class="stepinpt" formControlName="wua_subminor_id"
                                            (change)="getOutletList($event.target.value)">
                                            <option [value]="null">{{'please_select_sub_minor'|translate}}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let regionSub of subMinorCanelArray;"
                                                [value]="regionSub.subminor_canal_master_id">
                                                {{regionSub.subminor_canal_name}}
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{regionSub.subminor_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{regionSub.subminor_canal_name}}
                                                </ng-template>
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-2 inptbx">
                                        <label class="steplbl">{{'Name of Outlet' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>

                                        <select class="stepinpt" formControlName="wua_outlet_id">
                                            <option [value]="null">{{'please_select_outlet'|translate}}</option>
                                            <option value="0">{{'No'|translate}}</option>
                                            <option *ngFor="let canal of getOutlistingArray;"
                                                [value]="canal.outlet_canal_master_id">
                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                    {{canal.outlet_canal_name_marathi}}
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    {{canal.outlet_canal_name}}
                                                </ng-template>
                                            </option>
                                        </select>
                                    </div> -->

                                </div>

                            </div>
                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Benefitted Area Details' | translate}}</span>
                                </h2>

                            </div>

                            <div class="row frmrow">

                                <div class="modal fade" id="myModal" role="dialog" style="width: 100%;">
                                    <div class="modal-dialog">

                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">{{"Location"| translate}}</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'District' | translate}} <span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <!-- <select class="stepinpt"  -->
                                                        <select class="stepinpt" formControlName="wua_district_code"
                                                            (change)="forFamerTaluka($event.target.value)">
                                                            <option *ngFor="let list of getDistArray"
                                                                [value]="list.district_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.district_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.district_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'Taluka' | translate}} <span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <!-- <select class="stepinpt"  -->
                                                        <select class="stepinpt" formControlName="wua_taluka_code"
                                                            (change)="forarmerVillage($event.target.value)">
                                                            <option *ngFor="let list of getFarmerTaluka"
                                                                [value]="list.taluka_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.taluka_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.taluka_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row frmrow">
                                                    <div class="col-md-8 inptbx">
                                                        <label class="steplbl">{{'Village' | translate}}<span
                                                                aria-hidden="true" style="color: red;">*</span></label>
                                                        <!-- <select class="stepinpt"  -->

                                                        <select class="stepinpt" formControlName="wua_village_code"
                                                            (change)="getAllVillage($event.target.value)">
                                                            <option *ngFor="let list of getFarmerVillage"
                                                                [value]="list.village_code">

                                                                <ng-container *ngIf="language == 'mr';else elseBlock">
                                                                    {{list.village_name_marathi}}
                                                                </ng-container>
                                                                <ng-template #elseBlock>
                                                                    {{list.village_name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="stepgreenbtn"
                                                    data-dismiss="modal">{{'Submit' | translate}}</button>
                                                &nbsp;
                                                <!--<button type="button" class="btn btn-default" data-dismiss="modal">{{'Close' | translate}}</button>-->
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{"Location" | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <span><i class="fa fa-chevron-right stepinpt" aria-hidden="true" data-toggle="modal"
                                            data-target="#myModal1">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                <!-- {{getFarmerDistructNameMarathi}} -->
                                                {{wuaDistructNameMarathi}} | {{wuaTallukaNameMarathi}} |
                                                {{wuaVillageNameMarathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{wuaDistructName}} | {{wuaTallukaName}} |
                                                {{wuaVillageName}}
                                            </ng-template>
                                        </i> </span>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Delineation Area (Ha)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_delineation_area_ha"
                                        [(ngModel)]="reqData.wua_delineation_area_ha" placeholder="" class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_delineation_area_ha'].invalid &&
                                         (wuaForm.controls['wua_delineation_area_ha'].dirty || wuaForm.controls['wua_delineation_area_ha'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_delineation_area_ha'].errors.pattern">
                                            Maximum four digits allowed before decimal & 2 after decimal!
                                        </p>
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_delineation_area_ha'].errors.maxLength">
                                            6 digit number allowed!
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4 inptbx">
                                    <label class="steplbl">Name of benefitted Village</label>
                                    <input type="text" formControlName="wua_names_villages_benefitted"
                                        placeholder="Name of benefitted Village" class="stepinpt">
                                </div> -->

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Area of Village (Ha)' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_total_area_ha"
                                        [(ngModel)]="reqData.wua_total_area_ha" placeholder="" class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_total_area_ha'].invalid &&
                                         (wuaForm.controls['wua_total_area_ha'].dirty || wuaForm.controls['wua_total_area_ha'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_total_area_ha'].errors.pattern">
                                            Maximum four digits allowed before decimal & 2 after decimal!
                                        </p>
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_total_area_ha'].errors.maxLength">
                                            6 digit number allowed!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'No of Beneficiaries' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_no_of_beneficiaries"
                                        [(ngModel)]="reqData.wua_no_of_beneficiaries" placeholder="" class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_no_of_beneficiaries'].invalid &&
                                         (wuaForm.controls['wua_no_of_beneficiaries'].dirty || wuaForm.controls['wua_no_of_beneficiaries'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_no_of_beneficiaries'].errors.pattern">
                                            Maximum four digits allowed before decimal & 2 after decimal!
                                        </p>
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_no_of_beneficiaries'].errors.maxLength">
                                            6 digit number allowed!
                                        </p>
                                    </div>
                                </div>

                                <br />
                                <div class="row frmrow">

                                    <div class="col-md-12 inptbx">
                                        <input class="stepgreenbtn" type="Submit" value="{{'Add'| translate}}"
                                            (click)="pushBenefitedDetails()" />

                                    </div>






                                </div>
                            </div>








                            <!--table start-->
                            <div class="container">
                                <div class="row">
                                    <div class="fullwidth">
                                        <div class="frmtable">
                                            <table class="tablerecordpg">
                                                <thead>
                                                    <tr>
                                                        <th class="bgclr">{{'District'| translate}}</th>
                                                        <th class="bgclr">{{'Talluka'| translate}}</th>
                                                        <th class="bgclr">{{'Village'| translate}}</th>
                                                        <th class="bgclr">{{'Delineation Area (Ha)'| translate}}</th>
                                                        <th class="bgclr">{{'Area of Village (Ha)'| translate}}</th>
                                                        <th class="bgclr">{{'No. of Beneficiaries' | translate}}</th>
                                                        <th class="bgclr">{{"Action" | translate}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let list of benifitedAreaDetails; let i = index">
                                                        <td>{{list.districtname}}</td>
                                                        <td>{{list.talukaName}}</td>
                                                        <td>{{list.villageName}}</td>
                                                        <td>{{list.wua_delineation_area_ha}}</td>
                                                        <td>{{list.wua_total_area_ha}}</td>
                                                        <td>{{list.wua_no_of_beneficiaries}}</td>

                                                        <td><span><i (click)="deleteGutDetails(i)"
                                                                    class="fa fa-trash"></i></span></td>
                                                    </tr>
                                                </tbody>


                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--table over-->

                            <div class="rowgap clearfix"></div>






                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'WUA Bank Details' | translate}}</span></h2>

                            </div>

                            <div class="row frmrow">


                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Name Of bank' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <input type="text" formControlName="wua_bank_name" placeholder="Name Of Bank"
                                        class="stepinpt"> -->

                                    <select class="stepinpt" formControlName="wua_bank_name">
                                        <option value="null">{{'please_select_bank'|translate}}</option>
                                        <option *ngFor="let list of BankList;" [value]="list.mwrd_indian_bank_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseProjectBlock">
                                                {{list.mwrd_indian_bank_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseProjectBlock>
                                                {{list.mwrd_indian_bank_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="wuaForm.controls['wua_bank_name'].invalid &&
                                    (wuaForm.controls['wua_bank_name'].dirty || wuaForm.controls['wua_bank_name'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_bank_name'].errors.required">
                                            Please select bank!
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Name of branch' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span> </label>
                                    <input type="text" formControlName="wua_bank_branch_name" placeholder=""
                                        class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_bank_branch_name'].invalid &&
                                    (wuaForm.controls['wua_bank_branch_name'].dirty || wuaForm.controls['wua_bank_branch_name'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_bank_branch_name'].errors.required">
                                            {{'Bank Branch Name is required!!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4 inptbx">
                                    <label class="steplbl">{{'Bank Account No' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <input type="text" formControlName="wua_bank_ac_no" placeholder="" class="stepinpt">
                                    <div
                                        *ngIf="wuaForm.controls['wua_bank_ac_no'].invalid &&
                                (wuaForm.controls['wua_bank_ac_no'].dirty || wuaForm.controls['wua_bank_ac_no'].touched)">
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_bank_ac_no'].errors.required">
                                            {{'Bank Account Number is required!!' | translate}}
                                        </p>
                                        <p class="error-msg-ab" style="color: red;"
                                            *ngIf="wuaForm.controls['wua_bank_ac_no'].errors.pattern">
                                            Only number allowed!!
                                        </p>
                                    </div>
                                </div>
                            </div>



                            <div class="row frmrow">
                                <ng-container *ngIf="wua_id==-1; else: elseEditBlock">
                                    <div class="col-md-12 inptbx">

                                        <button type="button" class="stepgreenbtn" (click)="finalSubmit()"
                                            data-dismiss="modal">{{'Submit' | translate}}</button>

                                    </div>
                                </ng-container>
                                <ng-template #elseEditBlock>
                                    <input class="stepgreenbtn" type="Submit" value="{{'Update' | translate}}"
                                        (click)="updateWua()">
                                </ng-template>


                            </div>




                        </form>
                    </div>
                </div>




            </div>
            <!-- mid content over-->

        </div>
        <!-- dashboard right panel over-->
    </div>






    <!-- Modal -->