<div class="loginpg fullwidth">
<section class="logincnt">
	<!-- login left start-->
	<div class="loginleft">
	<img src="assets/images/login-logo.png" alt="logo" />	
	</div>
	<!-- login left over-->
	<!-- login right start-->
	<div class="loginright loginfrmbx">
		<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" (keyup.enter)="onEnterKeyDown($event)" >
			<!--  loginfull start -->
			<div class="loginfull fullwidth">
				<label class="loginlabel">Mobile Number</label>
                <input (keypress)="keyPress($event)" required type="text" formControlName="mobilenumber" class="form-control" placeholder="Enter Your Mobile Number"  maxlength="10" >
            </div>
            <div *ngIf="loginForm.get('mobilenumber').hasError('required')">
              <span style="color:white" >Please Provide Mobile Number</span> 
            </div>

            <!--<div *ngIf="!loginForm.valid">
                <span style="color:white" >Enter the Valid Mobile Number</span> 
			  </div>-->
			  
			  <div *ngIf="validNumber" >
                <span style="color:white" >Mobile Number Not Valid</span> 
              </div>
              
			<!--  loginfull over -->
			<!--  loginfull start -->
			<!--<div class="loginfull fullwidth">
				<label class="loginlabel">Password</label>
				<input   formControlName="password" type="password" placeholder="********" class="logininpt" > 
			</div>-->
			<!--  loginfull over -->
			<!--  loginfull start -->
			<!--<div class="loginfull fullwidth logincaptcha">
				<label class="loginlabel">Captcha</label>
				<input formControlName="Captcha" type="Password" placeholder="Enter Captcha" class="logininpt" > 
				<img src="assets/images/logincaptcha.png" alt="captcha" />
			</div>-->
			<!--  loginfull over -->
			<!--  loginfull start -->
			<div class="loginfull rememberbx fullwidth">
				
				<!--<input type="checkbox" class="logincheck" checked > 
				<label class="loginlabel">Remember me</label>-->
			
			</div>
			<!--  loginfull over -->
			<!--  loginfull start -->
			<div class="loginfull  fullwidth">
				<input class="loginsbmtbtn" type="submit"  value="Next"  [disabled]="loginForm.invalid" />
			</div>
			<!--  loginfull over -->
		</form>
	</div>
    <!-- login right over-->
    
 
</section>
<!--<app-footer></app-footer>-->
</div>