<body class="dashboardpg">
    <div class="dashboardstruck fullwidth unset-position">
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <!-- top blue nav over-->
            <!-- dashboardbnnr start-->
            <div class="greenstrip fullwidth">
                <h1>{{'Project Master' | translate}}</h1>
            </div>
            <div class="mid-content fullwidth formstep overlapmultiselect overlaperrorlbl">
                <div class="row">
                    <div class="col-md-12 recentlistbx">
                        <h2 class="smlheadh2 col-md-12"><span>{{'Project Details' | translate}}</span></h2>
                    </div>
                    <div class="stepfrmcmn stepfrm1">

                        <form [formGroup]="projectmasterForm">
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name of Project (English )' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>

                                    <input class="stepinpt" type="text" formControlName="project_name"
                                        (blur)="translateIntoMarathi($event.target.value)">
                                    <div
                                        *ngIf="projectmasterForm.controls['project_name'].invalid &&
                                    (projectmasterForm.controls['project_name'].dirty || projectmasterForm.controls['project_name'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_name'].errors.required">
                                            {{'Project name is required!' | translate}}
                                        </p>
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_name'].errors.pattern">
                                            Special Characters are not allowed!
                                        </p>
                                    </div>

                                    <!-- <select class="stepinpt" formControlName="projectName"
                                        >
                                       
                                        <option *ngFor="let project of projectSelect;" [value]="project.project_id">
                                            <ng-container *ngIf="selectedlang == 'mr';else elseBlock">
                                                {{project.project_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{project.project_name}}
                                            </ng-template>
                                        </option>

                                    </select> -->
                                </div>


                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Name of Project Marathi' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>

                                    <input class="stepinpt" type="text" formControlName="project_name_marathi"
                                        id="project_name_marathi">
                                    <div
                                        *ngIf="projectmasterForm.controls['project_name_marathi'].invalid &&
                                    (projectmasterForm.controls['project_name_marathi'].dirty || projectmasterForm.controls['project_name_marathi'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_name_marathi'].errors.required">
                                            {{'Project marathi name is required!' | translate}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Type of project' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_type">

                                        <option [value]="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>

                                        <option *ngFor="let projectype of projectType;"
                                            [value]="projectype.project_type_id">
                                            <ng-container *ngIf="language == 'mr';else elseProkjecBlock">
                                                {{projectype.project_type_marathi}}
                                            </ng-container>
                                            <ng-template #elseProkjecBlock>
                                                {{projectype.project_type}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_type'].invalid &&
                                    (projectmasterForm.controls['project_type'].dirty || projectmasterForm.controls['project_type'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_type'].errors.required">
                                            {{'Please select project type!' | translate}}
                                        </p>
                                    </div>
                                </div>





                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Status of project' | translate}}<span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_status">

                                        <option [value]="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>

                                        <option *ngFor="let projectstatus of projectStatus;"
                                            [value]="projectstatus.project_status_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{projectstatus.project_status_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{projectstatus.project_status}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_status'].invalid &&
                                    (projectmasterForm.controls['project_status'].dirty || projectmasterForm.controls['project_status'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_status'].errors.required">
                                            {{'Please select project status!' | translate}}
                                        </p>
                                    </div>
                                </div>




                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Ownership of the project' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span> </label>

                                    <select class="stepinpt" formControlName="project_ownership_id">

                                        <option [value]="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>

                                        <option *ngFor="let ownerShip of ownership;"
                                            [value]="ownerShip.mwrd_project_ownership_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{ownerShip.project_marathi_owner_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{ownerShip.project_owner_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_ownership_id'].invalid &&
                                    (projectmasterForm.controls['project_ownership_id'].dirty || projectmasterForm.controls['project_ownership_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_ownership_id'].errors.required">
                                            {{'Please select project ownership!' | translate}}
                                        </p>
                                    </div>

                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Interstate' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <select class="stepinpt" formControlName="project_is_interstate"
                                        (change)="isinterstate($event.target.value)">
                                        <option [value]="null">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of yesNo" value="{{list.master_common_id}}">
                                            <ng-container *ngIf="language == 'mr';else elseBlock1">
                                                {{list.common_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock1>
                                                {{list.common_name}}
                                            </ng-template>
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_is_interstate'].invalid &&
                                    (projectmasterForm.controls['project_is_interstate'].dirty || projectmasterForm.controls['project_is_interstate'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_is_interstate'].errors.required">
                                            {{'Please select if project is interstate or not!' | translate}}
                                        </p>
                                    </div>

                                </div>

                                <!-- <div class="col-md-4 inptbx radioinpt">
                                    <label class="steplbl">{{'Interstate?' | translate}} </label>
                                   <div class="col-md-4 inptbx radioinpt"> -->
                                <!-- <label class="steplbl">{{"Yes" | translate}}</label>
                                        <input type="radio" class="stepinpt" value="Yes"
                                           
                                            >

                                        <label class="steplbl">{{"No" | translate}}</label>
                                        <input type="radio" class="stepinpt" value="No"
                                             > -->

                                <!-- <select class="stepinpt">
                                        <option *ngFor="let list of yesNo" value="{{list.master_common_id}}">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.common_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.common_name}}
                                            </ng-template>
                                        </option>
                                    </select> -->
                                <!-- </div> -->
                                <!-- </div> -->

                                <!--/div-->
                                <!-- step row over-->
                                <!-- step row start-->
                                <!--div class="row frmrow"-->



                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'State' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_state_id">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let state of states;" [value]="state.mwrd_state_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{state.state_marathi_name}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{state.state_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Primary District' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_district_code"
                                        (change)="forFamerTaluka($event.target.value)">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let dist of getDistArray;" [value]="dist.district_code">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{dist.district_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{dist.district_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>

                                <div class="col-md-3 inptbx">
                                    <label class="steplbl">{{'Primary Taluka' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_taluka_code">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let taluka of getFarmerTaluka;" [value]="taluka.taluka_code">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{taluka.taluka_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{taluka.taluka_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                </div>


                                <div class="col-md-3 inptbx secondary-selection">
                                    <label class="steplbl">{{'Secondary District' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>

                                    <!-- <ng-multiselect-dropdown class="input-permissions" 
                                        [(ngModel)]="CorporatesEditArray" [ngModelOptions]="{standalone: true}"
                                        name="secondaryCorporation"
                                        [settings]="dropdownSettings"
                                        [placeholder]="this.select"
                                       
                                        [data]='secondary_corporation'
                                        (onDeSelect)="onItemDeselect($event,'Corporation')"
                                        (onSelect)="onItemSelect($event,'Corporation')">

                                    </ng-multiselect-dropdown> -->


                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryDistrict"
                                        [(ngModel)]="districtEditArray" [ngModelOptions]="{standalone: true}"
                                        [settings]="dropdownSettings1" [placeholder]="this.select" [data]='getDistArray'
                                        (onSelect)="onItemSelect1($event,'District')"
                                        (onDeSelect)="OnDeSelect1($event,'District')"
                                        [disabled]="dispSecondaryDistTaluka">
                                    </ng-multiselect-dropdown>
                                </div>

                                <div class="col-md-3 inptbx secondary-selection">
                                    <label class="steplbl">{{'Secondary Taluka' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" [(ngModel)]="talukaEditArray"
                                        name="project_secondary_taluka_code" [ngModelOptions]="{standalone: true}"
                                        [settings]="dropdownSettings2" [placeholder]="this.select"
                                        [data]='forFamerTalukaArr' (onSelect)="onItemSelect2($event,'Taluka')"
                                        [disabled]="dispSecondaryDistTaluka"
                                        (onDeSelect)="OnDeSelect1($event,'taluka')">
                                    </ng-multiselect-dropdown>
                                </div>

                            </div>

                            <!-- col-md-12 start-->
                            <div class="col-md-12 recentlistbx">

                                <h2 class="smlheadh2 col-md-12">
                                    <span>{{'Primary Authorities of Project' | translate}}</span>
                                </h2>
                            </div>
                            <!-- col-md-12 over-->
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-2 inptbx secondary-selection">
                                    <label class="steplbl"> {{'Corporation' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-container *ngIf="language == 'mr';else elseCorpBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.Corporation}}"
                                            type="text" disabled />
                                    </ng-container>
                                    <ng-template #elseCorpBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.Corporation}}"
                                            type="text" disabled />
                                    </ng-template>


                                </div>

                                <div class="col-md-2 inptbx secondary-selection">
                                    <label class="steplbl">{{'Region' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <input class="stepinpt" value="{{this.data.office_detail.Region}}" formControlName="region"  type="text"  disabled/> -->
                                    <ng-container *ngIf="language == 'mr';else elseRegionBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.Region}}" type="text"
                                            disabled />
                                    </ng-container>
                                    <ng-template #elseRegionBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.Region}}" type="text"
                                            disabled />
                                    </ng-template>

                                </div>

                                <div class="col-md-2 inptbx secondary-selection">
                                    <label class="steplbl">{{'Circle' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <input class="stepinpt" value="{{this.data.office_detail.circle}}" formControlName="circle" type="text"  disabled/> -->
                                    <ng-container *ngIf="language == 'mr';else elseCircleBlock">
                                        <input class="stepinpt" value="{{this.office_details_mr.circle}}" type="text"
                                            disabled />
                                    </ng-container>
                                    <ng-template #elseCircleBlock>
                                        <input class="stepinpt" value="{{this.office_details_en.circle}}" type="text"
                                            disabled />
                                    </ng-template>
                                </div>

                                <!--/div-->
                                <!-- step row over-->
                                <!-- step row start-->
                                <!--div class="row frmrow"-->

                                <div class="col-md-2 inptbx secondary-selection" *ngIf="divselectshow">
                                    <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <select class="stepinpt" formControlName="project_division_id"
                                        (change)="getOfficesPrimary($event.target.value,'Sub Division')">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_Division;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_division_id'].invalid &&
                                    (projectmasterForm.controls['project_division_id'].dirty || projectmasterForm.controls['project_division_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_division_id'].errors.required">
                                            Please select primary Division!
                                        </p>
                                    </div>
                                </div>


                                <ng-container *ngIf="language == 'mr';else elseBlock22">
                                    <div class="col-md-2 inptbx" *ngIf="divTextshow">
                                        <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                                style="color: red;">*</span></label>
                                        <input class="stepinpt" value="{{this.office_details_mr.Division}}" type="text"
                                            disabled />
                                    </div>
                                </ng-container>
                                <ng-template #elseBlock22>
                                    <div class="col-md-2 inptbx" *ngIf="divTextshow">
                                        <label class="steplbl">{{'Division' | translate}}</label>
                                        <input class="stepinpt" value="{{this.office_details_en.Division}}" type="text"
                                            disabled />
                                    </div>
                                </ng-template>

                                <div class="col-md-2 inptbx secondary-selection"  *ngIf="subdivshow">
                                    <label class="steplbl">{{'Sub Division' | translate}}
                                        <!-- <span
                                        aria-hidden="true" style="color: red;">*</span> -->
                                    </label>
                                    <select class="stepinpt" formControlName="project_subdivision_id"
                                        (change)="getOfficesPrimary($event.target.value,'Section')">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_SubDivision;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_subdivision_id'].invalid &&
                                    (projectmasterForm.controls['project_subdivision_id'].dirty || projectmasterForm.controls['project_subdivision_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_subdivision_id'].errors.required">
                                            Please select primary Sub Division!
                                        </p>
                                    </div>
                                </div>

                                <ng-container *ngIf="language == 'mr';else elseBlock1">
                                    <div class="col-md-2 inptbx" *ngIf="subdivTextshow">
                                        <label class="steplbl">{{'Sub Division' | translate}} <span
                                            aria-hidden="true" style="color: red;">*</span></label>
                                        <input class="stepinpt" value="{{this.office_details_mr.subDivis}}"
                                             type="text" disabled />
                                    </div>
                                </ng-container>
                                <ng-template #elseBlock1>
                                    <div class="col-md-2 inptbx" *ngIf="subdivTextshow">
                                        <label class="steplbl">{{'Sub Division' | translate}}</label>
                                        <input class="stepinpt" value="{{this.office_details_en.subDivis}}"
                                             type="text" disabled />
                                    </div>
                                </ng-template>

                                <div class="col-md-2 inptbx secondary-selection">
                                    <label class="steplbl">{{'Section' | translate}}</label>
                                    <select class="stepinpt" formControlName="project_section_id">
                                        <option value="">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{'Please Select1' | translate}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{'Please Select1' | translate}}
                                            </ng-template>
                                        </option>
                                        <option *ngFor="let list of primary_Section;" [value]="list.office_id">
                                            <ng-container *ngIf="language == 'mr';else elseBlock">
                                                {{list.office_name_marathi}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{list.office_name}}
                                            </ng-template>
                                        </option>

                                    </select>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_section_id'].invalid &&
                                    (projectmasterForm.controls['project_section_id'].dirty || projectmasterForm.controls['project_section_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_section_id'].errors.required">
                                            Please select primary Section!
                                        </p>
                                    </div>
                                </div>

                            </div>


                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12">
                                    <span>{{'Secondary Authorities of Project' | translate}}</span>
                                </h2>
                            </div>
                            <!-- col-md-12 over-->
                            <!-- step row start-->
                            <div class="row frmrow multi-sec-row">



                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl">{{'Corporation' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <select class="stepinpt">
                                        <option>Sindhudurg </option>
                                    </select> -->
                                    <ng-multiselect-dropdown class="input-permissions" [(ngModel)]="CorporatesEditArray"
                                        [ngModelOptions]="{standalone: true}" name="secondaryCorporation"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [data]='secondary_corporation'
                                        (onDeSelect)="onItemDeselect($event,'Corporation')"
                                        (onSelect)="onItemSelect($event,'Corporation')">

                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_corporation_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_corporation_id'].dirty || projectmasterForm.controls['project_secondary_corporation_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_corporation_id'].errors.required">
                                            Please select secondary corporation!
                                        </p>
                                    </div>
                                </div>




                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl">{{'Region' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <!-- <select class="stepinpt">
                                        <option>Ratnagiri </option>
                                    </select> -->
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryRegion"
                                        [(ngModel)]="regionEditArray" [ngModelOptions]="{standalone: true}"
                                        (onDeSelect)="onItemDeselect($event,'Region')" [settings]="dropdownSettings"
                                        [placeholder]="this.select" [data]='secondary_Region'
                                        (onSelect)="onItemSelect($event,'Region')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_region_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_region_id'].dirty || projectmasterForm.controls['project_secondary_region_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_region_id'].errors.required">
                                            Please select secondary corporation!
                                        </p>
                                    </div>
                                </div>



                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl"> {{'Circle'| translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryCircle"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [(ngModel)]="circleEditArray" [ngModelOptions]="{standalone: true}"
                                        [data]='secondary_Circle' (onDeSelect)="onItemDeselect($event,'Circle')"
                                        (onSelect)="onItemSelect($event,'Circle')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_circle_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_circle_id'].dirty || projectmasterForm.controls['project_secondary_circle_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_circle_id'].errors.required">
                                            Please select secondary corporation!
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <!-- step row over-->
                            <!-- step row start-->
                            <div class="row frmrow multi-sec-row">



                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl">{{'Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondaryDivision"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [(ngModel)]="DivEditArray" [ngModelOptions]="{standalone: true}"
                                        [data]='secondary_Division' (onDeSelect)="onItemDeselect($event,'Division')"
                                        (onSelect)="onItemSelect($event,'Division')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_division_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_division_id'].dirty || projectmasterForm.controls['project_secondary_division_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_division_id'].errors.required">
                                            Please select secondary division!
                                        </p>
                                    </div>
                                </div>



                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl">{{'Sub Division' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondarySubDivision"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [(ngModel)]="SubDivEditArray" [ngModelOptions]="{standalone: true}"
                                        [data]='secondary_SubDivision'
                                        (onDeSelect)="onItemDeselect($event,'Sub Division')"
                                        (onSelect)="onItemSelect($event,'Sub Division')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_subdivison_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_subdivison_id'].dirty || projectmasterForm.controls['project_secondary_subdivison_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_subdivison_id'].errors.required">
                                            Please select secondary sub division!
                                        </p>
                                    </div>
                                </div>


                                <div class="col-md-4 inptbx secondary-selection">
                                    <label class="steplbl">{{'Section' | translate}} <span aria-hidden="true"
                                            style="color: red;">*</span></label>
                                    <ng-multiselect-dropdown class="input-permissions" name="secondarySection"
                                        [(ngModel)]="SecEditArray" [ngModelOptions]="{standalone: true}"
                                        [settings]="dropdownSettings" [placeholder]="this.select"
                                        [data]='secondary_Section' (onDeSelect)="onItemDeselect($event,'Section')"
                                        (onSelect)="onItemSelect($event,'Section')">
                                    </ng-multiselect-dropdown>
                                    <div
                                        *ngIf="projectmasterForm.controls['project_secondary_section_id'].invalid &&
                                    (projectmasterForm.controls['project_secondary_section_id'].dirty || projectmasterForm.controls['project_secondary_section_id'].touched)">
                                        <p style="color: brown;"
                                            *ngIf="projectmasterForm.controls['project_secondary_section_id'].errors.required">
                                            Please select secondary sub division!
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <!-- step row over-->
                            <!-- col-md-12 start-->

                            <!-- step row over-->
                            <!-- step row start-->
                            <div class="row frmrow">

                                <div class="col-md-12 inptbx">
                                    <!-- <input class="stepgreenbtn" type="button" value="Save" /> -->
                                    <input class="stepgreenbtn" type="Submit" value="{{'Submit' | translate}}"
                                        (click)="finalSubmit()" />
                                    <!-- <input class="stepgreybtn" type="reset" value="Reset" /> -->
                                </div>

                            </div>
                            <!-- step row over-->
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <!-- dashboard right panel over-->
    <!-- footer start-->
    <footer class="dboardfooter fullwidth">
        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
    </footer>
    <!-- footer over-->
</body>