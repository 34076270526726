import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  


  isAlphaNumeric(event){
    var inp = String.fromCharCode(event.keyCode);
    
    var isValid = /[a-zA-Z0-9]/.test(inp);

		if (/[a-zA-Z0-9]/.test(inp)) {
      $('#errrmsgAlphaNum').hide();
		  return true;
		} else {
      $('#errrmsgAlphaNum').show();

		  event.preventDefault();
		  return false;
		}
        }

   // Only AlphaNumeric with Some Characters [-_ ]
  keyPressAlphaNumericWithCharacters(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }     


  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {

      $('#errrmsgDecNum').show();
      event.preventDefault();
      return false;
    }
    $('#errrmsgDecNum').hide();
    return true;
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  constructor() { }
}
