import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from '../ng-multiselect-dropdown/src'
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpComponent } from './otp/otp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddFarmerComponent } from './add-farmer/add-farmer.component';
import { ViewFarmerComponent } from './view-farmer/view-farmer.component';
import { StepOneComponent } from './step-one/step-one.component';
import { StepTwoComponent } from './step-two/step-two.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { FooterComponent } from './footer/footer.component';
import { Interceptor } from './_service/interceptor.service';
import { VerifyDetailsComponent } from './verify-details/verify-details.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { VerifyProjectComponent } from './verify-project/verify-project.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatSelectFilterModule } from 'mat-select-filter';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserSelectComponent } from './user-select/user-select.component';
import { WuaMasterComponent } from './wua-master/wua-master.component';
import { ViewWuaComponent } from './view-wua/view-wua.component';
import { DamsComponent } from './dams/dams.component';
import { IrrigationComponent } from './irrigation/irrigation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectLayoutComponent } from './project-layout/project-layout.component';
import { DataTablesModule } from 'angular-datatables';
import { FarmerMasterComponent } from './farmer-master/farmer-master.component';
import { ViewProjectLayoutComponent } from './view-project-layout/view-project-layout.component';
import { ProjectLayoutNewComponent } from './project-layout-new/project-layout-new.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NumberDirective } from './_service/number';
// import { GoogleInputToolModule } from 'google-input-tool';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ProjectMasterComponent } from './project-master/project-master.component';
import { DamMasterComponent } from './dam-master/dam-master.component';
import { ViewProjectMasterComponent } from './view-project-master/view-project-master.component';
import { ViewDamMasterComponent } from './view-dam-master/view-dam-master.component';
import { MatTreeModule } from '@angular/material/tree';
import { NestedTreeComponent } from './shared/nested-tree/nested-tree.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExcelService } from './_service/excel.service';
import { DatePipe } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { DemoDatatablesComponent } from './demo-datatables/demo-datatables.component';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  //return new TranslateHttpLoader(httpClient);//LOCAL
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');//LIVE
}
``

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    OtpComponent,
    DashboardComponent,
    AddFarmerComponent,
    ViewFarmerComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    FooterComponent,
    VerifyDetailsComponent,
    SidebarComponent,
    HeaderComponent,
    ProfileComponent,
    VerifyProjectComponent,
    UserSelectComponent,
    WuaMasterComponent,
    ViewWuaComponent,
    DamsComponent,
    IrrigationComponent,
    ProjectLayoutComponent,
    FarmerMasterComponent,
    ViewProjectLayoutComponent,
    ProjectLayoutNewComponent,
    NumberDirective,
    ProjectMasterComponent,
    DamMasterComponent,
    ViewProjectMasterComponent,
    ViewDamMasterComponent,
    NestedTreeComponent,
    DemoDatatablesComponent


  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectFilterModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxUiLoaderModule,
    DataTablesModule,
    MatTreeModule,
    MatProgressBarModule,
    NgxChartsModule,
    MatExpansionModule,
    NgbModule,
    // GoogleInputToolModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],

      }
    })



  ],

  providers: [
    ExcelService, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor, multi: true,

    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
