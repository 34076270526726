import { Component, OnInit,AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { SharedService }  from '../_service/shared.service';
import { Subscription } from 'rxjs';


declare var jQuery: any;
declare var $: any;

import { IDropdownSettings } from 'src/ng-multiselect-dropdown/src';



@Component({
  selector: 'app-dams',
  templateUrl: './dams.component.html',
  styleUrls: ['./dams.component.css']
})
export class DamsComponent implements OnInit ,AfterViewInit{
  clickEventsubscription:Subscription;
  userName = localStorage.getItem('userName');
  damNameArr: any;

  damMasterForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  dams: any = [];

  selectedItems: any = [];
  dropdownSettings: any = {};

  public permissions = [];
  public filteredList = this.permissions.slice();
  selectedItemsRoot: any[];
  typeArrValues: string;
  typeArr: any=[];
  irrigationList: any=[];
  damList: any=[];
  damsDetailsArr: any=[];
  irrigationids: any=[];
  selectedlang = localStorage.getItem('selected_lang');
  data: any = {};
  en_data: any ={
    welcome_string :  this.userName +  " Welcome to MWRD's Farmer Master Database",
    // welcome_string : "Welcome " + this.userName + " to MWRD's Farmer Master Database",
    dams_string: "Dams",
    project_name_string: "Project Name",
    dam_name_string: "Dam Name",
    irrigation_source_string: "Irrigation Source",
    back_string: "Back",
    confirm_string: "Confirm",
    final_sub:"Final Submit",
    sure_details:"Are You Sure You Want to Submit The Details",
    select_all : "Select All",
    
    select:"Select",
    save: "Save",
    Ok:"OK",
    cancel_string:"Cancel",
    searchPlaceholderText:"Search",
    un_select_all : "UnSelect All",

    skip : "Skip",
    err :"Please select the project for corresponding Irrigation Source!!!"
  }
  mr_data: any = {
    welcome_string :   this.userName +" यांचे जलसंपदा विभाग, महाराष्ट्र शासन यांच्या मास्टर डेटाबेस प्रणालीमध्ये स्वागत आहे.",
    dams_string: "धरण",
    project_name_string: "प्रकल्पाचे नाव",
    dam_name_string: "धरणाचे नाव",
    irrigation_source_string: "सिंचनाचे माध्यम",
    back_string: "मागे जा",
    confirm_string: "जतन करा",
    final_sub:"अंतिम जतन करा",
    sure_details:"सदर माहिती जतन करणेबाबत आपली खात्री आहे का?",
    select_all : "सर्व पर्याय निवडा",
    select:"निवडा",
    save: "जतन करा",
    Ok : "होय",
    un_select_all : "निवडलेले सर्व काढा",
     
    cancel_string:"नाही",
    searchPlaceholderText:"शोधा",
    skip : "वगळा",
    err :"कृपया संबंधित सिंचन स्त्रोतासाठी प्रकल्प निवडा !!!"
  }
  showDetails: any=[];
  isChecked: any;
  selData: string;
  timeou: any;
  isconfirm=false;
  checkbox
  damList1: any;
  userOfficeType: string;
  userPosition: any;
  skipDiv: boolean;
  dams1: any=[];
  public isFiltered(permission) {
    return this.filteredList.find(item => item.id === permission.id);
  }
  errorDiv: boolean=false;


  getDashboard() {

   // this.getBackDetailsProject();
    this.router.navigate(['verifyProject']);

  }

  onDamsnameSelected(ob) {

    // console.log(ob.length);
    this.damNameArr = '';
    for (let k = 0; k < ob.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + ob[k].id;
      else
        this.damNameArr = ob[k].id;
      //this.typeArr.push(ob[k].id);

    }

    //console.log(this.damNameArr)
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService, 
    private sharedService:SharedService) { 
      this.clickEventsubscription=    this.sharedService.getClickEvent().subscribe(()=>{
        this.selectedlang = localStorage.getItem('selected_lang');
        this.ngOnInit();
        }) }

  onSubmit(form: FormGroup) {

 if(this.damsDetailsArr.length){
  const wuatChk = this.damsDetailsArr.filter((elem)=>  elem.irrigation_sources !='');

//  console.log(wuatChk);
  if(wuatChk.length !== this.damsDetailsArr.length){
    this.isconfirm=false;
    this.errorDiv = true;
    return false
  }
}else {
  this.isconfirm=false;
  this.errorDiv = true;
  return false;
}


// console.log(this.damMasterForm)
   // this.router.navigate(['irrigation']);
  //  if (!this.damMasterForm.invalid) {

    if (this.damMasterForm.status == 'Invalid') {
      return false;
    }
    else {
      console.log("hbhhh")
      const body = new HttpParams({
        fromObject: {
          post_user_id: localStorage.getItem('post_user_id'),
          dams_details: JSON.stringify(this.damsDetailsArr),

        }
      });

      this.http.getDamsLinking(body).subscribe((resolve: any) => {
        let data1 = resolve;
        if (data1.status == 0) {
          return false;
        } else {
       console.log(resolve.data);
       // return false;
          if (resolve.data) {
            this.isconfirm=true;
            //this.router.navigate(['irrigation']);
           // this.router.navigate(['dashboard']);
           

          }
        }
      },
        (err) => {

          console.log(err);


        }
      )




    }



  }

  getdamId(isselected,damId){
    this.irrigationids=[];
    if (this.timeou) {
      clearInterval(this.timeou);
   }
    clearInterval(this.timeou);
    this.isChecked = isselected.target.checked;
    if (this.isChecked) {
      $('#show'+damId).show();
      this.damsDetailsArr.push({
        dam_id: damId,
        irrigation_sources: ''
      });
    }
    else {
      $('#show'+damId).hide();

      
      var removeIndex = this.damsDetailsArr.map(function (item) { return item.dam_id; }).indexOf(damId);
      this.damsDetailsArr.splice(removeIndex, 1);     
    }
  }

  getProjectId(irrigationId,damId,isselected) {

    if (this.timeou) {
      clearInterval(this.timeou);
   }
    clearInterval(this.timeou);

    this.isChecked = isselected.target.checked;

    if ( this.isChecked) {
      this.irrigationids.push(irrigationId);
      var str = this.irrigationids.toString(); 
      var removeIndex = this.damsDetailsArr.map(function (item) { return item.dam_id; }).indexOf(damId);
      this.damsDetailsArr.splice(removeIndex, 1)

      this.damsDetailsArr.push({
        dam_id: damId,
        irrigation_sources: str

      });
    }
    else {
       var removeIndex = this.irrigationids.map(function (item) { return item.dam_id; }).indexOf(irrigationId);
       this.irrigationids.splice(removeIndex, 1);
      var str = this.irrigationids.toString();  
      var removeIndex = this.damsDetailsArr.map(function (item) { return item.dam_id; }).indexOf(damId);
      this.damsDetailsArr.splice(removeIndex, 1)
      this.damsDetailsArr.push({
        dam_id: damId,
        irrigation_sources: str

      });

    }
  }


  filterDamList(arr){
    //this.damList
    this.damList =  this.damList1;
    if(arr.length)
    this.damList  = this.damList.filter(element => arr.includes(element.dam_unique_code));
    else

    this.damList =  this.damList1;

 }
  getProjectDetails() {
    throw new Error("Method not implemented.");
  }

  ngAfterViewInit() {
  
  }
  ngOnInit() {
    this.getUserDetails();

    switch(this.selectedlang){
      case "en":
        this.data = this.en_data;
        break;
      case "mr":
        this.data = this.mr_data;
        break;
    }

    this.getbackDamDetails();
    this.dams1 = this.dams;
 
     console.log(this.dams.length);
    this.getIrrigation();
    switch(this.selectedlang){
      case "en":
        this.data = this.en_data;
        break;
      case "mr":
        this.data = this.mr_data;
        break;
    }


  this.timeou =  setInterval(() => {
    this.checkedValues(this.selData);
  }, 1000);

    // this.getIrrigation();
    // this.dams1 =
    
    //   [
    //     {
    //       "item_id": "2000003552",
    //       "item_text": "Ashvi KT Weir (Pravara Loni )"
    //     },
    //     {
    //       "item_id": "2000001093",
    //       "item_text": "Abhalwadi KT Weir"
    //     },
    //     {
    //       "item_id": "2000000520",
    //       "item_text": "Adhala Dam"
    //     },
    //     {
    //       "item_id": "2000003483",
    //       "item_text": "Kolhar KTW"
    //     },
    //     {
    //       "item_id": "2000000767",
    //       "item_text": "Nilwande-2"
    //     },
    //     {
    //       "item_id": "2000000201",
    //       "item_text": "Mula"
    //     }
      
    // ];


    

   

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: this.data.select_all,
      unSelectAllText: this.data.un_select_all,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: this.data.searchPlaceholderText,
      noDataAvailablePlaceholderText : this.selectedlang == 'en' ? 'No data available' : 'माहिती उपलब्ध नाही',
    };

  
    this.damMasterForm = this.fb.group({
  //    "damSelectname": new FormControl('', [Validators.required]),

      "damSelectname": new FormControl(''),

      "damIdcont": new FormControl(''),
    });
 
    
  }


  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      console.log(this.userPosition + "==========" + this.userOfficeType);

      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division
      if (this.userOfficeType == "Division" || this.userOfficeType == "Circle" || this.userOfficeType == "Mantralaya"  || this.userOfficeType == "Region"  || this.userOfficeType == "Corporation"  ){ 

        this.skipDiv = true;
    
      }
      else {
        
        this.skipDiv = false;

      }

     
    });
  }

  skip(){
    this.router.navigate(['dashboard']);
  }
  getbackDamDetails() {
     const body = new HttpParams({
       fromObject: {
         post_user_id: localStorage.getItem('post_user_id'),
 
       }
     });
     this.http.getBackDetailsDams(body).subscribe((resolve: any) => {
       let data1 = resolve;
       if (data1.status == 0) {
 
         return false;
 
       } else {
 
         this.damList = resolve.data.dams_list;
         this.damList1 = resolve.data.dams_list;  //All values Hold
        //  console.log(resolve.data)
 
 
        this.dams=[];
         for (let k = 0; k < resolve.data.dams_list.length; k++) {

          if(this.selectedlang === 'en'){
            this.dams.push({
              item_id: resolve.data.dams_list[k]['dam_unique_code'],
              item_text: resolve.data.dams_list[k]['dam_name']
            });
          }else if(this.selectedlang === 'mr'){
            this.dams.push({
              item_id: resolve.data.dams_list[k]['dam_unique_code'],
              item_text: resolve.data.dams_list[k]['dam_name_marathi']
            });
          }      
         }

     

          //this.selData = '';
          this.selData = resolve.data;
          this.damsDetailsArr=[];
          this.checkedValues(this.selData);
         // return false;
        // console.log(resolve.data );
          for (let k = 0; k <resolve.data.selected.dams.length; k++) {
        //  console.log( "ready!" );
             $("#prochk"+resolve.data.selected.dams[k]['dam_id']).prop("checked", true);
             $('#show'+resolve.data.selected.dams[k]['dam_id']).show();  
            if(resolve.data.selected.dams[k]['irrigation_sources']){
             // this.showDetails.push(resolve.data.selected.dams[k]['dam_id']);
             $('#show'+resolve.data.selected.dams[k]['dam_id']).show();

             this.damsDetailsArr.push({
               dam_id: resolve.data.selected.dams[k]['dam_id'],
               irrigation_sources: resolve.data.selected.dams[k]['irrigation_sources']
       
             });
              var nameArr = resolve.data.selected.dams[k]['irrigation_sources'].split(',');
             for(let j=0;j<nameArr.length;j++){
               $("#mix"+nameArr[j]+"_"+resolve.data.selected.dams[k]['dam_id']).prop("checked", true);                
             }
 
           }
           
           
         }
        //  console.log("dd")
 
 
        console.log(this.dams);
        this.dams1 = this.dams;
       }
     },
       (err) => {
 
         console.log(err);
 
 
       }
     )
   }

   checkedValues(data){
    for (let k = 0; k <data.selected.dams.length; k++) {
      $("#prochk"+data.selected.dams[k]['dam_id']).prop("checked", true);
      $('#show'+data.selected.dams[k]['dam_id']).show();  
     if(data.selected.dams[k]['irrigation_sources']){
     $('#show'+data.selected.dams[k]['dam_id']).show();
      var nameArr = data.selected.dams[k]['irrigation_sources'].split(',');
      for(let j=0;j<nameArr.length;j++){
        $("#mix"+nameArr[j]+"_"+data.selected.dams[k]['dam_id']).prop("checked", true);                
      }

    }
  }


   }

   checkIfExists(damId)
   {

    if(this.showDetails.indexOf(damId) !== -1) {
      return true;
    }
    return false;
    

   }
  getDamsDetails(){


    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
        project_types: localStorage.getItem('project_types'),
        project_statuses: localStorage.getItem('project_statuses'),
        project_gis_no: localStorage.getItem('project_gis_no')
      }
    });

    this.http.getProjectLinking(body).subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {

        return false;

      } else {
        if (resolve.data) {
        // this.dams = [];
          for (let k = 0; k < resolve.data.damList.length; k++) {
            this.dams.push({
              item_id: resolve.data.damList[k]['dam_unique_code'],
              item_text: resolve.data.damList[k]['dam_name']
            });
          }
          console.log(this.dams);
        }
      }
    },
      (err) => {

        console.log(err);


      }
    )
  }


  onItemSelect(item: any) {
    this.typeArr.push(item.item_id);

    if (this.damNameArr)
      this.damNameArr += ',' + item.item_id;
    else
      this.damNameArr = item.item_id;
    this.filterDamList(this.typeArr);


 // this.getbackDamDetails();
   // console.log(this.damNameArr)
  }
  onSelectAll(items: any) {

    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }
    this.damNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArr[k];
      else
        this.damNameArr = this.typeArr[k];
    }
    //console.log(this.typeArr);
    this.filterDamList(this.typeArr);
  }

 
  onDeSelect(items: any) {

   this.typeArr.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.damNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArr[k];
      else
        this.damNameArr = this.typeArr[k];
    }

    this.filterDamList(this.typeArr);
   // console.log(this.damNameArr+'sss');

  }

  onDeSelectAll(items: any) {
    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.damNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.damNameArr)
        this.damNameArr += ',' + this.typeArr[k];
      else
        this.damNameArr = this.typeArr[k];
    }

    this.filterDamList(this.typeArr);
    
  }

  OnItemDeSelect(items: any) {
    const index: number = this.typeArr.indexOf(items.item_id);
    this.typeArr.splice(index, 1);
    //this.selectedItemsRoot = [];
   // this.typeArr.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }
    this.damNameArr = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.damNameArr){
        this.damNameArr += ',' + this.typeArr[k];
      }
      else{
        this.damNameArr = this.typeArr[k];
      }
    }

    this.filterDamList(this.typeArr);
 ///   console.log(this.damNameArr+'----');
  }

  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  getIrrigation(){


   // console.log( localStorage.getItem('post_user_id'));
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),

      }
    });

    this.http.getIrrigation().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {

        return false;

      } else {

        this.irrigationList=resolve.data.irrigation_sources;
      }
    },
      (err) => {

        console.log(err);


      }
    )

  }
  cancelupdate(){

    $("#preModal").hide();

  }

  okupdate(){
      this.disabled = !this.disabled;
      this.router.navigate(['dashboard']);
  }
  closeUp(){
    $("#preModal").hide();

  }
  finalSubmit() {

  
    if (this.timeou) {

     // console.log("FINAL SUB");
      clearInterval(this.timeou);
   }
    clearInterval(this.timeou);
       if (this.damMasterForm.status == 'Invalid') {
         return false;
        }
        else {
          const body = new HttpParams({
            fromObject: {
              post_user_id:  localStorage.getItem('post_user_id'), 
             //dams
            
            }
          });
      
          this.http.userUpdateFirstTime(body).subscribe((resolve:any)=>{
          let data1 = resolve;

          // console.log(resolve.data)
        //  return false;
            if(resolve.status == 0){
        
              return false;
              
            }else {
              if(resolve.status){

              //  alert("ddd");

                $("#preModal").show();
               

               
                
            }
          }
          },
          (err)=>{
             
            console.log(err);
        
             
          }
          )
             
           
         
    
        }
    
  }

}
