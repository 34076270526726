import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';



@Component({
	selector: 'app-step-one',
	templateUrl: './step-one.component.html',
	styleUrls: ['./step-one.component.css']
})


export class StepOneComponent implements OnInit {
	//City: any = ['Florida', 'South Dakota', 'Tennessee', 'Michigan']
	//projectSelect = this.getProjectList();
	isSubmitted = false;


	firstStepForm: FormGroup;
	projectSelect: any = [];
	optionsSelect = []
	damSelect = []
	districtSelect = []
	irrigationSelect = []
	canalSelect = []
	branchCanalSelect = [];
	distributorySelect = []
	minorSelect = []
	subminorSelect = []
	outletSelect = []
	outletLeftRightSelect = []
	mainCanelArray = [];
	regionSelect = [];
	circleSelect = [];
	divisionSelect = [];
	regionSubSelect = [];
	wuaSelect = [];
	sectionSelect = [];
	isShown: boolean = false
	projectGisCode
	damUniqueCode
	branchCanelArray = []
	distributoryArray = []
	mainCanelId
	branchCanelId
	distributoryUniqueId
	minorUniqueId
	subMinorUniqueId
	minorCanelArray = []
	subMinorCanelArray = []
	outLetArray = []
	wuaArray = []
	ngOnInit() {

		//this.projectSelect = this.getProjectList();
		console.log(this.optionsSelect[0]);
		this.firstStepForm = this.fb.group({
			farmer_master_project_code: new FormControl("", [Validators.required]),
			farmer_master_dam_id: new FormControl("",),
			farmer_master_district_id_in_which_dam_situated: new FormControl("", [Validators.required]),
			farmer_master_irregation_source: new FormControl("", ),
			farmer_master_canal_id: new FormControl("",),
			farmer_master_canal_branch_id: new FormControl("",),
			farmer_master_canal_distributary_id: new FormControl("",),
			farmer_master_minor_id: new FormControl("",),
			farmer_master_subminor_id: new FormControl("",),
			farmer_master_outlet_number: new FormControl("",),
			farmer_master_outlet_side: new FormControl("",),
			farmer_master_wua_id: new FormControl("",),
			post_user_id: new FormControl(localStorage.getItem('post_user_id'),),
			test: new FormControl("")

		});
		this.projectSelect = this.getProjectList();
	}

	onReset() {
		// this.submitted = false;
		this.firstStepForm.reset();
	}

	toggleShow(evt) {
		// console.log(evt);
		if (evt == "Canal Flow") {
			this.isShown = true;
			this.getMainCanel()
		} else {
			this.isShown = false;
		}
	}
	getProjectList() {

		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});

		this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve)
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.projects) {
					this.irrigationSelect = resolve.irrigationResources
					this.projectSelect = resolve.projects;
				} else
					return false;
			}

		},
			(err) => {
				console.log(err);
			}
		)


	}
	getDamList(ob) {
		// console.log(ob)
		this.projectGisCode = ob
		let post_user_id = localStorage.getItem('post_user_id');
		// this.project_gis_no = ob;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				project_gis_no: ob,
			}
		});
		this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.damSelect = resolve.dams

		})
	}

	getDistrictList(ob) {
		// console.log(ob);
		this.damUniqueCode = ob.dam_unique_code
		let post_user_id = localStorage.getItem('post_user_id');
		// this.project_gis_no = ob;
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
				dam_district_code: ob.dam_district_code,
			}
		});
		this.http.getDamDistricts(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.districtSelect = resolve.data

		})
		this.getwuaMaster()


	}
	getMainCanel() {
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.damUniqueCode,
			}
		});
		this.http.getMainCanel(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.mainCanelArray = resolve.data

		})
	}
	getBranchCanalList(id) {
		// console.log(id);
		this.mainCanelId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.damUniqueCode,
				main_canal_id: id
			}
		});
		this.http.getBranchCanel(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.branchCanelArray = resolve.data

		})

	}

	getDistributoryList(id) {
		this.branchCanelId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.damUniqueCode,
				main_canal_id: this.mainCanelId,
				branch_canal_id: id
			}
		});
		this.http.getdistributory(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.distributoryArray = resolve.data

		})
		// distributory_branch_canal_id
	}

	getMinorList(id) {
		this.distributoryUniqueId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.damUniqueCode,
				main_canal_id: this.mainCanelId,
				branch_canal_id: this.branchCanelId,
				distributory_canal_id: id
			}
		});
		this.http.getMinorListStep1(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.minorCanelArray = resolve.data

		})
	}


	getSubMinorList(id) {
		this.minorUniqueId = id
		const body = new HttpParams({
			fromObject: {
				project_unique_gis: this.projectGisCode,
				dam_unique_code: this.damUniqueCode,
				main_canal_id: this.mainCanelId,
				branch_canal_id: this.branchCanelId,
				distributory_canal_id: this.distributoryUniqueId,
				minor_canal_id: id
			}
		});
		this.http.getSubMinorListStep1(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.subMinorCanelArray = resolve.data

		})
	}


	getOutletList(id) {
		this.subMinorUniqueId = id
		

	}


	getwuaMaster() {
		const body = new HttpParams({
			fromObject: {

				wua_project_unique_gis: this.projectGisCode,
				wua_dam_unique_code: this.damUniqueCode,
				// wua_main_canal_id: this.mainCanelId,
				// wua_branch_id: this.branchCanelId,
				// wua_distributory_id: this.distributoryUniqueId,
				// wua_minor_id: this.minorUniqueId,
				// wua_subminor_id: this.subMinorUniqueId,
			}
		});
		this.http.getwuaMaster(body).subscribe((resolve: any) => {
			// console.log(resolve);
			this.wuaArray = resolve.data

		})
		// this.wuaArray = [
		//   { id:1,name:"1"},
		//   { id:2,name:"2"},
		//   { id:3,name:"3"}
		// ]
	}




	saveFinal = function () {
		// this.twoStepFormSaveState();
		// console.log(this.firstStepForm);
		this.firstStepForm.value.farmer_master_dam_id = this.damUniqueCode
		this.firstStepForm.value.post_user_id = localStorage.getItem('post_user_id');
		if (this.firstStepForm.status == "INVALID") {
			alert('All fields are mandatory')
			return
		}
		if(this.isShown == true){

				if(this.firstStepForm.value.farmer_master_canal_id == "" || this.firstStepForm.value.farmer_master_canal_id == undefined || this.firstStepForm.value.farmer_master_canal_id == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_canal_branch_id == "" || this.firstStepForm.value.farmer_master_canal_branch_id == undefined || this.firstStepForm.value.farmer_master_canal_branch_id == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_canal_distributary_id == "" || this.firstStepForm.value.farmer_master_canal_distributary_id == undefined || this.firstStepForm.value.farmer_master_canal_distributary_id == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_minor_id == "" || this.firstStepForm.value.farmer_master_minor_id == undefined || this.firstStepForm.value.farmer_master_minor_id == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_subminor_id == "" || this.firstStepForm.value.farmer_master_subminor_id == undefined || this.firstStepForm.value.farmer_master_subminor_id == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_outlet_number == "" || this.firstStepForm.value.farmer_master_outlet_number == undefined || this.firstStepForm.value.farmer_master_outlet_number == null){
					return
				}
				if(this.firstStepForm.value.farmer_master_outlet_side == "" || this.firstStepForm.value.farmer_master_outlet_side == undefined || this.firstStepForm.value.farmer_master_outlet_side == null){
					return
				}
		}

		// console.log(this.firstStepForm);
		const body = new HttpParams({
			fromObject: this.firstStepForm.value
		});
		this.http.saveFirstStap(body).subscribe((resolve: any) => {
			// console.log(resolve);
			// this.wuaArray = resolve.data
			this.router.navigate(['/StepTwo/' + resolve.data.id])

		})

	}


	onSubmit(form: FormGroup) {
		this.isSubmitted = true;

		if (!this.firstStepForm.valid) {
			return false;
		} else {
			// console.log(JSON.stringify(this.firstStepForm.value))
		}
		// console.log(this.firstStepForm.value);

		this.firstStepFormSave()

	}

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpRequestService,
		private common: CommonService,


	) {

	}

	firstStepFormSave() {
		let data = {};





		let id = localStorage.getItem('farmer_master_id');
		if (!id) {

			const body = new HttpParams({
				fromObject: {
					farmer_master_canal_branch_id: this.firstStepForm.value.branchCanalSelectName,
					farmer_master_canal_id: this.firstStepForm.value.canalSelectName,
					farmer_master_circle_id: this.firstStepForm.value.circleSelectName,
					farmer_master_corporation_id: this.firstStepForm.value.corporationSelectName,
					farmer_master_dam_id: this.firstStepForm.value.damSelectName,
					farmer_master_canal_distributary_id: this.firstStepForm.value.distributorySelectName,
					farmer_master_district_id_in_which_dam_situated: this.firstStepForm.value.districtSelectName,
					farmer_master_division_id: this.firstStepForm.value.divisionSelectName,
					farmer_master_irregation_source: this.firstStepForm.value.irrigationSelectName,
					farmer_master_minor_id: this.firstStepForm.value.minorSelectName,
					farmer_master_outlet_side: this.firstStepForm.value.outletLeftRightSelectName,
					farmer_master_outlet_number: this.firstStepForm.value.outletSelectName,
					farmer_master_project_code: this.firstStepForm.value.projectSelectname,
					farmer_master_region_id: this.firstStepForm.value.regionSelectName,
					farmer_master_subdivision_id: this.firstStepForm.value.regionSubSelectName,
					farmer_master_section_office_id: this.firstStepForm.value.sectionSelectName,
					farmer_master_subminor_id: this.firstStepForm.value.subminorSelectName,
					farmer_master_wua_name: this.firstStepForm.value.wuaSelectName,

				}
			});
			this.http.insertFarmerMaster(body).subscribe((resolve: any) => {
				let data = resolve;
				//localStorage.setItem('farmer_master_id', resolve.data.id);
				// console.log("Insert Success");
				this.router.navigate(['StepTwo']);
				return false
				if (data.status == 1) {
					//this.validNumber=1;

					return false;

				} else {
					// this.router.navigate(['otp']);
					this.common.mobile = this.firstStepForm.value.mobilenumber;
				}
				//this.common.mobile = resolve;

			},
				(err) => {

					console.log(err);


				}
			)
		}

		else {

			const body = new HttpParams({
				fromObject: {
					farmer_master_canal_branch_id: this.firstStepForm.value.branchCanalSelectName,
					farmer_master_canal_id: this.firstStepForm.value.canalSelectName,
					farmer_master_circle_id: this.firstStepForm.value.circleSelectName,
					farmer_master_corporation_id: this.firstStepForm.value.corporationSelectName,
					farmer_master_dam_id: this.firstStepForm.value.damSelectName,
					farmer_master_canal_distributary_id: this.firstStepForm.value.distributorySelectName,
					farmer_master_district_id_in_which_dam_situated: this.firstStepForm.value.districtSelectName,
					farmer_master_division_id: this.firstStepForm.value.divisionSelectName,
					farmer_master_irregation_source: this.firstStepForm.value.irrigationSelectName,
					farmer_master_minor_id: this.firstStepForm.value.minorSelectName,
					farmer_master_outlet_side: this.firstStepForm.value.outletLeftRightSelectName,
					farmer_master_outlet_number: this.firstStepForm.value.outletSelectName,
					farmer_master_project_code: this.firstStepForm.value.projectSelectname,
					farmer_master_region_id: this.firstStepForm.value.regionSelectName,
					farmer_master_subdivision_id: this.firstStepForm.value.regionSubSelectName,
					farmer_master_section_office_id: this.firstStepForm.value.sectionSelectName,
					farmer_master_subminor_id: this.firstStepForm.value.subminorSelectName,
					farmer_master_wua_name: this.firstStepForm.value.wuaSelectName,
					farmer_master_id: localStorage.getItem('farmer_master_id'),
				}
			});

			this.http.insertFarmerMaster(body).subscribe((resolve: any) => {
				// console.log(resolve);
				let data = resolve;
				localStorage.setItem('farmer_master_id', resolve.data.id);
				// console.log("Insert Success");
				this.router.navigate(['StepTwo']);
				return false
				//console.log(resolve.status)
				if (data.status == 1) {
					//this.validNumber=1;

					return false;

				} else {
					// this.router.navigate(['otp']);
					this.common.mobile = this.firstStepForm.value.mobilenumber;
				}
				//this.common.mobile = resolve;

			},
				(err) => {

					console.log(err);


				}
			)
		}


	}
	twoStepFormSaveState() {
		let data = {};




		let id = localStorage.getItem('farmer_master_id');
		//alert(id);
		if (!id) {


			const body = new HttpParams({
				fromObject: {
					farmer_master_canal_branch_id: this.firstStepForm.value.branchCanalSelectName,
					farmer_master_canal_id: this.firstStepForm.value.canalSelectName,
					farmer_master_circle_id: this.firstStepForm.value.circleSelectName,
					farmer_master_corporation_id: this.firstStepForm.value.corporationSelectName,
					farmer_master_dam_id: this.firstStepForm.value.damSelectName,
					farmer_master_canal_distributary_id: this.firstStepForm.value.distributorySelectName,
					farmer_master_district_id_in_which_dam_situated: this.firstStepForm.value.districtSelectName,
					farmer_master_division_id: this.firstStepForm.value.divisionSelectName,
					farmer_master_irregation_source: this.firstStepForm.value.irrigationSelectName,
					farmer_master_minor_id: this.firstStepForm.value.minorSelectName,
					farmer_master_outlet_side: this.firstStepForm.value.outletLeftRightSelectName,
					farmer_master_outlet_number: this.firstStepForm.value.outletSelectName,
					farmer_master_project_code: this.firstStepForm.value.projectSelectname,
					farmer_master_region_id: this.firstStepForm.value.regionSelectName,
					farmer_master_subdivision_id: this.firstStepForm.value.regionSubSelectName,
					farmer_master_section_office_id: this.firstStepForm.value.sectionSelectName,
					farmer_master_subminor_id: this.firstStepForm.value.subminorSelectName,
					farmer_master_wua_name: this.firstStepForm.value.wuaSelectName,
				}
			});
			this.http.insertFarmerMaster(body).subscribe((resolve: any) => {
				// console.log(resolve);
				let data = resolve;
				//alert(resolve.farmer_master_id);
				//alert("--");
				//alert(resolve.data.id);
				//this.common.farmer_master_id = resolve.data.id;
				localStorage.setItem('farmer_master_id', resolve.data.id);
				// console.log("Insert Success");
				return false

				if (data.status == 1) {
					//this.validNumber=1;

					return false;

				} else {
					// this.router.navigate(['otp']);
					this.common.mobile = this.firstStepForm.value.mobilenumber;
				}
				//this.common.mobile = resolve;

			},
				(err) => {

					console.log(err);


				}
			)



		}

		else {



			const body = new HttpParams({
				fromObject: {
					farmer_master_canal_branch_id: this.firstStepForm.value.branchCanalSelectName,
					farmer_master_canal_id: this.firstStepForm.value.canalSelectName,
					farmer_master_circle_id: this.firstStepForm.value.circleSelectName,
					farmer_master_corporation_id: this.firstStepForm.value.corporationSelectName,
					farmer_master_dam_id: this.firstStepForm.value.damSelectName,
					farmer_master_canal_distributary_id: this.firstStepForm.value.distributorySelectName,
					farmer_master_district_id_in_which_dam_situated: this.firstStepForm.value.districtSelectName,
					farmer_master_division_id: this.firstStepForm.value.divisionSelectName,
					farmer_master_irregation_source: this.firstStepForm.value.irrigationSelectName,
					farmer_master_minor_id: this.firstStepForm.value.minorSelectName,
					farmer_master_outlet_side: this.firstStepForm.value.outletLeftRightSelectName,
					farmer_master_outlet_number: this.firstStepForm.value.outletSelectName,
					farmer_master_project_code: this.firstStepForm.value.projectSelectname,
					farmer_master_region_id: this.firstStepForm.value.regionSelectName,
					farmer_master_subdivision_id: this.firstStepForm.value.regionSubSelectName,
					farmer_master_section_office_id: this.firstStepForm.value.sectionSelectName,
					farmer_master_subminor_id: this.firstStepForm.value.subminorSelectName,
					farmer_master_wua_name: this.firstStepForm.value.wuaSelectName,
					farmer_master_id: localStorage.getItem('farmer_master_id'),
				}
			});

			this.http.insertFarmerMaster(body).subscribe((resolve: any) => {
				//console.log(resolve+'===');
				let data = resolve;

				// console.log(resolve.farmer_master_id+'hello');
				// console.log(resolve.id+'helloqqq');
				//this.common.farmer_master_id = resolve.farmer_master_id;
				///localStorage.setItem('farmer_master_id', resolve.farmer_master_id);
				// console.log("Insert Success");
				return false

				if (data.status == 1) {


					return false;

				} else {
					// this.router.navigate(['otp']);
					this.common.mobile = this.firstStepForm.value.mobilenumber;
				}
				//this.common.mobile = resolve;

			},
				(err) => {

					console.log(err);


				}
			)


		}


	}

}
