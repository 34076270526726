import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { HttpRequestService } from './../_service/http-request.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $: any;
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { HeaderComponent } from '../header/header.component';
import { DataTableDirective } from 'angular-datatables';
import { ExcelService } from '../_service/excel.service';
// import "rxjs/add/operator/map";
@Component({
  selector: 'app-view-project-layout',
  templateUrl: './view-project-layout.component.html',
  styleUrls: ['./view-project-layout.component.css']
})
export class ViewProjectLayoutComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dataArray = []
  dtOptions: DataTables.Settings = {};
  clickEventsubscription: Subscription;
  dtTrigger: Subject<any> = new Subject();
  selectedlang = localStorage.getItem('selected_lang');
  projectLayoutSearchForm: FormGroup;
  damsSelect
  bankArray
  projectSelect
  excelData: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    private excelService: ExcelService,
    private sharedService: SharedService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      // console.log("test11")
      this.selectedlang = localStorage.getItem('selected_lang');

      this.ngOnInit();

      //this.ngAfterViewInit();
    })
  }
  data: any = {};
  en_data: any = {

    view_project: "Project Layout Master",
    add: "Add",
    project_name: "Project Name",
    dam_name: "Dam Name",
    canal_name: "Main Canal",
    branch: "Branch",
    distributory: "Distributory",
    minor: "Minor",
    subminor: "Subminor",
    outlet: "Outlet",
    action: "Action",
    edit: "Edit",
    save: "Save",
    bank_type: "Canal Type",
  }
  mr_data: any = {
    view_project: "प्रकल्पाची कालवा वितरण व्यवस्थेची माहिती",
    add: "माहिती भरा",
    project_name: "प्रकल्पाचे नाव",
    dam_name: "धरणाचे नाव",
    canal_name: "मुख्य कालवा",
    branch: "शाखा",
    distributory: "वितरिका",
    minor: "लघुवितरिका",
    subminor: "उप लघुवितरिका",
    outlet: "विमोचक",
    action: "कृती",
    edit: "सुधारणे",
    save: "जतन करा",
    bank_type: "तीर प्रकार"
  }
  ngOnInit(): void {

    switch (this.selectedlang) {
      case "en":
        this.data = { ...this.en_data };
        break;
      case "mr":
        this.data = { ...this.mr_data };
        break;
    }
    this.getData(1)
    if (this.selectedlang == 'mr') {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.marathi_datatables

      };
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: HeaderComponent.english_datatables

      };

    }
    // window.location.reload();
    $(() => {

      //   this.dtOptions =  this.dtOptions;
      //  this.dtTrigger.unsubscribe();
      // $('#display').datatable(this.dtOptions);
    });

    this.projectLayoutSearchForm = this.fb.group({
      "project_layout_project_unique_code": new FormControl(null),
      "project_layout_dam_unique_code": new FormControl(null),
      "project_layout_bank_id": new FormControl(null)
    });

    this.getProjectDetails()
    this.getBanks()
  }

  getProjectDetails() {
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });
    this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
      // console.log(resolve)
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          this.projectSelect = resolve.data;
        } else
          return false;
      }
    },
      (err) => {
        console.log(err)
      })
  }

  getBanks() {
    // getBankId  bankArray
    this.http.getBankId().subscribe((resolve: any) => {
      // console.log(resolve);

      this.bankArray = resolve.data;
      // 
    })

  }

  onChangeforDams(id) {


    let post_user_id = localStorage.getItem('post_user_id');
    // this.project_gis_no = id;
    this.projectLayoutSearchForm.patchValue({
      project_layout_dam_unique_code: null
    })
    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_id: id,
      }
    });
    this.http.getDamDetailLayout(body).subscribe((resolve: any) => {
      // console.log(resolve);
      //return false;
      let data1 = resolve;

      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          this.damsSelect = resolve.data;

          return false;
        } else
          return false;
      }
    },
      (err) => {
        console.log(err);
      })
  }

  projectLayoutSearchFormSubmit() {
    this.dataArray = []
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
        project_layout_project_unique_code: this.projectLayoutSearchForm.value.project_layout_project_unique_code,
        project_layout_dam_unique_code: this.projectLayoutSearchForm.value.project_layout_dam_unique_code,
        project_layout_bank_id: this.projectLayoutSearchForm.value.project_layout_bank_id,
      }
    });
    this.http.getViewProjectsLayout(body).subscribe((data: any) => {
      // setTimeout(() => {
      // console.log(data)
      this.dataArray = data.data;

      this.dtTrigger.next();
      //  this.ngOnDestroy()
      // this.ngAfterViewInit()
      // }, 3000);
    })
  }


  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      //this.dtTrigger.next();
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              that.search(this['value']).draw();

              // console.log(that.search(this['value']).draw())
            }
          });
        });
        this.dtTrigger.complete();
      });
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  getData(no) {

    this.dataArray = []
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id')
      }
    });
    this.http.getViewProjectsLayout(body).subscribe((data: any) => {
      // setTimeout(() => {
      // console.log(data)
      this.dataArray = data.data;

      this.dtTrigger.next();
      //  this.ngOnDestroy()
      // this.ngAfterViewInit()
      // }, 3000);
    })
  }
  Add() {
    this.router.navigate(['projectLayout']);

  }

  destroyProjectLayout(layoutId) {
    console.log(layoutId)
    // return;

    const body = new HttpParams({
      fromObject: {
        project_layout_id: layoutId,
        post_user_id: localStorage.getItem('post_user_id')
      }
    });

    this.http.deleteProjectLayout(body).subscribe((resolve: any) => {
      let data = resolve
      if (data.status) {
        this.sendAlert(data.message);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/viewProject'])
        );
      }
      else {
        this.sendAlert(data.message)
      }

    })

  }

  sendAlert(message) {
    switch (this.selectedlang) {
      case "mr":
        const body = new HttpParams({
          fromObject: {
            text: message,
          }
        });
        this.http.englishToMarathi(body).subscribe((data: any) => {
          alert(data.data);
        });
        break;
      case "en":
        alert(message);
        break;
    }
  }


  exportAsXLSX(): void {

    this.excelData = [];
    let post_user_id = localStorage.getItem('post_user_id');
    const body = new HttpParams({
      fromObject: {
        user_type: localStorage.getItem('user_type'),
        post_user_id: post_user_id,
        project_layout_project_unique_code: this.projectLayoutSearchForm.value.project_layout_project_unique_code,
        project_layout_dam_unique_code: this.projectLayoutSearchForm.value.project_layout_dam_unique_code,
        project_layout_bank_id: this.projectLayoutSearchForm.value.project_layout_bank_id,
      }
    });
    // console.log("here");

    this.http.getViewProjectsLayout(body).subscribe((data: any) => {
      console.log(data);
      // return
      this.dataArray = data.data
      for (var i in this.dataArray) {

        if (this.dataArray[i].mwrd_project_master) {

          var project = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_master.project_name : this.dataArray[i].mwrd_project_master.project_name_marathi
          var dam = this.selectedlang == 'en' ? this.dataArray[i].mwrd_dam_master.dam_name : this.dataArray[i].mwrd_dam_master.dam_name_marathi
          var bankName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_bank_master.bank_name : this.dataArray[i].mwrd_bank_master.bank_name_marathi

          if (this.dataArray[i].mwrd_main_canal_master) {
            var mainCanalName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_main_canal_master.main_canal_name : this.dataArray[i].mwrd_main_canal_master.main_canal_name_marathi
            if (this.dataArray[i].mwrd_main_canal_master) {
              var mainCanalType = this.selectedlang == 'en' ? this.dataArray[i].mwrd_main_canal_master.mwrd_canal_type_master.master_canal_type_name : this.dataArray[i].mwrd_main_canal_master.mwrd_canal_type_master.master_canal_type_marathi_name

            }
            else {
              mainCanalType = "";
            }
            var mainCanalICA = this.dataArray[i].mwrd_main_canal_master.main_canal_ica
            var mainCanalCCA = this.dataArray[i].mwrd_main_canal_master.main_canal_cca
            var mainCanalGCA = this.dataArray[i].mwrd_main_canal_master.main_canal_gca
          }
          else {
            mainCanalName = "";
            mainCanalType = "";
            mainCanalICA = "";
            mainCanalCCA = "";
            mainCanalGCA = "";
          }



          if (this.dataArray[i].mwrd_branch_canal_master) {
            var branchCanalName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_branch_canal_master.branch_canal_name : this.dataArray[i].mwrd_branch_canal_master.branch_canal_name_marathi
            var branchCanalOfftaking = this.dataArray[i].mwrd_branch_canal_master.branch_canal_offtaking_chainage_km
            var branchCanalICA = this.dataArray[i].mwrd_branch_canal_master.branch_canal_ica
            var branchCanalCCA = this.dataArray[i].mwrd_branch_canal_master.branch_canal_cca
            var branchCanalGCA = this.dataArray[i].mwrd_branch_canal_master.branch_canal_gca
          }
          else {
            branchCanalName = "";
            branchCanalOfftaking = "";
            branchCanalICA = "";
            branchCanalCCA = "";
            branchCanalGCA = "";
          }



          if (this.dataArray[i].mwrd_distributory_canal_master) {
            var distributoryName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_name : this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_name_marathi
            var distributoryOfftaking = this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_offtaking_chainage_km
            var distributoryICA = this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_ica
            var distributoryCCA = this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_cca
            var distributoryGCA = this.dataArray[i].mwrd_distributory_canal_master.distributory_canal_gca
          }
          else {
            distributoryName = '';
            distributoryOfftaking = '';
            distributoryICA = '';
            distributoryCCA = '';
            distributoryGCA = '';
          }

          if (this.dataArray[i].mwrd_minor_canal_master) {
            var minorCanalName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_minor_canal_master.minor_canal_name : this.dataArray[i].mwrd_minor_canal_master.minor_canal_name_marathi
            var minorCanalOfftaking = this.dataArray[i].mwrd_minor_canal_master.minor_canal_offtaking_chainage_km
            var minorCanalICA = this.dataArray[i].mwrd_minor_canal_master.minor_canal_ica
            var minorCanalCCA = this.dataArray[i].mwrd_minor_canal_master.minor_canal_cca
            var minorCanalGCA = this.dataArray[i].mwrd_minor_canal_master.minor_canal_gca
          }
          else {
            minorCanalName = '';
            minorCanalOfftaking = '';
            minorCanalICA = '';
            minorCanalCCA = '';
            minorCanalGCA = '';
          }

          if (this.dataArray[i].mwrd_subminor_canal_master) {
            var subminorCanalName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_name : this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_name_marathi
            var subminorCanalOfftaking = this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_offtaking_chainage_km
            var subminorCanalICA = this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_ica
            var subminorCanalCCA = this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_cca
            var subminorCanalGCA = this.dataArray[i].mwrd_subminor_canal_master.subminor_canal_gca
          }
          else {
            subminorCanalName = ''
            subminorCanalOfftaking = ''
            subminorCanalICA = ''
            subminorCanalCCA = ''
            subminorCanalGCA = ''
          }

          if (this.dataArray[i].mwrd_outlet_canal_master) {
            var outletCanalName = this.selectedlang == 'en' ? this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_name : this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_name_marathi
            if (this.dataArray[i].mwrd_common_master) {
              var outletSide = this.selectedlang == 'en' ? this.dataArray[i].mwrd_common_master.common_name : this.dataArray[i].mwrd_common_master.common_marathi_name
            }
            else {
              outletSide = "";
            }
            var outletCanalOfftaking = this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_offtaking_chainage_km
            var outletCanalICA = this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_ica
            var outletCanalCCA = this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_cca
            var outletCanalGCA = this.dataArray[i].mwrd_outlet_canal_master.outlet_canal_gca
          }
          else {
            outletCanalName = "";
            outletSide = "";
            outletCanalOfftaking = "";
            outletCanalICA = "";
            outletCanalCCA = "";
            outletCanalGCA = "";
          }






        } else {
          project = '';
          dam = '';
          bankName = ''

          mainCanalName = "";
          mainCanalType = "";
          mainCanalICA = "";
          mainCanalCCA = "";
          mainCanalGCA = "";

          branchCanalName = "";
          branchCanalOfftaking = "";
          branchCanalICA = "";
          branchCanalCCA = "";
          branchCanalGCA = "";

          distributoryName = "";
          distributoryOfftaking = "";
          distributoryICA = "";
          distributoryCCA = "";
          distributoryGCA = "";

          minorCanalName = "";
          minorCanalOfftaking = "";
          minorCanalICA = "";
          minorCanalCCA = "";
          minorCanalGCA = "";

          subminorCanalName = "";
          subminorCanalOfftaking = "";
          subminorCanalICA = "";
          subminorCanalCCA = "";
          subminorCanalGCA = "";

          outletCanalName = "";
          outletSide = "";
          outletCanalOfftaking = "";
          outletCanalICA = "";
          outletCanalCCA = "";
          outletCanalGCA = "";

        }

        // if (this.dataArray[i].mwrd_project_master) {

        //   var project = this.selectedlang == 'en' ? this.dataArray[i].mwrd_project_master.project_name : this.dataArray[i].mwrd_project_master.project_name_marathi
        // } else {
        //   project = '';

        // }


        var Yes = this.selectedlang == 'en' ? 'Yes' : 'होय'
        var No = this.selectedlang == 'en' ? 'No' : 'नाही'
        var dateTo = new Date((this.dataArray[i].farmer_master_valid_from));
        var dateToUpto = new Date((this.dataArray[i].farmer_master_valid_upto));

        // if(dateTo){
        // let dateTo_split = this.datepipe.transform(dateTo, 'dd-MM-yyyy');
        // }else {
        //   let dateTo_split ='';
        // }
        // if(dateToUpto){
        // let dateToUpto_split = this.datepipe.transform(dateToUpto, 'dd-MM-yyyy');
        // }else {
        //   let dateToUpto_split ='';
        // }

        // let dateToUpto_split = this.datepipe.transform(dateTo, 'dd-MM-yyyy');
        let dateTo_split = '';
        let dateToUpto_split = '';
        this.excelData.push(
          {
            'Project Name': project,
            'Dam Name': dam,
            'Bank Name': bankName,

            'Main Canal Name': mainCanalName,
            'Main Canal Type': mainCanalType,
            'ICA of Main Canal': mainCanalICA,
            'CCA of Main Canal': mainCanalCCA,
            'GCA of Main Canal': mainCanalGCA,

            'Branch Canal Name': branchCanalName,
            'Offtaking Chainage (km) of Branch': branchCanalOfftaking,
            'ICA of Branch Canal': branchCanalICA,
            'CCA of Branch Canal': branchCanalCCA,
            'GCA of Branch Canal': branchCanalGCA,

            'Distributory Name': distributoryName,
            'Offtaking Chainage (km) of Distributory': distributoryOfftaking,
            'ICA of Distributory': distributoryICA,
            'CCA of Distributory': distributoryCCA,
            'GCA of Distributory': distributoryGCA,

            'Minor Name': minorCanalName,
            'Offtaking Chainage (km) of Minor': minorCanalOfftaking,
            'ICA of Minor': minorCanalICA,
            'CCA of Minor': minorCanalCCA,
            'GCA of Minor': minorCanalGCA,

            'Subminor Name': subminorCanalName,
            'Offtaking Chainage (km) of Subminor': subminorCanalOfftaking,
            'ICA of Subminor': subminorCanalICA,
            'CCA of Subminor': subminorCanalCCA,
            'GCA of Subminor': subminorCanalGCA,

            'Outlet Name': outletCanalName,
            'Outlet Side': outletSide,
            'Offtaking Chainage (km) of Outlet': outletCanalOfftaking,
            'ICA of Outlet': outletCanalICA,
            'CCA of Outlet': outletCanalCCA,
            'GCA of Outlet': outletCanalGCA,



          }
        )
        // this.excelData.push(
        //   {
        //     'Project': project,
        //     'Dam Name': this.selectedlang == 'en' ? this.dataArray[i].mwrd_dam_master.dam_name : this.dataArray[i].mwrd_dam_master.dam_name_marathi,
        //     'Canal Type': this.selectedlang == 'en' ? this.dataArray[i].mwrd_bank_master.bank_name : this.dataArray[i].mwrd_bank_master.bank_name_marathi,

        //     'Main Canal Name': wuaName,
        //     'Main Canal Name (Marathi)': this.dataArray[i].farmer_master_farmer_marathi_name,
        //     'Type of Canal Name': this.dataArray[i].farmer_master_name_of_cultivator ? ' ' : this.dataArray[i].farmer_master_name_of_cultivator,
        //     'ICA of Main Canal (Ha)': this.dataArray[i].farmer_master_name_of_cultivator_marathi ? ' ' : this.dataArray[i].farmer_master_name_of_cultivator_marathi,
        //     'CCA of Main canal (Ha)': this.dataArray[i].farmer_costake_holder ? ' ' : this.dataArray[i].farmer_costake_holder,
        //     'GCA of Main canal (Ha)': this.dataArray[i].farmer_master_farmer_mobile_no ? ' ' : this.dataArray[i].farmer_master_farmer_mobile_no,

        //     'Name of Branch ': this.dataArray[i].farmer_master_farmer_whatsapp_no ? this.dataArray[i].farmer_master_farmer_whatsapp_no : ' ',
        //     'Branch Marathi Name': this.dataArray[i].farmer_master_farmer_email ? this.dataArray[i].farmer_master_farmer_email : ' ',
        //     'Offtaking Chainage (Km) of Branch': this.dataArray[i].farmer_master_farmer_address1 ? this.dataArray[i].farmer_master_farmer_address1 : ' ',
        //     'ICA of Branch (Ha)': this.dataArray[i].farmer_master_farmer_marathi_address1 ? this.dataArray[i].farmer_master_farmer_marathi_address1 : ' ',
        //     'CCA of Branch (Ha)': district,

        //     'Name of Distributory ': taluka,
        //     'Distributory Marathi Name': village,
        //     'Offtaking Chainage (Km) of Distributory ': this.dataArray[i].farmer_master_farmer_ledger_no ? ' ' : this.dataArray[i].farmer_master_farmer_ledger_no,
        //     'ICA of Distributory (Ha) ': this.dataArray[i].farmer_master_lift_irregation_permission_no ? this.dataArray[i].farmer_master_lift_irregation_permission_no : ' ',
        //     'CCA of Distributory (Ha) ': dateTo_split,
        //     'GCA of Distributory (Ha)': dateToUpto_split,

        //     'Name of Minor': sugarFactoryName ? Yes : No,
        //     'Minor Marathi Name ': this.dataArray[i].farmer_master_sugar_factory_name ? this.dataArray[i].farmer_master_sugar_factory_name : ' ',
        //     'Offtaking Chainage (Km) of Minor ': this.dataArray[i].farmer_master_sugar_factory_name_marathi ? this.dataArray[i].farmer_master_sugar_factory_name_marathi : ' ',
        //     'ICA of Minor (Ha)': this.dataArray[i].farmer_master_sugar_factory_email ? this.dataArray[i].farmer_master_sugar_factory_email : ' ',
        //     'CCA of Minor (Ha) ': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_gut_no : ' ',
        //     'GCA of Minor (Ha) ': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_part_no : ' ',

        //     'Name of Subminor': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_total_area_gut : ' ',
        //     'Subminor Marathi Name': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_owned_area_exclude_potkharab : ' ',
        //     'Offtaking Chainage (Km) of Subminor': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_potkharab_area : ' ',
        //     'ICA of Subminor (Ha)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',
        //     'CCA of Subminor (Ha)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',
        //     'GCA of Subminor (Ha)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',

        //     'Name of Outlet': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_total_area_gut : ' ',
        //     'Outlet Canal Name Marathi': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_owned_area_exclude_potkharab : ' ',
        //     'Side of outlet (left/right)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_potkharab_area : ' ',
        //     'Offtaking Chainage (Km) of Outlet': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',
        //     'ICA of Outlet (Ha)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',
        //     'CCA of Outlet (Ha) ': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',
        //     'GCA of Outlet (Ha)': this.dataArray[i].mwrd_gut_masters ? this.dataArray[i].mwrd_gut_masters[j].gut_master_whether_said_gutnumber_notified_command_area : ' ',


        //   }
        // )

      }
      this.excelService.exportAsExcelFile(this.excelData, 'export-to-excel');






    })


  }
}
