<!-- dashboard left panel start-->
<!-- <div class="dashbordleft">
    <a class="dashbordlogo fullwidth" (click)="dashboard()"><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
    <ul class="dashboardleftnav fullwidth">

        <li><a (click)="dashboard()">Dashboard</a></li>
        <li><a (click)="viewProject()">Project Layout</a></li>
        <li><a (click)="viewfarmer()">Dam</a></li>
        <li><a (click)="viewWuaMaster()">WUA</a></li>
        <li><a (click)="viewfarmer()">Farmer Master</a></li>
       
        
        



    </ul>
</div>	 -->

<!-- header over-->
<!-- top blue nav start-->
<div class="navstrip fullwidth">
	<ul class="navbluestrip">
		<li><a (click)="dashboard()"><img src="assets/images/bluenavimg1.png" alt="icon" /> {{data.dashboard}}</a></li>
		<li><a (click)="viewProjectMaster()"><img src="assets/images/bluenavimg2.png" alt="icon" /> {{data.project}}</a>
		</li>
		<!-- <li><a (click)="viewProject()"><img src="assets/images/bluenavimg2.png" alt="icon" /> Project</a></li> -->
		<li><a (click)="damMaster()"><img src="assets/images/bluenavimg3.png" alt="icon" /> {{data.dam}}</a></li>
		<!-- <li><a (click)="viewfarmer()"><img src="assets/images/bluenavimg3.png" alt="icon" /> Dam</a></li> -->
		<li><a (click)="viewProject()"><img src="assets/images/bluenavimg4.png" alt="icon" />
				{{data.project_layout}}</a></li>
		<!-- <li><a (click)="viewWuaMaster()"><img src="assets/images/bluenavimg5.png" alt="icon" /> WUA</a></li> -->
		<li><a (click)="viewWuaMaster()"><img src="assets/images/bluenavimg5.png" alt="icon" /> {{data.wua}}</a></li>
		<li><a (click)="viewfarmer()"><img src="assets/images/bluenavimg6.png" alt="icon" /> {{data.farmer}}</a></li>
	</ul>
</div>
<!-- top blue nav over-->
<!-- dashboard left panel over-->