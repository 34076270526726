<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">
        <!-- dashboard left panel start-->
        <!-- <div class="dashbordleft">
            <a class="dashbordlogo fullwidth" href=""><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
       
        </div>	 -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
                 <!-- header start-->
                 <app-header></app-header>
                <!-- header over-->
                <!-- dashboardbnnr start-->
                <!--<div class="dashboardbnnr fullwidth">
                    <img class="fullwidth" src="assets/images/innerbanner.jpg" alt="banner" />
                </div>-->
                <!-- dashboardbnnr over-->
                <!-- mid content start-->
               <!-- <div class="mid-content fullwidth formstep">-->
    
                  
                    <!-- row start-->
                    <div class="greenstrip fullwidth">
                        <h1>{{'Select User Type' | translate}}</h1>
                    </div>
                    
                    <!-- dashboardbnnr over-->
                    <!-- mid content start-->
                    <div class="mid-content fullwidth formstep" style="margin-top: 50px;">
        
                        
                        <!-- row start-->
                        <div class="row">
                            <!-- col-md-12 start-->
                            <div class="col-md-12 recentlistbx">
                                <h2 class="smlheadh2 col-md-12"><span>{{'Choose User' | translate }}</span></h2>
                                    
                            </div>
                            <!-- col-md-12 over-->
                            <!-- form step1 start-->
                            <form [formGroup]="form" (ngSubmit)="submit()">
                                <!-- step row start-->
                                <div class="row frmrow" >
                                    
                                    <div class="col-md-6 radiobtn" *ngFor="let userdata of userarr" >
                                        <ng-container *ngIf="selectedlang == 'en'" >
                                        <label >{{userdata.user_post}}, <br/> {{userdata.user_office_detail[0]['Circle'].office_name}} <br/>{{userdata.user_post_type == 'R' ? 'Regular' : 'Additional'}}
                                          <input type="radio" name="radio" (click) = "Errorhide()"formControlName="postLogin" name="postLogin" value="{{userdata.user_post_login_id}}">
                                          <span class="checkmark"></span>
                                        </label>	
                                        </ng-container>		
                                        
                                        <ng-container *ngIf="selectedlang == 'mr'" >
                                            <label >{{userdata.user_post}}, <br/> {{userdata.user_office_detail[0]['Circle'].office_name_marathi}} <br/>{{userdata.user_post_type == 'R' ? 'Regular' : 'Additional'}}
                                              <input type="radio" name="radio" (click) = "Errorhide()"formControlName="postLogin" name="postLogin" value="{{userdata.user_post_login_id}}">
                                              <span class="checkmark"></span>
                                            </label>	
                                            </ng-container>	
                                    </div>


        
                                </div>

                                <div class="row frmrow" *ngIf="promptErrMsg">
                                    
                                    <div class="col-md-6 radiobtn">
                                 
                                        <label style="color: red;" >
                                            *Please Choose User  
                                        </label>						
                                    </div>
        
                                </div>
                                <!-- step row over-->
                        
                            
                                <!-- step row start-->
                                <div class="row frmrow">
        
                                    <div class="col-md-12 inptbx">
                                        <input  class="stepgreenbtn" type="submit" value="{{'Confirm' | translate}}"  /> 
                                        <!-- <input [disabled]="!form.valid" class="stepgreenbtn" type="submit" value="Confirm"  />  -->
                                        
                                    </div>
        
                                </div>
                                <!-- step row over-->		
                            </form>
                            <!-- form step1 over-->
                        </div>
                    <!-- row over-->
    
                    <!-- footer start-->
                    <footer class="dboardfooter fullwidth">
                        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                    </footer>
                    <!-- footer over-->
    
                <!--</div>-->
                <!-- mid content over-->
                
        </div>
        <!-- dashboard right panel over-->	
    </div>
     </div>