import { Injectable } from '@angular/core';
import { url } from './url';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './../_service/common.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { data } from 'jquery';

export interface Person {
  id: string;
  isActive: boolean;
  age: number;
  name: string;
  gender: string;
  company: string;
  email: string;
  phone: string;
  disabled?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  token = localStorage.getItem('Token')
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'x-access-token': this.token
  //   })
  // }
  httpOptions = {
		headers: new HttpHeaders({
			// 'Content-Type': 'application/json'
			'x-access-token': this.token
		})
	}
  serviceUrl: url;
  constructor(
    private http: HttpClient,
    private common: CommonService,
  ) {
    this.serviceUrl = new url();
  }

  getLoginValidNumber(data) {
    let url = this.serviceUrl.loginUrl
    return this.http.post(url + 'otp-master/generate-otp', data);
  }

  // checkValidOtp(data) {
  //   let url1 = this.serviceUrl.loginUrl
  //   // alert(this.httpOptions.headers)
  //   //return this.http.get(url1 +'otp-verify/'+this.common.mobile +'/'+data)
  //   // alert(localStorage.getItem('Token'))
  //   console.log(this.token)
  //   console.log(this.httpOptions)
  //   return this.http.post(url1 + 'otp-master/verify-otp', data, this.httpOptions)

  // }

  checkValidOtp(data: any): Observable<any> {
    // console.log(this.httpOptions, this.token);
    
		return this.http.post<any>(this.serviceUrl.loginUrl + "otp-master/verify-otp",data)
			.pipe()
	}

  //Insertion OF first Step In Farmer Master 

  insertFarmerMaster(data) {
    let url = this.serviceUrl.loginUrl
    //return this.http.post(url+'farmerMasters/',data);
    return this.http.post(url + 'farmer-master/step-1', data);

  }

  insertTwoFarmerMaster(data) {
    let url = this.serviceUrl.loginUrl
    //return this.http.post(url+'farmerMasters/',data);
    return this.http.put(url + 'farmer-master/step-2', data);

  }


  updateFarmerMaster(data) {
    let id = localStorage.getItem('farmer_master_id');
    let url = this.serviceUrl.loginUrl
    //console.log(this.common.farmer_master_id)
    //return this.http.put(url+'farmer-master/step-1/'+id,data);
    return this.http.put(url + 'farmer-master/step-3', data);
    // return this.http.post(url+'farmer-master/step-1',data);
  }

  updateThreeFarmerMaster(data) {
    let id = localStorage.getItem('farmer_master_id');
    let url = this.serviceUrl.loginUrl
    //console.log(this.common.farmer_master_id)
    //return this.http.put(url+'farmer-master/step-1/'+id,data);
    return this.http.put(url + 'farmer-master/step-3', data);
    // return this.http.post(url+'farmer-master/step-1',data);
  }


  // getDataUser(data) {
  //   let url1 = this.serviceUrl.loginUrl;

  //   return this.http.post(url1 + 'otp-master/reload-first-screen', data, this.httpOptions)

  // }
  getDataUser(data: any): Observable<any> {
    console.log(this.httpOptions, this.token);
    
		return this.http.post<any>(this.serviceUrl.loginUrl + "otp-master/reload-first-screen",data, {	headers: new HttpHeaders({'Content-Type': 'application/json','x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImRlMmltY2RfcHVuZSIsImlhdCI6MTYxODgxODI4NSwiZXhwIjoxNjE4ODIxODg1fQ.cKdbMwRslbiBjz72CAlafLcSIpWNbRf0ZaWmKJw6K_I'})})
			.pipe()
	}
  getUserDetail(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/user-office-details', data)

  }
  getProjectMasterListing(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/get-project-master-listing', data)
  }
  getProjectDetail(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/get-all-projects', data)
  } getProjectLinking(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/user-project-linking', data)
  } getDamsLinking(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'project-master/user-dam-linking', data)
  }

  getIrrigationLinking(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'project-master/user-irrigation-linking', data)
  }

  userUpdateFirstTime(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'user-master/user-update-first-time', data)

  }


  previewFirstTime(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'user-master/preview-first-time-login', data)

  }

  getProjectDetailLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/get-all-projects', data)

  }

  getDamDetailLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/get-project-dams', data)

  }
  getCanalDetailLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'main-canal-master/maincanallist-against-projectdam', data)

  }

  getBackDetailsProject(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/reload-project-screen', data)

  }



  //ADdED BY VIKAS


  getBranchesOfCanel(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'branch-master/branchlist-against-projectdamcanal', data)

  }
  addCanelBranches(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'branch-master/add-update-branch-canal', data)

  }
  getDistributory(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'distributory-master/distributorylist-against-branch', data)

  }
  addUpdateDistributory(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'distributory-master/add-update-distributory-branch', data)

  }
  getViewProjectsLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/project-layout-master-listing', data)

  }
  addUpdateMinor(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'minor-master/add-update-minor-canal', data)

  }
  getListOfMinor(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'minor-master/minorCanallist-against-branch', data)

  }
  getListIfSubMinor(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'subminor-master/subminorCanallist-against-branchminor', data)

  }
  addUpdateSubMinor(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'subminor-master/add-update-subminor-canal', data)

  }
  getDamDistricts(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'dam-master/get-dam-districts', data)

  }

  getDamWuaDistricts(data) {

    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'wua-master/get-project-dams', data)

  }
  getMainCanel(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'main-canal-master/maincanallist-against-projectdam', data)

  }
  getBranchCanel(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'branch-master/branchlist-against-projectdamcanal', data)

  }
  getdistributory(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'distributory-master/distributorylist-against-branch', data)

  }
  getMinorListStep1(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'minor-master/minorCanallist-against-branch', data)

  }
  getSubMinorListStep1(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'subminor-master/subminorCanallist-against-branchminor', data)

  }
  getwuaMaster(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/wua-name-linking-dam-code', data)

  }
  saveFirstStap(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/step-1', data)

  }
  saveSecondStap(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/step-2', data)

  }
  saveThirdStap(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/step-3', data)

  }
  farmersDistricts() {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'farmer-master/farmer-districts')

  }
  farmersListings(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/farmers-listings', data)

  }
  addProjectlayoutMaster(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-layout-master/add-project-layout-master', data)

  }
  //

  addUpdateMainCanal(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'main-canal-master/add-update-main-canal', data)

  }

  getBackDetailsDams(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/reload-dam-screen', data)


  }

  getBackDetailsIrrigation(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/reload-irrigation-screen', data)

  }

  UpdateOfficeDetails(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/user-changeoffice-request', data)

  }


  getProjectNameAgainstPorjectIds(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'project-master/project-name-against-project-id', data)
  }

  getDamNameAgainstPorjectIdsNDamCode(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'dam-master/dam-name-linking-dam-code-project-id', data)
  }

  getAllDistrictName() {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.get(url1 + 'district-master/all-district-name')
  }

  getAllCanalDataAgainstProjectDam(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'project-layout-master/all-canal-against-project-dam', data)
  }

  getOfficeDetailOnchange(data) {

    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'office-master/gettype-offices', data)



  }
  addUpdateOutletCanel(data) {
    let url1 = this.serviceUrl.loginUrl;

    return this.http.post(url1 + 'outlet-canal-master/add-update-outlet-canal', data)
  }

  getWUAVerifyDetails(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/getwua-against-project', data)
  }

  getProjectTypes() {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-project-types');



  }

  getProjectStatus() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-project-statuses');

  }

  submitProjectDetailsWUA(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/user-project-linking', data);
  }
  englishToMarathi(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/english-to-marathi', data);
  }
  getDamIrrigatations(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/get-dam-irrigations', data);
  }

  getFarmerDetailId(id) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'farmer-master/get-farmer-details/' + id);
  }

  getWuaProject(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/get-project-wua', data);
  }
  getDistricts() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'farmer-master/farmer-districts');

  }
  getDistrictsTaluka(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/farmer-districts-taluka', data);
  }
  getTalukaVillage(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/farmer-taluka-village', data);
  }
  addFarmerMaster(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/add-farmer-master', data);
  }
  getoutletListing(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'outlet-canal-master/outletlist-against-projectdamcanal', data);
  }

  getProjectReloadScreenAp(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/reload-project-screen', data);


  }


  getIrrigation() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-irrigations');
  }
  getBankId() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-all-banks');
  }

  getMainCanalType() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-main-canal-type');
  }

  getOutletCanalSide() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'outlet-canal-master/get-outlet-side');
  }

  getYesNo() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'farmer-master/get-yes-no');
  }

  getOwnerShip() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-project-owner-list');
  }

  getStates() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'project-master/get-all-states');
  }


  getdamType() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'dam-master/get-dam-type');
  }


  getdamPurposes() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'dam-master/get-dam-purposes');
  }

  getdamStorages() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'dam-master/get-dam-storage-creation');
  }

  getdamBasins() {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.get(url1 + 'dam-master/get-basins');
  }


  getSubbasins(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'dam-master/get-sub-basins', data);
  }

  createProjectMaster(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-master/create', data);
  }

  getSingleProjectMaster(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'project-master/get-project-master', data);
  }

  approveProjectMaster(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'project-master/approve-project-master', data);
  }

  getDamMasterProject() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + 'project-master/get-project-listing')
  }

  createDamMaster(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'dam-master/create', data);
  }

  getDamMasterListing(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'dam-master/get-dam-masters', data)
  }

  approveDamMaster(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'dam-master/approve-dam', data)
  }

  getSingleDamMaster(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + "dam-master/get-dam-master", data)
  }


  addWuaMaster(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'wua-master/save-wua-details', data);
  }



  getSingleWuaMaster(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + "wua-master/get-single-wua-detail", data)
  }

  getAllBank() {
    let url1 = this.serviceUrl.loginUrl
    return this.http.get(url1 + 'wua-master/get-indian-banks-list')
  }

  getUserNotificationCount(body) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'user-master/notification-count', body);
  }

  getUserNotifications(body) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'user-master/get-user-notifications', body);
  }

  updateNotifications(body) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/update-notification', body)
  }

  exportFarmer(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/export-excel', data)

  }
  getprojectLayout(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-layout-master/get-project-layout', data)

  }

  getFarmerCount(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/get-analysis-data', data)

  }

  exportFarmerAndGetLink(data) {
    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'farmer-master/write-excel', data)
  }



  getProjectLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-layout-master/get-project-layout', data)

  }


  getAnalysisOffice(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'user-master/get-analysis-office-id', data)
  }

  deleteProjectLayout(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'project-layout-master/delete-project-layout', data)
  }

  approveFarmer(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/approve-farmer', data)
  }

  exportTempFarmer(data) {

    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/farmer-listing-temperorary', data)

  }

  exportExcelAdmin(data) {
    let url1 = this.serviceUrl.loginUrl;
    return this.http.post(url1 + 'farmer-master/export-excel-python', data)
  }

  getDamAganistProject(data) {

    let url1 = this.serviceUrl.loginUrl
    return this.http.post(url1 + 'farmer-master/get-dam-aganist-projects', data)

  }

}






