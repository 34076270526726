import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../_service/common.service';
import { SharedService } from '../_service/shared.service';
import { HttpRequestService, Person } from './../_service/http-request.service';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
declare var jQuery: any;

import { IDropdownSettings } from 'src/ng-multiselect-dropdown/src';

@Component({
  selector: 'app-verify-project',
  templateUrl: './verify-project.component.html',
  styleUrls: ['./verify-project.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class VerifyProjectComponent implements OnInit {
  clickEventsubscription: Subscription;
  projectList: any;
  permissions3: any;
  filteredList3: any;
  func: any;
  selectedDay: string = '';
  typeArr: any = [];
  typeStatus: any = [];
  proNameArr: any = [];
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  typeArrValues: any;
  typeStatusValues: any;
  projectMasterForm: FormGroup;
  proType: any = [];
  proSType: any = [];
  userName = localStorage.getItem('userName');
  public permissions2 = [];
  public filteredList2 = this.permissions2.slice();
  selectedItemsRoot: any;
  selectedItemsRoot1: any = [];
  selectedItemsRoot2: any;
  selecttedValues: any;
  selectedItemsArr: any = [];
  proArr: any = [];

  projectList1: any;
  projectArr: any = [];
  showDrop: boolean;
  WuaList: any = [];
  html1: any;
  contentWua: boolean;
  projectDetailsArr: any = [];
  wuaRadioShow: boolean;
  wuaids: any = [];
  filterWualist: any = [];
  filterWuaList: any;
  wuaidsAll: any = [];
  selectedlang = localStorage.getItem('selected_lang');
  data: any = {};
  orders = [];
  skipDiv = false;
  en_data: any = {
    welcome_string: this.userName + "Welcome  to MWRD's Farmer Master Database",
    title_string: "Project Details",
    type_string: "Project Type",
    status_type_string: "Project Status Type",
    status_string: "Project Status",
    name_string: "Project Name",
    back_string: "Back",
    confirm_string: "Confirm",
    reset_string: "Reset",
    Yes: "Yes",
    No: "No",
    select_all: "Select All",
    un_select_all: "UnSelect All",
    Deselect_all: "Deselect All",
    select: "Select",
    Filter: "Filter",
    WUAList: "WUA List",
    isWua: "Is WUA established on Project?",
    save: "Save",
    searchPlaceholderText: "Search",
    skip: "Skip",
    err: "Please select the project for coresponding WUA!!!"

  }
  mr_data: any = {
    welcome_string: this.userName + " यांचे जलसंपदा विभाग, महाराष्ट्र शासन यांच्या मास्टर डेटाबेस प्रणालीमध्ये स्वागत आहे.",
    dams_string: "धरण",
    title_string: "प्रकल्पाची माहिती",
    type_string: "प्रकल्पाचा प्रकार",
    status_type_string: "प्रकल्पाची सद्यस्थिती",
    status_string: "प्रकल्पाची सद्यस्थिती",
    name_string: "प्रकल्पाचे नाव",
    back_string: "मागे जा",
    confirm_string: "जतन करा",
    reset_string: "दुरुस्त करा",
    Yes: "होय",
    No: "नाही",
    select_all: "सर्व पर्याय निवडा",
    un_select_all: "निवडलेले सर्व काढा",
    Deselect_all: "Deselect All",
    select: "निवडा",
    Filter: "पर्याय निवडा",
    WUAList: "पाणी वापर संस्थांची यादी",
    isWua: "प्रकल्पावर पा. वा. सं. स्थापन झाली आहे का?",
    save: "जतन करा",
    searchPlaceholderText: "शोधा",
    skip: "वगळा",
    err: "कृपया संबंधित पाणी वापर संस्थेसाठी प्रकल्प निवडा !!!"
  }
  errorDiv: boolean = false;
  userOfficeType: string;
  userPosition: any;


  public isFiltered2(permission2) {
    return this.filteredList2.find(item => item.id === permission2.id);
  }



  onProjectTypeSelected(ob) {
    this.typeStatus = '';
    for (let k = 0; k < ob.length; k++) {
      if (this.typeStatus)
        this.typeStatus += ',' + ob[k].id;
      else
        this.typeStatus = ob[k].id;

    }
    this.getProjectDetails();
  }


  back() {

    this.router.navigate(['verifyDetails']);

  }

  skip() {

    this.router.navigate(['dams']);

  }
  onOptionsSelected(ob) {
    this.typeArr = '';
    for (let k = 0; k < ob.length; k++) {
      if (this.typeArr)
        this.typeArr += ',' + ob[k].id;
      else
        this.typeArr = ob[k].id;

    }
    this.getProjectDetails();
  }



  constructor(

    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,
    private sharedService: SharedService) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.selectedlang = localStorage.getItem('selected_lang');
      this.ngOnInit();
    })



    // this.projectMasterForm = this.fb.group({
    //   orders: ['']
    // });


    // of(this.getOrders()).subscribe(orders => {
    //   this.orders = orders;
    //   this.form.controls.orders.patchValue(this.orders[0].id);
    // });


  }

  onSubmit(form: FormGroup) {

    //  console.log(this.projectDetailsArr)

    if (this.projectDetailsArr.length) {

      const wuatChk = this.projectDetailsArr.filter((elem) => elem.hasOwnProperty('is_wua_exists'));
      if (wuatChk.length !== this.projectDetailsArr.length) {

        this.errorDiv = true;



        // console.log("hhhhhh");
        return false
      }
    } else {
      return false;
    }

    if (!this.projectMasterForm.invalid) {
      return false;
    }
    else {

      let post_user_id = localStorage.getItem('post_user_id');
      const body = new HttpParams({
        fromObject: {
          post_user_id: post_user_id,
          project_details: JSON.stringify(this.projectDetailsArr)
        }
      });
      this.http.submitProjectDetailsWUA(body).subscribe((resolve: any) => {

        let data1 = resolve;

        if (data1.status == 0) {


          return false;

        } else {

          if (resolve.data) {
            this.router.navigate(['dams']);

          } else
            return false;
        }

      },
        (err) => {

          console.log(err);


        }
      )



      // return false;
      localStorage.setItem('project_types', this.typeArr);
      localStorage.setItem('project_statuses', this.typeStatus);
      localStorage.setItem('project_gis_no', this.proNameArr);

    }



  }
  reset() {

    this.onDeSelectAll;
    this.onDeSelectAll1;
    this.onDeSelectAll2;
    this.selectedItemsRoot = [];
    this.selectedItemsRoot1 = [];
    this.selectedItemsRoot2 = [];
    this.typeArrValues = '';
    this.typeStatusValues = '';
    this.proNameArr = [];
    this.typeArr = [];
    this.typeStatus = [];
    this.getProjectDetails();



  }

  getProjectDetails() {

    let post_user_id = localStorage.getItem('post_user_id');
    if (typeof this.typeArrValues == 'undefined') {
      this.typeArrValues = '';

    } if (typeof this.typeStatusValues == 'undefined') {
      this.typeStatusValues = '';
    }


    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
        project_types: this.typeArrValues,
        project_statuses: this.typeStatusValues,
      }
    });

    this.http.getProjectDetail(body).subscribe((resolve: any) => {

      let data1 = resolve;

      // console.log(resolve.data);
      if (data1.status == 0) {


        return false;

      } else {

        if (resolve.data) {
          this.projectList = resolve.data;
          this.projectList1 = resolve.data;
          this.getProjectReloadScreen();

          this.cities = [];
          for (let k = 0; k < resolve.data.length; k++) {



            if (this.selectedlang === 'en') {
              this.cities.push({
                item_id: resolve.data[k]['project_unique_gis'],
                item_text: resolve.data[k]['project_name']
              });
            } else {

              this.cities.push({
                item_id: resolve.data[k]['project_unique_gis'],
                item_text: resolve.data[k]['project_name_marathi']
              });



            }

          }





        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )

  }



  getBackDetailsProject() {

    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),

      }
    });

    this.http.getBackDetailsProject(body).subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {

        return false;

      } else {
        if (resolve.data.selected.project_types) {
          this.selectedItemsRoot = [];
          for (let k = 0; k < resolve.data.selected.project_types.length; k++) {
            this.selectedItemsRoot.push({
              item_id: resolve.data.selected.project_types[k]['type_id'],
              item_text: resolve.data.selected.project_types[k]['type_val']
            });

            this.typeArrValues = ''
            if (this.typeArrValues) {

              this.typeArrValues += ',' + resolve.data.selected.project_types[k]['type_id'];
              this.typeArr.push(resolve.data.selected.project_types[k]['type_id']);

              //this.typeArr += ',' + resolve.data.selected.project_types[k]['type_id'];
            } else {
              this.typeArrValues = resolve.data.selected.project_types[k]['type_id'];
              this.typeArr.push(resolve.data.selected.project_types[k]['type_id']);
              //   this.typeArr =resolve.data.selected.project_types[k]['type_id'];
            }
          }
        }
        if (resolve.data.selected.projects) {
          this.selectedItemsRoot2 = [];
          for (let k = 0; k < resolve.data.selected.projects.length; k++) {
            this.selectedItemsRoot2.push({
              item_id: resolve.data.selected.projects[k]['project_unique_gis'],
              item_text: resolve.data.selected.projects[k]['project_name']
            });


            this.proNameArr = ''
            if (this.proNameArr) {
              this.proNameArr += ',' + resolve.data.selected.projects[k]['project_unique_gis'];
              this.proArr.push(resolve.data.selected.projects[k]['project_unique_gis']);
            } else {
              this.proNameArr = resolve.data.selected.projects[k]['project_unique_gis'];
              this.proArr.push(resolve.data.selected.projects[k]['project_unique_gis']);
            }
          }
        }
        if (resolve.data.selected.project_statuses) {
          this.selectedItemsRoot1 = [];
          for (let k = 0; k < resolve.data.selected.project_statuses.length; k++) {
            this.selectedItemsRoot1.push({
              item_id: resolve.data.selected.project_statuses[k]['status_id'],
              item_text: resolve.data.selected.project_statuses[k]['status_val']
            });

            this.typeStatusValues = ''
            if (this.typeStatusValues) {
              this.typeStatusValues += ',' + resolve.data.selected.project_statuses[k]['status_id'];
              this.typeStatus.push(resolve.data.selected.project_statuses[k]['status_id']);
            }
            else {
              this.typeStatusValues = resolve.data.selected.project_statuses[k]['status_id'];
              this.typeStatus.push(resolve.data.selected.project_statuses[k]['status_id']);
            }

          }


        }

        this.getProjectDetails();
        // return false;
      }
    },
      (err) => {

        console.log(err);


      }
    )
  }
  ngOnInit() {

    this.getUserDetails();
    this.wuaRadioShow = true;

    switch (this.selectedlang) {
      case "en":
        this.data = this.en_data;
        break;
      case "mr":
        this.data = this.mr_data;
        break;
    }

    //this.getBackDetailsProject();
    this.getProjectDetails();
    this.getProjectTypes();
    this.getProjectStatus();
    this.getProjectReloadScreen();


    this.cities = [

    ];


    this.proType = [
      // { item_id: "New", item_text: "New" },
      // { item_id: "Ongoing", item_text: "Ongoing" },
      // { item_id: "On Hold", item_text: "On Hold" },
      // { item_id: "Completed", item_text: "Completed" }
    ];

    this.proSType = [
      // { item_id: "Major", item_text: "Major" },
      // { item_id: "Medium", item_text: "Medium" },
      // { item_id: "Minor", item_text: "Minor" },
    ];
    // this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: this.data.select_all,
      unSelectAllText: this.data.un_select_all,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: this.data.searchPlaceholderText
    };


    this.projectMasterForm = this.fb.group({
      "city": new FormControl('', [Validators.required]),
      "city1": new FormControl(''),
      'city2': new FormControl(''),
      'wuaList': new FormControl(''),
      'projectId': new FormControl(''),
      'wuaId': new FormControl('')

    });

  }

  //ADDED BY T
  onItemSelect(item: any) {
    this.selectedItemsRoot2 = [];
    this.typeArr.push(item.item_id);
    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    this.getProjectDetails();

  }
  onDeSelect(items: any) {

    // console.log("Deselect");
    this.typeArr.pop(items.item_id);
    this.proArr = [];
    this.proNameArr = '';
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    this.getProjectDetails();

  }
  onDeSelectAll(items: any) {
    this.selectedItemsRoot2 = [];
    this.typeArr = [];
    this.proArr = [];
    this.proNameArr = '';
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();
  }

  OnItemDeSelect(items: any) {

    // console.log("OnItemDeSelect");
    const index: number = this.typeArr.indexOf(items.item_id);
    this.typeArr.splice(index, 1);
    this.selectedItemsRoot2 = [];
    this.proArr = [];
    this.proNameArr = '';
    // this.typeArr.pop(items.item_id);
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();
    this.getProjectReloadScreen();
  }
  onSelectAll(items: any) {
    this.selectedItemsRoot2 = [];
    this.typeArr = [];
    for (let k = 0; k < items.length; k++) {
      this.typeArr.push(items[k]['item_id']);
    }

    this.typeArrValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeArrValues)
        this.typeArrValues += ',' + this.typeArr[k];
      else
        this.typeArrValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();
  }

  //
  onItemSelect1(item: any) {
    //
    this.selectedItemsRoot2 = [];
    this.typeStatus.push(item.item_id);
    this.proArr = [];
    this.proNameArr = '';
    //  console.log(this.typeStatus.length)

    this.typeStatusValues = ''
    for (let k = 0; k < this.typeStatus.length; k++) {
      if (this.typeStatusValues)
        this.typeStatusValues += ',' + this.typeStatus[k];
      else
        this.typeStatusValues = this.typeStatus[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();
  }
  onDeSelect1(items: any) {
    this.typeStatus.pop(items.item_id);

    for (let k = 0; k < items.length; k++) {
      this.typeStatus.push(items[k]['item_id']);
    }

    this.typeStatusValues = ''
    for (let k = 0; k < this.typeArr.length; k++) {
      if (this.typeStatusValues)
        this.typeStatusValues += ',' + this.typeArr[k];
      else
        this.typeStatusValues = this.typeArr[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();

  }
  onDeSelectAll1(items: any) {
    this.selectedItemsRoot2 = [];
    this.typeStatus = [];
    this.proArr = [];
    this.proNameArr = '';
    for (let k = 0; k < items.length; k++) {
      this.typeStatus.push(items[k]['item_id']);
    }

    this.typeStatusValues = ''
    for (let k = 0; k < this.typeStatus.length; k++) {
      if (this.typeStatusValues)
        this.typeStatusValues += ',' + this.typeArr[k];
      else
        this.typeStatusValues = this.typeArr[k];
    }
    //console.log(this.typeStatusValues);
    this.getProjectDetails();
  }
  OnItemDeSelect1(items: any) {
    //this.selectedItemsRoot = [];        
    //this.selectedItemsRoot1 = [];    

    const index: number = this.typeStatus.indexOf(items.item_id);
    this.typeStatus.splice(index, 1);
    this.selectedItemsRoot2 = [];
    this.proArr = [];
    this.proNameArr = '';
    // this.typeStatus.pop(items.item_id);
    for (let k = 0; k < items.length; k++) {
      this.typeStatus.push(items[k]['item_id']);
    }

    this.typeStatusValues = ''
    for (let k = 0; k < this.typeStatus.length; k++) {
      if (this.typeStatusValues)
        this.typeStatusValues += ',' + this.typeStatus[k];
      else
        this.typeStatusValues = this.typeStatus[k];
    }
    //console.log(this.typeStatusValues);
    this.getProjectDetails();
  }
  onSelectAll1(items: any) {
    this.typeStatus = [];
    for (let k = 0; k < items.length; k++) {
      this.typeStatus.push(items[k]['item_id']);
    }

    this.typeStatusValues = ''
    for (let k = 0; k < this.typeStatus.length; k++) {
      if (this.typeStatusValues)
        this.typeStatusValues += ',' + this.typeStatus[k];
      else
        this.typeStatusValues = this.typeStatus[k];
    }
    //console.log(this.typeArrValues);
    this.getProjectDetails();
  }



  onItemSelect2(item: any) {
    this.proArr.push(item.item_id);

    // console.log(this.proArr);
    if (this.proNameArr.length) {
      this.proNameArr += ',' + item.item_id;
    } else {
      this.proNameArr = item.item_id;
    }
    this.filterProjcectList(this.proArr);
  }
  onDeSelect2(items: any) {


    //  console.log('onDeSelect', items);


  }
  onDeSelectAll2(items: any) {

    //  console.log('onDeSelectAll', items);


    this.proArr = [];
    for (let k = 0; k < items.length; k++) {
      this.proArr.push(items[k]['item_id']);
    }

    this.proNameArr = ''
    for (let k = 0; k < this.proArr.length; k++) {
      if (this.proNameArr)
        this.proNameArr += ',' + this.proArr[k];
      else
        this.proNameArr = this.proArr[k];
    }

    this.filterProjcectList(this.proArr);

    // console.log(this.proNameArr);
  }
  OnItemDeSelect2(items: any) {

    const index: number = this.proArr.indexOf(items.item_id);
    this.proArr.splice(index, 1);

    for (let k = 0; k < items.length; k++) {
      this.proArr.push(items[k]['item_id']);
    }
    //console.log(this.proArr,"===");
    this.filterProjcectList(this.proArr);


    this.proNameArr = '';
    for (let k = 0; k < this.proArr.length; k++) {
      if (this.proNameArr) {
        this.proNameArr += ',' + this.proArr[k];
      }
      else {
        this.proNameArr = this.proArr[k];
      }


    }


  }
  onSelectAll2(items: any) {
    this.proArr = [];
    for (let k = 0; k < items.length; k++) {
      this.proArr.push(items[k]['item_id']);
    }
    this.proNameArr = ''
    for (let k = 0; k < this.proArr.length; k++) {
      if (this.proNameArr)
        this.proNameArr += ',' + this.proArr[k];
      else
        this.proNameArr = this.proArr[k];
    }

    this.filterProjcectList(this.proArr);
    // console.log(this.__proNameArr);
  }


  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }
  getWUASelect(id, projectId, wuaId) {
    $('.clear').val('')
    if (id) {
      $("#preModal").show();
      // alert("dfhdjsfhdfgsf")
      // console.log("hhhhh");
      this.getWuaDetailsApi(projectId);
      for (let i = 0; i < this.wuaidsAll.length; i++) {
        $('#chk' + this.wuaidsAll[i]).prop('checked', true);
      }
      this.checkdProjScreen();
    } else {
      var removeIndex = this.projectDetailsArr.map(function (item) { return item.project_id; }).indexOf(projectId);
      this.projectDetailsArr.splice(removeIndex, 1);
      this.projectDetailsArr.push({
        project_id: projectId,
        is_wua_exists: 'No'
      });
      $("#show" + projectId).hide();
    }

    // console.log(this.projectDetailsArr)
  }
  getProjectId(sel, product, ind) {

    if (sel) {

      this.wuaRadioShow = true;
      $("#radioshow" + product).show();

      this.projectDetailsArr.push({
        project_id: product,
        wua_id: ''
      });
    }
    else {
      $("#radioshow" + product).hide();
      $('#No' + product).prop('checked', false);
      $('#Yes' + product).prop('checked', false);
      var removeIndex = this.projectDetailsArr.map(function (item) { return item.project_id; }).indexOf(product);
      this.projectDetailsArr.splice(removeIndex, 1);
    }
  }
  getWuaDetailsApi(projectId) {
    //  let post_user_id = localStorage.getItem('post_user_id');


    const body = new HttpParams({
      fromObject: {
        project_id: projectId,
      }
    });



    this.http.getWUAVerifyDetails(body).subscribe((resolve: any) => {

      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        this.html1 = '';
        this.WuaList = resolve.data;
        console.log(this.WuaList);
        console.log("Shreyaaa");

        this.filterWuaList = resolve.data;

        for (let i = 0; i < this.wuaidsAll.length; i++) {
          $('#chk' + this.wuaidsAll[i]).prop('checked', true);
        }

        if (resolve.data) {


        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )
  }


  filterWualList(value) {
    this.filterWuaList = this.WuaList.filter(element => element.wua_name.toLowerCase().indexOf(value) > -1)
  }

  filterWualListMarathi(value) {
    this.filterWuaList = this.WuaList.filter(element => element.wua_name_marathi.toLowerCase().indexOf(value) > -1)
  }


  filterProjcectList(arr) {
    this.projectList1 = this.projectList;

    if (arr.length)
      this.projectList1 = this.projectList1.filter(element => arr.includes(element.project_unique_gis));
    else

      this.projectList1 = this.projectList;

  }

  getwuaId(isselected, wuaid, projectid) {


    console.log(this.projectDetailsArr, "detailArr");

    if (isselected) {
      this.wuaidsAll.push(wuaid);
      this.wuaids.push(wuaid);
      var str = this.wuaids.toString();

      var removeIndex = this.projectDetailsArr.map(function (item) { return item.project_id; }).indexOf(projectid);
      this.projectDetailsArr.splice(removeIndex, 1)
      this.projectDetailsArr.push({
        project_id: projectid,
        is_wua_exists: 'Yes',
        wua_ids: str

      });
    }
    else {
      this.wuaidsAll.push(wuaid);
      this.wuaids.pop(wuaid);
      var str = this.wuaids.toString();
      var removeIndex = this.projectDetailsArr.map(function (item) { return item.project_id; }).indexOf(projectid);
      this.projectDetailsArr.splice(removeIndex, 1);
      this.projectDetailsArr.push({
        project_id: projectid,
        is_wua_exists: 'Yes',
        wua_ids: str

      });

    }
  }

  getProjectTypes() {
    const body = new HttpParams({
      fromObject: {
        project_id: '',
      }
    });
    this.http.getProjectTypes().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          // console.log(resolve.data.project_types);

          this.proSType = [];
          for (let k = 0; k < resolve.data.project_types.length; k++) {

            if (this.selectedlang === 'en') {
              this.proSType.push({
                item_id: resolve.data.project_types[k]['project_type_id'],
                item_text: resolve.data.project_types[k]['project_type']
              });
            } else {

              this.proSType.push({
                item_id: resolve.data.project_types[k]['project_type_id'],
                item_text: resolve.data.project_types[k]['project_type_marathi']
              });



            }
          }
          //  console.log(this.proSType)
        } else
          return false;
      }

    },
      (err) => {

        console.log(err);

      }
    )
  }

  close(id) {

    this.wuaids = [];
    if (id == 'mainCanel') {
      $("#preModal").hide();
    }

  }
  getProjectStatus() {


    const body = new HttpParams({
      fromObject: {
        project_id: '',
      }
    });

    this.http.getProjectStatus().subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          this.proType = [];
          for (let k = 0; k < resolve.data.project_statuses.length; k++) {

            if (this.selectedlang === 'en') {
              this.proType.push({
                item_id: resolve.data.project_statuses[k]['project_status_id'],
                item_text: resolve.data.project_statuses[k]['project_status']
              });

            }
            else {

              this.proType.push({
                item_id: resolve.data.project_statuses[k]['project_status_id'],
                item_text: resolve.data.project_statuses[k]['project_status_marathi']
              });





            }
          }
        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )
  }


  getProjectReloadScreen() {

    let post_user_id = localStorage.getItem('post_user_id');

    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });
    this.http.getProjectReloadScreenAp(body).subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        this.projectDetailsArr = (resolve.data.selected.projects);
        // console.log(resolve.data,"====")
        if (resolve.data) {


          for (let k = 0; k < resolve.data.selected.projects.length; k++) {

            //console.log(resolve.data.selected.projects[k]['project_id']);

            $('#prochk' + resolve.data.selected.projects[k]['project_id']).prop('checked', true);
            if (resolve.data.selected.projects[k]['is_wua_exists'] == 'Yes') {

              $("#radioshow" + resolve.data.selected.projects[k]['project_id']).show();
              $("#Yes" + resolve.data.selected.projects[k]['project_id']).prop("checked", true);
            }
            else {
              $("#radioshow" + resolve.data.selected.projects[k]['project_id']).show();
              $("#No" + resolve.data.selected.projects[k]['project_id']).prop("checked", true);

            }
            if (resolve.data.selected.projects[k]['wua_ids']) {

              var nameArr = resolve.data.selected.projects[k]['wua_ids'].split(',');

              for (let j = 0; j < nameArr.length; j++) {
                $("#chk" + nameArr[j]).prop("checked", true);
              }

            }


          }
        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )
  }




  getUserDetails() {
    const body = new HttpParams({
      fromObject: {
        post_user_id: localStorage.getItem('post_user_id'),
      }
    });
    this.http.getUserDetail(body).subscribe((resolve: any) => {
      for (let key in resolve.data.userDetail.user_office_detail) {
        this.userOfficeType = key;
        break;
      }
      this.userPosition = resolve.data.userDetail.user_post
      // console.log(this.userPosition + "==========" + this.userOfficeType);

      //Superintending Engineer and Deputy Secretary (Technical) - circle
      //Executive Engineer and Under Secretary (Technical) - division
      if (this.userOfficeType == "Division" || this.userOfficeType == "Circle" || this.userOfficeType == "Mantralaya" || this.userOfficeType == "Region" || this.userOfficeType == "Corporation") {

        this.skipDiv = true;

      }
      else {

        this.skipDiv = false;

      }


    });
  }
  checkdProjScreen() {
    let post_user_id = localStorage.getItem('post_user_id');

    const body = new HttpParams({
      fromObject: {
        post_user_id: post_user_id,
      }
    });
    this.http.getProjectReloadScreenAp(body).subscribe((resolve: any) => {
      let data1 = resolve;
      if (data1.status == 0) {
        return false;
      } else {
        if (resolve.data) {
          for (let k = 0; k < resolve.data.selected.projects.length; k++) {

            $('#prochk' + resolve.data.selected.projects[k]['project_id']).prop('checked', true);
            if (resolve.data.selected.projects[k]['is_wua_exists'] == 'Yes') {

              $("#radioshow" + resolve.data.selected.projects[k]['project_id']).show();
              $("#Yes" + resolve.data.selected.projects[k]['project_id']).prop("checked", true);
            }
            else {
              $("#radioshow" + resolve.data.selected.projects[k]['project_id']).show();
              $("#No" + resolve.data.selected.projects[k]['project_id']).prop("checked", true);

            }
            if (resolve.data.selected.projects[k]['wua_ids']) {

              var nameArr = resolve.data.selected.projects[k]['wua_ids'].split(',');

              for (let j = 0; j < nameArr.length; j++) {
                $("#chk" + nameArr[j]).prop("checked", true);
              }

            }


          }
        } else
          return false;
      }

    },
      (err) => {

        console.log(err);


      }
    )


  }

}
