import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService }  from '../_service/shared.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @ViewChild('para', { static: true }) para: ElementRef;
  selectedlang = localStorage.getItem('selected_lang');
  data: any = {};
  clickEventsubscription:Subscription;
  FontIncreseDecrese: Subscription;
  en_data: any ={
    dashboard : "Dashboard",
    project_layout: "Project Layout Master",
    wua:"WUA Master",
    farmer:"Farmer Master",
    project:"Project Master",
    dam:"Dam Master"
    }
    mr_data: any = {
      dashboard : "डॅशबोर्ड",
      project_layout : "प्रकल्पाची कालवा वितरण व्यवस्थेची माहिती",
      wua:"पाणी वापर संस्थेची माहिती",
      farmer:"शेतकऱ्यांची माहिती",
      project:"प्रकल्पाची माहिती",
      dam:"धरणाची माहिती"
    
    }

  viewfarmer() {
    this.router.navigate(['ViewFarmer']);
  }

  dashboard(){
    this.router.navigate(['dashboard']);

  }

  addWuaMaster(){
    this.router.navigate(['wuaMaster']);

  }

  viewWuaMaster(){
    this.router.navigate(['viewWua']);

  }
  stepOneForm(){
    this.router.navigate(['StepOne']);

  }

  addProjectLayout(){
    this.router.navigate(['projectLayout']);

  }
  viewProject(){
    this.router.navigate(['viewProject']);
  }

  viewProjectMaster(){

    this.router.navigate(['ViewprojectMaster']);
  }
  
  damMaster(){
    this.router.navigate(['viewdamMaster']);

  }
  constructor( private router: Router,
    private route: ActivatedRoute,
    private sharedService:SharedService) {

      this.clickEventsubscription=    this.sharedService.getClickEvent().subscribe(()=>{
     
        this.selectedlang = localStorage.getItem('selected_lang');
        this.ngOnInit();
        })

        this.FontIncreseDecrese =  this.sharedService.getFontChangeEvent().subscribe((fontSize) => {
          //alert("ddd");
     
          (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${fontSize}px`;
          
    
          
        
        });
     }

  ngOnInit(): void {
    switch(this.selectedlang){
			case "en":
			  this.data = this.en_data;
			  break;
			case "mr":
			  this.data = this.mr_data;
			  break;
		  }


  }

}
