import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  mobile:any;
  farmer_master_id:any;
  user_detail:any;
  office_detail:any;
  selectedProject:any;

  language$ = new Subject();
  
  constructor() {
   }

}
