import { Injectable, OnInit } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { finalize } from "rxjs/operators";
import { Observable } from 'rxjs';


@Injectable()
export class Interceptor implements HttpInterceptor, OnInit {
  token: any;

  constructor() { }

  ngOnInit() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //  const AuthRequest = httpRequest.clone({
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/x-www-form-urlencoded'
    //   })
    // });

    //  return next.handle(AuthRequest);
    // console.log(request.url)
    if (request.url.includes('otp-master/generate-otp') || request.url.includes('otp-master/verify-otp')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'x-access-token': localStorage.getItem('Token')
        }
      });
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token': localStorage.getItem('Token')
      }
    });
    return next.handle(request);

  }
}
