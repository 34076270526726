import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators,} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.css']
})
export class StepThreeComponent implements OnInit {

  submitted = false;


  threeStepForm: FormGroup;
  farmerSelect = [];
  optionsSelect = [];
  reqData: any = {};
  totalTr: any = [];
  myArr: any = [];
  ngOnInit() {

    this.threeStepForm = this.fb.group({
      farmer_master_id: [this.route.snapshot.params.id],
      farmer_master_sugar_factory_name: ['', Validators.required],
      farmer_master_sugar_factory_email: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$')])],
      farmer_master_lift_irregation_permission_no: ['', Validators.required],
      farmer_master_valid_from: ['', Validators.required],
      farmer_master_valid_upto: ['', Validators.required],
      user_post_id: [localStorage.getItem('post_user_id'),],
    });
    this.reqData = {};
    this.totalTr = [1];
    this.reqData.farmer_master_gut_no = [];
    this.reqData.farmer_master_gut_no[0] = '';
    this.reqData.farmer_master_part_no = [];
    this.reqData.farmer_master_part_no[0] = '';
    this.reqData.farmer_master_total_area_gut = [];
    this.reqData.farmer_master_total_area_gut[0] = '';
    this.reqData.farmer_master_owned_area_exclude_potkharab = [];
    this.reqData.farmer_master_owned_area_exclude_potkharab[0] = '';
    this.reqData.farmer_master_potkharab_area = [];
    this.reqData.farmer_master_potkharab_area[0] = '';
    this.reqData.farmer_master_whether_said_gutnumber_notified_command_area = [];
    this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[0] = '';
    this.myArr = [];
    this.farmerSelect = this.getFarmerList();
  }
  submit() {
    // console.log(this.threeStepForm);
    if (this.threeStepForm.status == "INVALID") {
      alert('All fields are mandatory')
      return
    }
    let arr = [];
    arr = this.reqData.farmer_master_gut_no.map((e: any, i: any) => [{ farmer_master_gut_no: e, farmer_master_part_no: this.reqData.farmer_master_part_no[i],
      farmer_master_total_area_gut: this.reqData.farmer_master_total_area_gut[i], 
      farmer_master_owned_area_exclude_potkharab: this.reqData.farmer_master_owned_area_exclude_potkharab[i],
      farmer_master_potkharab_area: this.reqData.farmer_master_potkharab_area[i],
      farmer_master_whether_said_gutnumber_notified_command_area: this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[i],}]);
      
      const merged = [].concat.apply([], arr);
      // console.log(merged);
      this.threeStepForm.value.farmer_gut_details = JSON.stringify(merged)
      // console.log(this.threeStepForm.value)
    
    // console.log(this.threeStepForm)
    const body = new HttpParams({
      fromObject: this.threeStepForm.value
    });
    this.http.saveThirdStap(body).subscribe((resolve: any) => {
      // console.log(resolve);
      // this.wuaArray = resolve.data
      this.router.navigate(['/ViewFarmer'])

    })
  }

  //get name() { return this.threeStepForm.get('farmername'); }

  get name() { return this.threeStepForm.controls; }


  getFarmerList() {
    return this.optionsSelect = [
      { id: '0', name: 'Please Select' },
      { id: '1', name: 'Frmer 1' },
      { id: '2', name: 'Frmer 2' },
      { id: '3', name: 'Frmer 3' },

    ];

  }




  saveFinal = function () {

    // stop here if form is invalid
    // console.log(JSON.stringify(this.threeStepForm.value))

    if (!this.threeStepForm.valid) {
      // console.log("SHRWYA");
      return false;
    } else {
      this.threeStepFormSave()
      //console.log(JSON.stringify(this.threeStepForm.value))
    }

  }
  onSubmit(form: FormGroup) {

    /*if (!this.threeStepForm.valid) {
      return false;
    } else {
      console.log(JSON.stringify(this.threeStepForm.value))
    }*/
    this.threeStepFormStage()
  }

  onReset() {
    this.submitted = false;
    this.threeStepForm.reset();
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpRequestService,
    private common: CommonService,


  ) {

  }
  addRow() {
    this.totalTr.push(1);
    this.reqData.farmer_master_gut_no[this.totalTr.length - 1] = '';
    this.reqData.farmer_master_part_no[this.totalTr.length - 1] = '';
    this.reqData.farmer_master_total_area_gut[this.totalTr.length - 1] = '';
    this.reqData.farmer_master_owned_area_exclude_potkharab[this.totalTr.length - 1] = '';
    this.reqData.farmer_master_potkharab_area[this.totalTr.length - 1] = '';
    this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[this.totalTr.length - 1] = '';
    // console.log(this.reqData);

  }
  removeRow(i) {
    if (this.totalTr.length == 1) {
      this.totalTr = [];
      this.totalTr.push(1);
      this.reqData.farmer_master_gut_no[0] = '';
      this.reqData.farmer_master_part_no[0] = '';
      this.reqData.farmer_master_total_area_gut[0] = '';
      this.reqData.farmer_master_owned_area_exclude_potkharab[0] = '';
      this.reqData.farmer_master_potkharab_area[0] = '';
      this.reqData.farmer_master_whether_said_gutnumber_notified_command_area[0] = '';
      this.myArr[0] = '';
    } else {
      this.totalTr.splice(i, 1);
      this.reqData.farmer_master_gut_no.splice(i, 1);
      this.reqData.farmer_master_part_no.splice(i, 1);
      this.reqData.farmer_master_total_area_gut.splice(i, 1);
      this.reqData.farmer_master_owned_area_exclude_potkharab.splice(i, 1);
      this.reqData.farmer_master_potkharab_area.splice(i, 1);
      this.reqData.farmer_master_whether_said_gutnumber_notified_command_area.splice(i, 1);
      this.myArr.splice(i, 1);

    }
    console.log(this.reqData);

  }

  threeStepFormSave() {
    let data = {};
    const body = new HttpParams({
      fromObject: {
        farmer_master_sugar_factory_name: this.threeStepForm.value.factoryname,
        farmer_master_sugar_factory_email: this.threeStepForm.value.emailname,
        farmer_master_farmer_irregation_source: this.threeStepForm.value.irrigationname,
        farmer_master_lift_irregation_permission_no: this.threeStepForm.value.liftname,
        farmer_master_valid_from: this.threeStepForm.value.validDateName,
        farmer_master_valid_upto: this.threeStepForm.value.validUptpName,
        farmer_master_gut_no: this.threeStepForm.value.gutNoName,
        farmer_master_part_no: this.threeStepForm.value.partNoName,
        farmer_master_total_area_gut: this.threeStepForm.value.totAreaName,
        farmer_master_owned_area_exclude_potkharab: this.threeStepForm.value.ownedAreaName,
        farmer_master_potkharab_area: this.threeStepForm.value.potName,
        farmer_master_whether_said_gutnumber_notified_command_area: this.threeStepForm.value.whetherName,
        farmer_master_id: localStorage.getItem('farmer_master_id'),

      }
    });



    this.http.updateThreeFarmerMaster(body).subscribe((resolve: any) => {
      // console.log(resolve);
      let data = resolve;
      this.common.farmer_master_id = resolve.farmer_master_id;

      // console.log("Update Success");
      this.router.navigate(['ViewFarmer']);
      ///this.router.navigate(['StepTwo']);
      //return false
      //console.log(resolve.status)
      if (data.status == 1) {
        //this.validNumber=1;

        return false;

      } else {
        this.router.navigate(['ViewFarmer']);
        // this.router.navigate(['otp']);
        this.common.mobile = this.threeStepForm.value.mobilenumber;
      }
      //this.common.mobile = resolve;

    },
      (err) => {

        console.log(err);


      }
    )
  }


  threeStepFormStage() {
    //console.log(this.threeStepForm.value.validDateName);
    let data = {};
    const body = new HttpParams({
      fromObject: {
        farmer_master_sugar_factory_name: this.threeStepForm.value.factoryname,
        farmer_master_sugar_factory_email: this.threeStepForm.value.emailname,
        farmer_master_farmer_irregation_source: this.threeStepForm.value.irrigationname,
        farmer_master_lift_irregation_permission_no: this.threeStepForm.value.liftname,
        farmer_master_valid_from: this.threeStepForm.value.validDateName,
        farmer_master_valid_upto: this.threeStepForm.value.validUptpName,
        farmer_master_gut_no: this.threeStepForm.value.gutNoName,
        farmer_master_part_no: this.threeStepForm.value.partNoName,
        farmer_master_total_area_gut: this.threeStepForm.value.totAreaName,
        farmer_master_owned_area_exclude_potkharab: this.threeStepForm.value.ownedAreaName,
        farmer_master_potkharab_area: this.threeStepForm.value.potName,
        farmer_master_whether_said_gutnumber_notified_command_area: this.threeStepForm.value.whetherName,
        farmer_master_id: localStorage.getItem('farmer_master_id'),

      }
    });



    this.http.updateFarmerMaster(body).subscribe((resolve: any) => {
      // console.log(resolve);
      let data = resolve;
      this.common.farmer_master_id = resolve.farmer_master_id;

      // console.log("Update Success");
      // this.router.navigate(['ViewFarmer']);
      ///this.router.navigate(['StepTwo']);
      return false
      //console.log(resolve.status)
      if (data.status == 1) {
        //this.validNumber=1;

        return false;

      } else {
        ///   this.router.navigate(['ViewFarmer']);
        // this.router.navigate(['otp']);
        this.common.mobile = this.threeStepForm.value.mobilenumber;
      }
      //this.common.mobile = resolve;

    },
      (err) => {

        console.log(err);


      }
    )
  }

}
