<div class="dashboardpg">
	<div class="dashboardstruck fullwidth">
		<!-- dashboard left panel start-->
		<!-- <app-sidebar></app-sidebar> -->
		<!-- dashboard left panel over-->
		<!-- dashboard right panel start-->
		<div class="dashbordright">
			<!-- header start-->
			<app-header></app-header>
			<app-sidebar></app-sidebar>
			<!-- header over-->
			<!-- dashboardbnnr start-->
			<div class="greenstrip fullwidth">
				<h1>Farmer Master</h1>
			</div>
			<div class="stepimg fullwidth">
				<img src="assets/images/step1.jpg" alt="step1" />
			</div>
			<!-- dashboardbnnr over-->
			<!-- mid content start-->
			<div class="mid-content fullwidth formstep">


				<!-- row start-->
				<div class="row">
					<!-- col-md-12 start-->
					<div class="col-md-12 recentlistbx">
						<h2 class="smlheadh2 col-md-12"><span>General</span></h2>

					</div>
					<!-- col-md-12 over-->
					<!-- form step1 start-->

					<div class="stepfrmcmn stepfrm1">
						<form [formGroup]="firstStepForm">
							<!-- step row start-->
							<div class="row frmrow">

								<div class="col-md-4 inptbx">
									<label for="project" class="steplbl">Project Name</label>
									<!--<select class="stepinpt" formControlName="projectSelectname"  (change)="changeProject($event)" > -->
									<select class="stepinpt" formControlName="farmer_master_project_code"
										(change)="getDamList($event.target.value)">

										<option *ngFor="let projects of projectSelect;"
											[value]="projects.project_unique_gis">
											{{projects.project_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">Dam Name</label>
									<select class="stepinpt" formControlName="test"
										(ngModelChange)="getDistrictList($event)">
										<option *ngFor="let dams of damSelect;" [ngValue]="dams">
											{{dams.dam_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">District in which dam is situated</label>
									<select class="stepinpt"
										formControlName="farmer_master_district_id_in_which_dam_situated">
										<option *ngFor="let district of districtSelect;" [value]="district.district_id">
											{{district.district_name}}
										</option>

									</select>
								</div>

							</div>
							<!-- step row over-->
							<!-- step row start-->
							<div class="row frmrow">
								<div class="col-md-4 inptbx">
									<label class="steplbl">Irrigation Source</label>
									<select class="stepinpt" formControlName="farmer_master_irregation_source"
										(change)="toggleShow($event.target.value)">
										<option value="Canal Flow">Canal Flow</option>
										<option *ngFor="let irrigation of irrigationSelect;" [value]="irrigation">
											{{irrigation}}
										</option>
									</select>
								</div>
							</div>
							<!-- step row over-->
							<!-- step row start-->
							<!-- <span *ngIf="isShown">
								<div class="row frmrow">

									<div class="col-md-4 inptbx">
										<label class="steplbl">Name of Distributary Canal</label>
										<select class="stepinpt" formControlName="distributorySelectName">
											<option *ngFor="let distributory of distributorySelect;"
												[value]="distributory.id">
												{{distributory.name}}
											</option>

										</select>
									</div>

									<div class="col-md-4 inptbx">
										<label class="steplbl">Name of Minor</label>
										<select class="stepinpt" formControlName="minorSelectName">
											<option *ngFor="let minor of minorSelect;" [value]="minor.id">
												{{minor.name}}
											</option>

										</select>
									</div>

									<div class="col-md-4 inptbx">
										<label class="steplbl">Name of Sub-minor</label>
										<select class="stepinpt" formControlName="subminorSelectName">
											<option *ngFor="let subminor of subminorSelect;" [value]="subminor.id">
												{{subminor.name}}
											</option>

										</select>
									</div>

								</div>
								<div class="row frmrow">

									<div class="col-md-4 inptbx">
										<label class="steplbl">Outlet Number</label>
										<select class="stepinpt" formControlName="outletSelectName">
											<option *ngFor="let outlet of outletSelect;" [value]="outlet.id">
												{{outlet.name}}
											</option>

										</select>
									</div>

									<div class="col-md-4 inptbx">
										<label class="steplbl">Side of outlet (left/right) copy</label>
										<select class="stepinpt" formControlName="outletLeftRightSelectName">
											<option *ngFor="let outletLeftRight of outletLeftRightSelect;"
												[value]="outletLeftRight.id">
												{{outletLeftRight.name}}
											</option>

										</select>
									</div>

								</div>

							</span> -->

							<div class="col-md-12 recentlistbx" *ngIf="isShown">
								<h2 class="smlheadh2 col-md-12"><span>Canal Details</span></h2>
							</div>

							<div class="row frmrow" *ngIf="isShown">

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of Main Canal</label>
									<select class="stepinpt" formControlName="farmer_master_canal_id"
										(change)="getBranchCanalList($event.target.value)">
										<option *ngFor="let corporation of mainCanelArray;"
											[value]="corporation.main_canal_id">
											{{corporation.main_canal_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of Branch Canal</label>
									<select class="stepinpt" formControlName="farmer_master_canal_branch_id"
										(change)="getDistributoryList($event.target.value)">
										<option *ngFor="let region of branchCanelArray;"
											[value]="region.branch_canal_master_id">
											{{region.branch_canal_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of Distributary Canal</label>
									<select class="stepinpt" formControlName="farmer_master_canal_distributary_id"
										(change)="getMinorList($event.target.value)">
										<option *ngFor="let circle of distributoryArray;"
											[value]="circle.distributory_branch_canal_id">
											{{circle.distributory_canal_name}}
										</option>

									</select>
								</div>

							</div>

							<div class="row frmrow" *ngIf="isShown">

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of Minor</label>
									<select class="stepinpt" formControlName="farmer_master_minor_id"
										(change)="getSubMinorList($event.target.value)">
										<option *ngFor="let division of minorCanelArray;"
											[value]="division.minor_canal_master_id">
											{{division.minor_canal_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of SUB Minor</label>
									<select class="stepinpt" formControlName="farmer_master_subminor_id"
										(change)="getOutletList($event.target.value)">
										<option *ngFor="let regionSub of subMinorCanelArray;"
											[value]="regionSub.subminor_canal_master_id">
											{{regionSub.subminor_canal_name}}
										</option>

									</select>
								</div>

								<div class="col-md-4 inptbx">
									<label class="steplbl">Outlet Number</label>
									<input class="stepinpt" formControlName="farmer_master_outlet_number">
								</div>
								<div class="col-md-4 inptbx">
									<label class="steplbl">Side of outlet (left/right) copy</label>
									<select class="stepinpt" formControlName="farmer_master_outlet_side">
										<option value="Left">Left</option>
										<option value="Right">Right</option>


									</select>
								</div>

							</div>
							<!-- step row over-->
							<!-- col-md-12 start-->
							<div class="col-md-12 recentlistbx">
								<h2 class="smlheadh2 col-md-12"><span>WUA Details</span></h2>
							</div>
							<!-- col-md-12 over-->
							<!-- step row start-->
							<div class="row frmrow">

								<div class="col-md-4 inptbx">
									<label class="steplbl">Name of WUA</label>
									<select class="stepinpt" formControlName="farmer_master_wua_id">
										<option *ngFor="let wua of wuaArray;" [value]="wua.wua_id">
											{{wua.wua_name}}
										</option>

									</select>
								</div>



							</div>
							<!-- step row over-->
							<!-- step row start-->
							<div class="row frmrow">

								<div class="col-md-12 inptbx">
									<input class="stepgreenbtn" type="Submit" value="Submit" (click)="saveFinal()" />
									<input class="stepgreenbtn" (click)="saveFinal()" value="Save" type="button" />
									<input class="stepgreybtn" type="reset" value="Reset" />
								</div>

							</div>
							<!-- step row over-->
						</form>

					</div>
					<!-- form step1 over-->

					<!-- row over-->
					<!-- row over-->
					<app-footer></app-footer>

				</div>
				<!-- mid content over-->

			</div>
			<!-- dashboard right panel over-->
		</div>
	</div>