import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewEncapsulation, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from './../_service/http-request.service';
import { CommonService } from './../_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../_service/shared.service';
import { ValidationService } from '../_service/validation.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { switchMap, debounceTime, map } from 'rxjs/operators';
import { resolve } from 'dns';
//import { ConsoleReporter } from 'jasmine';
declare var jQuery: any;
declare var $: any;

@Component({
	selector: 'app-project-master',
	templateUrl: './project-master.component.html',
	styleUrls: ['./project-master.component.css'],
	encapsulation: ViewEncapsulation.None,

})

export class ProjectMasterComponent implements OnInit {

	clickEventsubscription: Subscription;
	language: string;
	projectmasterForm: FormGroup;
	projectSelect: any;
	projectType: any;
	projectStatus: any;
	ownership: any;
	states: any;
	yesNo: any;
	getDistArray: any;
	bankArray: any;
	getFarmerTaluka: any;
	states1: any;
	office_details_en: any = {};
	office_details_mr: any = {};
	data: any = {};
	secondary_corporation: any;
	secondary_Region: any;
	secondary_Circle: any;
	secondary_Division: any;
	secondary_SubDivision: any;
	secondary_Section: any;
	selectedSecondaryCorporationIds: any = [];
	selectedSecondaryRegionIds: any = [];
	selectedSecondaryCircleIds: any = [];
	selectedSecondaryDivisionIds: any = [];
	selectedSecondarySubDivisionIds: any = [];
	selectedSecondarySectionIds: any = [];
	dropdownSettings: any = {};
	selectedlang = localStorage.getItem('selected_lang');
	isCircleCheck: string;
	FirstKey: string;
	divTextshow: boolean = false;
	divselectshow: boolean = false;
	dispSecondaryDistTaluka = true
	primary_Region: any;
	primary_Circle: any;
	primary_Division: any;
	primary_SubDivision: any;
	primary_Section: any;
	selectedSecondaryDistrictIds: any = [];
	dropdownSettings1: any = {};
	dropdownSettings2: any = {};
	selectedSecondaryTalukaIds: any = [];
	forFamerTalukaArr: any = [];
	project_id: Number = 0;
	getPorjectMasterDetails: any;
	districtEditArray = []
	talukaEditArray = []
	userOfficeType: string
	userPosition: string
	haveAccess: Boolean = false
	CorporationPrimaryId: any = '';
	DivisionPrimaryId: any = '';
	RegionPrimaryId: any = '';
	CirclePrimaryId: any = '';
	subDivisId: any = '';
	CorporatesEditArray: any;
	SubDivEditArray: any;
	regionEditArray: any;
	circleEditArray: any;
	DivEditArray: any;
	SecEditArray: any;
	select = this.selectedlang == 'en' ? 'Select' : 'निवडा'
	isAdmin: any;
	//subDivisId: any;
	subdivTextshow: boolean = false;
	subdivshow: boolean = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpRequestService,
		private common: CommonService,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService,
		private sharedService: SharedService,
		private ngxService: NgxUiLoaderService,
		public validationService: ValidationService,


	) {
		this.userOfficeType = localStorage.getItem('user_office_type')
		this.userPosition = localStorage.getItem('user_post')
		this.isAdmin = localStorage.getItem('user_type')
		// console.log(this.userOfficeType + "=========" + this.userPosition)
		// this.checkUserAccess()

		// if (!this.haveAccess) {
		// 	this.sendAlert("You don't have access to Add WUA")
		// 	this.router.navigate(["/dashboard"])
		// }



		this.iSFormInitialize();
		this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
			this.selectedlang = localStorage.getItem('selected_lang');

			// console.log(this.selectedlang);
			// console.log("here");
			this.ngxService.stop();
			this.ngOnInit();
			this.subscribeToLanguageChange(localStorage.getItem('selected_lang'));
		})
	}

	// checkUserAccess() {

	// 	if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (this.isAdmin == 0 )) {
	// 		// console.log(this.userOfficeType + "=============" + this.userPosition);
	// 		this.haveAccess = true
	// 	}
	// }

	iSFormInitialize() {
		this.projectmasterForm = this.fb.group({
			project_id: new FormControl(0),
			project_name: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9 ']*$"), Validators.maxLength(80)]),
			project_name_marathi: new FormControl(null, [Validators.required]),
			project_status: new FormControl(null, [Validators.required]),
			project_type: new FormControl(null, [Validators.required]),
			project_ownership_id: new FormControl(null, [Validators.required]),
			project_is_interstate: new FormControl(null, [Validators.required]),

			project_state_id: new FormControl(null),
			project_district_code: new FormControl(null),
			project_taluka_code: new FormControl(null),
			project_secondary_district_code: new FormControl(null),
			project_secondary_taluka_code: new FormControl(null),

			project_corporation_id: new FormControl(null),
			project_secondary_corporation_id: new FormControl(null, [Validators.required]),
			project_region_id: new FormControl(null),
			project_secondary_region_id: new FormControl(null, [Validators.required]),
			project_circle_id: new FormControl(null),
			project_secondary_circle_id: new FormControl(null, [Validators.required]),
			project_division_id: new FormControl(null),
			project_secondary_division_id: new FormControl(null, [Validators.required]),
			project_subdivision_id: new FormControl(null),
			project_secondary_subdivison_id: new FormControl(null, [Validators.required]),
			project_section_id: new FormControl(null),
			project_secondary_section_id: new FormControl(null, [Validators.required]),
			project_added_by_user_office_type: new FormControl(null),
			added_by: new FormControl(null),
			project_user_type: new FormControl(1),


			// distsecon: new FormControl(''),
			// corporation: new FormControl(''),
			// region: new FormControl(''),
			// circle: new FormControl(''),
			// divisionSelect: new FormControl(null),


			distsecon: [''],
			// corporation: [''],
			// region: [''],
			// circle: [''],
			// divisionSelect:[''],

		});
	}

	checkUserAccess() {

		// console.log(this.isAdmin,'========');
		// return
		if ((this.userOfficeType == "Division" && this.userPosition.includes("Executive Engineer") && !this.userPosition.includes("Assistant Executive Engineer")) || (this.userOfficeType == "Circle" && this.userPosition.includes('Superintending Engineer')) || (this.isAdmin == 0)) {

			this.haveAccess = true
		}

		if (!this.haveAccess) {
			this.sendAlert("You don't have access to Add WUA")
			this.router.navigate(["/dashboard"])
		}
	}


	ngOnInit(): void {
		// alert("jj")
		this.language = this.selectedlang
		// console.log(this.selectedlang);
		this.selectedlang = localStorage.getItem('selected_lang');
		this.getUserOfficeDetails();

		this.getProjectDetails()
		this.getProjectStatus()
		this.getProjectTypes()
		this.getOwnership()
		this.getYesNo();
		this.getCorporateOffices('NoEdit')
		this.getDistrict('NoEdit');





		//alert(this.selectedlang);
		this.select = this.selectedlang == 'en' ? 'Select' : 'निवडा'
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'office_id',
			textField: this.selectedlang == 'en' ? 'office_name' : 'office_name_marathi',
			enableCheckAll: false,
			allowSearchFilter: true,
			selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
			unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
			searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
		};

		this.dropdownSettings1 = {
			singleSelection: false,
			idField: 'district_code',
			textField: this.selectedlang == 'en' ? 'district_name' : 'district_name_marathi',

			// idField: 'item_id',
			// textField: 'item_text',
			enableCheckAll: false,
			allowSearchFilter: true,
			selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
			unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
			searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
		};
		this.dropdownSettings2 = {
			singleSelection: false,
			idField: 'taluka_code',
			textField: this.selectedlang == 'en' ? 'taluka_name' : 'taluka_name_marathi',
			enableCheckAll: false,
			allowSearchFilter: true,
			selectAllText: this.selectedlang == 'en' ? 'Select All' : 'सर्व पर्याय निवडा',
			unSelectAllText: this.selectedlang == 'en' ? 'UnSelect All' : 'निवडलेले सर्व काढा',
			searchPlaceholderText: this.selectedlang == 'en' ? 'Search' : 'शोधा',
		};



		this.selectedSecondaryDistrictIds = []

		// this.getUserOfficeDetails();
		// this.projectmasterForm.controls.project_state_id.disable();
		//this.projectmasterForm = null;


		if (this.route.snapshot.params['id']) {
			this.project_id = this.route.snapshot.params['id']
			this.getSingleProjectDetails(this.project_id)
		}
		this.projectmasterForm.controls.project_state_id.disable();
		// this.projectmasterForm.controls.project_district_code.disable();
		this.projectmasterForm.controls.project_taluka_code.disable();
		// this.projectmasterForm.controls.project_secondary_district_code.disable();
		// this.projectmasterForm.controls.project_secondary_taluka_code.disable();
		this.projectmasterForm.controls.project_district_code.disable();
		this.projectmasterForm.controls.distsecon.disable();
	}

	sendAlert(message) {
		switch (this.language) {
			case "mr":
				const body = new HttpParams({
					fromObject: {
						text: message,
					}
				});
				this.http.englishToMarathi(body).subscribe((data: any) => {
					alert(data.data);
				});
				break;
			case "en":
				alert(message);
				break;
		}
	}

	getSingleProjectDetails(projectId) {
		const body = new HttpParams({
			fromObject: {
				project_id: projectId,
			}
		});
		this.http.getSingleProjectMaster(body).subscribe(
			(resolve: any) => this.editProjectMaster(resolve.data),
			(err: any) => console.log(err)
		);
	}

	editProjectMaster(projectDetails: any) {
		this.getPorjectMasterDetails = projectDetails;

		// console.log(projectDetails)
		// console.log("hhhh")
		if (projectDetails.project_is_interstate == 1) {
			this.projectmasterForm.controls.project_state_id.enable();
			//this.projectmasterForm.controls.project_state_id.enable();
			this.projectmasterForm.controls.project_district_code.disable();
			this.projectmasterForm.controls.project_taluka_code.disable();
			// this.projectmasterForm.controls.distsecon.enable();
			this.projectmasterForm.controls.project_secondary_division_id.disable();
			this.projectmasterForm.controls.project_secondary_taluka_code.disable();
			this.getStates()
		}
		else {
			this.projectmasterForm.controls.project_state_id.disable();
			//this.projectmasterForm.controls.project_state_id.enable();
			this.projectmasterForm.controls.project_district_code.enable();
			this.projectmasterForm.controls.project_taluka_code.enable();
			// this.projectmasterForm.controls.distsecon.enable();
			this.projectmasterForm.controls.project_secondary_division_id.enable();
			this.projectmasterForm.controls.project_secondary_taluka_code.enable();
			this.forFamerTaluka(projectDetails.project_district_code)

			this.selectedSecondaryDistrictIds = JSON.parse(projectDetails.project_secondary_district_code)
			this.selectedSecondaryTalukaIds = JSON.parse(projectDetails.project_secondary_taluka_code)

			this.getDistrict('edit');


			this.projectmasterForm.controls.project_district_code.enable();
			this.projectmasterForm.controls.project_taluka_code.enable();
			this.dispSecondaryDistTaluka = false

		}
		this.selectedSecondaryCorporationIds = JSON.parse(projectDetails.project_secondary_corporation_id)

		this.selectedSecondaryRegionIds = JSON.parse(projectDetails.project_secondary_region_id)

		this.selectedSecondaryCircleIds = JSON.parse(projectDetails.project_secondary_circle_id)

		this.selectedSecondaryDivisionIds = JSON.parse(projectDetails.project_secondary_division_id)

		this.selectedSecondarySubDivisionIds = JSON.parse(projectDetails.project_secondary_subdivison_id)

		this.selectedSecondarySectionIds = JSON.parse(projectDetails.project_secondary_section_id)
		this.getCorporateOffices('edit');

		//	project_division_name = projectDetails.project_division_,

		this.office_details_en = {
			circle: projectDetails.project_circle_name,
			Region: projectDetails.project_region_name,
			Division: projectDetails.project_division_name,
			Corporation: projectDetails.project_corporation_name,
			SubDivision: projectDetails.project_corporation_name,

		}

		this.office_details_mr = {
			circle: projectDetails.project_circle_name_marathi,
			Region: projectDetails.project_region_name_marathi,
			Division: projectDetails.project_division_name_marathi,
			Corporation: projectDetails.project_corporation_name_marathi,
			SubDivision: projectDetails.project_corporation_name,

		}

		// console.log(this.office_details_mr);
		// console.log(this.office_details_en);
		this.projectmasterForm.patchValue({
			project_id: projectDetails.project_id,
			project_name: projectDetails.project_name,
			project_name_marathi: projectDetails.project_name_marathi,
			project_status: projectDetails.project_status,
			project_type: projectDetails.project_type,
			project_ownership_id: projectDetails.project_ownership_id,
			project_is_interstate: projectDetails.project_is_interstate,
			project_state_id: projectDetails.project_state_id,
			project_district_code: projectDetails.project_district_code,
			// project_secondary_district_code: projectDetails.project_secondary_district_code,
			// project_secondary_taluka_code: projectDetails.project_secondary_taluka_code,
			project_corporation_id: projectDetails.project_corporation_id,
			project_secondary_corporation_id: JSON.parse(projectDetails.project_secondary_corporation_id),
			project_region_id: projectDetails.project_region_id,
			project_secondary_region_id: JSON.parse(projectDetails.project_secondary_region_id),
			project_circle_id: projectDetails.project_circle_id,
			project_secondary_circle_id: JSON.parse(projectDetails.project_secondary_circle_id),
			project_division_id: projectDetails.project_division_id,
			project_secondary_division_id: JSON.parse(projectDetails.project_secondary_division_id),
			project_subdivision_id: projectDetails.project_subdivision_id,
			project_secondary_subdivison_id: JSON.parse(projectDetails.project_secondary_subdivison_id),
			project_section_id: projectDetails.project_section_id,
			project_secondary_section_id: JSON.parse(projectDetails.project_secondary_section_id),

		});

	}

	subscribeToLanguageChange(lang) {
		this.language = lang;
		this.translate.use(lang)
		this.cdr.markForCheck();
	}

	getProjectDetails() {
		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {
				post_user_id: post_user_id,
			}
		});
		this.http.getProjectDetailLayout(body).subscribe((resolve: any) => {
			// console.log(resolve)
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.projectSelect = resolve.data;
				} else
					return false;
			}
		},
			(err) => {
				console.log(err)
			})
	}
	getProjectStatus() {
		this.http.getProjectStatus().subscribe((resolve: any) => {
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					// console.log(resolve.data,"===")
					this.projectStatus = resolve.data.project_statuses;
				} else
					return false;
			}

		},
			(err) => {
				console.log(err);
			}
		)
	}



	getProjectTypes() {
		this.projectType = []

		this.http.getProjectTypes().subscribe((resolve: any) => {
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.projectType = resolve.data.project_types;

					// console.log(this.projectType,"===");
				} else
					return false;
			}

		},
			(err) => {

				console.log(err);

			}
		)
	}

	getOwnership() {
		this.http.getOwnerShip().subscribe((resolve: any) => {
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.ownership = resolve.data;

					// console.log(resolve.data,"===");
				} else
					return false;
			}

		},
			(err) => {

				console.log(err);

			}
		)
	}

	getStates() {
		this.states = []
		this.http.getStates().subscribe((resolve: any) => {
			let data1 = resolve;
			if (data1.status == 0) {
				return false;
			} else {
				if (resolve.data) {
					this.states = resolve.data;
				} else
					return false;
			}

		},
			(err) => {

				console.log(err);

			}
		)
	}

	getYesNo() {
		this.http.getYesNo().subscribe((data: any) => {
			// console.log(data)
			if (data) {
				// console.log(data);
				this.yesNo = data.data
			}
			else {
				return false;
			}
		})
	}

	getDistrict(val) {
		//	alert("fff")
		this.http.getDistricts().subscribe((resolve: any) => {

			//console.log( resolve)
			this.getDistArray = resolve.data;

			// this.getDistArray  = [];
			//  for (let k = 0; k < resolve.data.length; k++) {

			//   if(this.selectedlang === 'en'){
			//     this.getDistArray.push({
			//       item_id: resolve.data[k]['district_code'],
			//       item_text: resolve.data[k]['district_name']
			//     });
			//   }else if(this.selectedlang === 'mr'){
			//     this.getDistArray.push({
			//       item_id: resolve.data[k]['district_code'],
			//       item_text: resolve.data[k]['district_name_marathi']
			//     });
			//   }      
			//  }

			//  console.log(this.getDistArray);
			// echo "kkk";
			if (val == 'edit') {
				if (this.route.snapshot.params['id']) {
					var mySecondaryDistricts = [];
					var selectedSecondaryDistricts = []
					mySecondaryDistricts = JSON.parse(this.getPorjectMasterDetails.project_secondary_district_code)
					for (let i = 0; i < resolve.data.length; i++) {
						for (let j = 0; j < mySecondaryDistricts.length; j++) {
							if (resolve.data[i].district_code == mySecondaryDistricts[j]) {
								selectedSecondaryDistricts.push(resolve.data[i])
							}
						}
					}
					this.districtEditArray = selectedSecondaryDistricts
					this.forFamerTalukaSelect(mySecondaryDistricts)
				}
			}
		})

	}


	forFamerTaluka(data) {

		const body = new HttpParams({
			fromObject: {
				district_code: data
			}
		});
		this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
			// console.log(resolve,"====");
			this.getFarmerTaluka = resolve.data
			if (this.route.snapshot.params['id']) {

				// console.log(this.getPorjectMasterDetails.project_taluka_code)
				this.projectmasterForm.patchValue({ project_taluka_code: this.getPorjectMasterDetails.project_taluka_code })

			}
			// console.log(this.getFarmerTaluka)

		})
	}

	forFamerTalukaSelect(data) {


		const body = new HttpParams({
			fromObject: {
				district_code: data
			}
		});
		this.http.getDistrictsTaluka(body).subscribe((resolve: any) => {
			// console.log(resolve, "====");
			// return false;
			this.forFamerTalukaArr = resolve.data
			if (this.route.snapshot.params["id"]) {
				var mySecondaryTalukas = []
				var SelectedSecondaryTalukas = []
				mySecondaryTalukas = JSON.parse(this.getPorjectMasterDetails.project_secondary_taluka_code)
				//console.log(this.forFamerTalukaArr + "&***********************");
				for (let i = 0; i < resolve.data.length; i++) {
					for (let j = 0; j < mySecondaryTalukas.length; j++) {
						if (resolve.data[i].taluka_code == mySecondaryTalukas[j]) {
							SelectedSecondaryTalukas.push(resolve.data[i])
						}
					}
				}
				this.talukaEditArray = SelectedSecondaryTalukas
				// console.log(SelectedSecondaryTalukas);

			}

		})
	}


	translateIntoMarathi(text) {
		if (!text) {
			return
		}

		const body = new HttpParams({
			fromObject: {
				text: text,
			}
		});

		this.http.englishToMarathi(body).pipe(debounceTime(2000)).subscribe((data: any) => {
			//	console.log(data)

			this.projectmasterForm.patchValue({ project_name_marathi: data.data })

		})
	}
	isinterstate(value) {
		// console.log(value)

		if (value == 2) {  //No
			this.projectmasterForm.patchValue({
				project_state_id: null
			})
			this.projectmasterForm.controls.project_state_id.disable();
			//this.projectmasterForm.controls.project_state_id.enable();
			this.projectmasterForm.controls.project_district_code.enable();
			this.projectmasterForm.controls.project_taluka_code.enable();
			// this.projectmasterForm.controls.distsecon.enable();
			this.projectmasterForm.controls.project_secondary_division_id.enable();
			this.projectmasterForm.controls.project_secondary_taluka_code.enable();
			this.dispSecondaryDistTaluka = false
			this.getDistrict('NoEdit');
			//  this.forFamerTaluka(1);
		} else {

			this.projectmasterForm.controls.project_state_id.enable();
			this.projectmasterForm.controls.project_district_code.disable();
			this.projectmasterForm.controls.project_taluka_code.disable();
			// this.projectmasterForm.controls.distsecon.disable();
			// this.projectmasterForm.controls.project_secondary_taluka_code.disable();
			this.getStates();
			this.dispSecondaryDistTaluka = true
		}



	}


	getUserOfficeDetails() {

		//alert("Dd")

		let post_user_id = localStorage.getItem('post_user_id');
		const body = new HttpParams({
			fromObject: {

				post_user_id: post_user_id,
			}
		});

		this.http.getUserDetail(body).subscribe((resolve: any) => {

			let data1 = resolve;
			// console.log(resolve);
			//Mantralaya.office_id


			for (let key in resolve.data.userDetail.user_office_detail) {

				this.FirstKey = key;
				//   console.log(key)
				break;
			}
			// console.log(this.FirstKey, "===ss");

			if (this.FirstKey == 'Circle') {

				this.divTextshow = false;
				this.divselectshow = true;
				this.projectmasterForm.patchValue({
					project_corporation_id: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "",
					project_region_id: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "",
					project_circle_id: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "",
				})
				this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Circle.office_id, 'Division')

			} else if (this.FirstKey == 'Division') {
				// console.log(this.divTextshow + "asasssssssssssssssss")
				this.projectmasterForm.patchValue({
					project_corporation_id: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "",
					project_region_id: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "",
					project_circle_id: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "",
					project_division_id: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "",
				});
				this.divTextshow = true;
				this.divselectshow = false;

				///console.log(resolve.data.userDetail.user_office_detail.Division.office_id,"====")

				this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Division.office_id, 'Sub Division')






			}
			if (data1.status == 0) {
				return false;

			} else {
				if (resolve.data) {

					// console.log(resolve.data.userDetail.user_office_detail.Corporation, "===");
					// console.log(resolve.data);
					const subDivision = "Sub Division";
					this.office_details_en = {
						circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "",
						subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "",
						Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : " ",
						Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : " ",
						Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : " ",
						Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "",
						//  Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : ""
					}

					this.office_details_mr = {
						circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "",
						subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "",
						Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : " ",
						Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : " ",
						Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : " ",
						Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "",
						// Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : ""
					}

				} else
					return false;
			}

		},
			(err) => {

				console.log(err);


			}
		)

	}
	// getUserOfficeDetails() {

	// let data1 = resolve;


	// 		for (let key in resolve.data.userDetail.user_office_detail) {

	// 			this.FirstKey = key;
	// 			break;
	// 		}

	// 		console.log(this.FirstKey);


	// 		if (this.FirstKey == 'Circle') {

	// 			this.divTextshow = false;
	// 			this.divselectshow = true;
	// 			this.projectmasterForm.patchValue({
	// 				// project_corporation_id: resolve.data.userDetail.user_office_detail.Corporation.office_id,
	// 				project_region_id: resolve.data.userDetail.user_office_detail.Region.office_id,
	// 				project_circle_id: resolve.data.userDetail.user_office_detail.Circle.office_id
	// 			})
	// 			this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Circle.office_id, 'Division')

	// 		} else if (this.FirstKey == 'Division') {

	// 			//console.log("ijjjjj");

	// 			this.divTextshow = true;
	// 			this.divselectshow = false;
	// 			//this.MantraId = resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_id : " ";
	// 			this.CirclePrimaryId = resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_id : "";
	// 			//this.subDivisId = resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_id : "";
	// 			this.RegionPrimaryId = resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_id : "";
	// 			this.DivisionPrimaryId = resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_id : "";
	// 			this.CorporationPrimaryId = resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_id : "";
	// 			//	this.SectionId = resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_id : "";


	// 			//console.log()
	// 			//shreya 
	// 			//project_corporation_id: this.CorporationPrimaryId,
	// 			this.projectmasterForm.patchValue({
	// 				project_division_id: this.DivisionPrimaryId,
	// 				project_region_id: this.RegionPrimaryId,
	// 				project_circle_id: this.CirclePrimaryId,
	// 				project_corporation_id: this.CorporationPrimaryId,
	// 				project_subdivision_id: this.getPorjectMasterDetails.project_subdivision_id,
	// 				project_section_id:  this.getPorjectMasterDetails.project_section_id
	// 			})

	// 			this.getOfficesPrimary(resolve.data.userDetail.user_office_detail.Division.office_id, 'Sub Division')

	// 		}
	// 		if (data1.status == 0) {
	// 			return false;

	// 		} else {





	// 			if (resolve.data) {

	// 				// console.log(resolve.data.userDetail.user_office_detail.Corporation, "===");
	// 				//	console.log(resolve.data);
	// 				const subDivision = "Sub Division";
	// 				this.office_details_en = {
	// 					circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name : "",
	// 					//subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name : "",
	// 					Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name : " ",
	// 					Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name : " ",
	// 					Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name : " ",
	// 					Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name : "",
	// 					//  Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name : ""
	// 				}

	// 				this.office_details_mr = {
	// 					circle: resolve.data.userDetail.user_office_detail.Circle ? resolve.data.userDetail.user_office_detail.Circle.office_name_marathi : "",
	// 					//subDivis: resolve.data.userDetail.user_office_detail[subDivision] ? resolve.data.userDetail.user_office_detail[subDivision].office_name_marathi : "",
	// 					Mantralaya: resolve.data.userDetail.user_office_detail.Mantralaya ? resolve.data.userDetail.user_office_detail.Mantralaya.office_name_marathi : " ",
	// 					Region: resolve.data.userDetail.user_office_detail.Region ? resolve.data.userDetail.user_office_detail.Region.office_name_marathi : " ",
	// 					Division: resolve.data.userDetail.user_office_detail.Division ? resolve.data.userDetail.user_office_detail.Division.office_name_marathi : " ",
	// 					Corporation: resolve.data.userDetail.user_office_detail.Corporation ? resolve.data.userDetail.user_office_detail.Corporation.office_name_marathi : "",
	// 					// Section: resolve.data.userDetail.user_office_detail.Section ? resolve.data.userDetail.user_office_detail.Section.office_name_marathi : ""
	// 				}




	// 				//   console.log(this.office_details_en)


	// 			} else
	// 				return false;
	// 		}

	// 		this.checkUserAccess();

	// 	},
	// 		(err) => {
	// 			console.log(err);
	// 		}
	// 	)

	// }

	getCorporateOffices(val) {



		let parent_id = '1'
		const body = new HttpParams({
			fromObject: {
				parent_id: parent_id,
				office_type: 'Corporation'
			}
		});
		this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
			let data1 = resolve;
			if (data1.status == "1") {

				this.secondary_corporation = data1.data;

				if (val == 'edit') {
					if (this.route.snapshot.params['id']) {
						var mySecondaryCorporates = [];
						var selectedSecondaryCorporates = []
						mySecondaryCorporates = JSON.parse(this.getPorjectMasterDetails.project_secondary_corporation_id)
						for (let i = 0; i < resolve.data.length; i++) {
							for (let j = 0; j < mySecondaryCorporates.length; j++) {
								if (resolve.data[i].office_id == mySecondaryCorporates[j]) {
									selectedSecondaryCorporates.push(resolve.data[i])
								}
							}
						}

						//console.log(selectedSecondaryCorporates);
						this.CorporatesEditArray = selectedSecondaryCorporates;
						this.getOffices(mySecondaryCorporates, "Region", 'edit')
					}
				}

			}
			else {
			}

		});
	}

	getOffices(parent_id, type, isEdit) {
		const body = new HttpParams({
			fromObject: {
				parent_id: parent_id,
				office_type: type
			}
		});
		this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
			let data1 = resolve
			if (data1.status == "1") {
				if (type == "Region") {
					this.secondary_Region = data1.data;

					if (isEdit == 'edit') {

						if (this.route.snapshot.params['id']) {
							var mySecondaryRegions = [];
							var selectedSecondaryRegion = []
							mySecondaryRegions = JSON.parse(this.getPorjectMasterDetails.project_secondary_region_id)
							for (let i = 0; i < resolve.data.length; i++) {
								for (let j = 0; j < mySecondaryRegions.length; j++) {
									if (resolve.data[i].office_id == mySecondaryRegions[j]) {
										selectedSecondaryRegion.push(resolve.data[i])
									}
								}
							}

							//	console.log(selectedSecondaryCorporates);
							this.regionEditArray = selectedSecondaryRegion;
							this.getOffices(mySecondaryRegions, "Circle", 'edit')
						}



					}


					// console.log(this.secondary_Region);
				}
				else if (type == "Circle") {
					this.secondary_Circle = data1.data

					if (isEdit == 'edit') {

						if (this.route.snapshot.params['id']) {
							var mySecondaryCircles = [];
							var selectedSecondaryCircles = []
							mySecondaryCircles = JSON.parse(this.getPorjectMasterDetails.project_secondary_circle_id)
							for (let i = 0; i < resolve.data.length; i++) {
								for (let j = 0; j < mySecondaryCircles.length; j++) {
									if (resolve.data[i].office_id == mySecondaryCircles[j]) {
										selectedSecondaryCircles.push(resolve.data[i])
									}
								}
							}

							//	console.log(selectedSecondaryCorporates);
							this.circleEditArray = selectedSecondaryCircles;

							// console.log(this.circleEditArray);
							this.getOffices(mySecondaryCircles, "Division", 'edit')
						}



					}
				}
				else if (type == "Division") {
					this.secondary_Division = data1.data;


					if (isEdit == 'edit') {

						if (this.route.snapshot.params['id']) {
							var mySecondaryDivisions = [];
							var selectedSecondaryDivisions = []
							mySecondaryDivisions = JSON.parse(this.getPorjectMasterDetails.project_secondary_division_id)
							for (let i = 0; i < resolve.data.length; i++) {
								for (let j = 0; j < mySecondaryDivisions.length; j++) {
									if (resolve.data[i].office_id == mySecondaryDivisions[j]) {
										selectedSecondaryDivisions.push(resolve.data[i])
									}
								}
							}
							this.DivEditArray = selectedSecondaryDivisions;
							this.getOffices(mySecondaryDivisions, "Sub Division", 'edit')
						}



					}
				}
				else if (type == "Sub Division") {
					this.secondary_SubDivision = data1.data;

					if (isEdit == 'edit') {

						if (this.route.snapshot.params['id']) {
							var mySecondarySubDivisions = [];
							var selectedSecondarySubDivisions = []
							mySecondarySubDivisions = JSON.parse(this.getPorjectMasterDetails.project_secondary_subdivison_id)
							for (let i = 0; i < resolve.data.length; i++) {
								for (let j = 0; j < mySecondarySubDivisions.length; j++) {
									if (resolve.data[i].office_id == mySecondarySubDivisions[j]) {
										selectedSecondarySubDivisions.push(resolve.data[i])
									}
								}
							}
							this.SubDivEditArray = selectedSecondarySubDivisions;
							this.getOffices(mySecondarySubDivisions, "Section", 'edit')
						}



					}
				}
				else if (type == "Section") {
					this.secondary_Section = data1.data;



					if (isEdit == 'edit') {

						if (this.route.snapshot.params['id']) {
							var mySecondarySections = [];
							var selectedSecondarySections = []
							mySecondarySections = JSON.parse(this.getPorjectMasterDetails.project_secondary_section_id)
							for (let i = 0; i < resolve.data.length; i++) {
								for (let j = 0; j < mySecondarySections.length; j++) {
									if (resolve.data[i].office_id == mySecondarySections[j]) {
										selectedSecondarySections.push(resolve.data[i])
									}
								}
							}
							this.SecEditArray = selectedSecondarySections;
							//this.getOffices(mySecondarySubDivisions,"Section",'edit')
						}



					}

				}
			}
		})
	}

	getOfficesPrimary(parent_id, type) {
		const body = new HttpParams({
			fromObject: {
				parent_id: parent_id,
				office_type: type
			}
		});
		this.http.getOfficeDetailOnchange(body).subscribe((resolve: any) => {
			let data1 = resolve

			///console.log(resolve);
			if (data1.status == "1") {
				if (type == "Division") {

					this.primary_Division = data1.data;
					if (this.route.snapshot.params['id']) {
						this.projectmasterForm.patchValue({
							project_division_id: this.getPorjectMasterDetails.project_division_id
						})
						this.getOfficesPrimary(this.getPorjectMasterDetails.project_division_id, "Sub Division")
					}
				}
				else if (type == "Sub Division") {
					this.primary_SubDivision = data1.data
					if (this.route.snapshot.params['id'] && this.projectmasterForm.value.project_subdivision_id) {
						this.projectmasterForm.patchValue({
							project_subdivision_id: this.getPorjectMasterDetails.project_subdivision_id
						})
						this.getOfficesPrimary(this.getPorjectMasterDetails.project_subdivision_id, "Section")
					}
				}
				else if (type == "Section") {
					this.primary_Section = data1.data
					if (this.route.snapshot.params['id']) {
						this.projectmasterForm.patchValue({
							project_section_id: this.getPorjectMasterDetails.project_section_id
						})
					}
				}
			}
		})
	}

	onItemSelect(evt, type) {
		// console.log(evt.office_id + "" + type)
		if (type == "Region") {
			this.selectedSecondaryRegionIds.push((evt.office_id).toString())
			// console.log(this.selectedSecondaryRegionIds)
			this.getOffices(this.selectedSecondaryRegionIds, "Circle", "noedit")
		}
		else if (type == "Circle") {
			this.selectedSecondaryCircleIds.push((evt.office_id).toString())
			this.getOffices(this.selectedSecondaryCircleIds, "Division", "noedit")
		}
		else if (type == "Division") {
			this.selectedSecondaryDivisionIds.push((evt.office_id).toString())
			this.getOffices(this.selectedSecondaryDivisionIds, "Sub Division", "noedit")

		}
		else if (type == "Sub Division") {
			this.selectedSecondarySubDivisionIds.push((evt.office_id).toString())
			this.getOffices(this.selectedSecondarySubDivisionIds, "Section", "noedit")
		}
		else if (type == "Section") {
			this.selectedSecondarySectionIds.push((evt.office_id).toString())
		}
		else if (type == "Corporation") {
			this.selectedSecondaryCorporationIds.push((evt.office_id).toString())
			this.getOffices(this.selectedSecondaryCorporationIds, "Region", "noedit");
		}
	}

	onItemDeselect(evt, type) {
		// console.log(evt);

		if (type == "Region") {
			const index = this.selectedSecondaryRegionIds.indexOf((evt.office_id).toString())
			this.selectedSecondaryRegionIds.splice(index, 1)
			this.callAllSecondaryArrReload()
		}
		else if (type == "Circle") {
			const index = this.selectedSecondaryCircleIds.indexOf((evt.office_id).toString())
			this.selectedSecondaryCircleIds.splice(index, 1)
			this.callAllSecondaryArrReload()
		}
		else if (type == "Division") {
			const index = this.selectedSecondaryDivisionIds.indexOf((evt.office_id).toString())
			this.selectedSecondaryDivisionIds.splice(index, 1)
			this.callAllSecondaryArrReload()
		}
		else if (type == "Sub Division") {
			const index = this.selectedSecondarySubDivisionIds.indexOf((evt.office_id).toString())
			this.selectedSecondarySubDivisionIds.splice(index, 1)
			this.callAllSecondaryArrReload()
		}
		else if (type == "Section") {
			const index = this.selectedSecondarySectionIds.indexOf((evt.office_id).toString())
			this.selectedSecondarySectionIds.splice(index, 1)
			// console.log(this.selectedSecondarySectionIds);

		}
		else if (type == "Corporation") {
			const index = this.selectedSecondaryCorporationIds.indexOf((evt.office_id).toString())
			this.selectedSecondaryCorporationIds.splice(index, 1)
			//console.log(this.selectedSecondaryCorporationIds);

			this.callAllSecondaryArrReload()

		}


	}

	onItemSelect1(evt, type) {

		if (type == "District") {
			this.selectedSecondaryDistrictIds.push((evt.district_code).toString());
			this.forFamerTalukaSelect(this.selectedSecondaryDistrictIds);
		}
	}
	onItemSelect2(evt, type) {
		if (type == "Taluka") {
			this.selectedSecondaryTalukaIds.push((evt.taluka_code).toString())

		}
	}

	OnDeSelect1(evt, type) {
		if (type == "District") {
			const index = this.selectedSecondaryDistrictIds.indexOf((evt.district_code).toString())
			this.selectedSecondaryDistrictIds.splice(index, 1)
			if (this.selectedSecondaryDistrictIds.length > 0) {
				this.forFamerTalukaSelect(this.selectedSecondaryDistrictIds);
			}
			else {
				this.districtEditArray = []
				this.selectedSecondaryTalukaIds = []
				this.forFamerTalukaArr = []
			}
		}
		else if (type == "taluka") {
			const index = this.selectedSecondaryTalukaIds.indexOf((evt.taluka_code).toString())
			this.selectedSecondaryTalukaIds.splice(index, 1)
			if (this.selectedSecondaryTalukaIds.length == 0) {
				// this.forFamerTalukaSelect(this.selectedSecondaryTalukaIds);
				this.talukaEditArray = []
				this.selectedSecondaryTalukaIds = []
			}
			// console.log(this.selectedSecondaryTalukaIds)
		}
	}

	callAllSecondaryArrReload() {
		this.selectedSecondaryCorporationIds.length > 0 ? this.getOffices(this.selectedSecondaryCorporationIds, "Region", "noedit") : this.secondary_Region = null

		this.selectedSecondaryRegionIds.length > 0 ? this.getOffices(this.selectedSecondaryRegionIds, "Circle", "noedit") : this.secondary_Circle = null

		this.selectedSecondaryCircleIds.length > 0 ? this.getOffices(this.selectedSecondaryCircleIds, "Divison", "noedit") : this.secondary_Division = null

		this.selectedSecondaryDivisionIds.length > 0 ? this.getOffices(this.selectedSecondaryDivisionIds, "Sub Division", "noedit") : this.secondary_SubDivision = null

		this.selectedSecondarySubDivisionIds.length > 0 ? this.getOffices(this.selectedSecondarySubDivisionIds, "Section", "noedit") : this.secondary_SubDivision = null
	}
	finalSubmit() {
		// console.log(this.projectmasterForm.value.project_is_interstate)





		if (this.projectmasterForm.value.project_is_interstate == '2') {
			// console.log(this.selectedSecondaryDistrictIds)
			// console.log(this.selectedSecondaryTalukaIds)
			var secondaryTalukaIds: any = JSON.stringify(this.selectedSecondaryTalukaIds);
			var secondaryDistricts: any = JSON.stringify(this.selectedSecondaryDistrictIds);
			this.projectmasterForm.patchValue({
				project_secondary_district_code: secondaryDistricts,
				project_secondary_taluka_code: secondaryTalukaIds,


			})
			// this.projectmasterForm.value.project_secondary_district_code = secondaryDistricts
			// this.projectmasterForm.value.project_secondary_taluka_code = secondaryTalukaIds
		}

		var secondaryCorporations: any = JSON.stringify(this.selectedSecondaryCorporationIds);
		var secondaryDivision: any = JSON.stringify(this.selectedSecondaryDivisionIds);
		var secondaryCircle: any = JSON.stringify(this.selectedSecondaryCircleIds);
		var secondaryRegionIds: any = JSON.stringify(this.selectedSecondaryRegionIds);
		var secondarySectionIds: any = JSON.stringify(this.selectedSecondarySectionIds);
		var secondarySubDivisionIds: any = JSON.stringify(this.selectedSecondarySubDivisionIds);
		// console.log(secondaryDivision)

		this.projectmasterForm.patchValue({
			project_secondary_corporation_id: secondaryCorporations,
			project_secondary_division_id: secondaryDivision,
			project_secondary_circle_id: secondaryCircle,
			project_secondary_region_id: secondaryRegionIds,
			project_secondary_section_id: secondarySectionIds,
			project_secondary_subdivison_id: secondarySubDivisionIds,
			project_added_by_user_office_type: this.FirstKey,
			project_user_type: localStorage.getItem('user_type'),
			added_by: localStorage.getItem('post_user_id')
		})

		this.projectmasterForm.value.project_secondary_division_id = secondaryDivision



		// console.log(this.projectmasterForm.value.project_secondary_division_id);


		// console.log(this.projectmasterForm);
		// return;

		if (this.projectmasterForm.invalid) {
			if (this.selectedlang == 'mr') {

				alert("सर्व माहिती भरणे अनिवार्य आहे.");

			} else {
				alert("All Fields are Mandatory!");

			}
			return false;
		}
		else {
			const body = new HttpParams({
				fromObject: this.projectmasterForm.value
			});
			this.http.createProjectMaster(body).subscribe((resolve: any) => {
				if (resolve.status == true || resolve.status == "1") {
					if (this.language == 'mr') {

						alert("सदर माहिती जतन करण्यात आलेली आहे.");

					} else {
						alert("This information has been saved");
					}
					// console.log(data);
					this.router.navigateByUrl('/ViewprojectMaster')
				}
				else {
					this.sendAlert(resolve.message)
				}
			})
		}
	}

}
