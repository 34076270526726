<div class="dashboardpg">
    <div class="dashboardstruck fullwidth">

        <!-- dashboard left panel start-->
        <!-- <div class="dashbordleft">
            <a class="dashbordlogo fullwidth" href=""><img src="assets/images/dasboardlogo.png" alt="logo" /></a>
         
        </div> -->
        <!-- dashboard left panel over-->
        <!-- dashboard right panel start-->
        <div class="dashbordright">
            <!-- header start-->
            <app-header></app-header>

            <!-- header over-->
            <!-- dashboardbnnr start-->
            <!-- <div class="dashboardbnnr fullwidth">
                    <img class="fullwidth" src="assets/images/innerbanner.jpg" alt="banner" />
                </div>-->
            <!-- dashboardbnnr over-->
            <!-- mid content start-->

            <div class="greenstrip-bot fullwidth">


            </div>

            <div class="mid-content fullwidth formstep verify-detail-page">
                <!-- row start-->
                <div class="row">

                    <div class="ofc-detial">

                        <!-- col-md-12 start-->

                        <div class="col-md-12 recentlistbx">
                            <h2 class="smlheadh2 col-md-12"><span>{{data?.label?.offc_string}} </span></h2>
                            <p>{{data?.label?.form_string}}</p>
                        </div>

                        <div class="stepfrmcmn stepfrm1 col-md-12">
                            <div class="row frmrow">

                                <div class="col-md-4 inptbx" *ngIf="manShow">

                                    <label class="steplbl">{{data?.label?.mantralaya_string}}
                                        <!--<a (click)="editValue(5)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(5)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <span> <input [(ngModel)]="data.office_detail.Mantralaya" disabled
                                            (keyup)="doUpdateOfficeDetails()" class="readonly" type="text"
                                            value="{{this.data.office_detail.Mantralaya}}"></span>

                                </div>

                                <div class="col-md-4 inptbx" *ngIf="corShow">


                                    <label class="steplbl">{{data.label.corporation_string}}
                                        <!-- <a (click)="editValue(6)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(6)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <!--<span class="readonly">{{this.Corporation}}</span>-->
                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.Corporation"
                                            (keyup)="doUpdateOfficeDetails()" type="text"
                                            value="{{this.data.office_detail.Corporation}}"></span>




                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect"
                                        (change)="onChangeValueForOffice('Region',$event)">
                                        <option [ngValue]="null">
                                            {{data.label.please_select}}
                                        </option>

                                        <option *ngFor="let permission of this.CoprationArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>


                                </div>


                                <div class="col-md-4 inptbx" *ngIf="regShow">

                                    <label class="steplbl">{{data.label.region_string}}
                                        <!--<a (click)="editValue(1)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(1)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.Region" (keyup)="doUpdateOfficeDetails()"
                                            type="text" value="{{this.data.office_detail.Region}}"></span>
                                    <!--<span class="readonly">{{this.Region}}</span>-->

                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect1"
                                        (change)="onChangeValueForRegion('Circle',$event)">

                                        <option>

                                            {{data.label.please_select}}
                                        </option>
                                        <option *ngFor="let permission of this.RegionArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>

                                </div>
                                <div class="col-md-4 inptbx" *ngIf="cirShow">
                                    <label class="steplbl">{{data.label.circle_string}}
                                        <!-- <a (click)="editValue(2)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(2)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <!--<span class="readonly"> {{this.circle}}</span>-->
                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.circle" (keyup)="doUpdateOfficeDetails()"
                                            type="text" value="{{this.data.office_detail.circle}}"></span>

                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect2"
                                        (change)="onChangeValueForCircle('Division',$event)">

                                        <option>

                                            {{data.label.please_select}}
                                        </option>
                                        <option *ngFor="let permission of this.CircleArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>

                                </div>
                                <div class="col-md-4 inptbx" *ngIf="divShow">
                                    <label class="steplbl">{{data.label.division_string}}
                                        <!-- <a (click)="editValue(3)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(3)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <!--<span class="readonly">{{this.Division}}</span>-->
                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.Division" (keyup)="doUpdateOfficeDetails()"
                                            type="text" value="{{this.data.office_detail.Division}}"></span>


                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect4"
                                        (change)="onChangeValueForDiv('Sub Division',$event)">
                                        <option>

                                            {{data.label.please_select}}
                                        </option>
                                        <option *ngFor="let permission of this.DivisionArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>

                                </div>
                                <div class="col-md-4 inptbx" *ngIf="subShow">
                                    <label class="steplbl">{{data.label.sub_division_string}}
                                        <!-- <a (click)="editValue(4)" class="fa fa-check" aria-hidden="true"></a>
                                        <a (click)="noteditValue(4)" class="fa fa-times" aria-hidden="true"></a>-->
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>
                                    <!--<span class="readonly">{{this.subDivis}}</span>-->
                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.subDivis" (keyup)="doUpdateOfficeDetails()"
                                            type="text" value="{{this.data.office_detail.subDivis}}"></span>

                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect3"
                                        (change)="onChangeValueForSection('Section',$event)">
                                        <option>

                                            {{data.label.please_select}}
                                        </option>

                                        <option *ngFor="let permission of this.SubDivArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>

                                </div>


                                <div class="col-md-4 inptbx" *ngIf="sectionShow">
                                    <label class="steplbl">{{data.label.section_string}}
                                        <span aria-hidden="true"
                                        style="color: red;">*</span>
                                    </label>

                                    <span> <input class="readonly" *ngIf="show" disabled
                                            [(ngModel)]="data.office_detail.Section" type="text"
                                            value="{{this.data.office_detail.Section}}"></span>

                                    <select class="stepinpt" *ngIf="!show" [(ngModel)]="mySelect5"
                                        (change)="onChangeValueLast($event)">
                                        <option>

                                            {{data.label.please_select}}
                                        </option>

                                        <option *ngFor="let permission of this.SectionArr"
                                            [value]="this.permission.office_id">

                                            {{permission.office_name}}
                                        </option>
                                    </select>

                                </div>






                            </div>
                        </div>



                        <!-- form step1 over-->


                        <!-- <a  (click)="getDashboard()"class="loginsbmtbtn"  type="submit" value="Submit">Submit</a>-->
                        <!--<div class="dashbordbgimg text-center"><img src="assets/images/chartbig.jpg" alt="image" /></div>
                           -->

                        <!-- col-md-6 over-->

                        <div class="col-md-12 inptbx" *ngIf="!disabled">

                            {{data.label.request_string}}
                        </div>
                        <div class="col-md-12 inptbx">

                            <input *ngIf="ifEdit" type="button" class="stepgreenbtn ofc-hide" type="button"
                                (click)="clickMethod('name')" value="{{data.label.save}}" />

                            <button *ngIf="ifEdit" class="stepgreenbtn ofc-hide" type="button"
                                (click)="cancel()">{{data.label.cancel_string}}</button>


                            <input [disabled]="!disabled" *ngIf="!ifEdit" type="button" class="stepgreenbtn ofc-hide"
                                type="button" (click)="NextprojectScreen('name')" value="{{data?.label?.confirm}}" />

                            <button *ngIf="!ifEdit" [disabled]="!disabled" class="stepgreenbtn ofc-hide" type="button"
                                (click)="EditMethod('name')" value="Edit">{{data?.label?.edit_string}}</button>


                        </div>

                    </div>

                    <div class="modal fade very-pop show" id="preModal" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                                    <br />
                                    <button type="button" class="close" data-dismiss="modal"
                                        (click)="close()">&times;</button>
                                </div>
                                <div class="modal-body">


                                    <ul style=" height: 22vh;">
                                        <li>
                                            <div>
                                                {{data.label.request_string}}

                                            </div>
                                        </li>
                                        <li>
                                            <input class="stepgreenbtn" type="Submit" (click)="oknextupdate()"
                                                value="{{data.label.Ok}}" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal fade very-pop show" id="preModal1" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto!important;">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <!-- <h2>{{'Add Main Canal Details' | translate}} </h2> -->
                                    <br />
                                    <button type="button" class="close" data-dismiss="modal"
                                        (click)="closeUp()">&times;</button>
                                </div>
                                <div class="modal-body">


                                    <ul style=" height: 22vh;">
                                        <li>
                                            <div>
                                                {{data.label.confirm_string}}

                                            </div>
                                        </li>
                                        <li>
                                            <input class="stepgreenbtn" type="button" (click)="okupdate()"
                                                value="{{data.label.Ok}}" />

                                            <input class="stepgreenbtn" type="button" (click)="cancelupdate()"
                                                value="{{data.label.cancel_string}}" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- row over-->

                    <!-- footer start-->
                    <footer class="dboardfooter fullwidth">
                        <p>Designed & Developed by <a href="https://cyfuture.com/" target="_blank">Cyfuture</a></p>
                    </footer>
                    <!-- footer over-->

                </div>
                <!-- mid content over-->

            </div>
            <!-- dashboard right panel over-->
        </div>
    </div>